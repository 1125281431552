import { EnvironmentDetailsHeader } from "@/components/environments/EnvironmentDetailsHeader";
import { EnvironmentTaskRuns } from "@/components/environments/EnvironmentTaskRuns";

import { useDocumentTitle } from "@/hooks/use-document-title";
import { type FC } from "react";
import { useParams } from "react-router-dom";

export const EnvironmentTaskRunsPage: FC = () => {
    useDocumentTitle("Task Runs");

    const { environmentId, taskId } = useParams();

    return (
        <div className="flex size-full flex-col gap-4">
            {environmentId && (
                <>
                    <div className="flex flex-col gap-4">
                        <EnvironmentDetailsHeader showActionBar={false} />
                    </div>
                    <EnvironmentTaskRuns environmentId={environmentId} taskId={taskId} />
                </>
            )}
        </div>
    );
};
