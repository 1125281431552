import { useEffect, useMemo, useRef, useState, type FC, type PropsWithChildren } from "react";
import { OnboardingContext } from "@/contexts/OnboardingContext";
import { getPrincipal, onDidChangePrincipal } from "@/principal";
import { useListEnvironments } from "@/queries/environment-queries";
import { useListRunners } from "@/queries/runner-queries";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import {
    OnboardingStepId,
    type OrganizationOnboardingStep,
    type OrganizationOnboardingSteps,
} from "@/hooks/use-onboarding";
import { useMembers } from "@/queries/organization-queries";

/**
 * Hook for computing the initial onboarding state for a user in an organization
 */
function useOrganizationOnboardingSteps(): OrganizationOnboardingSteps {
    const { data: user } = useAuthenticatedUser();
    const { data: remoteRunners, isLoading: isLoadingRemoteRunners } = useListRunners({
        kind: RunnerKind.REMOTE,
        creatorId: user?.id,
    });
    const { data: localRunners, isPending: isPendingLocalRunners } = useListRunners({
        kind: RunnerKind.LOCAL,
        creatorId: user?.id,
    });

    const { data: environments, isLoading: isLoadingEnvironments } = useListEnvironments();
    const { data: members, isLoading: isLoadingMembers } = useMembers();

    const hasUser = !!user?.id;
    const isLoading =
        !hasUser || isLoadingRemoteRunners || isPendingLocalRunners || isLoadingEnvironments || isLoadingMembers;

    const hasLocalRunner = (localRunners?.runners?.length ?? 0) > 0;
    const hasAtLeastOneRemoteRunner = (remoteRunners?.runners?.length ?? 0) > 0;
    const hasAtLeastOneEnvironment = (environments?.environments?.length ?? 0) > 0;
    const hasAtLeastOneOtherMember = (members?.members?.length ?? 0) > 1;

    let hasCompletedHowGitpodWorks = false;
    let hasCompletedSetUpARunner = false;
    let hasCompletedAutomateYourDevEnvironment = false;
    let hasCompletedShare = false;

    if (!isLoading) {
        if (hasAtLeastOneEnvironment) {
            hasCompletedHowGitpodWorks = true;
            hasCompletedSetUpARunner = true;
            hasCompletedAutomateYourDevEnvironment = true;
        } else if (hasLocalRunner) {
            hasCompletedHowGitpodWorks = true;
            hasCompletedSetUpARunner = true;
        } else if (hasAtLeastOneRemoteRunner) {
            hasCompletedHowGitpodWorks = true;
        } else {
            // Nothing is completed
        }

        if (hasAtLeastOneOtherMember) {
            hasCompletedShare = true;
        }
    }

    const steps = [
        {
            id: OnboardingStepId.HowGitpodWorks,
            title: "How Gitpod works",
            completed: hasCompletedHowGitpodWorks,
        },
        {
            id: OnboardingStepId.SetUpARunner,
            title: "Set up a runner",
            completed: hasCompletedSetUpARunner,
        },
        {
            id: OnboardingStepId.AutomateYourDevEnvironment,
            title: "Automate your dev environment",
            completed: hasCompletedAutomateYourDevEnvironment,
        },
        {
            id: OnboardingStepId.ShareGoldenPathWithYourTeam,
            title: "Share with your team",
            completed: hasCompletedShare,
        },
    ];

    // The first non-completed step.
    let defaultActive: OnboardingStepId = OnboardingStepId.HowGitpodWorks;
    for (const step of steps) {
        if (!step.completed) {
            defaultActive = step.id;
            break;
        }
        defaultActive = step.id;
    }

    return {
        isLoading,
        steps,
        defaultActive,
    };
}

export const OnboardingProvider: FC<PropsWithChildren> = ({ children }) => {
    const steps = useOrganizationOnboardingSteps();
    const [inMemorySteps, setInMemorySteps] = useState<OrganizationOnboardingStep[]>(steps.steps);

    // This ensures that we trigger a re-render when the principal changes - this is needed to
    // ensure we re-render when the user is updated.
    const [, setPrincipal] = useState<string | null>(null);
    useEffect(() => {
        return onDidChangePrincipal(() => {
            setPrincipal(getPrincipal());
        });
    }, [setPrincipal]);

    const calledForPrincipal = useRef<string | null>(null);
    useEffect(() => {
        if (calledForPrincipal.current === getPrincipal()) {
            return;
        }
        if (!steps.isLoading) {
            calledForPrincipal.current = getPrincipal();
            setInMemorySteps(steps.steps);
        }
    }, [steps, setInMemorySteps]);

    const complete = useMemo(() => {
        return (step: OnboardingStepId) => {
            setInMemorySteps((prev) => {
                return prev.map((s) => {
                    if (s.id === step) {
                        return { ...s, completed: true };
                    }
                    return s;
                });
            });
        };
    }, []);

    return (
        <OnboardingContext.Provider
            value={{
                steps: {
                    defaultActive: steps.defaultActive,
                    isLoading: steps.isLoading,
                    steps: inMemorySteps,
                },
                complete,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};
