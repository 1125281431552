import { type PropsWithClassName, cn } from "@/components/podkit/lib/cn";
import React from "react";

const Card = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & PropsWithClassName & React.PropsWithChildren
>(({ className, ...props }, ref) => {
    return (
        <div className={cn("rounded-xl border border-border-light p-6", className)} ref={ref}>
            {props.children}
        </div>
    );
});

Card.displayName = "Card";

export { Card };
