import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconPort: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.50002 8C2.50002 11.0375 4.96245 13.5 8.00002 13.5C10.332 13.5 12.325 12.0487 13.1251 10M2.50002 8C2.50002 4.96243 4.96245 2.5 8.00002 2.5C10.332 2.5 12.325 3.95132 13.1251 6M2.50002 8H1.16669M11.5 8C11.5 9.933 9.93302 11.5 8.00002 11.5C6.06703 11.5 4.50002 9.933 4.50002 8C4.50002 6.06701 6.06703 4.5 8.00002 4.5C9.93302 4.5 11.5 6.06701 11.5 8ZM11.5 8H14.8334"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.56098 10.0001C3.56098 13.5562 6.44382 16.4391 10 16.4391C12.7301 16.4391 15.0634 14.74 16.0001 12.3415M3.56098 10.0001C3.56098 6.44388 6.44382 3.56104 10 3.56104C12.7301 3.56104 15.0634 5.26014 16.0001 7.6586M3.56098 10.0001H2M14.0976 10.0001C14.0976 12.2631 12.263 14.0976 10 14.0976C7.73698 14.0976 5.90244 12.2631 5.90244 10.0001C5.90244 7.73704 7.73698 5.9025 10 5.9025C12.263 5.9025 14.0976 7.73704 14.0976 10.0001ZM14.0976 10.0001H18"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.7561 12C4.7561 16.0007 7.9993 19.2439 12 19.2439C15.0714 19.2439 17.6963 17.3324 18.7501 14.6342M4.7561 12C4.7561 7.99931 7.9993 4.7561 12 4.7561C15.0714 4.7561 17.6963 6.6676 18.7501 9.36586M4.7561 12H3M16.6098 12C16.6098 14.5459 14.5459 16.6098 12 16.6098C9.45411 16.6098 7.39024 14.5459 7.39024 12C7.39024 9.45411 9.45411 7.39025 12 7.39025C14.5459 7.39025 16.6098 9.45411 16.6098 12ZM16.6098 12H21"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
