import { Check, Copy } from "lucide-react";
import { useTemporaryState } from "../../../hooks/use-temporary-value";
import { forwardRef, useCallback } from "react";
import { Input } from "@/components/podkit/forms/Input";
import { Button } from "@/components/flexkit/Button";
import type { PropsWithClassName } from "@/components/podkit/lib/cn";

type InputProps = React.ComponentPropsWithoutRef<"input"> & {
    copyable?: boolean;
    value: string;
} & PropsWithClassName;
const CopyableInput = forwardRef<HTMLInputElement, InputProps>(({ value, ...props }, ref) => {
    const [copied, setCopied] = useTemporaryState(false, 2000);

    const handleCopyToClipboard = useCallback(() => {
        void navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
        });
    }, [setCopied, value]);

    return (
        <div className="relative">
            <Input className="pr-12 ring-inset" readOnly value={value} {...props} ref={ref} />
            <div className="absolute inset-y-0 right-2 flex items-center">
                <Button
                    variant={"ghost"}
                    type="button"
                    className="h-8 rounded-lg border-none px-2 py-2 text-content-tertiary hover:text-content-secondary hover:opacity-100"
                    onClick={handleCopyToClipboard}
                    aria-label="Copy to clipboard"
                >
                    {copied ? <Check className="text-green-500" size={16} /> : <Copy size={16} />}
                </Button>
            </div>
        </div>
    );
});
CopyableInput.displayName = "CopyableInput";

export { CopyableInput };
