// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/runner.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { Subject } from "./identity_pb.js";
import { FieldValue } from "./runner_configuration_pb.js";

/**
 * RunnerKind represents the kind of a runner
 *
 * @generated from enum gitpod.v1.RunnerKind
 */
export enum RunnerKind {
  /**
   * Invalid kind
   *
   * @generated from enum value: RUNNER_KIND_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * The runner is a local runner
   *
   * @generated from enum value: RUNNER_KIND_LOCAL = 1;
   */
  LOCAL = 1,

  /**
   * The runner is a remote runner
   *
   * @generated from enum value: RUNNER_KIND_REMOTE = 2;
   */
  REMOTE = 2,

  /**
   * The runner is a system-managed runner that holds shared configuration for local runners.
   * Every organization automatically has one of these runners, and it cannot be deleted nor
   * can new runners of this kind be created.
   * Organization admins can update this runner to change the shared configuration, including:
   * - SCM Integrations. All local runners will use these integrations.
   * - DesiredPhase. Can be set to STOPPED to disable all local runners.
   * This runner cannot be used to run environments.
   *
   * @generated from enum value: RUNNER_KIND_LOCAL_CONFIGURATION = 3;
   */
  LOCAL_CONFIGURATION = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(RunnerKind)
proto3.util.setEnumType(RunnerKind, "gitpod.v1.RunnerKind", [
  { no: 0, name: "RUNNER_KIND_UNSPECIFIED" },
  { no: 1, name: "RUNNER_KIND_LOCAL" },
  { no: 2, name: "RUNNER_KIND_REMOTE" },
  { no: 3, name: "RUNNER_KIND_LOCAL_CONFIGURATION" },
]);

/**
 * @generated from enum gitpod.v1.RunnerReleaseChannel
 */
export enum RunnerReleaseChannel {
  /**
   * Invalid release channel
   *
   * @generated from enum value: RUNNER_RELEASE_CHANNEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * The runner is on the stable release channel
   *
   * @generated from enum value: RUNNER_RELEASE_CHANNEL_STABLE = 1;
   */
  STABLE = 1,

  /**
   * The runner is on the latest release channel
   *
   * @generated from enum value: RUNNER_RELEASE_CHANNEL_LATEST = 2;
   */
  LATEST = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(RunnerReleaseChannel)
proto3.util.setEnumType(RunnerReleaseChannel, "gitpod.v1.RunnerReleaseChannel", [
  { no: 0, name: "RUNNER_RELEASE_CHANNEL_UNSPECIFIED" },
  { no: 1, name: "RUNNER_RELEASE_CHANNEL_STABLE" },
  { no: 2, name: "RUNNER_RELEASE_CHANNEL_LATEST" },
]);

/**
 * RunnerPhase represents the phase a runner is in
 *
 * @generated from enum gitpod.v1.RunnerPhase
 */
export enum RunnerPhase {
  /**
   * Invalid phase
   *
   * @generated from enum value: RUNNER_PHASE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * The runner was created but not yet signed up
   *
   * @generated from enum value: RUNNER_PHASE_CREATED = 1;
   */
  CREATED = 1,

  /**
   * The runner was signed up and is ready to be used, but has not communicated with us yet
   *
   * @generated from enum value: RUNNER_PHASE_INACTIVE = 2;
   */
  INACTIVE = 2,

  /**
   * The runner was signed up and is ready to be used
   *
   * @generated from enum value: RUNNER_PHASE_ACTIVE = 3;
   */
  ACTIVE = 3,

  /**
   * The runner is in the process of being deleted.
   *
   * @generated from enum value: RUNNER_PHASE_DELETING = 4;
   */
  DELETING = 4,

  /**
   * The runner has been deleted.
   *
   * @generated from enum value: RUNNER_PHASE_DELETED = 5;
   */
  DELETED = 5,

  /**
   * The runner is in a degraded state.
   *
   * @generated from enum value: RUNNER_PHASE_DEGRADED = 6;
   */
  DEGRADED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(RunnerPhase)
proto3.util.setEnumType(RunnerPhase, "gitpod.v1.RunnerPhase", [
  { no: 0, name: "RUNNER_PHASE_UNSPECIFIED" },
  { no: 1, name: "RUNNER_PHASE_CREATED" },
  { no: 2, name: "RUNNER_PHASE_INACTIVE" },
  { no: 3, name: "RUNNER_PHASE_ACTIVE" },
  { no: 4, name: "RUNNER_PHASE_DELETING" },
  { no: 5, name: "RUNNER_PHASE_DELETED" },
  { no: 6, name: "RUNNER_PHASE_DEGRADED" },
]);

/**
 * @generated from enum gitpod.v1.RunnerCapability
 */
export enum RunnerCapability {
  /**
   * @generated from enum value: RUNNER_CAPABILITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * RUNNER_CAPABILITY_FETCH_LOCAL_SCM_INTEGRATIONS is used by local runners to indicate they support fetching SCM Integrations.
   *
   * @generated from enum value: RUNNER_CAPABILITY_FETCH_LOCAL_SCM_INTEGRATIONS = 1;
   */
  FETCH_LOCAL_SCM_INTEGRATIONS = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(RunnerCapability)
proto3.util.setEnumType(RunnerCapability, "gitpod.v1.RunnerCapability", [
  { no: 0, name: "RUNNER_CAPABILITY_UNSPECIFIED" },
  { no: 1, name: "RUNNER_CAPABILITY_FETCH_LOCAL_SCM_INTEGRATIONS" },
]);

/**
 * @generated from enum gitpod.v1.RunnerRole
 */
export enum RunnerRole {
  /**
   * @generated from enum value: RUNNER_ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RUNNER_ROLE_ADMIN = 1;
   */
  ADMIN = 1,

  /**
   * @generated from enum value: RUNNER_ROLE_USER = 2;
   */
  USER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(RunnerRole)
proto3.util.setEnumType(RunnerRole, "gitpod.v1.RunnerRole", [
  { no: 0, name: "RUNNER_ROLE_UNSPECIFIED" },
  { no: 1, name: "RUNNER_ROLE_ADMIN" },
  { no: 2, name: "RUNNER_ROLE_USER" },
]);

/**
 * @generated from message gitpod.v1.CreateRunnerRequest
 */
export class CreateRunnerRequest extends Message<CreateRunnerRequest> {
  /**
   * The runner name for humans
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * The runner's kind
   *
   * We deliberately do not validate the input just yet to maintain backwards compatibility
   * with existing local apps.
   *
   * @generated from field: gitpod.v1.RunnerKind kind = 2;
   */
  kind = RunnerKind.UNSPECIFIED;

  /**
   * @generated from field: gitpod.v1.RunnerSpec spec = 3;
   */
  spec?: RunnerSpec;

  constructor(data?: PartialMessage<CreateRunnerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateRunnerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "kind", kind: "enum", T: proto3.getEnumType(RunnerKind) },
    { no: 3, name: "spec", kind: "message", T: RunnerSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunnerRequest {
    return new CreateRunnerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunnerRequest {
    return new CreateRunnerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunnerRequest {
    return new CreateRunnerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunnerRequest | PlainMessage<CreateRunnerRequest> | undefined, b: CreateRunnerRequest | PlainMessage<CreateRunnerRequest> | undefined): boolean {
    return proto3.util.equals(CreateRunnerRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateRunnerResponse
 */
export class CreateRunnerResponse extends Message<CreateRunnerResponse> {
  /**
   * @generated from field: gitpod.v1.Runner runner = 1;
   */
  runner?: Runner;

  /**
   * @generated from field: string access_token = 2;
   */
  accessToken = "";

  constructor(data?: PartialMessage<CreateRunnerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateRunnerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner", kind: "message", T: Runner },
    { no: 2, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunnerResponse {
    return new CreateRunnerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunnerResponse {
    return new CreateRunnerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunnerResponse {
    return new CreateRunnerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunnerResponse | PlainMessage<CreateRunnerResponse> | undefined, b: CreateRunnerResponse | PlainMessage<CreateRunnerResponse> | undefined): boolean {
    return proto3.util.equals(CreateRunnerResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetRunnerRequest
 */
export class GetRunnerRequest extends Message<GetRunnerRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  constructor(data?: PartialMessage<GetRunnerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetRunnerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRunnerRequest {
    return new GetRunnerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRunnerRequest {
    return new GetRunnerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRunnerRequest {
    return new GetRunnerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRunnerRequest | PlainMessage<GetRunnerRequest> | undefined, b: GetRunnerRequest | PlainMessage<GetRunnerRequest> | undefined): boolean {
    return proto3.util.equals(GetRunnerRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetRunnerResponse
 */
export class GetRunnerResponse extends Message<GetRunnerResponse> {
  /**
   * @generated from field: gitpod.v1.Runner runner = 1;
   */
  runner?: Runner;

  constructor(data?: PartialMessage<GetRunnerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetRunnerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner", kind: "message", T: Runner },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRunnerResponse {
    return new GetRunnerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRunnerResponse {
    return new GetRunnerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRunnerResponse {
    return new GetRunnerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRunnerResponse | PlainMessage<GetRunnerResponse> | undefined, b: GetRunnerResponse | PlainMessage<GetRunnerResponse> | undefined): boolean {
    return proto3.util.equals(GetRunnerResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnersRequest
 */
export class ListRunnersRequest extends Message<ListRunnersRequest> {
  /**
   * pagination contains the pagination options for listing runners
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * @generated from field: gitpod.v1.ListRunnersRequest.Filter filter = 2;
   */
  filter?: ListRunnersRequest_Filter;

  constructor(data?: PartialMessage<ListRunnersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListRunnersRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnersRequest {
    return new ListRunnersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnersRequest {
    return new ListRunnersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnersRequest {
    return new ListRunnersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnersRequest | PlainMessage<ListRunnersRequest> | undefined, b: ListRunnersRequest | PlainMessage<ListRunnersRequest> | undefined): boolean {
    return proto3.util.equals(ListRunnersRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnersRequest.Filter
 */
export class ListRunnersRequest_Filter extends Message<ListRunnersRequest_Filter> {
  /**
   * creator_ids filters the response to only runner created by specified users
   *
   * @generated from field: repeated string creator_ids = 1;
   */
  creatorIds: string[] = [];

  /**
   * kinds filters the response to only runners of the specified kinds
   *
   * @generated from field: repeated gitpod.v1.RunnerKind kinds = 2;
   */
  kinds: RunnerKind[] = [];

  constructor(data?: PartialMessage<ListRunnersRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnersRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "kinds", kind: "enum", T: proto3.getEnumType(RunnerKind), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnersRequest_Filter {
    return new ListRunnersRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnersRequest_Filter {
    return new ListRunnersRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnersRequest_Filter {
    return new ListRunnersRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnersRequest_Filter | PlainMessage<ListRunnersRequest_Filter> | undefined, b: ListRunnersRequest_Filter | PlainMessage<ListRunnersRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListRunnersRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnersResponse
 */
export class ListRunnersResponse extends Message<ListRunnersResponse> {
  /**
   * pagination contains the pagination options for listing runners
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * The runners registered in the scope
   *
   * @generated from field: repeated gitpod.v1.Runner runners = 2;
   */
  runners: Runner[] = [];

  constructor(data?: PartialMessage<ListRunnersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "runners", kind: "message", T: Runner, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnersResponse {
    return new ListRunnersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnersResponse {
    return new ListRunnersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnersResponse {
    return new ListRunnersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnersResponse | PlainMessage<ListRunnersResponse> | undefined, b: ListRunnersResponse | PlainMessage<ListRunnersResponse> | undefined): boolean {
    return proto3.util.equals(ListRunnersResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerRequest
 */
export class UpdateRunnerRequest extends Message<UpdateRunnerRequest> {
  /**
   * runner_id specifies which runner to be updated.
   *
   * +required
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * The runner's name which is shown to users
   *
   * @generated from field: optional string name = 2;
   */
  name?: string;

  /**
   * @generated from field: optional gitpod.v1.UpdateRunnerRequest.Spec spec = 3;
   */
  spec?: UpdateRunnerRequest_Spec;

  constructor(data?: PartialMessage<UpdateRunnerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "spec", kind: "message", T: UpdateRunnerRequest_Spec, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerRequest {
    return new UpdateRunnerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerRequest {
    return new UpdateRunnerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerRequest {
    return new UpdateRunnerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerRequest | PlainMessage<UpdateRunnerRequest> | undefined, b: UpdateRunnerRequest | PlainMessage<UpdateRunnerRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerRequest.RunnerConfiguration
 */
export class UpdateRunnerRequest_RunnerConfiguration extends Message<UpdateRunnerRequest_RunnerConfiguration> {
  /**
   * The release channel the runner is on
   *
   * @generated from field: optional gitpod.v1.RunnerReleaseChannel release_channel = 1;
   */
  releaseChannel?: RunnerReleaseChannel;

  /**
   * auto_update indicates whether the runner should automatically update itself.
   *
   * @generated from field: optional bool auto_update = 2;
   */
  autoUpdate?: boolean;

  constructor(data?: PartialMessage<UpdateRunnerRequest_RunnerConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerRequest.RunnerConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "release_channel", kind: "enum", T: proto3.getEnumType(RunnerReleaseChannel), opt: true },
    { no: 2, name: "auto_update", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerRequest_RunnerConfiguration {
    return new UpdateRunnerRequest_RunnerConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerRequest_RunnerConfiguration {
    return new UpdateRunnerRequest_RunnerConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerRequest_RunnerConfiguration {
    return new UpdateRunnerRequest_RunnerConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerRequest_RunnerConfiguration | PlainMessage<UpdateRunnerRequest_RunnerConfiguration> | undefined, b: UpdateRunnerRequest_RunnerConfiguration | PlainMessage<UpdateRunnerRequest_RunnerConfiguration> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerRequest_RunnerConfiguration, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerRequest.Spec
 */
export class UpdateRunnerRequest_Spec extends Message<UpdateRunnerRequest_Spec> {
  /**
   * @generated from field: optional gitpod.v1.UpdateRunnerRequest.RunnerConfiguration configuration = 1;
   */
  configuration?: UpdateRunnerRequest_RunnerConfiguration;

  /**
   * desired_phase can currently only be updated on local-configuration runners,
   * to toggle whether local runners are allowed for running environments in the
   * organization.
   * Set to:
   * - ACTIVE to enable local runners.
   * - INACTIVE to disable all local runners. Existing local runners and their
   *   environments will stop, and cannot be started again until the desired_phase
   *   is set to ACTIVE. Use this carefully, as it will affect all users in the
   *   organization who use local runners.
   *
   * @generated from field: optional gitpod.v1.RunnerPhase desired_phase = 2;
   */
  desiredPhase?: RunnerPhase;

  constructor(data?: PartialMessage<UpdateRunnerRequest_Spec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerRequest.Spec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "configuration", kind: "message", T: UpdateRunnerRequest_RunnerConfiguration, opt: true },
    { no: 2, name: "desired_phase", kind: "enum", T: proto3.getEnumType(RunnerPhase), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerRequest_Spec {
    return new UpdateRunnerRequest_Spec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerRequest_Spec {
    return new UpdateRunnerRequest_Spec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerRequest_Spec {
    return new UpdateRunnerRequest_Spec().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerRequest_Spec | PlainMessage<UpdateRunnerRequest_Spec> | undefined, b: UpdateRunnerRequest_Spec | PlainMessage<UpdateRunnerRequest_Spec> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerRequest_Spec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerResponse
 */
export class UpdateRunnerResponse extends Message<UpdateRunnerResponse> {
  constructor(data?: PartialMessage<UpdateRunnerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerResponse {
    return new UpdateRunnerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerResponse {
    return new UpdateRunnerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerResponse {
    return new UpdateRunnerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerResponse | PlainMessage<UpdateRunnerResponse> | undefined, b: UpdateRunnerResponse | PlainMessage<UpdateRunnerResponse> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteRunnerRequest
 */
export class DeleteRunnerRequest extends Message<DeleteRunnerRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * force indicates whether the runner should be deleted forcefully.
   * When force deleting a Runner, all Environments on the runner are also force deleted and
   * regular Runner lifecycle is not respected.
   * Force deleting can result in data loss.
   *
   * @generated from field: bool force = 2;
   */
  force = false;

  constructor(data?: PartialMessage<DeleteRunnerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteRunnerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRunnerRequest {
    return new DeleteRunnerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRunnerRequest {
    return new DeleteRunnerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRunnerRequest {
    return new DeleteRunnerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRunnerRequest | PlainMessage<DeleteRunnerRequest> | undefined, b: DeleteRunnerRequest | PlainMessage<DeleteRunnerRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRunnerRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteRunnerResponse
 */
export class DeleteRunnerResponse extends Message<DeleteRunnerResponse> {
  constructor(data?: PartialMessage<DeleteRunnerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteRunnerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRunnerResponse {
    return new DeleteRunnerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRunnerResponse {
    return new DeleteRunnerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRunnerResponse {
    return new DeleteRunnerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRunnerResponse | PlainMessage<DeleteRunnerResponse> | undefined, b: DeleteRunnerResponse | PlainMessage<DeleteRunnerResponse> | undefined): boolean {
    return proto3.util.equals(DeleteRunnerResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateRunnerTokenRequest
 */
export class CreateRunnerTokenRequest extends Message<CreateRunnerTokenRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  constructor(data?: PartialMessage<CreateRunnerTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateRunnerTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunnerTokenRequest {
    return new CreateRunnerTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunnerTokenRequest {
    return new CreateRunnerTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunnerTokenRequest {
    return new CreateRunnerTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunnerTokenRequest | PlainMessage<CreateRunnerTokenRequest> | undefined, b: CreateRunnerTokenRequest | PlainMessage<CreateRunnerTokenRequest> | undefined): boolean {
    return proto3.util.equals(CreateRunnerTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateRunnerTokenResponse
 */
export class CreateRunnerTokenResponse extends Message<CreateRunnerTokenResponse> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  constructor(data?: PartialMessage<CreateRunnerTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateRunnerTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunnerTokenResponse {
    return new CreateRunnerTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunnerTokenResponse {
    return new CreateRunnerTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunnerTokenResponse {
    return new CreateRunnerTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunnerTokenResponse | PlainMessage<CreateRunnerTokenResponse> | undefined, b: CreateRunnerTokenResponse | PlainMessage<CreateRunnerTokenResponse> | undefined): boolean {
    return proto3.util.equals(CreateRunnerTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ParseContextURLRequest
 */
export class ParseContextURLRequest extends Message<ParseContextURLRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * @generated from field: string context_url = 2;
   */
  contextUrl = "";

  constructor(data?: PartialMessage<ParseContextURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ParseContextURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "context_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParseContextURLRequest {
    return new ParseContextURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParseContextURLRequest {
    return new ParseContextURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParseContextURLRequest {
    return new ParseContextURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ParseContextURLRequest | PlainMessage<ParseContextURLRequest> | undefined, b: ParseContextURLRequest | PlainMessage<ParseContextURLRequest> | undefined): boolean {
    return proto3.util.equals(ParseContextURLRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ParseContextURLResponse
 */
export class ParseContextURLResponse extends Message<ParseContextURLResponse> {
  /**
   * @generated from field: string original_context_url = 1;
   */
  originalContextUrl = "";

  /**
   * @generated from field: gitpod.v1.ParseContextURLResponse.GitContext git = 2;
   */
  git?: ParseContextURLResponse_GitContext;

  constructor(data?: PartialMessage<ParseContextURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ParseContextURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "original_context_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "git", kind: "message", T: ParseContextURLResponse_GitContext },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParseContextURLResponse {
    return new ParseContextURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParseContextURLResponse {
    return new ParseContextURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParseContextURLResponse {
    return new ParseContextURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ParseContextURLResponse | PlainMessage<ParseContextURLResponse> | undefined, b: ParseContextURLResponse | PlainMessage<ParseContextURLResponse> | undefined): boolean {
    return proto3.util.equals(ParseContextURLResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ParseContextURLResponse.GitContext
 */
export class ParseContextURLResponse_GitContext extends Message<ParseContextURLResponse_GitContext> {
  /**
   * @generated from field: string clone_url = 1;
   */
  cloneUrl = "";

  /**
   * @generated from field: string branch = 2;
   */
  branch = "";

  /**
   * @generated from field: string commit = 3;
   */
  commit = "";

  /**
   * @generated from field: string host = 4;
   */
  host = "";

  /**
   * @generated from field: string owner = 5;
   */
  owner = "";

  /**
   * @generated from field: string repo = 6;
   */
  repo = "";

  /**
   * @generated from field: string upstream_remote_url = 7;
   */
  upstreamRemoteUrl = "";

  constructor(data?: PartialMessage<ParseContextURLResponse_GitContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ParseContextURLResponse.GitContext";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "clone_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "branch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "repo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "upstream_remote_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParseContextURLResponse_GitContext {
    return new ParseContextURLResponse_GitContext().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParseContextURLResponse_GitContext {
    return new ParseContextURLResponse_GitContext().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParseContextURLResponse_GitContext {
    return new ParseContextURLResponse_GitContext().fromJsonString(jsonString, options);
  }

  static equals(a: ParseContextURLResponse_GitContext | PlainMessage<ParseContextURLResponse_GitContext> | undefined, b: ParseContextURLResponse_GitContext | PlainMessage<ParseContextURLResponse_GitContext> | undefined): boolean {
    return proto3.util.equals(ParseContextURLResponse_GitContext, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ParseContextURLPreconditionFailureDetails
 */
export class ParseContextURLPreconditionFailureDetails extends Message<ParseContextURLPreconditionFailureDetails> {
  /**
   * @generated from field: string authentication_required_url = 1;
   */
  authenticationRequiredUrl = "";

  /**
   * @generated from field: string scm_id = 2;
   */
  scmId = "";

  constructor(data?: PartialMessage<ParseContextURLPreconditionFailureDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ParseContextURLPreconditionFailureDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "authentication_required_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scm_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParseContextURLPreconditionFailureDetails {
    return new ParseContextURLPreconditionFailureDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParseContextURLPreconditionFailureDetails {
    return new ParseContextURLPreconditionFailureDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParseContextURLPreconditionFailureDetails {
    return new ParseContextURLPreconditionFailureDetails().fromJsonString(jsonString, options);
  }

  static equals(a: ParseContextURLPreconditionFailureDetails | PlainMessage<ParseContextURLPreconditionFailureDetails> | undefined, b: ParseContextURLPreconditionFailureDetails | PlainMessage<ParseContextURLPreconditionFailureDetails> | undefined): boolean {
    return proto3.util.equals(ParseContextURLPreconditionFailureDetails, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CheckAuthenticationForHostRequest
 */
export class CheckAuthenticationForHostRequest extends Message<CheckAuthenticationForHostRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * @generated from field: string host = 2;
   */
  host = "";

  constructor(data?: PartialMessage<CheckAuthenticationForHostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CheckAuthenticationForHostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckAuthenticationForHostRequest {
    return new CheckAuthenticationForHostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckAuthenticationForHostRequest {
    return new CheckAuthenticationForHostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckAuthenticationForHostRequest {
    return new CheckAuthenticationForHostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckAuthenticationForHostRequest | PlainMessage<CheckAuthenticationForHostRequest> | undefined, b: CheckAuthenticationForHostRequest | PlainMessage<CheckAuthenticationForHostRequest> | undefined): boolean {
    return proto3.util.equals(CheckAuthenticationForHostRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CheckAuthenticationForHostResponse
 */
export class CheckAuthenticationForHostResponse extends Message<CheckAuthenticationForHostResponse> {
  /**
   * @generated from field: bool authenticated = 1;
   */
  authenticated = false;

  /**
   * @generated from field: string authentication_url = 2;
   */
  authenticationUrl = "";

  /**
   * @generated from field: bool pat_supported = 3;
   */
  patSupported = false;

  /**
   * @generated from field: string scm_id = 4;
   */
  scmId = "";

  constructor(data?: PartialMessage<CheckAuthenticationForHostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CheckAuthenticationForHostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "authenticated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "authentication_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pat_supported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "scm_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckAuthenticationForHostResponse {
    return new CheckAuthenticationForHostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckAuthenticationForHostResponse {
    return new CheckAuthenticationForHostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckAuthenticationForHostResponse {
    return new CheckAuthenticationForHostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckAuthenticationForHostResponse | PlainMessage<CheckAuthenticationForHostResponse> | undefined, b: CheckAuthenticationForHostResponse | PlainMessage<CheckAuthenticationForHostResponse> | undefined): boolean {
    return proto3.util.equals(CheckAuthenticationForHostResponse, a, b);
  }
}

/**
 * ListRunnerPoliciesRequest lists policies for a project.
 *
 * @generated from message gitpod.v1.ListRunnerPoliciesRequest
 */
export class ListRunnerPoliciesRequest extends Message<ListRunnerPoliciesRequest> {
  /**
   * pagination contains the pagination options for listing project policies
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * runner_id specifies the project identifier
   *
   * @generated from field: string runner_id = 2;
   */
  runnerId = "";

  constructor(data?: PartialMessage<ListRunnerPoliciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerPoliciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerPoliciesRequest {
    return new ListRunnerPoliciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerPoliciesRequest {
    return new ListRunnerPoliciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerPoliciesRequest {
    return new ListRunnerPoliciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerPoliciesRequest | PlainMessage<ListRunnerPoliciesRequest> | undefined, b: ListRunnerPoliciesRequest | PlainMessage<ListRunnerPoliciesRequest> | undefined): boolean {
    return proto3.util.equals(ListRunnerPoliciesRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerPoliciesResponse
 */
export class ListRunnerPoliciesResponse extends Message<ListRunnerPoliciesResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.RunnerPolicy policies = 2;
   */
  policies: RunnerPolicy[] = [];

  constructor(data?: PartialMessage<ListRunnerPoliciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerPoliciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "policies", kind: "message", T: RunnerPolicy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerPoliciesResponse {
    return new ListRunnerPoliciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerPoliciesResponse {
    return new ListRunnerPoliciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerPoliciesResponse {
    return new ListRunnerPoliciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerPoliciesResponse | PlainMessage<ListRunnerPoliciesResponse> | undefined, b: ListRunnerPoliciesResponse | PlainMessage<ListRunnerPoliciesResponse> | undefined): boolean {
    return proto3.util.equals(ListRunnerPoliciesResponse, a, b);
  }
}

/**
 * CreateRunnerPolicyRequest creates a Runner Policy.
 *
 * @generated from message gitpod.v1.CreateRunnerPolicyRequest
 */
export class CreateRunnerPolicyRequest extends Message<CreateRunnerPolicyRequest> {
  /**
   * runner_id specifies the project identifier
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * group_id specifies the group_id identifier
   *
   * @generated from field: string group_id = 2;
   */
  groupId = "";

  /**
   * @generated from field: gitpod.v1.RunnerRole role = 3;
   */
  role = RunnerRole.UNSPECIFIED;

  constructor(data?: PartialMessage<CreateRunnerPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateRunnerPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(RunnerRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunnerPolicyRequest {
    return new CreateRunnerPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunnerPolicyRequest {
    return new CreateRunnerPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunnerPolicyRequest {
    return new CreateRunnerPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunnerPolicyRequest | PlainMessage<CreateRunnerPolicyRequest> | undefined, b: CreateRunnerPolicyRequest | PlainMessage<CreateRunnerPolicyRequest> | undefined): boolean {
    return proto3.util.equals(CreateRunnerPolicyRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateRunnerPolicyResponse
 */
export class CreateRunnerPolicyResponse extends Message<CreateRunnerPolicyResponse> {
  /**
   * @generated from field: gitpod.v1.RunnerPolicy policy = 1;
   */
  policy?: RunnerPolicy;

  constructor(data?: PartialMessage<CreateRunnerPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateRunnerPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: RunnerPolicy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRunnerPolicyResponse {
    return new CreateRunnerPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRunnerPolicyResponse {
    return new CreateRunnerPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRunnerPolicyResponse {
    return new CreateRunnerPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRunnerPolicyResponse | PlainMessage<CreateRunnerPolicyResponse> | undefined, b: CreateRunnerPolicyResponse | PlainMessage<CreateRunnerPolicyResponse> | undefined): boolean {
    return proto3.util.equals(CreateRunnerPolicyResponse, a, b);
  }
}

/**
 * UpdateRunnerPolicyRequest updates a Project Policy.
 *
 * @generated from message gitpod.v1.UpdateRunnerPolicyRequest
 */
export class UpdateRunnerPolicyRequest extends Message<UpdateRunnerPolicyRequest> {
  /**
   * runner_id specifies the project identifier
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * group_id specifies the group_id identifier
   *
   * @generated from field: string group_id = 2;
   */
  groupId = "";

  /**
   * @generated from field: gitpod.v1.RunnerRole role = 3;
   */
  role = RunnerRole.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateRunnerPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(RunnerRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerPolicyRequest {
    return new UpdateRunnerPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerPolicyRequest {
    return new UpdateRunnerPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerPolicyRequest {
    return new UpdateRunnerPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerPolicyRequest | PlainMessage<UpdateRunnerPolicyRequest> | undefined, b: UpdateRunnerPolicyRequest | PlainMessage<UpdateRunnerPolicyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerPolicyRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerPolicyResponse
 */
export class UpdateRunnerPolicyResponse extends Message<UpdateRunnerPolicyResponse> {
  /**
   * @generated from field: gitpod.v1.RunnerPolicy policy = 1;
   */
  policy?: RunnerPolicy;

  constructor(data?: PartialMessage<UpdateRunnerPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: RunnerPolicy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerPolicyResponse {
    return new UpdateRunnerPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerPolicyResponse {
    return new UpdateRunnerPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerPolicyResponse {
    return new UpdateRunnerPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerPolicyResponse | PlainMessage<UpdateRunnerPolicyResponse> | undefined, b: UpdateRunnerPolicyResponse | PlainMessage<UpdateRunnerPolicyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerPolicyResponse, a, b);
  }
}

/**
 * DeleteRunnerPolicyRequest deletes a Project Policy.
 *
 * @generated from message gitpod.v1.DeleteRunnerPolicyRequest
 */
export class DeleteRunnerPolicyRequest extends Message<DeleteRunnerPolicyRequest> {
  /**
   * runner_id specifies the project identifier
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * group_id specifies the group_id identifier
   *
   * @generated from field: string group_id = 2;
   */
  groupId = "";

  constructor(data?: PartialMessage<DeleteRunnerPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteRunnerPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRunnerPolicyRequest {
    return new DeleteRunnerPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRunnerPolicyRequest {
    return new DeleteRunnerPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRunnerPolicyRequest {
    return new DeleteRunnerPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRunnerPolicyRequest | PlainMessage<DeleteRunnerPolicyRequest> | undefined, b: DeleteRunnerPolicyRequest | PlainMessage<DeleteRunnerPolicyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRunnerPolicyRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteRunnerPolicyResponse
 */
export class DeleteRunnerPolicyResponse extends Message<DeleteRunnerPolicyResponse> {
  constructor(data?: PartialMessage<DeleteRunnerPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteRunnerPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRunnerPolicyResponse {
    return new DeleteRunnerPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRunnerPolicyResponse {
    return new DeleteRunnerPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRunnerPolicyResponse {
    return new DeleteRunnerPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRunnerPolicyResponse | PlainMessage<DeleteRunnerPolicyResponse> | undefined, b: DeleteRunnerPolicyResponse | PlainMessage<DeleteRunnerPolicyResponse> | undefined): boolean {
    return proto3.util.equals(DeleteRunnerPolicyResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Runner
 */
export class Runner extends Message<Runner> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * Time when the Runner was created.
   *
   * @generated from field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;

  /**
   * Time when the Runner was last udpated.
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;

  /**
   * The runner's name which is shown to users
   *
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * The runner's specification
   *
   * @generated from field: gitpod.v1.RunnerSpec spec = 5;
   */
  spec?: RunnerSpec;

  /**
   * The runner's status
   *
   * @generated from field: gitpod.v1.RunnerStatus status = 6;
   */
  status?: RunnerStatus;

  /**
   * creator is the identity of the creator of the environment
   *
   * @generated from field: gitpod.v1.Subject creator = 7;
   */
  creator?: Subject;

  /**
   * The runner's kind
   *
   * @generated from field: gitpod.v1.RunnerKind kind = 8;
   */
  kind = RunnerKind.UNSPECIFIED;

  constructor(data?: PartialMessage<Runner>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Runner";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "spec", kind: "message", T: RunnerSpec },
    { no: 6, name: "status", kind: "message", T: RunnerStatus },
    { no: 7, name: "creator", kind: "message", T: Subject },
    { no: 8, name: "kind", kind: "enum", T: proto3.getEnumType(RunnerKind) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Runner {
    return new Runner().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Runner {
    return new Runner().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Runner {
    return new Runner().fromJsonString(jsonString, options);
  }

  static equals(a: Runner | PlainMessage<Runner> | undefined, b: Runner | PlainMessage<Runner> | undefined): boolean {
    return proto3.util.equals(Runner, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerSpec
 */
export class RunnerSpec extends Message<RunnerSpec> {
  /**
   * @generated from field: gitpod.v1.RunnerPhase desired_phase = 1;
   */
  desiredPhase = RunnerPhase.UNSPECIFIED;

  /**
   * The runner's configuration
   *
   * @generated from field: gitpod.v1.RunnerConfiguration configuration = 2;
   */
  configuration?: RunnerConfiguration;

  constructor(data?: PartialMessage<RunnerSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "desired_phase", kind: "enum", T: proto3.getEnumType(RunnerPhase) },
    { no: 2, name: "configuration", kind: "message", T: RunnerConfiguration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerSpec {
    return new RunnerSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerSpec {
    return new RunnerSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerSpec {
    return new RunnerSpec().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerSpec | PlainMessage<RunnerSpec> | undefined, b: RunnerSpec | PlainMessage<RunnerSpec> | undefined): boolean {
    return proto3.util.equals(RunnerSpec, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfiguration
 */
export class RunnerConfiguration extends Message<RunnerConfiguration> {
  /**
   * Region to deploy the runner in, if applicable.
   * This is mainly used for remote runners, and is only a hint. The runner may be
   * deployed in a different region. See the runner's status for the actual region.
   *
   * @generated from field: string region = 1;
   */
  region = "";

  /**
   * The release channel the runner is on
   *
   * @generated from field: gitpod.v1.RunnerReleaseChannel release_channel = 2;
   */
  releaseChannel = RunnerReleaseChannel.UNSPECIFIED;

  /**
   * auto_update indicates whether the runner should automatically update itself.
   *
   * @generated from field: bool auto_update = 3;
   */
  autoUpdate = false;

  constructor(data?: PartialMessage<RunnerConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "release_channel", kind: "enum", T: proto3.getEnumType(RunnerReleaseChannel) },
    { no: 3, name: "auto_update", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfiguration {
    return new RunnerConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfiguration {
    return new RunnerConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfiguration {
    return new RunnerConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfiguration | PlainMessage<RunnerConfiguration> | undefined, b: RunnerConfiguration | PlainMessage<RunnerConfiguration> | undefined): boolean {
    return proto3.util.equals(RunnerConfiguration, a, b);
  }
}

/**
 * RunnerStatus represents the status of a runner
 *
 * @generated from message gitpod.v1.RunnerStatus
 */
export class RunnerStatus extends Message<RunnerStatus> {
  /**
   * Time when the status was last udpated.
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 1;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  /**
   * @generated from field: string system_details = 3;
   */
  systemDetails = "";

  /**
   * The runner's reported phase
   *
   * @generated from field: gitpod.v1.RunnerPhase phase = 4;
   */
  phase = RunnerPhase.UNSPECIFIED;

  /**
   * @generated from field: string log_url = 5;
   */
  logUrl = "";

  /**
   * The runner's reported message which is shown to users.
   * This message adds more context to the runner's phase.
   *
   * @generated from field: string message = 6;
   */
  message = "";

  /**
   * region is the region the runner is running in, if applicable.
   *
   * @generated from field: string region = 7;
   */
  region = "";

  /**
   * additional_info contains additional information about the runner,
   * e.g. a CloudFormation stack URL.
   *
   * @generated from field: repeated gitpod.v1.FieldValue additional_info = 8;
   */
  additionalInfo: FieldValue[] = [];

  /**
   * capabilities is a list of capabilities the runner supports.
   *
   * @generated from field: repeated gitpod.v1.RunnerCapability capabilities = 9;
   */
  capabilities: RunnerCapability[] = [];

  constructor(data?: PartialMessage<RunnerStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updated_at", kind: "message", T: Timestamp },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "system_details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phase", kind: "enum", T: proto3.getEnumType(RunnerPhase) },
    { no: 5, name: "log_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "additional_info", kind: "message", T: FieldValue, repeated: true },
    { no: 9, name: "capabilities", kind: "enum", T: proto3.getEnumType(RunnerCapability), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerStatus {
    return new RunnerStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerStatus {
    return new RunnerStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerStatus {
    return new RunnerStatus().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerStatus | PlainMessage<RunnerStatus> | undefined, b: RunnerStatus | PlainMessage<RunnerStatus> | undefined): boolean {
    return proto3.util.equals(RunnerStatus, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerPolicy
 */
export class RunnerPolicy extends Message<RunnerPolicy> {
  /**
   * @generated from field: string group_id = 1;
   */
  groupId = "";

  /**
   * role is the role assigned to the group
   *
   * @generated from field: gitpod.v1.RunnerRole role = 2;
   */
  role = RunnerRole.UNSPECIFIED;

  constructor(data?: PartialMessage<RunnerPolicy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerPolicy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(RunnerRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerPolicy {
    return new RunnerPolicy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerPolicy {
    return new RunnerPolicy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerPolicy {
    return new RunnerPolicy().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerPolicy | PlainMessage<RunnerPolicy> | undefined, b: RunnerPolicy | PlainMessage<RunnerPolicy> | undefined): boolean {
    return proto3.util.equals(RunnerPolicy, a, b);
  }
}

