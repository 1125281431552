// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/environment_automation.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateServiceRequest, CreateServiceResponse, CreateTaskRequest, CreateTaskResponse, DeleteServiceRequest, DeleteServiceResponse, DeleteTaskRequest, DeleteTaskResponse, GetServiceRequest, GetServiceResponse, GetTaskExecutionRequest, GetTaskExecutionResponse, GetTaskRequest, GetTaskResponse, ListServicesRequest, ListServicesResponse, ListTaskExecutionsRequest, ListTaskExecutionsResponse, ListTasksRequest, ListTasksResponse, StartServiceRequest, StartServiceResponse, StartTaskRequest, StartTaskResponse, StopServiceRequest, StopServiceResponse, StopTaskExecutionRequest, StopTaskExecutionResponse, UpdateServiceRequest, UpdateServiceResponse, UpdateTaskExecutionStatusRequest, UpdateTaskExecutionStatusResponse, UpdateTaskRequest, UpdateTaskResponse, UpsertAutomationsFileRequest, UpsertAutomationsFileResponse } from "./environment_automation_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.EnvironmentAutomationService
 */
export const EnvironmentAutomationService = {
  typeName: "gitpod.v1.EnvironmentAutomationService",
  methods: {
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.CreateService
     */
    createService: {
      name: "CreateService",
      I: CreateServiceRequest,
      O: CreateServiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.GetService
     */
    getService: {
      name: "GetService",
      I: GetServiceRequest,
      O: GetServiceResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.ListServices
     */
    listServices: {
      name: "ListServices",
      I: ListServicesRequest,
      O: ListServicesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.UpdateService
     */
    updateService: {
      name: "UpdateService",
      I: UpdateServiceRequest,
      O: UpdateServiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteService deletes a service. This call does not block until the service is deleted.
     * If the service is not stopped it will be stopped before deletion.
     *
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.DeleteService
     */
    deleteService: {
      name: "DeleteService",
      I: DeleteServiceRequest,
      O: DeleteServiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * StartService starts a service. This call does not block until the service is started.
     * This call will not error if the service is already running or has been started.
     *
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.StartService
     */
    startService: {
      name: "StartService",
      I: StartServiceRequest,
      O: StartServiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * StopService stops a service. This call does not block until the service is stopped.
     * This call will not error if the service is already stopped or has been stopped.
     *
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.StopService
     */
    stopService: {
      name: "StopService",
      I: StopServiceRequest,
      O: StopServiceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpsertAutomationsFile upserts the automations file for the given environment.
     *
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.UpsertAutomationsFile
     */
    upsertAutomationsFile: {
      name: "UpsertAutomationsFile",
      I: UpsertAutomationsFileRequest,
      O: UpsertAutomationsFileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.CreateTask
     */
    createTask: {
      name: "CreateTask",
      I: CreateTaskRequest,
      O: CreateTaskResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.GetTask
     */
    getTask: {
      name: "GetTask",
      I: GetTaskRequest,
      O: GetTaskResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.ListTasks
     */
    listTasks: {
      name: "ListTasks",
      I: ListTasksRequest,
      O: ListTasksResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.UpdateTask
     */
    updateTask: {
      name: "UpdateTask",
      I: UpdateTaskRequest,
      O: UpdateTaskResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.DeleteTask
     */
    deleteTask: {
      name: "DeleteTask",
      I: DeleteTaskRequest,
      O: DeleteTaskResponse,
      kind: MethodKind.Unary,
    },
    /**
     * StartTask starts a task, i.e. creates a task execution.
     * This call does not block until the task is started; the task will be started asynchronously.
     *
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.StartTask
     */
    startTask: {
      name: "StartTask",
      I: StartTaskRequest,
      O: StartTaskResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.ListTaskExecutions
     */
    listTaskExecutions: {
      name: "ListTaskExecutions",
      I: ListTaskExecutionsRequest,
      O: ListTaskExecutionsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.GetTaskExecution
     */
    getTaskExecution: {
      name: "GetTaskExecution",
      I: GetTaskExecutionRequest,
      O: GetTaskExecutionResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.StopTaskExecution
     */
    stopTaskExecution: {
      name: "StopTaskExecution",
      I: StopTaskExecutionRequest,
      O: StopTaskExecutionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateTaskExecutionStatus updates the status of a task execution. Only the environment executing a task execution is expected to call this function.
     *
     * @generated from rpc gitpod.v1.EnvironmentAutomationService.UpdateTaskExecutionStatus
     */
    updateTaskExecutionStatus: {
      name: "UpdateTaskExecutionStatus",
      I: UpdateTaskExecutionStatusRequest,
      O: UpdateTaskExecutionStatusResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

