import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useEffect, useRef } from "react";

/**
 * Will call `onRunning` when the environment transitions from any phase to RUNNING.
 */
export function useTriggerOnRunning(phase: EnvironmentPhase | undefined, onRunning: () => void) {
    const previousPhase = useRef<EnvironmentPhase | undefined>(phase);
    useEffect(() => {
        if (!previousPhase.current) {
            previousPhase.current = phase;
            return;
        }

        if (previousPhase.current === phase) {
            return;
        }

        if (previousPhase.current !== EnvironmentPhase.RUNNING && phase === EnvironmentPhase.RUNNING) {
            onRunning();
        }
        previousPhase.current = phase;
    }, [phase, previousPhase, onRunning]);
}
