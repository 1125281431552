const rtf = new Intl.RelativeTimeFormat("en", {
    style: "narrow",
});
export function timeAgo(date: Date, format = rtf) {
    const millis = date.getTime();
    const diffToNowInSeconds = Math.round((millis - Date.now()) / 1000);

    const thresholds: [number, Intl.RelativeTimeFormatUnit][] = [
        [60, "second"],
        [60 * 60, "minute"],
        [60 * 60 * 24, "hour"],
        [60 * 60 * 24 * 7, "day"],
        [60 * 60 * 24 * 30, "week"],
        [60 * 60 * 24 * 365, "month"],
        [Infinity, "year"],
    ];

    const index = thresholds.findIndex(([second]) => second > Math.abs(diffToNowInSeconds));
    const unit = thresholds[index];

    const divisor = thresholds[index - 1] ? thresholds[index - 1][0] : 1;

    return format.format(Math.round(diffToNowInSeconds / divisor), unit[1]);
}

const mediumTime = Intl.DateTimeFormat("en", {
    timeStyle: "medium",
});
export function formatMediumTime(date: Date) {
    return mediumTime.format(date);
}

const absoluteDateTime = Intl.DateTimeFormat("en", {
    dateStyle: "medium",
    timeStyle: "short",
});
export function formatAbsoluteDateTime(date: Date) {
    return absoluteDateTime.format(date);
}

export function duration(seconds: number) {
    if (seconds < 60) {
        return `${Math.ceil(Math.max(seconds, 0))}s`;
    }

    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 3600) % 60;

    let formattedDuration = "";

    if (hours > 0) {
        const formattedHours = new Intl.NumberFormat("en-US", {
            minimumIntegerDigits: 1,
            maximumFractionDigits: 0,
        }).format(hours);
        formattedDuration += `${formattedHours}h `;
    }

    if (remainingMinutes > 0) {
        const formattedMinutes = new Intl.NumberFormat("en-US", {
            minimumIntegerDigits: 1,
            maximumFractionDigits: 0,
        }).format(remainingMinutes);
        formattedDuration += `${formattedMinutes}m `;
    }

    if (remainingSeconds > 0) {
        const formattedSeconds = new Intl.NumberFormat("en-US", {
            minimumIntegerDigits: 1,
            maximumFractionDigits: 0,
        }).format(remainingSeconds);
        formattedDuration += `${formattedSeconds}s`;
    }

    return formattedDuration.trim();
}
