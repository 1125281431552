import type { PlainMessage } from "@bufbuild/protobuf";
import { type EnvironmentSpec_Secret } from "gitpod-next-api/gitpod/v1/environment_pb";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/podkit/tables/Table";
import { useCallback, type FC } from "react";
import type { PlainEnvironment } from "@/queries/environment-queries";
import { Text } from "@/components/podkit/typography/Text";
import { Button } from "@/components/flexkit/Button";
import { IconCopy } from "@/assets/icons/geist/IconCopy";
import { useTemporaryState } from "@/hooks/use-temporary-value";
import { IconCheck } from "@/assets/icons/geist/IconCheck";
import { Tooltip } from "@/components/Tooltip";
import { useToast } from "@/components/podkit/toasts/use-toast";

export const EnvironmentSecretsTable: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const secrets = environment.spec?.secrets || [];

    if (!secrets.length) {
        return (
            <div data-testid="environment-secrets-empty-state">
                <Text className="text-base text-content-tertiary">This environment has no secrets.</Text>
            </div>
        );
    }

    return (
        <Table className="table-fixed" data-testid="environment-secrets-table">
            <TableHeader className="h-10 [&_th:last-child]:pl-0 [&_th:last-child]:pr-2 [&_th]:border-border-base [&_th]:border-b-[0.5] [&_th]:bg-transparent [&_th]:px-2 [&_th]:py-1">
                <TableRow>
                    <TableHead className="w-[200px] text-sm font-bold text-content-secondary">Name</TableHead>
                    <TableHead />
                    <TableHead className="w-[34px]" />
                </TableRow>
            </TableHeader>
            <TableBody>
                {secrets.map((secret) => (
                    <EnvironmentSecretsRow key={secret.name} secret={secret} />
                ))}
            </TableBody>
        </Table>
    );
};

const EnvironmentSecretsRow: FC<{ secret: PlainMessage<EnvironmentSpec_Secret> }> = ({ secret }) => {
    const { toast } = useToast();
    const [copied, setCopied] = useTemporaryState(false, 2000);

    const handleCopyToClipboard = useCallback(() => {
        void navigator.clipboard.writeText(secret.mount.value || "").then(() => {
            setCopied(true);
            toast({ title: "Copied to clipboard" });
        });
    }, [setCopied, toast, secret.mount.value]);

    let mountLabel = "Environment variable";
    let tooltip = "Copy name";
    if (secret.mount.case === "filePath") {
        mountLabel = secret.mount.value;
        tooltip = "Copy path";
    }

    return (
        <TableRow
            className="group border-0 hover:bg-surface-02 [&_td:first-child]:rounded-l-md [&_td:first-child]:pl-2 [&_td:last-child]:rounded-r-md [&_td:last-child]:pl-0 [&_td:last-child]:pr-2 [&_td]:px-2 [&_td]:py-1"
            data-testid={`secret-${secret.name}`}
        >
            <TableCell className="p-0">
                <Tooltip content={secret.name}>
                    <Text className="truncate font-mono text-sm font-bold text-content-primary">{secret.name}</Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip content={mountLabel}>
                    <Text className="truncate text-sm text-content-secondary">{mountLabel}</Text>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip content={tooltip}>
                    <Button
                        variant="link"
                        size="sm"
                        disabled={copied}
                        LeadingIcon={
                            copied
                                ? (p) => <IconCheck className="text-green-500 hover:text-green-500" {...p} />
                                : IconCopy
                        }
                        className="hidden border-0 p-0 active:outline-0 group-hover:flex"
                        onClick={handleCopyToClipboard}
                        aria-label={tooltip}
                    />
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
