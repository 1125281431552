import type { PlainEnvironment } from "@/queries/environment-queries";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";

export const canOpen = (environment: PlainEnvironment) => {
    const actual = environment.status?.phase ?? EnvironmentPhase.UNSPECIFIED;

    switch (actual) {
        case EnvironmentPhase.CREATING:
        case EnvironmentPhase.STARTING:
        case EnvironmentPhase.RUNNING:
        case EnvironmentPhase.UPDATING:
            return true;
        default:
            return false;
    }
};

export const showStop = (environment: PlainEnvironment) => {
    const desired = environment.spec?.desiredPhase ?? EnvironmentPhase.UNSPECIFIED;

    if (desiredRunningStoppedSameSession(environment)) {
        return false;
    }

    switch (desired) {
        case EnvironmentPhase.CREATING:
        case EnvironmentPhase.STARTING:
        case EnvironmentPhase.UPDATING:
        case EnvironmentPhase.RUNNING:
            return true;
        default:
            return false;
    }
};

export const showStart = (environment: PlainEnvironment) => {
    const desired = environment.spec?.desiredPhase ?? EnvironmentPhase.UNSPECIFIED;

    if (desiredRunningStoppedSameSession(environment)) {
        return true;
    }

    switch (desired) {
        case EnvironmentPhase.STOPPED:
        case EnvironmentPhase.STOPPING:
        case EnvironmentPhase.UNSPECIFIED:
            return true;
        default:
            return false;
    }
};

export const canConnectSSH = (environment: PlainEnvironment) => {
    const actual = environment.status?.phase ?? EnvironmentPhase.UNSPECIFIED;

    switch (actual) {
        case EnvironmentPhase.RUNNING:
            return true;
        default:
            return false;
    }
};

export type EffectiveState = {
    state: EnvironmentPhase;
    failures?: string[];
    warnings?: string[];
    timeout?: string;
};

function desiredRunningStoppedSameSession(environment: PlainEnvironment): boolean {
    const desired = environment.spec?.desiredPhase ?? EnvironmentPhase.UNSPECIFIED;
    const actual = environment.status?.phase ?? EnvironmentPhase.UNSPECIFIED;
    return isSameSession(environment) && desired === EnvironmentPhase.RUNNING && actual === EnvironmentPhase.STOPPED;
}

export function isSameSession(environment: PlainEnvironment): boolean {
    return environment.status?.machine?.session === environment.spec?.machine?.session;
}

export function hasFailureMessageFromSameSession(environment: PlainEnvironment): boolean {
    return isSameSession(environment) && (environment.status?.failureMessage?.length ?? 0) > 0;
}

export function environmentHasReachedStablePhase(environment: PlainEnvironment): boolean {
    if (desiredRunningStoppedSameSession(environment)) {
        return true;
    }

    const desired = environment.spec?.desiredPhase ?? EnvironmentPhase.UNSPECIFIED;
    const actual = environment.status?.phase ?? EnvironmentPhase.UNSPECIFIED;
    return isSameSession(environment) && desired === actual;
}

export function effectiveState(environment: PlainEnvironment): EffectiveState {
    const desired = environment.spec?.desiredPhase ?? EnvironmentPhase.UNSPECIFIED;
    const actual = environment.status?.phase ?? EnvironmentPhase.UNSPECIFIED;

    const sameSession = isSameSession(environment);

    let timeout: string | undefined;
    let failures: string[] | undefined;
    let warnings: string[] | undefined;
    if (sameSession) {
        const failureMessages = environment.status?.failureMessage || [];
        if (failureMessages.length > 0) {
            failures = failureMessages;
        }
        const warningMessage = environment.status?.warningMessage || [];
        if (warningMessage.length > 0) {
            warnings = warningMessage;
        }
        if (environment.status?.machine?.timeout) {
            timeout = environment.status?.machine?.timeout;
        }
    }

    if (!sameSession) {
        // Optimistically update state to STARTING
        if (desired === EnvironmentPhase.RUNNING && actual !== EnvironmentPhase.RUNNING) {
            return {
                state: EnvironmentPhase.STARTING,
            };
        }
    }

    // We consider the CREATING phase internal and skip showing it
    if (actual === EnvironmentPhase.CREATING) {
        return {
            state: EnvironmentPhase.STARTING,
            failures,
            warnings,
            timeout,
        };
    }

    if (desired === EnvironmentPhase.DELETED && actual !== EnvironmentPhase.DELETED) {
        return {
            state: EnvironmentPhase.DELETING,
            failures,
            warnings,
            timeout,
        };
    }

    if (desired === EnvironmentPhase.STOPPED && actual !== EnvironmentPhase.STOPPED) {
        return {
            state: EnvironmentPhase.STOPPING,
            failures,
            warnings,
            timeout,
        };
    }

    return {
        state: actual,
        failures,
        warnings,
        timeout,
    };
}
