import { useSegmentTrack } from "@/hooks/use-segment";
import { useEditorURL } from "@/queries/environment-queries";
import { type PlainMessage } from "@bufbuild/protobuf";
import { type Environment } from "gitpod-next-api/gitpod/v1/environment_pb";

const VSCodeDesktopEditorID = "018d655f-2f16-761e-b530-390d640fd9db";

export function useOpenEditor(environment?: Environment | PlainMessage<Environment>) {
    const editorURL = useEditorURL(VSCodeDesktopEditorID, environment);
    const segmentTrack = useSegmentTrack();

    return () => {
        const url = editorURL.data?.url;
        if (!url) {
            return;
        }

        let redirect = false;
        try {
            const desktopLink = new URL(url);
            // allow to redirect only for whitelisted trusted protocols
            const trustedProtocols = ["vscode:", "vscode-insiders:", "jetbrains-gateway:"];
            redirect = trustedProtocols.includes(desktopLink.protocol);
        } catch (e) {
            console.error("invalid desktop link:", e);
        }
        // redirect only if points to desktop application
        // don't navigate browser to another page
        segmentTrack("Open Editor", { redirect });
        if (redirect) {
            window.location.href = url;
        } else {
            window.open(url, "_blank", "noopener");
        }
    };
}
