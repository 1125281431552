import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconBitbucket: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_554_773)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.00650437 1.21867C-0.0401157 0.962238 0.169692 0.705811 0.449436 0.705811L13.5506 0.729126C13.8303 0.729126 14.0401 0.962238 13.9935 1.24198L12.6387 9.51935L12.1053 12.8978C12.0837 12.995 12.0321 13.0822 11.962 13.1478C11.8784 13.2374 11.7628 13.2942 11.639 13.2942H2.50087C2.19782 13.2942 1.94137 13.0843 1.89474 12.7813L0.00650437 1.21867ZM5.55468 9.05139H8.46867L9.16796 4.92524L4.78538 4.92524L5.55468 9.05139Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_554_773">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.00743 3.39277C1.95415 3.0997 2.19393 2.80664 2.51364 2.80664L17.4864 2.83329C17.8061 2.83329 18.0459 3.0997 17.9926 3.41941L16.4442 12.8794L15.8346 16.7404C15.8099 16.8514 15.7509 16.9511 15.6709 17.026C15.5753 17.1284 15.4432 17.1933 15.3017 17.1933H4.85814C4.5118 17.1933 4.21871 16.9535 4.16542 16.6072L2.00743 3.39277ZM12.5043 7.62885H7.46901L8.34821 12.3445H11.6785L12.4777 7.62885L12.5043 7.62885Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.00836 4.56686C2.94842 4.23716 3.21818 3.90747 3.57785 3.90747L20.4221 3.93745C20.7818 3.93745 21.0516 4.23716 20.9916 4.59683L19.2489 15.2446L18.5639 19.5829C18.5361 19.7077 18.4699 19.8197 18.38 19.904C18.2725 20.0194 18.1237 20.0925 17.9645 20.0925H6.2154C5.82577 20.0925 5.49605 19.8227 5.4361 19.4331L3.00836 4.56686ZM14.7873 9.33245L13.8882 14.6375H10.1417L9.15263 9.33245H14.7873Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
