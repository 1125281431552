import { useGetAccount } from "@/queries/account-queries";
import { useFeatureFlag, type SettingTypeOf, type SettingValue } from "configcat-react";

export function useFlag<T extends SettingValue>(flagKey: string, defaultValue: T): { value: SettingTypeOf<T> } {
    const { data: account } = useGetAccount();

    const { value } = useFeatureFlag<T>(
        flagKey,
        defaultValue,
        account
            ? {
                  custom: {},
                  identifier: account.id,
                  email: account.email,
              }
            : undefined,
    );

    return { value };
}
