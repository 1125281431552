import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconWarning: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5_267)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.48865 1.4375C8.99236 1.4375 9.45164 1.72577 9.67063 2.1794L14.8314 12.8696C15.1119 13.4506 14.6886 14.125 14.0435 14.125H1.95651C1.31136 14.125 0.88805 13.4506 1.16853 12.8696L6.32932 2.17939C6.54831 1.72577 7.00758 1.4375 7.5113 1.4375H8.48865ZM8.65622 5.15625V5.8125V8V8.65625H7.34372V8V5.8125V5.15625H8.65622ZM7.99997 11.5C8.48323 11.5 8.87497 11.1083 8.87497 10.625C8.87497 10.1417 8.48323 9.75 7.99997 9.75C7.51673 9.75 7.12497 10.1417 7.12497 10.625C7.12497 11.1083 7.51673 11.5 7.99997 11.5Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5_267">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.5585 2.5C11.1341 2.5 11.659 2.82946 11.9093 3.34788L17.8073 15.5653C18.1279 16.2293 17.6441 17 16.9068 17H3.09316C2.35583 17 1.87206 16.2293 2.19261 15.5653L8.09065 3.34787C8.34092 2.82945 8.86581 2.5 9.44148 2.5H10.5585ZM10.75 6.75V7.5V10V10.75H9.24997V10V7.5V6.75H10.75ZM9.99997 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 9.99997 12C9.44769 12 8.99997 12.4477 8.99997 13C8.99997 13.5523 9.44769 14 9.99997 14Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.6283 3.5625C13.2759 3.5625 13.8664 3.93314 14.148 4.51636L20.7832 18.261C21.1439 19.008 20.5996 19.875 19.7702 19.875H4.2298C3.40031 19.875 2.85606 19.008 3.21668 18.261L9.85198 4.51635C10.1335 3.93314 10.724 3.5625 11.3717 3.5625H12.6283ZM12.8437 8.34375V9.1875V12V12.8438H11.1562V12V9.1875V8.34375H12.8437ZM12 16.5C12.6213 16.5 13.125 15.9963 13.125 15.375C13.125 14.7537 12.6213 14.25 12 14.25C11.3787 14.25 10.875 14.7537 10.875 15.375C10.875 15.9963 11.3787 16.5 12 16.5Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
