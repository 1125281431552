import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { useGetAccount } from "@/queries/account-queries";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useMutation, useQueries, useQuery, useQueryClient, type QueryClient } from "@tanstack/react-query";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import {
    type Project,
    type ProjectEnvironmentClass,
    type ProjectPolicy,
    type ProjectRole,
} from "gitpod-next-api/gitpod/v1/project_pb";
import { toPlainMessage, type PartialMessage, type PlainMessage } from "@bufbuild/protobuf";
import type { EnvironmentInitializer } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useOrganization } from "@/queries/organization-queries";
import { useListGroups } from "@/queries/group-queries";
import { useEffect, useMemo, useState } from "react";
import { equals } from "@/utils/arrays";
import type { GitpodAPI } from "@/api";
import { ResourceOperation, type WatchEventsResponse } from "gitpod-next-api/gitpod/v1/event_pb";
import { Code, ConnectError } from "@connectrpc/connect";

export const projectsQueryKey = {
    list: () => keyWithPrincipal(["projects", "list", "all"]),
    get: (projectId?: string) => keyWithPrincipal(["projects", { projectId }]),
    listPolicies: (projectId?: string) => keyWithPrincipal(["projectsPolicies", "list", { projectId }]),
};

export type PlainProject = PlainMessage<Project>;

export function toPlainProject(project: Project): PlainProject {
    return toPlainMessage(project);
}

export type PlainProjectPolicy = PlainMessage<ProjectPolicy>;

export function toPlainProjectPolicy(policy: ProjectPolicy): PlainProjectPolicy {
    return toPlainMessage(policy);
}

export const handleProjectEvent = async (api: GitpodAPI, client: QueryClient, evt: WatchEventsResponse) => {
    if (evt.operation === ResourceOperation.UPDATE) {
        const project = await refetchProject(api, evt.resourceId);
        setProjectInCache(client, evt.resourceId, project ? toPlainProject(project) : undefined);
    }
    if (evt.operation === ResourceOperation.CREATE) {
        await client.invalidateQueries({ queryKey: projectsQueryKey.list() });
    }
    if (evt.operation === ResourceOperation.DELETE) {
        setProjectInCache(client, evt.resourceId, undefined);
    }
};

const refetchProject = async (api: GitpodAPI, projectId: string) => {
    try {
        const response = await api.projectService.getProject({ projectId });
        return response.project;
    } catch (error) {
        const isNotFound = error instanceof ConnectError && error.code === Code.NotFound;
        // this might happen if the project was deleted in the meantime
        if (!isNotFound) {
            console.error("Failed to refetch project", projectId);
        }
    }
};

function setProjectInCache(client: QueryClient, projectId: string, project?: PlainProject) {
    client.setQueryData(projectsQueryKey.get(projectId), project);
    client.setQueryData(projectsQueryKey.list(), (currentData?: { projects: PlainProject[] }) => {
        if (!currentData) {
            return currentData;
        }
        return {
            ...currentData,
            projects: currentData.projects
                .map((p) => {
                    if (p.id !== projectId) {
                        return p;
                    }
                    return project;
                })
                .filter((w) => !!w),
        };
    });
}

function setProjectPolicyInCache(client: QueryClient, projectId: string, groupId: string, policy?: ProjectPolicy) {
    client.setQueryData(
        projectsQueryKey.listPolicies(projectId),
        (currentData?: { policies: PlainProjectPolicy[] }) => {
            if (!currentData) {
                return currentData;
            }
            return {
                ...currentData,
                policies: currentData.policies
                    .map((p) => {
                        if (p.groupId !== groupId) {
                            return p;
                        }
                        return policy;
                    })
                    .filter((w) => !!w),
            };
        },
    );
}

export const useCreateProject = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: account } = useGetAccount();

    return useMutation({
        mutationFn: async ({
            name,
            contextURL,
            classID,
            devcontainerFilePath,
            automationsFilePath,
        }: {
            name: string;
            contextURL: string;
            classID: string | undefined;
            devcontainerFilePath?: string;
            automationsFilePath?: string;
        }) => {
            if (!account) {
                throw new Error("Not authenticated");
            }

            let environmentClass:
                | {
                      case: "localRunner";
                      value: boolean;
                  }
                | {
                      case: "environmentClassId";
                      value: string;
                  } = {
                value: true,
                case: "localRunner",
            };
            if (classID) {
                environmentClass = {
                    value: classID,
                    case: "environmentClassId",
                };
            }

            const { project } = await api.projectService.createProject({
                name,
                initializer: {
                    specs: [
                        {
                            spec: { case: "contextUrl", value: { url: contextURL } },
                        },
                    ],
                },
                environmentClass: { environmentClass },
                devcontainerFilePath,
                automationsFilePath,
            });

            if (!project) {
                throw new Error("Failed to create project");
            }

            return toPlainProject(project);
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: projectsQueryKey.list() });
        },
    });
};

export const useCreateProjectFromEnvironment = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: account } = useGetAccount();

    return useMutation({
        mutationFn: async ({ projectName, environmentId }: { projectName: string; environmentId: string }) => {
            if (!account) {
                throw new Error("Not authenticated");
            }

            const { project } = await api.projectService.createProjectFromEnvironment({
                name: projectName,
                environmentId,
            });

            if (!project) {
                throw new Error("Failed to create project");
            }

            return toPlainProject(project);
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: projectsQueryKey.list() });
        },
    });
};

export const useUpdateProject = () => {
    const api = useGitpodAPI();
    const queryClient = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({
            projectId,
            name,
            initializer,
            classID,
            devcontainerFilePath,
            automationsFilePath,
        }: {
            projectId: string;
            name?: string;
            initializer?: PartialMessage<EnvironmentInitializer>;
            classID?: string | null;
            devcontainerFilePath?: string;
            automationsFilePath?: string;
        }) => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            let environmentClass: PartialMessage<ProjectEnvironmentClass> | undefined;
            if (typeof classID !== "undefined") {
                if (classID) {
                    environmentClass = {
                        environmentClass: {
                            value: classID,
                            case: "environmentClassId",
                        },
                    };
                } else {
                    environmentClass = {
                        environmentClass: {
                            value: true,
                            case: "localRunner",
                        },
                    };
                }
            }

            const { project } = await api.projectService.updateProject({
                projectId,
                name,
                initializer,
                environmentClass,
                devcontainerFilePath,
                automationsFilePath,
            });

            return toPlainProject(project!);
        },
        onSuccess: async (_, { projectId }) => {
            await queryClient.invalidateQueries({ queryKey: projectsQueryKey.get(projectId) });
        },
    });
};

export const useListProjects = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: projectsQueryKey.list(),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { projects, pagination } = await api.projectService.listProjects({
                pagination: {
                    pageSize: 100,
                },
            });

            return {
                projects: projects.map(toPlainProject),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 200,
    });
};

export const useProject = (projectId?: string) => {
    const api = useGitpodAPI();

    return useQuery<PlainProject, Error>({
        queryKey: projectsQueryKey.get(projectId),
        queryFn: async (): Promise<PlainProject> => {
            if (!projectId) {
                throw new Error("No project ID provided");
            }

            const { project } = await api.projectService.getProject({ projectId });
            if (!project) {
                throw new Error("Project not found");
            }

            return toPlainProject(project);
        },
        enabled: !!projectId,
    });
};

export const useDeleteProject = () => {
    const queryClient = useQueryClient();
    const api = useGitpodAPI();

    return useMutation({
        mutationFn: async ({ projectId }: { projectId: string }) => {
            await api.projectService.deleteProject({
                projectId,
            });

            setProjectInCache(queryClient, projectId, undefined);
        },
    });
};

export const useCreateProjectPolicy = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: account } = useGetAccount();

    return useMutation({
        mutationFn: async ({ projectId, groupId, role }: { projectId: string; groupId: string; role: ProjectRole }) => {
            if (!account) {
                throw new Error("Not authenticated");
            }

            const { policy } = await api.projectService.createProjectPolicy({
                projectId,
                groupId,
                role,
            });

            await client.invalidateQueries({ queryKey: projectsQueryKey.listPolicies(projectId) });
            return toPlainProjectPolicy(policy!);
        },
    });
};

export const useListProjectPolicies = (projectId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: projectsQueryKey.listPolicies(projectId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { policies, pagination } = await api.projectService.listProjectPolicies({
                pagination: {
                    pageSize: 100,
                },
                projectId,
            });

            return {
                policies: policies.map(toPlainProjectPolicy),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user && !!projectId,
        staleTime: 200,
    });
};

export const useDeleteProjectPolicy = () => {
    const queryClient = useQueryClient();
    const api = useGitpodAPI();

    return useMutation({
        mutationFn: async ({ projectId, groupId }: { projectId: string; groupId: string }) => {
            await api.projectService.deleteProjectPolicy({
                projectId,
                groupId,
            });

            setProjectPolicyInCache(queryClient, projectId, groupId, undefined);
        },
    });
};

export const useListProjectsPolicies = (projectIds?: string[]) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQueries({
        queries: projectIds
            ? projectIds.map((projectId) => {
                  return {
                      queryKey: projectsQueryKey.listPolicies(projectId),
                      queryFn: async () => {
                          if (!user) {
                              throw new Error("User not authenticated");
                          }

                          const { policies, pagination } = await api.projectService.listProjectPolicies({
                              pagination: {
                                  pageSize: 100,
                              },
                              projectId,
                          });

                          return {
                              policies: policies.map(toPlainProjectPolicy),
                              pagination,
                          };
                      },
                  };
              })
            : [],
    });
};

export type ProjectState = {
    projectId: string;
    orgId: string;
    orgMembersGroupId: string;
    shared: boolean;
};

export const useProjectState = (
    projectId?: string,
): { isLoading: boolean; data: ProjectState | undefined; error?: Error } => {
    const { data: organization, isPending: isLoadingOrganization, error: organizationError } = useOrganization();
    const { data: groups, isPending: isLoadingGroups, error: groupError } = useListGroups();
    const {
        data: policies,
        isPending: isLoadingProjectPolicies,
        error: projectPolicyError,
    } = useListProjectPolicies(projectId);

    const [sharingValue, setSharingValue] = useState<boolean>();

    const orgMembersGroup = useMemo(() => {
        if (!organization || !groups) {
            return;
        }

        return groups.groups.find((g) => g.organizationId === organization.id && g.name === "org-members") ?? null;
    }, [organization, groups]);

    useEffect(() => {
        if (!policies || orgMembersGroup === undefined) {
            return;
        }

        const policy = orgMembersGroup ? policies.policies.find((p) => p.groupId === orgMembersGroup.id) : undefined;
        setSharingValue(!!policy);
    }, [policies, orgMembersGroup, setSharingValue]);

    const isLoading = isLoadingOrganization || isLoadingGroups || isLoadingProjectPolicies;
    const error = organizationError || groupError || projectPolicyError;

    return {
        isLoading,
        error: error || undefined,
        data:
            typeof sharingValue != "undefined"
                ? {
                      orgId: organization!.id,
                      projectId: projectId!,
                      orgMembersGroupId: orgMembersGroup!.id,
                      shared: sharingValue,
                  }
                : undefined,
    };
};

export const useListProjectStates = (
    projectIds?: string[],
): { isLoading: boolean; data: ProjectState[] | undefined } => {
    const { data: organization, isPending: isLoadingOrganization } = useOrganization();
    const { data: groups, isPending: isLoadingGroups } = useListGroups();
    const projectPoliciesArr = useListProjectsPolicies(projectIds);

    const [sharingValue, setSharingValue] = useState<boolean[]>();

    const orgMembersGroup = useMemo(() => {
        if (!organization || !groups) {
            return;
        }

        return groups.groups.find((g) => g.organizationId === organization.id && g.name === "org-members") ?? null;
    }, [organization, groups]);

    useEffect(() => {
        if (orgMembersGroup === undefined || projectPoliciesArr.some((i) => !i.data)) {
            return;
        }

        const sharingValueArr = projectPoliciesArr.map((r) => {
            const policy = orgMembersGroup ? r.data?.policies.find((p) => p.groupId === orgMembersGroup.id) : undefined;
            return !!policy;
        });

        if (!equals(sharingValue, sharingValueArr)) {
            setSharingValue(sharingValueArr);
        }
    }, [orgMembersGroup, projectPoliciesArr, sharingValue]);

    const isLoading = isLoadingOrganization || isLoadingGroups || projectPoliciesArr.some((i) => i.isPending);

    return {
        isLoading,
        data:
            typeof sharingValue !== "undefined" && organization && orgMembersGroup && projectIds
                ? projectIds.map((projectId, idx) => {
                      return {
                          orgId: organization.id,
                          projectId: projectId,
                          orgMembersGroupId: orgMembersGroup.id,
                          shared: sharingValue[idx],
                      };
                  })
                : undefined,
    };
};
