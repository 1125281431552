export enum AutomationsTemplateId {
    Example = "example",
}

export type AutomationsTemplate = {
    id: AutomationsTemplateId;
    title: string;
    automationsFileContents: string;
};

export const templates: Record<AutomationsTemplateId, AutomationsTemplate> = {
    example: {
        id: AutomationsTemplateId.Example,
        title: "Hello World",
        automationsFileContents: `tasks:
    hello:
        name: Hello World
        command: |
            echo "Hello, World!"
        triggeredBy:
        - manual
        # - postEnvironmentStart
        # - postDevcontainerStart

services:
    backend:
        name: Backend Service
        description: Example service simulating a backend
        commands:
            start: |
                echo "Starting backend service..."
                touch /tmp/backend.started
                while true; do
                    sleep 1
                    date
                done
            ready: |
                if [ -f /tmp/backend.started ]; then
                    echo "Backend service is ready"
                    exit 0
                else
                    echo "Backend service is not ready"
                    exit 1
                fi
            # stop: |
            # 	echo "Stopping backend service..."
            # 	rm /tmp/backend.started
            #   pkill backend
        triggeredBy:
            - postEnvironmentStart
            # - postDevcontainerStart
        `.trim(),
    },
};
