import { cn } from "@/components/podkit/lib/cn";
import { Text } from "@/components/podkit/typography/Text";
import type { PlainService } from "@/queries/automations-queries";
import { ServicePhase } from "gitpod-next-api/gitpod/v1/environment_automation_pb";
import { type FC, useMemo } from "react";

export const EnvironmentServiceStatus: FC<{ service: PlainService }> = ({ service }) => {
    const variant: { label: string; textColor: string; bgColor: string } | undefined = useMemo(() => {
        const sameSession = service.status?.session === service.spec?.session;
        if (!sameSession) {
            // Optimistically update state to STARTING
            if (service.spec?.desiredPhase === ServicePhase.RUNNING) {
                return {
                    label: "Starting",
                    textColor: "text-content-yield",
                    bgColor: "bg-surface-yield",
                };
            }
        }
        switch (service.status?.phase) {
            case ServicePhase.UNSPECIFIED:
                return {
                    label: "Unspecified",
                    textColor: "text-content-tertiary",
                    bgColor: "bg-surface-03",
                };
            case ServicePhase.STOPPED:
                return {
                    label: "Stopped",
                    textColor: "text-content-tertiary",
                    bgColor: "bg-surface-03",
                };
            case ServicePhase.DELETED:
                return {
                    label: "Deleted",
                    textColor: "text-content-tertiary",
                    bgColor: "bg-surface-03",
                };
            case ServicePhase.STOPPING:
                return {
                    label: "Stopping",
                    textColor: "text-content-yield",
                    bgColor: "bg-surface-yield",
                };
            case ServicePhase.STARTING:
                return {
                    label: "Starting",
                    textColor: "text-content-yield",
                    bgColor: "bg-surface-yield",
                };
            case ServicePhase.RUNNING:
                if (
                    service.spec?.desiredPhase === ServicePhase.STOPPED ||
                    service.spec?.desiredPhase === ServicePhase.DELETED
                ) {
                    // Optimistically update state to STOPPING.
                    return {
                        label: "Stopping",
                        textColor: "text-content-yield",
                        bgColor: "bg-surface-yield",
                    };
                }
                return {
                    label: "Running",
                    textColor: "text-content-green",
                    bgColor: "bg-surface-green",
                };
            case ServicePhase.FAILED:
                return {
                    label: "Failed",
                    textColor: "text-content-red",
                    bgColor: "bg-surface-red",
                };
        }
    }, [service.spec?.desiredPhase, service.spec?.session, service.status?.phase, service.status?.session]);

    if (!variant) {
        return null;
    }

    return (
        <div className={cn("flex content-center items-center rounded-xl px-2 py-[2px]", variant.bgColor)}>
            <Text className={cn("text-sm font-bold", variant.textColor)}>{variant.label}</Text>
        </div>
    );
};
