import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { useShutdownIntercom } from "@/hooks/use-intercom";
import { useResetSegment } from "@/hooks/use-segment";
import { getPrincipal } from "@/principal";
import { getAccountQueryKey, useGetAccount } from "@/queries/account-queries";
import { isNotFoundError, isUnauthorizedError } from "@/queries/errors";
import { keyWithPrincipal } from "@/queries/principal-key";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { type User } from "gitpod-next-api/gitpod/v1/user_pb";

export type PlainUser = PlainMessage<User>;

export const getAuthenticatedUserQueryKey = () => keyWithPrincipal(["user", "authenticated"]);

export const useAuthenticatedUser = () => {
    const api = useGitpodAPI();
    const { data: account } = useGetAccount();

    const query = useQuery({
        queryKey: getAuthenticatedUserQueryKey(),
        enabled: !!account && !!getPrincipal(),
        queryFn: async () => {
            try {
                const { user } = await api.userService.getAuthenticatedUser(
                    {},
                    {
                        timeoutMs: 1000 * 30,
                    },
                );

                if (!user) {
                    throw new Error("User not found");
                }
                return toPlainMessage(user);
            } catch (error) {
                if (isUnauthorizedError(error)) {
                    // TODO(at) implicit rerender into create/join org selector
                    return undefined;
                }
                if (isNotFoundError(error)) {
                    // TODO(at) implicit rerender into create/join org selector
                    return undefined;
                }

                // Otherwise throw like normal
                throw error;
            }
        },
        retry(_failureCount, error) {
            // Don't bother retrying if it's an unauthenticated error
            if (isUnauthorizedError(error)) {
                return false;
            }
            if (_failureCount > 2) {
                return false;
            }

            return true;
        },
        refetchInterval(query) {
            // Don't refetch if unauthenticated
            if (isUnauthorizedError(query.state.error) || query.state.data === null) {
                return false;
            }

            // TODO(at) decide if this is still necessary

            // otherwise refetch every minute
            return 1000 * 60;
        },
        refetchOnWindowFocus: true,
        staleTime: 500, // using 500ms as to debounce on the initial load
        gcTime: 1_000 * 60 * 5, // 5 minutes
    });
    return query;
};

export const useLogout = () => {
    const { data: account } = useGetAccount();
    const client = useQueryClient();
    const shutdownIntercom = useShutdownIntercom();
    const resetMixpanel = useResetSegment();

    return useMutation({
        mutationFn: async () => {
            if (!account) {
                throw new Error("Not authenticated");
            }

            const resp = await fetch("/auth/oidc/logout", {
                method: "POST",
                credentials: "same-origin",
            });

            return resp;
        },
        onSuccess: () => {
            client.clear();
            shutdownIntercom();
            resetMixpanel();
        },
    });
};

export const useSuspendUser = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ userId, suspended }: { userId: string; suspended: boolean }) => {
            return await api.userService.setSuspended({
                userId,
                suspended,
            });
        },
        onSuccess: async (_, { userId }) => {
            if (!user) {
                return;
            }

            if (user.id === userId) {
                // We've just removed ourselves from the org
                await client.invalidateQueries({
                    queryKey: getAccountQueryKey(),
                });
            }
        },
    });
};
