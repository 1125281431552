import { useContext } from "react";
import { GitpodAPIContext } from "@/contexts/GitpodAPIContext";

export const useGitpodAPI = () => {
    const api = useContext(GitpodAPIContext);

    if (api === undefined) {
        throw new Error("useGitpodAPI must be used within a GitpodAPIProvider");
    }

    return api;
};
