import { EnvironmentLogs } from "@/components/EnvironmentLogs";
import { BackButton } from "@/components/environments/BackButton";
import { EnvironmentDetailsHeader } from "@/components/environments/EnvironmentDetailsHeader";
import { EnvironmentServiceActions } from "@/components/environments/EnvironmentServiceActions";
import { EnvironmentServiceStatus } from "@/components/environments/EnvironmentServiceStatus";
import { ErrorMessage } from "@/components/ErrorMessage";
import { cn } from "@/components/podkit/lib/cn";
import { Text } from "@/components/podkit/typography/Text";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useGetService } from "@/queries/automations-queries";
import { useEnvironment } from "@/queries/environment-queries";
import { serviceLogsUrl } from "@/utils/service";
import { Code } from "@connectrpc/connect";
import { type FC } from "react";
import { useParams } from "react-router-dom";

export const ServiceLogsPage: FC = () => {
    const { environmentId, serviceId } = useParams();
    useDocumentTitle("Service - Logs");

    const { data: environment, error: environmentError } = useEnvironment(environmentId);
    const { data: service, error: serviceError } = useGetService(serviceId);

    if (environmentError || serviceError) {
        return (
            <div className="flex size-full flex-col items-center justify-center gap-2">
                <ErrorMessage
                    error={environmentError}
                    messages={{
                        [Code.InvalidArgument]: "Invalid environment ID",
                    }}
                />
                <ErrorMessage
                    error={serviceError}
                    messages={{
                        [Code.InvalidArgument]: "Invalid service ID",
                    }}
                />
                <Text className="text-sm text-content-negative">Environment ID: {environmentId}</Text>
                <Text className="text-sm text-content-negative">Service ID: {serviceId}</Text>
            </div>
        );
    }

    const logsUrl = serviceLogsUrl(service);

    return (
        <div className={cn("flex size-full flex-col")}>
            {environmentId && serviceId && (
                <div className="flex flex-col gap-4">
                    <EnvironmentDetailsHeader showActionBar={false} />
                    <div className="flex items-center justify-between gap-3 pb-4">
                        <BackButton showLabel={false} className="size-10 rounded-[20px] p-0" />
                        {service && (
                            <div className="flex w-full min-w-0 items-center justify-between gap-2 rounded-xl border border-border-base bg-surface-glass p-3">
                                <div className="flex grow items-center gap-2 truncate">
                                    <div className="flex w-full min-w-0 flex-col">
                                        <Text className="truncate text-base font-bold text-content-primary">
                                            {service.metadata?.name}
                                        </Text>
                                        <Text className="truncate text-sm text-content-secondary">
                                            {service.metadata?.description}
                                        </Text>
                                    </div>
                                </div>
                                <EnvironmentServiceActions
                                    service={service}
                                    environmentId={environmentId}
                                    showLogs={false}
                                    disabled={false}
                                    withLabels={true}
                                />
                                <EnvironmentServiceStatus service={service} />
                            </div>
                        )}
                    </div>
                </div>
            )}
            <EnvironmentLogs environment={environment} logsUrl={logsUrl} filterLogLinesWithoutTimestamps={false} />
        </div>
    );
};
