// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/user.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { Subject } from "./identity_pb.js";

/**
 * @generated from enum gitpod.v1.UserStatus
 */
export enum UserStatus {
  /**
   * @generated from enum value: USER_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: USER_STATUS_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: USER_STATUS_SUSPENDED = 2;
   */
  SUSPENDED = 2,

  /**
   * @generated from enum value: USER_STATUS_LEFT = 3;
   */
  LEFT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(UserStatus)
proto3.util.setEnumType(UserStatus, "gitpod.v1.UserStatus", [
  { no: 0, name: "USER_STATUS_UNSPECIFIED" },
  { no: 1, name: "USER_STATUS_ACTIVE" },
  { no: 2, name: "USER_STATUS_SUSPENDED" },
  { no: 3, name: "USER_STATUS_LEFT" },
]);

/**
 * @generated from message gitpod.v1.GetAuthenticatedUserRequest
 */
export class GetAuthenticatedUserRequest extends Message<GetAuthenticatedUserRequest> {
  constructor(data?: PartialMessage<GetAuthenticatedUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetAuthenticatedUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthenticatedUserRequest {
    return new GetAuthenticatedUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthenticatedUserRequest {
    return new GetAuthenticatedUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthenticatedUserRequest {
    return new GetAuthenticatedUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthenticatedUserRequest | PlainMessage<GetAuthenticatedUserRequest> | undefined, b: GetAuthenticatedUserRequest | PlainMessage<GetAuthenticatedUserRequest> | undefined): boolean {
    return proto3.util.equals(GetAuthenticatedUserRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetAuthenticatedUserResponse
 */
export class GetAuthenticatedUserResponse extends Message<GetAuthenticatedUserResponse> {
  /**
   * @generated from field: gitpod.v1.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<GetAuthenticatedUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetAuthenticatedUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthenticatedUserResponse {
    return new GetAuthenticatedUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthenticatedUserResponse {
    return new GetAuthenticatedUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthenticatedUserResponse {
    return new GetAuthenticatedUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthenticatedUserResponse | PlainMessage<GetAuthenticatedUserResponse> | undefined, b: GetAuthenticatedUserResponse | PlainMessage<GetAuthenticatedUserResponse> | undefined): boolean {
    return proto3.util.equals(GetAuthenticatedUserResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateUserRequest
 */
export class UpdateUserRequest extends Message<UpdateUserRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: optional string name = 2;
   */
  name?: string;

  constructor(data?: PartialMessage<UpdateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined, b: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateUserResponse
 */
export class UpdateUserResponse extends Message<UpdateUserResponse> {
  /**
   * @generated from field: gitpod.v1.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<UpdateUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserResponse | PlainMessage<UpdateUserResponse> | undefined, b: UpdateUserResponse | PlainMessage<UpdateUserResponse> | undefined): boolean {
    return proto3.util.equals(UpdateUserResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SetSuspendedRequest
 */
export class SetSuspendedRequest extends Message<SetSuspendedRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: bool suspended = 2;
   */
  suspended = false;

  constructor(data?: PartialMessage<SetSuspendedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SetSuspendedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "suspended", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetSuspendedRequest {
    return new SetSuspendedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetSuspendedRequest {
    return new SetSuspendedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetSuspendedRequest {
    return new SetSuspendedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetSuspendedRequest | PlainMessage<SetSuspendedRequest> | undefined, b: SetSuspendedRequest | PlainMessage<SetSuspendedRequest> | undefined): boolean {
    return proto3.util.equals(SetSuspendedRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SetSuspendedResponse
 */
export class SetSuspendedResponse extends Message<SetSuspendedResponse> {
  constructor(data?: PartialMessage<SetSuspendedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SetSuspendedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetSuspendedResponse {
    return new SetSuspendedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetSuspendedResponse {
    return new SetSuspendedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetSuspendedResponse {
    return new SetSuspendedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetSuspendedResponse | PlainMessage<SetSuspendedResponse> | undefined, b: SetSuspendedResponse | PlainMessage<SetSuspendedResponse> | undefined): boolean {
    return proto3.util.equals(SetSuspendedResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.User
 */
export class User extends Message<User> {
  /**
   * id is a UUID of the user
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * organization_id is the id of the organization this account is owned by.
   *
   * +optional if not set, this account is owned by the installation.
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * name is the full name of the user
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * avatar_url is a link to the user avatar
   *
   * @generated from field: string avatar_url = 4;
   */
  avatarUrl = "";

  /**
   * created_at is the creation time
   *
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * status is the status the user is in
   *
   * @generated from field: gitpod.v1.UserStatus status = 6;
   */
  status = UserStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "avatar_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(UserStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreatePersonalAccessTokenRequest
 */
export class CreatePersonalAccessTokenRequest extends Message<CreatePersonalAccessTokenRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Duration valid_for = 3;
   */
  validFor?: Duration;

  constructor(data?: PartialMessage<CreatePersonalAccessTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreatePersonalAccessTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "valid_for", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePersonalAccessTokenRequest {
    return new CreatePersonalAccessTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePersonalAccessTokenRequest {
    return new CreatePersonalAccessTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePersonalAccessTokenRequest {
    return new CreatePersonalAccessTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePersonalAccessTokenRequest | PlainMessage<CreatePersonalAccessTokenRequest> | undefined, b: CreatePersonalAccessTokenRequest | PlainMessage<CreatePersonalAccessTokenRequest> | undefined): boolean {
    return proto3.util.equals(CreatePersonalAccessTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreatePersonalAccessTokenResponse
 */
export class CreatePersonalAccessTokenResponse extends Message<CreatePersonalAccessTokenResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<CreatePersonalAccessTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreatePersonalAccessTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePersonalAccessTokenResponse {
    return new CreatePersonalAccessTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePersonalAccessTokenResponse {
    return new CreatePersonalAccessTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePersonalAccessTokenResponse {
    return new CreatePersonalAccessTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePersonalAccessTokenResponse | PlainMessage<CreatePersonalAccessTokenResponse> | undefined, b: CreatePersonalAccessTokenResponse | PlainMessage<CreatePersonalAccessTokenResponse> | undefined): boolean {
    return proto3.util.equals(CreatePersonalAccessTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetPersonalAccessTokenRequest
 */
export class GetPersonalAccessTokenRequest extends Message<GetPersonalAccessTokenRequest> {
  /**
   * @generated from field: string personal_access_token_id = 1;
   */
  personalAccessTokenId = "";

  constructor(data?: PartialMessage<GetPersonalAccessTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetPersonalAccessTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "personal_access_token_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPersonalAccessTokenRequest {
    return new GetPersonalAccessTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPersonalAccessTokenRequest {
    return new GetPersonalAccessTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPersonalAccessTokenRequest {
    return new GetPersonalAccessTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPersonalAccessTokenRequest | PlainMessage<GetPersonalAccessTokenRequest> | undefined, b: GetPersonalAccessTokenRequest | PlainMessage<GetPersonalAccessTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetPersonalAccessTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetPersonalAccessTokenResponse
 */
export class GetPersonalAccessTokenResponse extends Message<GetPersonalAccessTokenResponse> {
  /**
   * @generated from field: gitpod.v1.PersonalAccessToken pat = 1;
   */
  pat?: PersonalAccessToken;

  constructor(data?: PartialMessage<GetPersonalAccessTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetPersonalAccessTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pat", kind: "message", T: PersonalAccessToken },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPersonalAccessTokenResponse {
    return new GetPersonalAccessTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPersonalAccessTokenResponse {
    return new GetPersonalAccessTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPersonalAccessTokenResponse {
    return new GetPersonalAccessTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPersonalAccessTokenResponse | PlainMessage<GetPersonalAccessTokenResponse> | undefined, b: GetPersonalAccessTokenResponse | PlainMessage<GetPersonalAccessTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetPersonalAccessTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListPersonalAccessTokensRequest
 */
export class ListPersonalAccessTokensRequest extends Message<ListPersonalAccessTokensRequest> {
  /**
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * @generated from field: gitpod.v1.ListPersonalAccessTokensRequest.Filter filter = 2;
   */
  filter?: ListPersonalAccessTokensRequest_Filter;

  constructor(data?: PartialMessage<ListPersonalAccessTokensRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListPersonalAccessTokensRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListPersonalAccessTokensRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPersonalAccessTokensRequest {
    return new ListPersonalAccessTokensRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPersonalAccessTokensRequest {
    return new ListPersonalAccessTokensRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPersonalAccessTokensRequest {
    return new ListPersonalAccessTokensRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListPersonalAccessTokensRequest | PlainMessage<ListPersonalAccessTokensRequest> | undefined, b: ListPersonalAccessTokensRequest | PlainMessage<ListPersonalAccessTokensRequest> | undefined): boolean {
    return proto3.util.equals(ListPersonalAccessTokensRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListPersonalAccessTokensRequest.Filter
 */
export class ListPersonalAccessTokensRequest_Filter extends Message<ListPersonalAccessTokensRequest_Filter> {
  /**
   * creator_ids filters the response to only Environments created by specified members
   *
   * @generated from field: repeated string user_ids = 3;
   */
  userIds: string[] = [];

  constructor(data?: PartialMessage<ListPersonalAccessTokensRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListPersonalAccessTokensRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "user_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPersonalAccessTokensRequest_Filter {
    return new ListPersonalAccessTokensRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPersonalAccessTokensRequest_Filter {
    return new ListPersonalAccessTokensRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPersonalAccessTokensRequest_Filter {
    return new ListPersonalAccessTokensRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListPersonalAccessTokensRequest_Filter | PlainMessage<ListPersonalAccessTokensRequest_Filter> | undefined, b: ListPersonalAccessTokensRequest_Filter | PlainMessage<ListPersonalAccessTokensRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListPersonalAccessTokensRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListPersonalAccessTokensResponse
 */
export class ListPersonalAccessTokensResponse extends Message<ListPersonalAccessTokensResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.PersonalAccessToken personal_access_tokens = 2;
   */
  personalAccessTokens: PersonalAccessToken[] = [];

  constructor(data?: PartialMessage<ListPersonalAccessTokensResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListPersonalAccessTokensResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "personal_access_tokens", kind: "message", T: PersonalAccessToken, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPersonalAccessTokensResponse {
    return new ListPersonalAccessTokensResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPersonalAccessTokensResponse {
    return new ListPersonalAccessTokensResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPersonalAccessTokensResponse {
    return new ListPersonalAccessTokensResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListPersonalAccessTokensResponse | PlainMessage<ListPersonalAccessTokensResponse> | undefined, b: ListPersonalAccessTokensResponse | PlainMessage<ListPersonalAccessTokensResponse> | undefined): boolean {
    return proto3.util.equals(ListPersonalAccessTokensResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeletePersonalAccessTokenRequest
 */
export class DeletePersonalAccessTokenRequest extends Message<DeletePersonalAccessTokenRequest> {
  /**
   * @generated from field: string personal_access_token_id = 1;
   */
  personalAccessTokenId = "";

  constructor(data?: PartialMessage<DeletePersonalAccessTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeletePersonalAccessTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "personal_access_token_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePersonalAccessTokenRequest {
    return new DeletePersonalAccessTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePersonalAccessTokenRequest {
    return new DeletePersonalAccessTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePersonalAccessTokenRequest {
    return new DeletePersonalAccessTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePersonalAccessTokenRequest | PlainMessage<DeletePersonalAccessTokenRequest> | undefined, b: DeletePersonalAccessTokenRequest | PlainMessage<DeletePersonalAccessTokenRequest> | undefined): boolean {
    return proto3.util.equals(DeletePersonalAccessTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeletePersonalAccessTokenResponse
 */
export class DeletePersonalAccessTokenResponse extends Message<DeletePersonalAccessTokenResponse> {
  constructor(data?: PartialMessage<DeletePersonalAccessTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeletePersonalAccessTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePersonalAccessTokenResponse {
    return new DeletePersonalAccessTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePersonalAccessTokenResponse {
    return new DeletePersonalAccessTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePersonalAccessTokenResponse {
    return new DeletePersonalAccessTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePersonalAccessTokenResponse | PlainMessage<DeletePersonalAccessTokenResponse> | undefined, b: DeletePersonalAccessTokenResponse | PlainMessage<DeletePersonalAccessTokenResponse> | undefined): boolean {
    return proto3.util.equals(DeletePersonalAccessTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.PersonalAccessToken
 */
export class PersonalAccessToken extends Message<PersonalAccessToken> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: gitpod.v1.Subject creator = 4;
   */
  creator?: Subject;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expires_at = 6;
   */
  expiresAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_used = 7;
   */
  lastUsed?: Timestamp;

  constructor(data?: PartialMessage<PersonalAccessToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.PersonalAccessToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "creator", kind: "message", T: Subject },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "expires_at", kind: "message", T: Timestamp },
    { no: 7, name: "last_used", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PersonalAccessToken {
    return new PersonalAccessToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PersonalAccessToken {
    return new PersonalAccessToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PersonalAccessToken {
    return new PersonalAccessToken().fromJsonString(jsonString, options);
  }

  static equals(a: PersonalAccessToken | PlainMessage<PersonalAccessToken> | undefined, b: PersonalAccessToken | PlainMessage<PersonalAccessToken> | undefined): boolean {
    return proto3.util.equals(PersonalAccessToken, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SetPreferenceRequest
 */
export class SetPreferenceRequest extends Message<SetPreferenceRequest> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * omitting value field will cause an error due to min_len validation,
   * to unset a preference use DeletePreference instead
   *
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<SetPreferenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SetPreferenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetPreferenceRequest {
    return new SetPreferenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetPreferenceRequest {
    return new SetPreferenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetPreferenceRequest {
    return new SetPreferenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetPreferenceRequest | PlainMessage<SetPreferenceRequest> | undefined, b: SetPreferenceRequest | PlainMessage<SetPreferenceRequest> | undefined): boolean {
    return proto3.util.equals(SetPreferenceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SetPreferenceResponse
 */
export class SetPreferenceResponse extends Message<SetPreferenceResponse> {
  constructor(data?: PartialMessage<SetPreferenceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SetPreferenceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetPreferenceResponse {
    return new SetPreferenceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetPreferenceResponse {
    return new SetPreferenceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetPreferenceResponse {
    return new SetPreferenceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetPreferenceResponse | PlainMessage<SetPreferenceResponse> | undefined, b: SetPreferenceResponse | PlainMessage<SetPreferenceResponse> | undefined): boolean {
    return proto3.util.equals(SetPreferenceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetPreferenceRequest
 */
export class GetPreferenceRequest extends Message<GetPreferenceRequest> {
  /**
   * @generated from field: string user_preference_id = 1;
   */
  userPreferenceId = "";

  constructor(data?: PartialMessage<GetPreferenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetPreferenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_preference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPreferenceRequest {
    return new GetPreferenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPreferenceRequest {
    return new GetPreferenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPreferenceRequest {
    return new GetPreferenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPreferenceRequest | PlainMessage<GetPreferenceRequest> | undefined, b: GetPreferenceRequest | PlainMessage<GetPreferenceRequest> | undefined): boolean {
    return proto3.util.equals(GetPreferenceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetPreferenceResponse
 */
export class GetPreferenceResponse extends Message<GetPreferenceResponse> {
  /**
   * @generated from field: gitpod.v1.UserPreference preference = 1;
   */
  preference?: UserPreference;

  constructor(data?: PartialMessage<GetPreferenceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetPreferenceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "preference", kind: "message", T: UserPreference },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPreferenceResponse {
    return new GetPreferenceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPreferenceResponse {
    return new GetPreferenceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPreferenceResponse {
    return new GetPreferenceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPreferenceResponse | PlainMessage<GetPreferenceResponse> | undefined, b: GetPreferenceResponse | PlainMessage<GetPreferenceResponse> | undefined): boolean {
    return proto3.util.equals(GetPreferenceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListPreferencesRequest
 */
export class ListPreferencesRequest extends Message<ListPreferencesRequest> {
  /**
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  constructor(data?: PartialMessage<ListPreferencesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListPreferencesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPreferencesRequest {
    return new ListPreferencesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPreferencesRequest {
    return new ListPreferencesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPreferencesRequest {
    return new ListPreferencesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListPreferencesRequest | PlainMessage<ListPreferencesRequest> | undefined, b: ListPreferencesRequest | PlainMessage<ListPreferencesRequest> | undefined): boolean {
    return proto3.util.equals(ListPreferencesRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListPreferencesResponse
 */
export class ListPreferencesResponse extends Message<ListPreferencesResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.UserPreference preferences = 2;
   */
  preferences: UserPreference[] = [];

  constructor(data?: PartialMessage<ListPreferencesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListPreferencesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "preferences", kind: "message", T: UserPreference, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPreferencesResponse {
    return new ListPreferencesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPreferencesResponse {
    return new ListPreferencesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPreferencesResponse {
    return new ListPreferencesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListPreferencesResponse | PlainMessage<ListPreferencesResponse> | undefined, b: ListPreferencesResponse | PlainMessage<ListPreferencesResponse> | undefined): boolean {
    return proto3.util.equals(ListPreferencesResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeletePreferenceRequest
 */
export class DeletePreferenceRequest extends Message<DeletePreferenceRequest> {
  /**
   * @generated from field: string user_preference_id = 1;
   */
  userPreferenceId = "";

  constructor(data?: PartialMessage<DeletePreferenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeletePreferenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_preference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePreferenceRequest {
    return new DeletePreferenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePreferenceRequest {
    return new DeletePreferenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePreferenceRequest {
    return new DeletePreferenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePreferenceRequest | PlainMessage<DeletePreferenceRequest> | undefined, b: DeletePreferenceRequest | PlainMessage<DeletePreferenceRequest> | undefined): boolean {
    return proto3.util.equals(DeletePreferenceRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeletePreferenceResponse
 */
export class DeletePreferenceResponse extends Message<DeletePreferenceResponse> {
  constructor(data?: PartialMessage<DeletePreferenceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeletePreferenceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePreferenceResponse {
    return new DeletePreferenceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePreferenceResponse {
    return new DeletePreferenceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePreferenceResponse {
    return new DeletePreferenceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePreferenceResponse | PlainMessage<DeletePreferenceResponse> | undefined, b: DeletePreferenceResponse | PlainMessage<DeletePreferenceResponse> | undefined): boolean {
    return proto3.util.equals(DeletePreferenceResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UserPreference
 */
export class UserPreference extends Message<UserPreference> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * omitting value field will cause an error due to min_len validation,
   * to unset a preference use DeletePreference instead
   *
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<UserPreference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UserPreference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPreference {
    return new UserPreference().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPreference {
    return new UserPreference().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPreference {
    return new UserPreference().fromJsonString(jsonString, options);
  }

  static equals(a: UserPreference | PlainMessage<UserPreference> | undefined, b: UserPreference | PlainMessage<UserPreference> | undefined): boolean {
    return proto3.util.equals(UserPreference, a, b);
  }
}

