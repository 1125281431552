import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconElipseHorizontal: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.5 8C4.5 8.72488 3.91238 9.3125 3.1875 9.3125C2.46262 9.3125 1.875 8.72488 1.875 8C1.875 7.27512 2.46262 6.6875 3.1875 6.6875C3.91238 6.6875 4.5 7.27512 4.5 8ZM9.3125 8C9.3125 8.72488 8.72488 9.3125 8 9.3125C7.27512 9.3125 6.6875 8.72488 6.6875 8C6.6875 7.27512 7.27512 6.6875 8 6.6875C8.72488 6.6875 9.3125 7.27512 9.3125 8ZM12.8125 9.3125C13.5373 9.3125 14.125 8.72488 14.125 8C14.125 7.27512 13.5373 6.6875 12.8125 6.6875C12.0877 6.6875 11.5 7.27512 11.5 8C11.5 8.72488 12.0877 9.3125 12.8125 9.3125Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.5 12C7.5 12.932 6.74448 13.6875 5.8125 13.6875C4.88052 13.6875 4.125 12.932 4.125 12C4.125 11.068 4.88052 10.3125 5.8125 10.3125C6.74448 10.3125 7.5 11.068 7.5 12ZM13.6875 12C13.6875 12.932 12.932 13.6875 12 13.6875C11.068 13.6875 10.3125 12.932 10.3125 12C10.3125 11.068 11.068 10.3125 12 10.3125C12.932 10.3125 13.6875 11.068 13.6875 12ZM18.1875 13.6875C19.1194 13.6875 19.875 12.932 19.875 12C19.875 11.068 19.1194 10.3125 18.1875 10.3125C17.2556 10.3125 16.5 11.068 16.5 12C16.5 12.932 17.2556 13.6875 18.1875 13.6875Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.5 12C7.5 12.932 6.74448 13.6875 5.8125 13.6875C4.88052 13.6875 4.125 12.932 4.125 12C4.125 11.068 4.88052 10.3125 5.8125 10.3125C6.74448 10.3125 7.5 11.068 7.5 12ZM13.6875 12C13.6875 12.932 12.932 13.6875 12 13.6875C11.068 13.6875 10.3125 12.932 10.3125 12C10.3125 11.068 11.068 10.3125 12 10.3125C12.932 10.3125 13.6875 11.068 13.6875 12ZM18.1875 13.6875C19.1194 13.6875 19.875 12.932 19.875 12C19.875 11.068 19.1194 10.3125 18.1875 10.3125C17.2556 10.3125 16.5 11.068 16.5 12C16.5 12.932 17.2556 13.6875 18.1875 13.6875Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
