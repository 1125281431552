// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/runner_interaction.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetHostAuthenticationTokenValueRequest, GetHostAuthenticationTokenValueResponse, GetLatestVersionRequest, GetLatestVersionResponse, GetRunnerEnvironmentRequest, GetRunnerEnvironmentResponse, ListRunnerEnvironmentClassesRequest, ListRunnerEnvironmentClassesResponse, ListRunnerEnvironmentsRequest, ListRunnerEnvironmentsResponse, ListRunnerSCMIntegrationsRequest, ListRunnerSCMIntegrationsResponse, MarkRunnerActiveRequest, MarkRunnerActiveResponse, SendResponseRequest, SendResponseResponse, SignupRequest, SignupResponse, UpdateRunnerConfigurationSchemaRequest, UpdateRunnerConfigurationSchemaResponse, UpdateRunnerEnvironmentStatusRequest, UpdateRunnerEnvironmentStatusResponse, UpdateRunnerStatusRequest, UpdateRunnerStatusResponse, WatchRequestsRequest, WatchRequestsResponse } from "./runner_interaction_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * RunnerInteractionService provides a way for the backend to interact with environment runners.
 *
 * @generated from service gitpod.v1.RunnerInteractionService
 */
export const RunnerInteractionService = {
  typeName: "gitpod.v1.RunnerInteractionService",
  methods: {
    /**
     * Signup is called by a runner to sign up with the backend. This is the first call a runner makes.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.Signup
     */
    signup: {
      name: "Signup",
      I: SignupRequest,
      O: SignupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * MarkRunnerActive marks a runner as available. This must be called every 30 seconds
     * to keep the runner active.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.MarkRunnerActive
     */
    markRunnerActive: {
      name: "MarkRunnerActive",
      I: MarkRunnerActiveRequest,
      O: MarkRunnerActiveResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetRunnerEnvironment returns the environment given it is owned by the runner.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.GetRunnerEnvironment
     */
    getRunnerEnvironment: {
      name: "GetRunnerEnvironment",
      I: GetRunnerEnvironmentRequest,
      O: GetRunnerEnvironmentResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListRunnerEnvironments returns the environments this runner is responsible for.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.ListRunnerEnvironments
     */
    listRunnerEnvironments: {
      name: "ListRunnerEnvironments",
      I: ListRunnerEnvironmentsRequest,
      O: ListRunnerEnvironmentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateRunnerEnvironmentStatus updates the status of an environment this runner is responsible for.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.UpdateRunnerEnvironmentStatus
     */
    updateRunnerEnvironmentStatus: {
      name: "UpdateRunnerEnvironmentStatus",
      I: UpdateRunnerEnvironmentStatusRequest,
      O: UpdateRunnerEnvironmentStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateRunnerStatus updates the status of the runner.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.UpdateRunnerStatus
     */
    updateRunnerStatus: {
      name: "UpdateRunnerStatus",
      I: UpdateRunnerStatusRequest,
      O: UpdateRunnerStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateRunnerConfigurationSchema updates the runner's configuration schema.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.UpdateRunnerConfigurationSchema
     */
    updateRunnerConfigurationSchema: {
      name: "UpdateRunnerConfigurationSchema",
      I: UpdateRunnerConfigurationSchemaRequest,
      O: UpdateRunnerConfigurationSchemaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetRunnerHostAuthenticationToken returns an authentication token for the given host.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.GetHostAuthenticationTokenValue
     */
    getHostAuthenticationTokenValue: {
      name: "GetHostAuthenticationTokenValue",
      I: GetHostAuthenticationTokenValueRequest,
      O: GetHostAuthenticationTokenValueResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListRunnerEnvironmentClasses returns the environment classes configured for the runner.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.ListRunnerEnvironmentClasses
     */
    listRunnerEnvironmentClasses: {
      name: "ListRunnerEnvironmentClasses",
      I: ListRunnerEnvironmentClassesRequest,
      O: ListRunnerEnvironmentClassesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListRunnerSCMIntegrations returns the SCM integrations configured for the runner.
     * For local runners, this returns the SCM integrations configured on the organization's
     * local-configuration runner.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.ListRunnerSCMIntegrations
     */
    listRunnerSCMIntegrations: {
      name: "ListRunnerSCMIntegrations",
      I: ListRunnerSCMIntegrationsRequest,
      O: ListRunnerSCMIntegrationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * WatchRequests returns a stream of requests for this runner to handle.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.WatchRequests
     */
    watchRequests: {
      name: "WatchRequests",
      I: WatchRequestsRequest,
      O: WatchRequestsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SendResponse sends a response to a request.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.SendResponse
     */
    sendResponse: {
      name: "SendResponse",
      I: SendResponseRequest,
      O: SendResponseResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetLatestVersion returns the latest version of the runner.
     *
     * @generated from rpc gitpod.v1.RunnerInteractionService.GetLatestVersion
     */
    getLatestVersion: {
      name: "GetLatestVersion",
      I: GetLatestVersionRequest,
      O: GetLatestVersionResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

