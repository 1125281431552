import { type FC, type PropsWithChildren, useMemo } from "react";
import { type AnalyticsBrowser } from "@segment/analytics-next";
import { SegmentContext } from "@/contexts/SegmentContext";

export const SegmentProvider: FC<PropsWithChildren & { create?: () => AnalyticsBrowser }> = ({ create, children }) => {
    const context = useMemo(() => {
        if (create) {
            return create();
        }
        return undefined;
    }, [create]);

    return <SegmentContext.Provider value={context}>{children}</SegmentContext.Provider>;
};
