import { Code, ConnectError } from "@connectrpc/connect";

export const isUnauthorizedError = (error: unknown) => {
    return error instanceof ConnectError && error.code === Code.Unauthenticated;
};

export const isNotFoundError = (error: unknown) => {
    return error instanceof ConnectError && error.code === Code.NotFound;
};

export const defaultRetry = (failureCount: number, error: Error) => {
    if (error instanceof ConnectError) {
        switch (error.code) {
            case Code.DeadlineExceeded:
                return true;
            case Code.Unauthenticated:
            case Code.PermissionDenied:
            case Code.NotFound:
            case Code.InvalidArgument:
            case Code.FailedPrecondition:
            case Code.Internal:
                return false;
        }
    }
    return failureCount < 3;
};

/**
From docs:

> If set to `true` [...], all errors will be thrown to the error boundary.


So, we carefully select exceptional errors to throw by default,
and let the global error boundary handle them.

In any other case, it should be up to the subscribing component
to handle error states locally.
*/
export const defaultThrowOnError = (error: Error) => {
    if (error instanceof ConnectError) {
        switch (error.code) {
            case Code.DeadlineExceeded:
            case Code.Unauthenticated:
            case Code.PermissionDenied:
            case Code.NotFound:
            case Code.InvalidArgument:
            case Code.FailedPrecondition:
                return false;
            case Code.Internal:
                return true;
        }
    }
    return false;
};
