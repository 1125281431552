// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/identity.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum gitpod.v1.Principal
 */
export enum Principal {
  /**
   * @generated from enum value: PRINCIPAL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRINCIPAL_ACCOUNT = 1;
   */
  ACCOUNT = 1,

  /**
   * @generated from enum value: PRINCIPAL_USER = 2;
   */
  USER = 2,

  /**
   * @generated from enum value: PRINCIPAL_RUNNER = 3;
   */
  RUNNER = 3,

  /**
   * @generated from enum value: PRINCIPAL_ENVIRONMENT = 4;
   */
  ENVIRONMENT = 4,

  /**
   * @generated from enum value: PRINCIPAL_SERVICE_ACCOUNT = 5;
   */
  SERVICE_ACCOUNT = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Principal)
proto3.util.setEnumType(Principal, "gitpod.v1.Principal", [
  { no: 0, name: "PRINCIPAL_UNSPECIFIED" },
  { no: 1, name: "PRINCIPAL_ACCOUNT" },
  { no: 2, name: "PRINCIPAL_USER" },
  { no: 3, name: "PRINCIPAL_RUNNER" },
  { no: 4, name: "PRINCIPAL_ENVIRONMENT" },
  { no: 5, name: "PRINCIPAL_SERVICE_ACCOUNT" },
]);

/**
 * @generated from message gitpod.v1.GetIDTokenRequest
 */
export class GetIDTokenRequest extends Message<GetIDTokenRequest> {
  /**
   * @generated from field: repeated string audience = 1;
   */
  audience: string[] = [];

  constructor(data?: PartialMessage<GetIDTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetIDTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "audience", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIDTokenRequest {
    return new GetIDTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIDTokenRequest {
    return new GetIDTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIDTokenRequest {
    return new GetIDTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetIDTokenRequest | PlainMessage<GetIDTokenRequest> | undefined, b: GetIDTokenRequest | PlainMessage<GetIDTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetIDTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetIDTokenResponse
 */
export class GetIDTokenResponse extends Message<GetIDTokenResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetIDTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetIDTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetIDTokenResponse {
    return new GetIDTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetIDTokenResponse {
    return new GetIDTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetIDTokenResponse {
    return new GetIDTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetIDTokenResponse | PlainMessage<GetIDTokenResponse> | undefined, b: GetIDTokenResponse | PlainMessage<GetIDTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetIDTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Subject
 */
export class Subject extends Message<Subject> {
  /**
   * id is the UUID of the subject
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Principal is the principal of the subject
   *
   * @generated from field: gitpod.v1.Principal principal = 2;
   */
  principal = Principal.UNSPECIFIED;

  constructor(data?: PartialMessage<Subject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Subject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal", kind: "enum", T: proto3.getEnumType(Principal) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Subject {
    return new Subject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Subject {
    return new Subject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Subject {
    return new Subject().fromJsonString(jsonString, options);
  }

  static equals(a: Subject | PlainMessage<Subject> | undefined, b: Subject | PlainMessage<Subject> | undefined): boolean {
    return proto3.util.equals(Subject, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetAuthenticatedIdentityRequest
 */
export class GetAuthenticatedIdentityRequest extends Message<GetAuthenticatedIdentityRequest> {
  constructor(data?: PartialMessage<GetAuthenticatedIdentityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetAuthenticatedIdentityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthenticatedIdentityRequest {
    return new GetAuthenticatedIdentityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthenticatedIdentityRequest {
    return new GetAuthenticatedIdentityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthenticatedIdentityRequest {
    return new GetAuthenticatedIdentityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthenticatedIdentityRequest | PlainMessage<GetAuthenticatedIdentityRequest> | undefined, b: GetAuthenticatedIdentityRequest | PlainMessage<GetAuthenticatedIdentityRequest> | undefined): boolean {
    return proto3.util.equals(GetAuthenticatedIdentityRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetAuthenticatedIdentityResponse
 */
export class GetAuthenticatedIdentityResponse extends Message<GetAuthenticatedIdentityResponse> {
  /**
   * subject is the identity of the current user
   *
   * @generated from field: gitpod.v1.Subject subject = 1;
   */
  subject?: Subject;

  /**
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  constructor(data?: PartialMessage<GetAuthenticatedIdentityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetAuthenticatedIdentityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject", kind: "message", T: Subject },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthenticatedIdentityResponse {
    return new GetAuthenticatedIdentityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthenticatedIdentityResponse {
    return new GetAuthenticatedIdentityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthenticatedIdentityResponse {
    return new GetAuthenticatedIdentityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthenticatedIdentityResponse | PlainMessage<GetAuthenticatedIdentityResponse> | undefined, b: GetAuthenticatedIdentityResponse | PlainMessage<GetAuthenticatedIdentityResponse> | undefined): boolean {
    return proto3.util.equals(GetAuthenticatedIdentityResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ExchangeTokenRequest
 */
export class ExchangeTokenRequest extends Message<ExchangeTokenRequest> {
  /**
   * exchange_token is the token to exchange
   *
   * @generated from field: string exchange_token = 1;
   */
  exchangeToken = "";

  constructor(data?: PartialMessage<ExchangeTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ExchangeTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "exchange_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExchangeTokenRequest {
    return new ExchangeTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExchangeTokenRequest {
    return new ExchangeTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExchangeTokenRequest {
    return new ExchangeTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExchangeTokenRequest | PlainMessage<ExchangeTokenRequest> | undefined, b: ExchangeTokenRequest | PlainMessage<ExchangeTokenRequest> | undefined): boolean {
    return proto3.util.equals(ExchangeTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ExchangeTokenResponse
 */
export class ExchangeTokenResponse extends Message<ExchangeTokenResponse> {
  /**
   * access_token is the new access token
   *
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  constructor(data?: PartialMessage<ExchangeTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ExchangeTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExchangeTokenResponse {
    return new ExchangeTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExchangeTokenResponse {
    return new ExchangeTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExchangeTokenResponse {
    return new ExchangeTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ExchangeTokenResponse | PlainMessage<ExchangeTokenResponse> | undefined, b: ExchangeTokenResponse | PlainMessage<ExchangeTokenResponse> | undefined): boolean {
    return proto3.util.equals(ExchangeTokenResponse, a, b);
  }
}

