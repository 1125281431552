export function equals<T>(
    one: readonly T[] | undefined,
    other: readonly T[] | undefined,
    itemEquals: (a: T, b: T) => boolean = (a, b) => a === b,
): boolean {
    if (one === other) {
        return true;
    }

    if (!one || !other) {
        return false;
    }

    if (one.length !== other.length) {
        return false;
    }

    for (let i = 0, len = one.length; i < len; i++) {
        if (!itemEquals(one[i], other[i])) {
            return false;
        }
    }

    return true;
}

export function coalesce<T>(array: readonly (T | undefined | null)[]): T[] {
    return array.filter((e): e is T => !!e);
}

export function distinct<T>(array: readonly T[], keyFn: (value: T) => unknown = (value) => value): T[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const seen = new Set<any>();

    return array.filter((element) => {
        const key = keyFn(element);
        if (seen.has(key)) {
            return false;
        }
        seen.add(key);
        return true;
    });
}
