// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/runner_configuration.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateEnvironmentClassRequest, CreateEnvironmentClassResponse, CreateHostAuthenticationTokenRequest, CreateHostAuthenticationTokenResponse, CreateSCMIntegrationRequest, CreateSCMIntegrationResponse, DeleteHostAuthenticationTokenRequest, DeleteHostAuthenticationTokenResponse, DeleteSCMIntegrationRequest, DeleteSCMIntegrationResponse, GetEnvironmentClassRequest, GetEnvironmentClassResponse, GetHostAuthenticationTokenRequest, GetHostAuthenticationTokenResponse, GetRunnerConfigurationSchemaRequest, GetRunnerConfigurationSchemaResponse, GetSCMIntegrationRequest, GetSCMIntegrationResponse, ListEnvironmentClassesRequest, ListEnvironmentClassesResponse, ListHostAuthenticationTokensRequest, ListHostAuthenticationTokensResponse, ListSCMIntegrationsRequest, ListSCMIntegrationsResponse, UpdateEnvironmentClassRequest, UpdateEnvironmentClassResponse, UpdateHostAuthenticationTokenRequest, UpdateHostAuthenticationTokenResponse, UpdateSCMIntegrationRequest, UpdateSCMIntegrationResponse, ValidateRunnerConfigurationRequest, ValidateRunnerConfigurationResponse } from "./runner_configuration_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.RunnerConfigurationService
 */
export const RunnerConfigurationService = {
  typeName: "gitpod.v1.RunnerConfigurationService",
  methods: {
    /**
     * @generated from rpc gitpod.v1.RunnerConfigurationService.CreateHostAuthenticationToken
     */
    createHostAuthenticationToken: {
      name: "CreateHostAuthenticationToken",
      I: CreateHostAuthenticationTokenRequest,
      O: CreateHostAuthenticationTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.RunnerConfigurationService.GetHostAuthenticationToken
     */
    getHostAuthenticationToken: {
      name: "GetHostAuthenticationToken",
      I: GetHostAuthenticationTokenRequest,
      O: GetHostAuthenticationTokenResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.RunnerConfigurationService.ListHostAuthenticationTokens
     */
    listHostAuthenticationTokens: {
      name: "ListHostAuthenticationTokens",
      I: ListHostAuthenticationTokensRequest,
      O: ListHostAuthenticationTokensResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.RunnerConfigurationService.UpdateHostAuthenticationToken
     */
    updateHostAuthenticationToken: {
      name: "UpdateHostAuthenticationToken",
      I: UpdateHostAuthenticationTokenRequest,
      O: UpdateHostAuthenticationTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.RunnerConfigurationService.DeleteHostAuthenticationToken
     */
    deleteHostAuthenticationToken: {
      name: "DeleteHostAuthenticationToken",
      I: DeleteHostAuthenticationTokenRequest,
      O: DeleteHostAuthenticationTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetRunnerConfigurationSchema retrieves the latest Runner configuration schema
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.GetRunnerConfigurationSchema
     */
    getRunnerConfigurationSchema: {
      name: "GetRunnerConfigurationSchema",
      I: GetRunnerConfigurationSchemaRequest,
      O: GetRunnerConfigurationSchemaResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * CreateSCMIntegration creates a new SCM integration on a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.CreateSCMIntegration
     */
    createSCMIntegration: {
      name: "CreateSCMIntegration",
      I: CreateSCMIntegrationRequest,
      O: CreateSCMIntegrationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetSCMIntegration returns a single SCM integration configured for a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.GetSCMIntegration
     */
    getSCMIntegration: {
      name: "GetSCMIntegration",
      I: GetSCMIntegrationRequest,
      O: GetSCMIntegrationResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListSCMIntegrations returns all SCM integrations configured for a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.ListSCMIntegrations
     */
    listSCMIntegrations: {
      name: "ListSCMIntegrations",
      I: ListSCMIntegrationsRequest,
      O: ListSCMIntegrationsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * UpdateSCMIntegration updates an existing SCM integration on a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.UpdateSCMIntegration
     */
    updateSCMIntegration: {
      name: "UpdateSCMIntegration",
      I: UpdateSCMIntegrationRequest,
      O: UpdateSCMIntegrationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteSCMIntegration deletes an existing SCM integration on a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.DeleteSCMIntegration
     */
    deleteSCMIntegration: {
      name: "DeleteSCMIntegration",
      I: DeleteSCMIntegrationRequest,
      O: DeleteSCMIntegrationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateEnvironmentClass creates a new environment class on a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.CreateEnvironmentClass
     */
    createEnvironmentClass: {
      name: "CreateEnvironmentClass",
      I: CreateEnvironmentClassRequest,
      O: CreateEnvironmentClassResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetEnvironmentClass returns a single environment class configured for a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.GetEnvironmentClass
     */
    getEnvironmentClass: {
      name: "GetEnvironmentClass",
      I: GetEnvironmentClassRequest,
      O: GetEnvironmentClassResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListEnvironmentClasses returns all environment classes configured for a runner.
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.ListEnvironmentClasses
     */
    listEnvironmentClasses: {
      name: "ListEnvironmentClasses",
      I: ListEnvironmentClassesRequest,
      O: ListEnvironmentClassesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * UpdateEnvironmentClass updates an existing environment class on a runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.UpdateEnvironmentClass
     */
    updateEnvironmentClass: {
      name: "UpdateEnvironmentClass",
      I: UpdateEnvironmentClassRequest,
      O: UpdateEnvironmentClassResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ValidateRunnerConfiguration validates a runner configuration (e.g. environment class, SCM integration)
     * with the runner.
     *
     * @generated from rpc gitpod.v1.RunnerConfigurationService.ValidateRunnerConfiguration
     */
    validateRunnerConfiguration: {
      name: "ValidateRunnerConfiguration",
      I: ValidateRunnerConfigurationRequest,
      O: ValidateRunnerConfigurationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

