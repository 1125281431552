import * as React from "react";
import { cn } from "@/components/podkit/lib/cn";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, ...props }, ref) => {
    return (
        <textarea
            className={cn(
                "flex w-full max-w-[600px] rounded-lg border border-border-light bg-surface-secondary px-3 py-2 text-base ring-inset ring-offset-surface-primary",
                "file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-content-tertiary",
                "disabled:cursor-not-allowed disabled:opacity-50",
                className,
            )}
            ref={ref}
            {...props}
        />
    );
});
Textarea.displayName = "Textarea";
