// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/project.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { EnvironmentInitializer } from "./environment_pb.js";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { Subject } from "./identity_pb.js";

/**
 * @generated from enum gitpod.v1.ProjectRole
 */
export enum ProjectRole {
  /**
   * @generated from enum value: PROJECT_ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PROJECT_ROLE_ADMIN = 1;
   */
  ADMIN = 1,

  /**
   * @generated from enum value: PROJECT_ROLE_USER = 2;
   */
  USER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProjectRole)
proto3.util.setEnumType(ProjectRole, "gitpod.v1.ProjectRole", [
  { no: 0, name: "PROJECT_ROLE_UNSPECIFIED" },
  { no: 1, name: "PROJECT_ROLE_ADMIN" },
  { no: 2, name: "PROJECT_ROLE_USER" },
]);

/**
 * @generated from message gitpod.v1.CreateProjectRequest
 */
export class CreateProjectRequest extends Message<CreateProjectRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * initializer is the content initializer
   *
   * @generated from field: gitpod.v1.EnvironmentInitializer initializer = 2;
   */
  initializer?: EnvironmentInitializer;

  /**
   * @generated from field: gitpod.v1.ProjectEnvironmentClass environment_class = 3;
   */
  environmentClass?: ProjectEnvironmentClass;

  /**
   * devcontainer_file_path is the path to the devcontainer file relative to the repo root
   *
   * @generated from field: string devcontainer_file_path = 4;
   */
  devcontainerFilePath = "";

  /**
   * automations_file_path is the path to the automations file relative to the repo root
   *
   * @generated from field: string automations_file_path = 5;
   */
  automationsFilePath = "";

  constructor(data?: PartialMessage<CreateProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "initializer", kind: "message", T: EnvironmentInitializer },
    { no: 3, name: "environment_class", kind: "message", T: ProjectEnvironmentClass },
    { no: 4, name: "devcontainer_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "automations_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectRequest | PlainMessage<CreateProjectRequest> | undefined, b: CreateProjectRequest | PlainMessage<CreateProjectRequest> | undefined): boolean {
    return proto3.util.equals(CreateProjectRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateProjectResponse
 */
export class CreateProjectResponse extends Message<CreateProjectResponse> {
  /**
   * @generated from field: gitpod.v1.Project project = 1;
   */
  project?: Project;

  constructor(data?: PartialMessage<CreateProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectResponse | PlainMessage<CreateProjectResponse> | undefined, b: CreateProjectResponse | PlainMessage<CreateProjectResponse> | undefined): boolean {
    return proto3.util.equals(CreateProjectResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateProjectFromEnvironmentRequest
 */
export class CreateProjectFromEnvironmentRequest extends Message<CreateProjectFromEnvironmentRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * environment_id specifies the environment identifier
   *
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  constructor(data?: PartialMessage<CreateProjectFromEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateProjectFromEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectFromEnvironmentRequest {
    return new CreateProjectFromEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectFromEnvironmentRequest {
    return new CreateProjectFromEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectFromEnvironmentRequest {
    return new CreateProjectFromEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectFromEnvironmentRequest | PlainMessage<CreateProjectFromEnvironmentRequest> | undefined, b: CreateProjectFromEnvironmentRequest | PlainMessage<CreateProjectFromEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(CreateProjectFromEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateProjectFromEnvironmentResponse
 */
export class CreateProjectFromEnvironmentResponse extends Message<CreateProjectFromEnvironmentResponse> {
  /**
   * @generated from field: gitpod.v1.Project project = 1;
   */
  project?: Project;

  constructor(data?: PartialMessage<CreateProjectFromEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateProjectFromEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectFromEnvironmentResponse {
    return new CreateProjectFromEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectFromEnvironmentResponse {
    return new CreateProjectFromEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectFromEnvironmentResponse {
    return new CreateProjectFromEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectFromEnvironmentResponse | PlainMessage<CreateProjectFromEnvironmentResponse> | undefined, b: CreateProjectFromEnvironmentResponse | PlainMessage<CreateProjectFromEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(CreateProjectFromEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetProjectRequest
 */
export class GetProjectRequest extends Message<GetProjectRequest> {
  /**
   * project_id specifies the project identifier
   *
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<GetProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectRequest | PlainMessage<GetProjectRequest> | undefined, b: GetProjectRequest | PlainMessage<GetProjectRequest> | undefined): boolean {
    return proto3.util.equals(GetProjectRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetProjectResponse
 */
export class GetProjectResponse extends Message<GetProjectResponse> {
  /**
   * @generated from field: gitpod.v1.Project project = 1;
   */
  project?: Project;

  constructor(data?: PartialMessage<GetProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectResponse | PlainMessage<GetProjectResponse> | undefined, b: GetProjectResponse | PlainMessage<GetProjectResponse> | undefined): boolean {
    return proto3.util.equals(GetProjectResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateProjectRequest
 */
export class UpdateProjectRequest extends Message<UpdateProjectRequest> {
  /**
   * project_id specifies the project identifier
   *
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: optional string name = 2;
   */
  name?: string;

  /**
   * @generated from field: optional gitpod.v1.ProjectEnvironmentClass environment_class = 3;
   */
  environmentClass?: ProjectEnvironmentClass;

  /**
   * initializer is the content initializer
   *
   * @generated from field: optional gitpod.v1.EnvironmentInitializer initializer = 4;
   */
  initializer?: EnvironmentInitializer;

  /**
   * devcontainer_file_path is the path to the devcontainer file relative to the repo root
   *
   * @generated from field: optional string devcontainer_file_path = 5;
   */
  devcontainerFilePath?: string;

  /**
   * automations_file_path is the path to the automations file relative to the repo root
   *
   * @generated from field: optional string automations_file_path = 6;
   */
  automationsFilePath?: string;

  constructor(data?: PartialMessage<UpdateProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "environment_class", kind: "message", T: ProjectEnvironmentClass, opt: true },
    { no: 4, name: "initializer", kind: "message", T: EnvironmentInitializer, opt: true },
    { no: 5, name: "devcontainer_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "automations_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectRequest {
    return new UpdateProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectRequest {
    return new UpdateProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectRequest {
    return new UpdateProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectRequest | PlainMessage<UpdateProjectRequest> | undefined, b: UpdateProjectRequest | PlainMessage<UpdateProjectRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProjectRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateProjectResponse
 */
export class UpdateProjectResponse extends Message<UpdateProjectResponse> {
  /**
   * @generated from field: gitpod.v1.Project project = 1;
   */
  project?: Project;

  constructor(data?: PartialMessage<UpdateProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectResponse {
    return new UpdateProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectResponse {
    return new UpdateProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectResponse {
    return new UpdateProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectResponse | PlainMessage<UpdateProjectResponse> | undefined, b: UpdateProjectResponse | PlainMessage<UpdateProjectResponse> | undefined): boolean {
    return proto3.util.equals(UpdateProjectResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListProjectsRequest
 */
export class ListProjectsRequest extends Message<ListProjectsRequest> {
  /**
   * pagination contains the pagination options for listing organizations
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  constructor(data?: PartialMessage<ListProjectsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListProjectsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectsRequest | PlainMessage<ListProjectsRequest> | undefined, b: ListProjectsRequest | PlainMessage<ListProjectsRequest> | undefined): boolean {
    return proto3.util.equals(ListProjectsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListProjectsResponse
 */
export class ListProjectsResponse extends Message<ListProjectsResponse> {
  /**
   * pagination contains the pagination options for listing organizations
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.Project projects = 2;
   */
  projects: Project[] = [];

  constructor(data?: PartialMessage<ListProjectsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListProjectsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "projects", kind: "message", T: Project, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectsResponse | PlainMessage<ListProjectsResponse> | undefined, b: ListProjectsResponse | PlainMessage<ListProjectsResponse> | undefined): boolean {
    return proto3.util.equals(ListProjectsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteProjectRequest
 */
export class DeleteProjectRequest extends Message<DeleteProjectRequest> {
  /**
   * project_id specifies the project identifier
   *
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<DeleteProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProjectRequest {
    return new DeleteProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProjectRequest {
    return new DeleteProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProjectRequest {
    return new DeleteProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProjectRequest | PlainMessage<DeleteProjectRequest> | undefined, b: DeleteProjectRequest | PlainMessage<DeleteProjectRequest> | undefined): boolean {
    return proto3.util.equals(DeleteProjectRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteProjectResponse
 */
export class DeleteProjectResponse extends Message<DeleteProjectResponse> {
  constructor(data?: PartialMessage<DeleteProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProjectResponse {
    return new DeleteProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProjectResponse {
    return new DeleteProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProjectResponse {
    return new DeleteProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProjectResponse | PlainMessage<DeleteProjectResponse> | undefined, b: DeleteProjectResponse | PlainMessage<DeleteProjectResponse> | undefined): boolean {
    return proto3.util.equals(DeleteProjectResponse, a, b);
  }
}

/**
 * ListProjectPoliciesRequest lists policies for a project.
 *
 * @generated from message gitpod.v1.ListProjectPoliciesRequest
 */
export class ListProjectPoliciesRequest extends Message<ListProjectPoliciesRequest> {
  /**
   * pagination contains the pagination options for listing project policies
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * project_id specifies the project identifier
   *
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListProjectPoliciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListProjectPoliciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectPoliciesRequest {
    return new ListProjectPoliciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectPoliciesRequest {
    return new ListProjectPoliciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectPoliciesRequest {
    return new ListProjectPoliciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectPoliciesRequest | PlainMessage<ListProjectPoliciesRequest> | undefined, b: ListProjectPoliciesRequest | PlainMessage<ListProjectPoliciesRequest> | undefined): boolean {
    return proto3.util.equals(ListProjectPoliciesRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListProjectPoliciesResponse
 */
export class ListProjectPoliciesResponse extends Message<ListProjectPoliciesResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.ProjectPolicy policies = 2;
   */
  policies: ProjectPolicy[] = [];

  constructor(data?: PartialMessage<ListProjectPoliciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListProjectPoliciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "policies", kind: "message", T: ProjectPolicy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectPoliciesResponse {
    return new ListProjectPoliciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectPoliciesResponse {
    return new ListProjectPoliciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectPoliciesResponse {
    return new ListProjectPoliciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectPoliciesResponse | PlainMessage<ListProjectPoliciesResponse> | undefined, b: ListProjectPoliciesResponse | PlainMessage<ListProjectPoliciesResponse> | undefined): boolean {
    return proto3.util.equals(ListProjectPoliciesResponse, a, b);
  }
}

/**
 * CreateProjectPolicyRequest creates a Project Policy.
 *
 * @generated from message gitpod.v1.CreateProjectPolicyRequest
 */
export class CreateProjectPolicyRequest extends Message<CreateProjectPolicyRequest> {
  /**
   * project_id specifies the project identifier
   *
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * group_id specifies the group_id identifier
   *
   * @generated from field: string group_id = 2;
   */
  groupId = "";

  /**
   * @generated from field: gitpod.v1.ProjectRole role = 3;
   */
  role = ProjectRole.UNSPECIFIED;

  constructor(data?: PartialMessage<CreateProjectPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateProjectPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProjectRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectPolicyRequest {
    return new CreateProjectPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectPolicyRequest {
    return new CreateProjectPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectPolicyRequest {
    return new CreateProjectPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectPolicyRequest | PlainMessage<CreateProjectPolicyRequest> | undefined, b: CreateProjectPolicyRequest | PlainMessage<CreateProjectPolicyRequest> | undefined): boolean {
    return proto3.util.equals(CreateProjectPolicyRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateProjectPolicyResponse
 */
export class CreateProjectPolicyResponse extends Message<CreateProjectPolicyResponse> {
  /**
   * @generated from field: gitpod.v1.ProjectPolicy policy = 1;
   */
  policy?: ProjectPolicy;

  constructor(data?: PartialMessage<CreateProjectPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateProjectPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: ProjectPolicy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectPolicyResponse {
    return new CreateProjectPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectPolicyResponse {
    return new CreateProjectPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectPolicyResponse {
    return new CreateProjectPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectPolicyResponse | PlainMessage<CreateProjectPolicyResponse> | undefined, b: CreateProjectPolicyResponse | PlainMessage<CreateProjectPolicyResponse> | undefined): boolean {
    return proto3.util.equals(CreateProjectPolicyResponse, a, b);
  }
}

/**
 * UpdateProjectPolicyRequest updates a Project Policy.
 *
 * @generated from message gitpod.v1.UpdateProjectPolicyRequest
 */
export class UpdateProjectPolicyRequest extends Message<UpdateProjectPolicyRequest> {
  /**
   * project_id specifies the project identifier
   *
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * group_id specifies the group_id identifier
   *
   * @generated from field: string group_id = 2;
   */
  groupId = "";

  /**
   * @generated from field: gitpod.v1.ProjectRole role = 3;
   */
  role = ProjectRole.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateProjectPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateProjectPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProjectRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectPolicyRequest {
    return new UpdateProjectPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectPolicyRequest {
    return new UpdateProjectPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectPolicyRequest {
    return new UpdateProjectPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectPolicyRequest | PlainMessage<UpdateProjectPolicyRequest> | undefined, b: UpdateProjectPolicyRequest | PlainMessage<UpdateProjectPolicyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProjectPolicyRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateProjectPolicyResponse
 */
export class UpdateProjectPolicyResponse extends Message<UpdateProjectPolicyResponse> {
  /**
   * @generated from field: gitpod.v1.ProjectPolicy policy = 1;
   */
  policy?: ProjectPolicy;

  constructor(data?: PartialMessage<UpdateProjectPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateProjectPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: ProjectPolicy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectPolicyResponse {
    return new UpdateProjectPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectPolicyResponse {
    return new UpdateProjectPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectPolicyResponse {
    return new UpdateProjectPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectPolicyResponse | PlainMessage<UpdateProjectPolicyResponse> | undefined, b: UpdateProjectPolicyResponse | PlainMessage<UpdateProjectPolicyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateProjectPolicyResponse, a, b);
  }
}

/**
 * DeleteProjectPolicyRequest deletes a Project Policy.
 *
 * @generated from message gitpod.v1.DeleteProjectPolicyRequest
 */
export class DeleteProjectPolicyRequest extends Message<DeleteProjectPolicyRequest> {
  /**
   * project_id specifies the project identifier
   *
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * group_id specifies the group_id identifier
   *
   * @generated from field: string group_id = 2;
   */
  groupId = "";

  constructor(data?: PartialMessage<DeleteProjectPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteProjectPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProjectPolicyRequest {
    return new DeleteProjectPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProjectPolicyRequest {
    return new DeleteProjectPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProjectPolicyRequest {
    return new DeleteProjectPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProjectPolicyRequest | PlainMessage<DeleteProjectPolicyRequest> | undefined, b: DeleteProjectPolicyRequest | PlainMessage<DeleteProjectPolicyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteProjectPolicyRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteProjectPolicyResponse
 */
export class DeleteProjectPolicyResponse extends Message<DeleteProjectPolicyResponse> {
  constructor(data?: PartialMessage<DeleteProjectPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteProjectPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProjectPolicyResponse {
    return new DeleteProjectPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProjectPolicyResponse {
    return new DeleteProjectPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProjectPolicyResponse {
    return new DeleteProjectPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProjectPolicyResponse | PlainMessage<DeleteProjectPolicyResponse> | undefined, b: DeleteProjectPolicyResponse | PlainMessage<DeleteProjectPolicyResponse> | undefined): boolean {
    return proto3.util.equals(DeleteProjectPolicyResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Project
 */
export class Project extends Message<Project> {
  /**
   * id is the unique identifier for the project
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: gitpod.v1.ProjectMetadata metadata = 2;
   */
  metadata?: ProjectMetadata;

  /**
   * initializer is the content initializer
   *
   * @generated from field: gitpod.v1.EnvironmentInitializer initializer = 3;
   */
  initializer?: EnvironmentInitializer;

  /**
   * @generated from field: gitpod.v1.ProjectEnvironmentClass environment_class = 4;
   */
  environmentClass?: ProjectEnvironmentClass;

  /**
   * devcontainer_file_path is the path to the devcontainer file relative to the repo root
   *
   * @generated from field: string devcontainer_file_path = 5;
   */
  devcontainerFilePath = "";

  /**
   * @generated from field: gitpod.v1.Project.UsedBy used_by = 6;
   */
  usedBy?: Project_UsedBy;

  /**
   * automations_file_path is the path to the automations file relative to the repo root
   *
   * @generated from field: string automations_file_path = 7;
   */
  automationsFilePath = "";

  constructor(data?: PartialMessage<Project>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Project";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: ProjectMetadata },
    { no: 3, name: "initializer", kind: "message", T: EnvironmentInitializer },
    { no: 4, name: "environment_class", kind: "message", T: ProjectEnvironmentClass },
    { no: 5, name: "devcontainer_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "used_by", kind: "message", T: Project_UsedBy },
    { no: 7, name: "automations_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Project {
    return new Project().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJsonString(jsonString, options);
  }

  static equals(a: Project | PlainMessage<Project> | undefined, b: Project | PlainMessage<Project> | undefined): boolean {
    return proto3.util.equals(Project, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Project.UsedBy
 */
export class Project_UsedBy extends Message<Project_UsedBy> {
  /**
   * Subjects are the 10 most recent subjects who have used the project to create an environment
   *
   * @generated from field: repeated gitpod.v1.Subject subjects = 4;
   */
  subjects: Subject[] = [];

  /**
   * Total number of unique subjects who have used the project
   *
   * @generated from field: int32 total_subjects = 3;
   */
  totalSubjects = 0;

  constructor(data?: PartialMessage<Project_UsedBy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Project.UsedBy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "subjects", kind: "message", T: Subject, repeated: true },
    { no: 3, name: "total_subjects", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Project_UsedBy {
    return new Project_UsedBy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Project_UsedBy {
    return new Project_UsedBy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Project_UsedBy {
    return new Project_UsedBy().fromJsonString(jsonString, options);
  }

  static equals(a: Project_UsedBy | PlainMessage<Project_UsedBy> | undefined, b: Project_UsedBy | PlainMessage<Project_UsedBy> | undefined): boolean {
    return proto3.util.equals(Project_UsedBy, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ProjectEnvironmentClass
 */
export class ProjectEnvironmentClass extends Message<ProjectEnvironmentClass> {
  /**
   * @generated from oneof gitpod.v1.ProjectEnvironmentClass.environment_class
   */
  environmentClass: {
    /**
     * Use a local runner for the user
     *
     * @generated from field: bool local_runner = 1;
     */
    value: boolean;
    case: "localRunner";
  } | {
    /**
     * Use a fixed environment class on a given Runner. This cannot be a local runner's environment class.
     *
     * @generated from field: string environment_class_id = 2;
     */
    value: string;
    case: "environmentClassId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ProjectEnvironmentClass>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ProjectEnvironmentClass";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "local_runner", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "environment_class" },
    { no: 2, name: "environment_class_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "environment_class" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjectEnvironmentClass {
    return new ProjectEnvironmentClass().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjectEnvironmentClass {
    return new ProjectEnvironmentClass().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjectEnvironmentClass {
    return new ProjectEnvironmentClass().fromJsonString(jsonString, options);
  }

  static equals(a: ProjectEnvironmentClass | PlainMessage<ProjectEnvironmentClass> | undefined, b: ProjectEnvironmentClass | PlainMessage<ProjectEnvironmentClass> | undefined): boolean {
    return proto3.util.equals(ProjectEnvironmentClass, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ProjectMetadata
 */
export class ProjectMetadata extends Message<ProjectMetadata> {
  /**
   * organization_id is the ID of the organization that contains the environment
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * name is the human readable name of the project
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * creator is the identity of the project creator
   *
   * @generated from field: gitpod.v1.Subject creator = 3;
   */
  creator?: Subject;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<ProjectMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ProjectMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "creator", kind: "message", T: Subject },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjectMetadata {
    return new ProjectMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjectMetadata {
    return new ProjectMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjectMetadata {
    return new ProjectMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: ProjectMetadata | PlainMessage<ProjectMetadata> | undefined, b: ProjectMetadata | PlainMessage<ProjectMetadata> | undefined): boolean {
    return proto3.util.equals(ProjectMetadata, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ProjectPolicy
 */
export class ProjectPolicy extends Message<ProjectPolicy> {
  /**
   * @generated from field: string group_id = 1;
   */
  groupId = "";

  /**
   * role is the role assigned to the group
   *
   * @generated from field: gitpod.v1.ProjectRole role = 2;
   */
  role = ProjectRole.UNSPECIFIED;

  constructor(data?: PartialMessage<ProjectPolicy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ProjectPolicy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProjectRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjectPolicy {
    return new ProjectPolicy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjectPolicy {
    return new ProjectPolicy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjectPolicy {
    return new ProjectPolicy().fromJsonString(jsonString, options);
  }

  static equals(a: ProjectPolicy | PlainMessage<ProjectPolicy> | undefined, b: ProjectPolicy | PlainMessage<ProjectPolicy> | undefined): boolean {
    return proto3.util.equals(ProjectPolicy, a, b);
  }
}

