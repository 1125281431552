import { createContext, useEffect, useState, type FC, type PropsWithChildren } from "react";
import { type RunnerMainService } from "@/ipc";
import type { AppMainInfo, AppMainService } from "frontend-shared/local-runner";

export type DesktopContextValue = {
    isDesktop: boolean;
    runnerService?: RunnerMainService;
    appMainService?: AppMainService;
    appMainInfo?: AppMainInfo;
};

/**
 * This context can be used to check whether the dashboard is running in a desktop environment, and if so,
 * provides access to the {@link runnerMainService} IPC service.
 */
export const DesktopContext = createContext<DesktopContextValue | null>(null);

export const DesktopProvider: FC<
    {
        isDesktop: boolean;
        runnerService?: RunnerMainService;
        appMainService?: AppMainService;
    } & PropsWithChildren
> = ({ isDesktop, runnerService, appMainService, children }) => {
    const [appMainInfo, setAppMainInfo] = useState<AppMainInfo | undefined>(undefined);

    useEffect(() => {
        if (!appMainService) {
            return;
        }
        appMainService.about().then(
            (appMainInfo: AppMainInfo) => {
                setAppMainInfo(appMainInfo);
            },
            (error: unknown) => {
                console.error("Failed to fetch app main info", error);
            },
        );
    }, [appMainService]);

    const value: DesktopContextValue = { isDesktop, runnerService, appMainService, appMainInfo };
    return <DesktopContext.Provider value={value}>{children}</DesktopContext.Provider>;
};
