import { useToast } from "@/components/podkit/toasts/use-toast";
import { type PlainEnvironment, useStartEnvironment, useStopEnvironment } from "@/queries/environment-queries";
import {
    type EffectiveState,
    environmentHasReachedStablePhase,
    hasFailureMessageFromSameSession,
    showStart,
} from "@/routes/environments/phase";
import { formatError } from "@/utils/errors";
import { type FC, useCallback, useMemo } from "react";
import { cn } from "../podkit/lib/cn";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { statusText } from "@/utils/environment";
import { Toggle } from "@/components/Toggle.tsx";

type State = "running" | "starting" | "stopped" | "stopping" | "deleting" | "deleted";

function stateFromEnvironment(environment: PlainEnvironment): State {
    const hasReachedStablePhase = environmentHasReachedStablePhase(environment);
    if (environment.spec?.desiredPhase === EnvironmentPhase.DELETED) {
        return hasReachedStablePhase ? "deleted" : "deleting";
    }
    if (showStart(environment)) {
        return hasReachedStablePhase ? "stopped" : "stopping";
    }

    return hasReachedStablePhase ? "running" : "starting";
}

export const EnvironmentToggle: FC<{ environment: PlainEnvironment; effectiveState?: EffectiveState }> = ({
    environment,
    effectiveState,
}) => {
    const { toast } = useToast();
    const startEnvironment = useStartEnvironment();
    const stopEnvironment = useStopEnvironment();
    const isPendingAction = startEnvironment.isPending || stopEnvironment.isPending;

    const state = useMemo(() => stateFromEnvironment(environment), [environment]);

    const hasFailureMessage = environment ? hasFailureMessageFromSameSession(environment) : false;
    const isTransitory = isPendingAction || state === "starting" || state === "stopping" || state === "deleting";
    const disabled = isTransitory || state === "deleted";
    const checked = state === "running" || state === "starting";

    const handleClick = useCallback(async () => {
        if (state === "stopped") {
            try {
                await startEnvironment.mutateAsync(environment.id);
            } catch (error) {
                toast({
                    title: "Failed to start environment",
                    description: formatError(error),
                });
            }
        } else if (state === "running") {
            try {
                await stopEnvironment.mutateAsync(environment.id);
            } catch (error) {
                toast({
                    title: "Failed to stop environment",
                    description: formatError(error),
                });
            }
        }
    }, [environment.id, startEnvironment, state, stopEnvironment, toast]);

    return (
        <div className="inline-flex h-12 items-center justify-end gap-2">
            <Toggle state={isTransitory ? "pending" : checked ? "checked" : "unchecked"}
                    hasFailed={hasFailureMessage && !isTransitory}
                    disabled={disabled}
                    onToggle={handleClick}
                    id={"toggle-env-" + environment.id} />
            <label
                className={cn(
                    "grow cursor-pointer select-none justify-start text-base font-normal leading-tight text-content-primary",
                    { "cursor-default": disabled },
                )}
                htmlFor={"toggle-env-" + environment.id}
                translate="no"
            >
                <StatusText state={effectiveState} />
            </label>
        </div>
    );
};

const StatusText: FC<{ state?: EffectiveState }> = ({ state }) => {
    const text = statusText(state);
    if (!text) {
        return null;
    }
    return <Heading1 className="text-xl">{text}</Heading1>;
};
