import { IconPlay } from "@/assets/icons/geist/IconPlay";
import { IconStop } from "@/assets/icons/geist/IconStop";
import { canShowLogs, showStart, showStop } from "@/components/environments/service-actions";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { useCallbackPreventDefault } from "@/hooks/use-callback-prevent-default";
import { useStartService, useStopService, type PlainService } from "@/queries/automations-queries";
import { formatError } from "@/utils/errors";
import { type FC } from "react";
import { useNavigate } from "react-router-dom";

export const EnvironmentServiceActions: FC<{
    service: PlainService;
    environmentId: string;
    showLogs?: boolean;
    disabled: boolean;
    withLabels: boolean;
}> = ({ service, environmentId, withLabels, ...props }) => {
    const stopService = useStopService();
    const startService = useStartService();
    const { toast } = useToast();
    const navigate = useNavigate();

    const showLogs = props.showLogs ?? true;

    const onStop = useCallbackPreventDefault(async () => {
        await stopService.mutateAsync(
            { serviceId: service.id, environmentId },
            {
                onError: (error: Error) =>
                    toast({
                        title: `Failed to stop ${service.metadata?.name || "service"}`,
                        description: formatError(error),
                    }),
            },
        );
    }, [toast, stopService, service, environmentId]);

    const onStart = useCallbackPreventDefault(async () => {
        await startService.mutateAsync(
            { serviceId: service.id, environmentId },
            {
                onError: (error: Error) =>
                    toast({
                        title: `Failed to start ${service.metadata?.name || "service"}`,
                        description: formatError(error),
                    }),
            },
        );
    }, [toast, startService, service, environmentId]);

    const onViewLogs = useCallbackPreventDefault(() => {
        navigate(`/details/${environmentId}/services/${service.id}/logs`);
    }, [navigate, environmentId, service.id]);

    return (
        <div className="flex gap-2 self-center">
            {showStop(service) && (
                <Button
                    size="sm"
                    variant="secondary"
                    className={cn(withLabels ? "min-h-7" : "size-7 p-0")}
                    onClick={onStop}
                    disabled={props.disabled}
                    loading={stopService.isPending}
                    aria-label="Stop"
                >
                    {!stopService.isPending && <IconStop size="sm" />}
                    {withLabels && <span>Stop</span>}
                </Button>
            )}
            {showStart(service) && (
                <Button
                    size="sm"
                    variant="secondary"
                    className={cn(withLabels ? "min-h-7" : "size-7 p-0")}
                    onClick={onStart}
                    disabled={props.disabled}
                    loading={startService.isPending}
                    aria-label="Start"
                >
                    {!startService.isPending && <IconPlay size="sm" />}
                    {withLabels && <span>Start</span>}
                </Button>
            )}
            {showLogs && canShowLogs(service) && (
                <Button size="sm" variant="secondary" onClick={onViewLogs} disabled={props.disabled}>
                    Logs
                </Button>
            )}
        </div>
    );
};
