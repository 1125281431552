import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCheck: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.8793 6.30686L7.99043 10.9735C7.63686 11.3978 7.0063 11.4551 6.58202 11.1016L4.24869 9.15712L5.52906 7.62067L7.09417 8.92493L10.3429 5.02649L11.8793 6.30686Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.7682 7.64023L9.76821 13.6402C9.41464 14.0645 8.78408 14.1218 8.3598 13.7683L5.3598 11.2683L6.64017 9.73183L8.87195 11.5916L13.2318 6.35986L14.7682 7.64023Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.2126 9.30686L11.6571 15.9735C11.3035 16.3978 10.673 16.4551 10.2487 16.1016L6.91534 13.3238L8.19571 11.7873L10.7608 13.9249L15.6762 8.02649L17.2126 9.30686Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
