import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCheckFill: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.99999 14.2223C11.4364 14.2223 14.2222 11.4365 14.2222 8.00005C14.2222 4.56361 11.4364 1.77783 7.99999 1.77783C4.56355 1.77783 1.77777 4.56361 1.77777 8.00005C1.77777 11.4365 4.56355 14.2223 7.99999 14.2223ZM7.99044 10.9736L11.8793 6.3069L10.3429 5.02653L7.09418 8.92498L5.52907 7.62072L4.2487 9.15716L6.58203 11.1016C7.00631 11.4552 7.63688 11.3978 7.99044 10.9736Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.76822 13.6402L14.7682 7.64018L13.2318 6.35982L8.87196 11.5916L6.64018 9.73178L5.35982 11.2682L8.35982 13.7682C8.78409 14.1218 9.41466 14.0645 9.76822 13.6402Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 20.8889C16.9092 20.8889 20.8889 16.9092 20.8889 12C20.8889 7.09077 16.9092 3.11108 12 3.11108C7.0908 3.11108 3.11111 7.09077 3.11111 12C3.11111 16.9092 7.0908 20.8889 12 20.8889ZM11.6571 15.9735L17.2127 9.30681L15.6762 8.02644L10.7608 13.9249L8.19573 11.7873L6.91536 13.3237L10.2487 16.1015C10.673 16.4551 11.3035 16.3978 11.6571 15.9735Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
