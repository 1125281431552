import { Input } from "@/components/podkit/forms/Input";
import { InputField } from "@/components/podkit/forms/InputField";
import { Textarea } from "@/components/podkit/forms/Textarea";
import { Text } from "@/components/podkit/typography/Text";
import type { PlainSecret } from "@/queries/secret-queries";
import { useCallback, useEffect, useId, useState, type FC } from "react";

export type EditSecretFormOnSubmitInput = { secretValuePlaintext: string };

export const EditSecretForm: FC<{
    secret: PlainSecret;
    formName: string;
    onDisabledChange: (disabled: boolean) => void;
    onSubmit: (values: EditSecretFormOnSubmitInput) => Promise<void>;
}> = ({ secret, formName, onSubmit, onDisabledChange }) => {
    const nameId = useId();
    const secretId = useId();
    const [newsSecretValue, setNewsSecretValue] = useState<string>("");

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await onSubmit({ secretValuePlaintext: newsSecretValue });
        },
        [onSubmit, newsSecretValue],
    );

    useEffect(() => {
        if (!newsSecretValue) {
            onDisabledChange(true);
            return;
        }
        onDisabledChange(false);
    }, [newsSecretValue, onDisabledChange]);

    return (
        <form
            onSubmit={handleSubmit}
            name={formName}
            id={formName}
            data-testid="edit-secret-form"
            className="flex flex-col gap-4"
        >
            <InputField label="Name" id={nameId} disabled={true}>
                <Input
                    id={nameId}
                    type="text"
                    name="secret-name"
                    value={secret.name}
                    disabled={true}
                    data-testid="secret-name-input"
                />
            </InputField>

            <div className="flex flex-col gap-1">
                <InputField id={nameId} label="Secret">
                    <Textarea
                        id={secretId}
                        name="secret-value"
                        rows={10}
                        value={newsSecretValue || ""}
                        onChange={(e) => setNewsSecretValue(e.target.value)}
                        className="resize-none"
                        data-testid="secret-value-input"
                    />
                </InputField>

                {secret.mount && (
                    <Text className="text-sm text-content-tertiary">
                        {secret.mount?.case === "environmentVariable"
                            ? "Environment variable"
                            : `File: ${secret.mount.value}`}
                    </Text>
                )}
            </div>
        </form>
    );
};
