// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/account.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { OrganizationRole } from "./organization_pb.js";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";

/**
 * @generated from message gitpod.v1.GetAccountRequest
 */
export class GetAccountRequest extends Message<GetAccountRequest> {
  constructor(data?: PartialMessage<GetAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountRequest | PlainMessage<GetAccountRequest> | undefined, b: GetAccountRequest | PlainMessage<GetAccountRequest> | undefined): boolean {
    return proto3.util.equals(GetAccountRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetAccountResponse
 */
export class GetAccountResponse extends Message<GetAccountResponse> {
  /**
   * @generated from field: gitpod.v1.Account account = 1;
   */
  account?: Account;

  constructor(data?: PartialMessage<GetAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountResponse {
    return new GetAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountResponse {
    return new GetAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountResponse {
    return new GetAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountResponse | PlainMessage<GetAccountResponse> | undefined, b: GetAccountResponse | PlainMessage<GetAccountResponse> | undefined): boolean {
    return proto3.util.equals(GetAccountResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteAccountRequest
 */
export class DeleteAccountRequest extends Message<DeleteAccountRequest> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  constructor(data?: PartialMessage<DeleteAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAccountRequest {
    return new DeleteAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAccountRequest {
    return new DeleteAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAccountRequest {
    return new DeleteAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAccountRequest | PlainMessage<DeleteAccountRequest> | undefined, b: DeleteAccountRequest | PlainMessage<DeleteAccountRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAccountRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteAccountResponse
 */
export class DeleteAccountResponse extends Message<DeleteAccountResponse> {
  constructor(data?: PartialMessage<DeleteAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAccountResponse {
    return new DeleteAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAccountResponse {
    return new DeleteAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAccountResponse {
    return new DeleteAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAccountResponse | PlainMessage<DeleteAccountResponse> | undefined, b: DeleteAccountResponse | PlainMessage<DeleteAccountResponse> | undefined): boolean {
    return proto3.util.equals(DeleteAccountResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string avatar_url = 3;
   */
  avatarUrl = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: repeated gitpod.v1.AccountMembership memberships = 7;
   */
  memberships: AccountMembership[] = [];

  /**
   * @generated from field: repeated gitpod.v1.JoinableOrganization joinables = 8;
   */
  joinables: JoinableOrganization[] = [];

  /**
   * public_email_provider is true if the email for the Account matches a known public email provider
   *
   * @generated from field: bool public_email_provider = 9;
   */
  publicEmailProvider = false;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "avatar_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    { no: 7, name: "memberships", kind: "message", T: AccountMembership, repeated: true },
    { no: 8, name: "joinables", kind: "message", T: JoinableOrganization, repeated: true },
    { no: 9, name: "public_email_provider", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message gitpod.v1.AccountMembership
 */
export class AccountMembership extends Message<AccountMembership> {
  /**
   * user_id is the ID the user has in the organization
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * user_role is the role the user has in the organization
   *
   * @generated from field: gitpod.v1.OrganizationRole user_role = 2;
   */
  userRole = OrganizationRole.UNSPECIFIED;

  /**
   * organization_id is the id of the organization the user is a member of
   *
   * @generated from field: string organization_id = 3;
   */
  organizationId = "";

  /**
   * organization_name is the name of the organization the user is a member of
   *
   * @generated from field: string organization_name = 4;
   */
  organizationName = "";

  /**
   * organization_name is the member count of the organization the user is a member of
   *
   * @generated from field: int32 organization_member_count = 5;
   */
  organizationMemberCount = 0;

  constructor(data?: PartialMessage<AccountMembership>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.AccountMembership";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_role", kind: "enum", T: proto3.getEnumType(OrganizationRole) },
    { no: 3, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "organization_member_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountMembership {
    return new AccountMembership().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountMembership {
    return new AccountMembership().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountMembership {
    return new AccountMembership().fromJsonString(jsonString, options);
  }

  static equals(a: AccountMembership | PlainMessage<AccountMembership> | undefined, b: AccountMembership | PlainMessage<AccountMembership> | undefined): boolean {
    return proto3.util.equals(AccountMembership, a, b);
  }
}

/**
 * @generated from message gitpod.v1.JoinableOrganization
 */
export class JoinableOrganization extends Message<JoinableOrganization> {
  /**
   * organization_id is the id of the organization the user can join
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * organization_name is the name of the organization the user can join
   *
   * @generated from field: string organization_name = 2;
   */
  organizationName = "";

  /**
   * organization_member_count is the member count of the organization the user can join
   *
   * @generated from field: int32 organization_member_count = 3;
   */
  organizationMemberCount = 0;

  constructor(data?: PartialMessage<JoinableOrganization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.JoinableOrganization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_member_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinableOrganization {
    return new JoinableOrganization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinableOrganization {
    return new JoinableOrganization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinableOrganization {
    return new JoinableOrganization().fromJsonString(jsonString, options);
  }

  static equals(a: JoinableOrganization | PlainMessage<JoinableOrganization> | undefined, b: JoinableOrganization | PlainMessage<JoinableOrganization> | undefined): boolean {
    return proto3.util.equals(JoinableOrganization, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSSOLoginURLRequest
 */
export class GetSSOLoginURLRequest extends Message<GetSSOLoginURLRequest> {
  /**
   * email is the email the user wants to login with
   *
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * return_to is the URL the user will be redirected to after login
   *
   * @generated from field: optional string return_to = 2;
   */
  returnTo?: string;

  constructor(data?: PartialMessage<GetSSOLoginURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSSOLoginURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "return_to", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSOLoginURLRequest {
    return new GetSSOLoginURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSOLoginURLRequest {
    return new GetSSOLoginURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSOLoginURLRequest {
    return new GetSSOLoginURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSOLoginURLRequest | PlainMessage<GetSSOLoginURLRequest> | undefined, b: GetSSOLoginURLRequest | PlainMessage<GetSSOLoginURLRequest> | undefined): boolean {
    return proto3.util.equals(GetSSOLoginURLRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSSOLoginURLResponse
 */
export class GetSSOLoginURLResponse extends Message<GetSSOLoginURLResponse> {
  /**
   * login_url is the URL to redirect the user to for SSO login
   *
   * @generated from field: string login_url = 1;
   */
  loginUrl = "";

  constructor(data?: PartialMessage<GetSSOLoginURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSSOLoginURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "login_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSOLoginURLResponse {
    return new GetSSOLoginURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSOLoginURLResponse {
    return new GetSSOLoginURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSOLoginURLResponse {
    return new GetSSOLoginURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSOLoginURLResponse | PlainMessage<GetSSOLoginURLResponse> | undefined, b: GetSSOLoginURLResponse | PlainMessage<GetSSOLoginURLResponse> | undefined): boolean {
    return proto3.util.equals(GetSSOLoginURLResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListLoginProvidersRequest
 */
export class ListLoginProvidersRequest extends Message<ListLoginProvidersRequest> {
  /**
   * pagination contains the pagination options for listing login methods
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * filter contains the filter options for listing login methods
   *
   * @generated from field: gitpod.v1.ListLoginProvidersRequest.Filter filter = 2;
   */
  filter?: ListLoginProvidersRequest_Filter;

  constructor(data?: PartialMessage<ListLoginProvidersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListLoginProvidersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListLoginProvidersRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLoginProvidersRequest {
    return new ListLoginProvidersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLoginProvidersRequest {
    return new ListLoginProvidersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLoginProvidersRequest {
    return new ListLoginProvidersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListLoginProvidersRequest | PlainMessage<ListLoginProvidersRequest> | undefined, b: ListLoginProvidersRequest | PlainMessage<ListLoginProvidersRequest> | undefined): boolean {
    return proto3.util.equals(ListLoginProvidersRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListLoginProvidersRequest.Filter
 */
export class ListLoginProvidersRequest_Filter extends Message<ListLoginProvidersRequest_Filter> {
  /**
   * invite_id is the ID of the invite URL the user wants to login with
   *
   * @generated from field: string invite_id = 1;
   */
  inviteId = "";

  constructor(data?: PartialMessage<ListLoginProvidersRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListLoginProvidersRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invite_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLoginProvidersRequest_Filter {
    return new ListLoginProvidersRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLoginProvidersRequest_Filter {
    return new ListLoginProvidersRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLoginProvidersRequest_Filter {
    return new ListLoginProvidersRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListLoginProvidersRequest_Filter | PlainMessage<ListLoginProvidersRequest_Filter> | undefined, b: ListLoginProvidersRequest_Filter | PlainMessage<ListLoginProvidersRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListLoginProvidersRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListLoginProvidersResponse
 */
export class ListLoginProvidersResponse extends Message<ListLoginProvidersResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.LoginProvider login_providers = 2;
   */
  loginProviders: LoginProvider[] = [];

  constructor(data?: PartialMessage<ListLoginProvidersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListLoginProvidersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "login_providers", kind: "message", T: LoginProvider, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLoginProvidersResponse {
    return new ListLoginProvidersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLoginProvidersResponse {
    return new ListLoginProvidersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLoginProvidersResponse {
    return new ListLoginProvidersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListLoginProvidersResponse | PlainMessage<ListLoginProvidersResponse> | undefined, b: ListLoginProvidersResponse | PlainMessage<ListLoginProvidersResponse> | undefined): boolean {
    return proto3.util.equals(ListLoginProvidersResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.LoginProvider
 */
export class LoginProvider extends Message<LoginProvider> {
  /**
   * login_url is the URL to redirect the browser agent to for login
   *
   * @generated from field: string login_url = 1;
   */
  loginUrl = "";

  /**
   * provider is the provider used by this login method, e.g. "github", "google", "custom"
   *
   * @generated from field: string provider = 2;
   */
  provider = "";

  constructor(data?: PartialMessage<LoginProvider>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.LoginProvider";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "login_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginProvider {
    return new LoginProvider().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginProvider {
    return new LoginProvider().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginProvider {
    return new LoginProvider().fromJsonString(jsonString, options);
  }

  static equals(a: LoginProvider | PlainMessage<LoginProvider> | undefined, b: LoginProvider | PlainMessage<LoginProvider> | undefined): boolean {
    return proto3.util.equals(LoginProvider, a, b);
  }
}

