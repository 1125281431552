import { forwardRef, type HTMLAttributes } from "react";
import { Text } from "@/components/podkit/typography/Text";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/components/podkit/lib/cn";

const BannerVariant = cva(
    cn("flex justify-center items-center gap-1 rounded-lg border-[0.5px] border-border-base p-[10px]"),
    {
        variants: {
            variant: {
                danger: "bg-surface-red",
                warning: "bg-surface-yield",
            },
        },
        defaultVariants: {
            variant: "danger",
        },
    },
);

export type BannerVariant = VariantProps<typeof BannerVariant>["variant"];

export type BannerProps = {
    text: string | React.ReactNode;
} & VariantProps<typeof BannerVariant> &
    HTMLAttributes<HTMLDivElement>;

export const Banner = forwardRef<HTMLDivElement, BannerProps>(({ text, variant, className, ...props }, ref) => {
    let iconClassName = "";
    switch (variant) {
        case "danger":
            iconClassName = "text-content-red";
            break;
        case "warning":
            iconClassName = "text-content-yield";
            break;
    }
    return (
        <div ref={ref} className={cn(BannerVariant({ variant }), className)} {...props}>
            <IconWarning size="sm" className={iconClassName} />
            <Text className="text-sm font-bold text-content-primary">{text}</Text>
        </div>
    );
});
Banner.displayName = "Banner";
