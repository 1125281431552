import type { PlainEnvironment } from "@/queries/environment-queries";
import type { EffectiveState } from "@/routes/environments/phase";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";

export function statusText(state?: EffectiveState): string | undefined {
    if (!state) {
        return;
    }

    let text = "Unknown";

    switch (state.state) {
        case EnvironmentPhase.RUNNING:
            text = "Running";
            break;
        case EnvironmentPhase.CREATING:
        case EnvironmentPhase.STARTING:
            text = "Starting";
            break;
        case EnvironmentPhase.UPDATING:
            text = "Updating";
            break;
        case EnvironmentPhase.STOPPING:
            text = "Stopping";
            if (state.timeout) {
                text = `Auto-stopping`;
            }
            break;
        case EnvironmentPhase.STOPPED:
            text = "Stopped";
            if (state.timeout) {
                text = `Auto-stopped`;
            }
            break;
        case EnvironmentPhase.DELETING:
            text = "Deleting";
            break;
        case EnvironmentPhase.DELETED:
            text = "Deleted";
            break;
    }

    return text;
}

export function environmentLogsUrl(environment?: PlainEnvironment) {
    if (!environment?.status?.environmentUrls?.logs) {
        return;
    }
    // We're adding the status machine session to the URL to trigger a refresh when a new machine session starts
    // The macOS runner resets the log stream, so this ensures we clear our logs as well.
    return `${environment?.status?.environmentUrls?.logs}#${environment?.status?.machine?.session}`;
}

export function environmentLogGroupUrl(environmentId?: string, logGroupId?: string) {
    return `/details/${environmentId}/logs${logGroupId ? `#${logGroupId}` : ""}`;
}
