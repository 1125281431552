import { cn } from "@/components/podkit/lib/cn";
import { type PropsWithChildren } from "react";

type Props = PropsWithChildren<{
    disabled?: boolean;
}>;
export const InputFieldHint = ({ disabled = false, children }: Props) => {
    return (
        <span className={cn("text-sm", disabled ? "text-content-tertiary" : "text-content-secondary")}>{children}</span>
    );
};
