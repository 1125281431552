import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconSpinner: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_599_1241)">
                        <path
                            d="M13.3594 8C13.3594 10.9599 10.9599 13.3594 8 13.3594V14.8906C11.8056 14.8906 14.8906 11.8056 14.8906 8H13.3594ZM8 13.3594C5.0401 13.3594 2.64063 10.9599 2.64063 8H1.10937C1.10937 11.8056 4.19441 14.8906 8 14.8906V13.3594ZM8 2.64063C10.9599 2.64063 13.3594 5.0401 13.3594 8H14.8906C14.8906 4.19441 11.8056 1.10937 8 1.10937V2.64063Z"
                            fill="currentColor"
                        />
                    </g>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_599_1257)">
                        <path
                            d="M16.125 10C16.125 13.3827 13.3827 16.125 10 16.125V17.875C14.3492 17.875 17.875 14.3492 17.875 10H16.125ZM10 16.125C6.61726 16.125 3.875 13.3827 3.875 10H2.125C2.125 14.3492 5.65076 17.875 10 17.875V16.125ZM10 3.875C13.3827 3.875 16.125 6.61726 16.125 10H17.875C17.875 5.65076 14.3492 2.125 10 2.125V3.875Z"
                            fill="currentColor"
                        />
                    </g>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_599_1273)">
                        <path
                            d="M18.8906 12C18.8906 15.8056 15.8056 18.8906 12 18.8906V20.8594C16.8929 20.8594 20.8594 16.8929 20.8594 12H18.8906ZM12 18.8906C8.19441 18.8906 5.10938 15.8056 5.10938 12H3.14062C3.14062 16.8929 7.1071 20.8594 12 20.8594V18.8906ZM12 5.10938C15.8056 5.10938 18.8906 8.19441 18.8906 12H20.8594C20.8594 7.1071 16.8929 3.14062 12 3.14062V5.10938Z"
                            fill="currentColor"
                        />
                    </g>
                </svg>
            );
    }
};
