import type { PlainService } from "@/queries/automations-queries";

export function serviceLogsUrl(service?: PlainService): string | undefined {
    let logsUrl = service?.status?.logUrl;
    if (!logsUrl) {
        return;
    }

    if (service?.status?.session) {
        // We refetch the logs when the session changes as this ensures we stream new logs when starting a stopped service.
        logsUrl += `?${service?.status?.session}`;
    }

    return logsUrl;
}
