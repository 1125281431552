import { useListServices, useListTasks, type PlainService, type PlainTask } from "@/queries/automations-queries";
import { useEnvironment, type PlainEnvironment } from "@/queries/environment-queries";
import {
    EnvironmentStatus_DevContainer_Phase,
    EnvironmentStatus_DevContainer_Presence,
} from "gitpod-next-api/gitpod/v1/environment_pb";

export enum EnvironmentOnboardingStepId {
    Devcontainer = "devcontainer",
    Automations = "automations",
    CommitFiles = "commit-files",
    Share = "share",
}

export type EnvironmentOnboardingStep = {
    id: EnvironmentOnboardingStepId;
    title: string;
    completed: boolean;
};

export type EnvironmentOnboardingSteps = {
    isLoading: boolean;
    steps: EnvironmentOnboardingStep[];
    defaultActive: EnvironmentOnboardingStepId;
};

export function useEnvironmentOnboardingSteps(environmentId?: string): EnvironmentOnboardingSteps {
    const { data: environment, isPending: isLoadingEnvironment } = useEnvironment(environmentId);
    const { data: tasks, isPending: isLoadingTasks } = useListTasks(environmentId);
    const { data: services, isPending: isLoadingServices } = useListServices(environmentId);

    const devContainerCompleted = hasCompletedDevContainer(environment);
    const automationsCompleted = hasCompletedAutomations(tasks || [], services || []);
    const commitFilesCompleted =
        devContainerCompleted && automationsCompleted && !hasDirtyFilesOrUnpushedCommits(environment);
    const shareCompleted = hasCreatedProjectFromEnvironment(environment);

    const steps = [
        {
            id: EnvironmentOnboardingStepId.Devcontainer,
            title: "Install your tools",
            completed: devContainerCompleted,
        },
        {
            id: EnvironmentOnboardingStepId.Automations,
            title: "Add automations",
            completed: automationsCompleted,
        },
        {
            id: EnvironmentOnboardingStepId.CommitFiles,
            title: "Commit files",
            completed: commitFilesCompleted,
        },
        {
            id: EnvironmentOnboardingStepId.Share,
            title: "Create project",
            completed: shareCompleted,
        },
    ];

    // The first non-completed step.
    let defaultActive: EnvironmentOnboardingStepId = EnvironmentOnboardingStepId.Devcontainer;
    for (const step of steps) {
        if (!step.completed) {
            defaultActive = step.id;
            break;
        }
        defaultActive = step.id;
    }

    return {
        isLoading: isLoadingEnvironment || isLoadingTasks || isLoadingServices,
        steps,
        defaultActive,
    };
}

function hasCompletedDevContainer(environment?: PlainEnvironment): boolean {
    if (!environment) {
        return false;
    }

    const discoveredOrSpecified =
        environment.status?.devcontainer?.devcontainerFilePresence ===
            EnvironmentStatus_DevContainer_Presence.DISCOVERED ||
        environment.status?.devcontainer?.devcontainerFilePresence ===
            EnvironmentStatus_DevContainer_Presence.SPECIFIED;

    const isRunning = environment.status?.devcontainer?.phase === EnvironmentStatus_DevContainer_Phase.RUNNING;

    const isStale =
        environment.spec?.devcontainer?.session &&
        environment.status?.devcontainer?.session &&
        environment.spec?.devcontainer?.session !== environment.status?.devcontainer?.session;

    const isInSync = environment.status?.devcontainer?.devcontainerconfigInSync ?? false;

    return isRunning && discoveredOrSpecified && isInSync && !isStale;
}

function hasCompletedAutomations(tasks: PlainTask[], services: PlainService[]): boolean {
    if (tasks.length > 0 || services.length > 0) {
        return true;
    }
    return false;
}

function hasDirtyFilesOrUnpushedCommits(environment?: PlainEnvironment): boolean {
    if (!environment) {
        return true;
    }

    const files = new Set([".gitpod/automations.yaml", ".devcontainer/devcontainer.json", ".devcontainer/Dockerfile"]);
    for (const file of environment.status?.content?.git?.changedFiles || []) {
        if (files.has(file.path)) {
            return true;
        }
    }

    if ((environment.status?.content?.git?.unpushedCommits?.length ?? 0) > 0) {
        return true;
    }

    return false;
}

function hasCreatedProjectFromEnvironment(environment?: PlainEnvironment): boolean {
    return !!environment?.metadata?.projectId;
}
