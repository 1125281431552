// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/group.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListGroupsRequest, ListGroupsResponse } from "./group_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.GroupService
 */
export const GroupService = {
  typeName: "gitpod.v1.GroupService",
  methods: {
    /**
     * ListGroups lists groups
     *
     * @generated from rpc gitpod.v1.GroupService.ListGroups
     */
    listGroups: {
      name: "ListGroups",
      I: ListGroupsRequest,
      O: ListGroupsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

