import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/podkit/dropdown/DropDown";
import { DropdownActions } from "@/components/podkit/dropdown/DropDownActions";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { useOpenEditor } from "@/hooks/use-open-editor";
import { useStartEnvironment, useStopEnvironment, type PlainEnvironment } from "@/queries/environment-queries";
import { DeleteEnvironmentModal, ForceDeleteEnvironmentModal } from "@/routes/environments/DeleteEnvironmentModal";
import { canOpen, environmentHasReachedStablePhase, showStart, showStop } from "@/routes/environments/phase";
import { formatError } from "@/utils/errors";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useCallback, useState, type FC } from "react";
import { useNavigate } from "react-router-dom";

export const EnvironmentActionsDropdown: FC<{
    button?: React.ReactNode;
    environment: PlainEnvironment;
    handleViewDetails?: () => void;
}> = ({ button, environment, handleViewDetails }) => {
    const { toast } = useToast();
    const navigate = useNavigate();

    const startEnvironment = useStartEnvironment();
    const stopEnvironment = useStopEnvironment();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showForceDeleteModal, setShowForceDeleteModal] = useState(false);

    const handleViewLogs = useCallback(() => {
        navigate(`/details/${environment.id}/logs`);
    }, [environment.id, navigate]);

    const handleStart = useCallback(async () => {
        try {
            await startEnvironment.mutateAsync(environment.id);
        } catch (error) {
            toast({
                title: "Failed to start environment",
                description: formatError(error),
            });
        }
    }, [startEnvironment, environment.id, toast]);

    const handleCopyId = useCallback(async () => {
        await navigator.clipboard.writeText(environment.id);
        toast({
            title: `Environment ID copied to clipboard`,
            description: environment.id,
        });
    }, [toast, environment.id]);

    const handleOpen = useOpenEditor(environment);
    const handleStop = useCallback(() => {
        stopEnvironment.mutate(environment.id, {
            onError: (e) => {
                toast({
                    title: "Failed to stop environment",
                    description: formatError(e),
                });
            },
        });
    }, [stopEnvironment, toast, environment.id]);
    const handleDelete = useCallback(() => setShowDeleteModal(true), [setShowDeleteModal]);
    const handleForceDelete = useCallback(() => setShowForceDeleteModal(true), [setShowForceDeleteModal]);

    return (
        <>
            <DropdownActions button={button} triggerTestId="environment-actions-dropdown-trigger">
                <DropdownMenuItem onClick={handleOpen} disabled={!canOpen(environment)}>
                    Open
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleViewLogs} data-testid="environment-actions-dropdown-view-logs">View Logs</DropdownMenuItem>
                <DropdownMenuSeparator className="bg-content-tertiary/20" />
                {handleViewDetails && <DropdownMenuItem onClick={handleViewDetails}>View Details</DropdownMenuItem>}
                <DropdownMenuItem onClick={handleCopyId}>Copy ID</DropdownMenuItem>
                <DropdownMenuSeparator className="bg-content-tertiary/20" />
                <DropdownMenuItem
                    onClick={handleStart}
                    disabled={!showStart(environment) || !environmentHasReachedStablePhase(environment)}
                    data-testid="environment-actions-dropdown-start"
                >
                    Start
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={handleStop}
                    disabled={!showStop(environment)}
                    data-testid="environment-actions-dropdown-stop"
                >
                    Stop
                </DropdownMenuItem>
                {/* Show Delete, when the Environment has not yet been requested to delete */}
                {environment.spec?.desiredPhase !== EnvironmentPhase.DELETED && (
                    <DropdownMenuItem className="text-red-500 focus:text-red-500" onClick={handleDelete}>
                        Delete
                    </DropdownMenuItem>
                )}
                {/* Show Force Delete, when the Environment has bene requested to delete */}
                {environment.spec?.desiredPhase === EnvironmentPhase.DELETED && (
                    <DropdownMenuItem className="text-red-500 focus:text-red-500" onClick={handleForceDelete}>
                        Force Delete
                    </DropdownMenuItem>
                )}
            </DropdownActions>
            {showDeleteModal && (
                <DeleteEnvironmentModal environment={environment} onClose={() => setShowDeleteModal(false)} />
            )}
            {showForceDeleteModal && (
                <ForceDeleteEnvironmentModal environment={environment} onClose={() => setShowForceDeleteModal(false)} />
            )}
        </>
    );
};
