import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconChevronRight: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.90624 4.44689L7.37028 4.91099L9.84056 7.38129C10.1823 7.723 10.1823 8.27702 9.84056 8.61873L7.37028 11.089L6.90624 11.5531L5.97816 10.625L6.4422 10.161L8.60316 8.00001L6.4422 5.83901L5.97816 5.375L6.90624 4.44689Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.49999 3.9393L8.03032 4.4697L12.8535 9.2929C13.2441 9.68342 13.2441 10.3166 12.8535 10.7071L8.03032 15.5303L7.49999 16.0607L6.43933 15L6.96966 14.4697L11.4393 10L6.96966 5.5303L6.43933 5L7.49999 3.9393Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.18747 5.18173L9.7841 5.77843L15.2102 11.2045C15.6496 11.6439 15.6496 12.3562 15.2102 12.7955L9.7841 18.2216L9.18747 18.8183L7.99423 17.625L8.59085 17.0284L13.6192 12L8.59085 6.9716L7.99423 6.37501L9.18747 5.18173Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
