import * as React from "react";
import { Combobox } from "@ariakit/react";
import { cn } from "@/components/podkit/lib/cn";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const inputClasses = cn(
    "flex h-9 w-full max-w-[600px] rounded-lg border border-border-light bg-surface-secondary px-3 py-2 text-base ring-inset ring-offset-surface-primary",
    "file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-content-tertiary",
    "disabled:cursor-not-allowed disabled:opacity-50",
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
    return <input type={type} className={cn(inputClasses, className)} ref={ref} {...props} />;
});
Input.displayName = "Input";

const ComboboxInput = React.forwardRef<
    React.ElementRef<typeof Combobox>,
    React.ComponentPropsWithoutRef<typeof Combobox>
>(({ ...props }, ref) => (
    <Combobox
        ref={ref}
        className={inputClasses}
        // Ariakit's Combobox manually triggers a blur event on virtually
        // blurred items, making them work as if they had actual DOM
        // focus. These blur events might happen after the corresponding
        // focus events in the capture phase, leading Radix Select to
        // close the popover. This happens because Radix Select relies on
        // the order of these captured events to discern if the focus was
        // outside the element. Since we don't have access to the
        // onInteractOutside prop in the Radix SelectContent component to
        // stop this behavior, we can turn off Ariakit's behavior here.
        onBlurCapture={(event) => {
            event.preventDefault();
            event.stopPropagation();
        }}
        {...props}
    />
));
ComboboxInput.displayName = "ComboboxInput";

export { Input, ComboboxInput };
