import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconPlay: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 4.948C5 3.9986 6.0503 3.42519 6.84891 3.93858L11.5965 6.99058C12.3313 7.46295 12.3313 8.53705 11.5965 9.00941L6.84891 12.0614C6.0503 12.5748 5 12.0014 5 11.052V4.948Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.5 6.198C6.5 5.2486 7.5503 4.67519 8.34891 5.18858L14.2631 8.99059C14.9979 9.46295 14.9979 10.5371 14.2631 11.0094L8.34891 14.8114C7.55029 15.3248 6.5 14.7514 6.5 13.802V6.198Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8 7.9975C8 6.81075 9.31287 6.09399 10.3111 6.73573L16.5372 10.7382C17.4557 11.3287 17.4557 12.6713 16.5373 13.2618L10.3111 17.2643C9.31287 17.906 8 17.1893 8 16.0025V7.9975Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
