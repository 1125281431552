import { createPromiseClient, type CallOptions, type Interceptor } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AccountService } from "gitpod-next-api/gitpod/v1/account_connect";
import { UserService } from "gitpod-next-api/gitpod/v1/user_connect";
import { EnvironmentService } from "gitpod-next-api/gitpod/v1/environment_connect";
import { ProjectService } from "gitpod-next-api/gitpod/v1/project_connect";
import { SecretService } from "gitpod-next-api/gitpod/v1/secret_connect";
import { OrganizationService } from "gitpod-next-api/gitpod/v1/organization_connect";
import { RunnerService } from "gitpod-next-api/gitpod/v1/runner_connect";
import { RunnerConfigurationService } from "gitpod-next-api/gitpod/v1/runner_configuration_connect";
import { RunnerInteractionService } from "gitpod-next-api/gitpod/v1/runner_interaction_connect";
import { EnvironmentAutomationService } from "gitpod-next-api/gitpod/v1/environment_automation_connect";
import { GroupService } from "gitpod-next-api/gitpod/v1/group_connect";
import { EventService } from "gitpod-next-api/gitpod/v1/event_connect";
import { EditorService } from "gitpod-next-api/gitpod/v1/editor_connect";
import { getPrincipal } from "@/principal";
import webConfig from "@/web-config";
import { BuildVersion } from "@/utils/version";

// TODO(at) can only explor the FE's behavior with a different header, as the backend starts validating the header
// but fails as the user cookie is not sent.
const XGitpodPrincipalHeader = "X-Gitpod-Principal";
const principal: Interceptor = (next) => async (req) => {
    const principal = getPrincipal();
    if (principal && !req.header.get(XGitpodPrincipalHeader)) {
        req.header.set(XGitpodPrincipalHeader, "user/" + principal);
    }
    return await next(req);
};

const XGitpodDashboardVersionHeader = "X-Gitpod-Dashboard-Version";
const dashboardVersion: Interceptor = (next) => async (req) => {
    const principal = getPrincipal();
    if (principal && !req.header.get(XGitpodDashboardVersionHeader)) {
        req.header.set(XGitpodDashboardVersionHeader, BuildVersion);
    }
    return await next(req);
};

const interceptors = [principal, dashboardVersion];
if (webConfig.accessToken) {
    const token: Interceptor = (next) => async (req) => {
        req.header.set("Authorization", `Bearer ${webConfig.accessToken}`);
        return await next(req);
    };
    interceptors.push(token);
}

const transport = createConnectTransport({
    baseUrl: webConfig.origin + "/api",
    // TODO: add interceptor for csrf token once implemented
    // TODO: should we enable for non-dev environments?
    useBinaryFormat: false,
    interceptors,
    defaultTimeoutMs: 30 * 1000,
});

const accountService = createPromiseClient(AccountService, transport);
const userService = createPromiseClient(UserService, transport);
const organizationService = createPromiseClient(OrganizationService, transport);
const environmentService = createPromiseClient(EnvironmentService, transport);
const environmentAutomationService = createPromiseClient(EnvironmentAutomationService, transport);
const projectService = createPromiseClient(ProjectService, transport);
const secretService = createPromiseClient(SecretService, transport);
const runnerInteractionService = createPromiseClient(RunnerInteractionService, transport);
const eventService = createPromiseClient(EventService, transport);
const editorService = createPromiseClient(EditorService, transport);
const runnerService = createPromiseClient(RunnerService, transport);
const runnerConfigurationService = createPromiseClient(RunnerConfigurationService, transport);
const groupService = createPromiseClient(GroupService, transport);

const GitpodAPI = {
    accountService,
    userService,
    organizationService,
    environmentService,
    environmentAutomationService,
    secretService,
    projectService,
    eventService,
    editorService,
    runnerService,
    runnerInteractionService,
    runnerConfigurationService,
    groupService,
};

type GitpodAPI = typeof GitpodAPI;

export { GitpodAPI };

export function withUser(options: CallOptions, userId: string): CallOptions {
    options.headers = Object.assign(options.headers || {}, {
        [XGitpodPrincipalHeader]: "user/" + userId,
    });
    return options;
}
