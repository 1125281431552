// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/project.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateProjectFromEnvironmentRequest, CreateProjectFromEnvironmentResponse, CreateProjectPolicyRequest, CreateProjectPolicyResponse, CreateProjectRequest, CreateProjectResponse, DeleteProjectPolicyRequest, DeleteProjectPolicyResponse, DeleteProjectRequest, DeleteProjectResponse, GetProjectRequest, GetProjectResponse, ListProjectPoliciesRequest, ListProjectPoliciesResponse, ListProjectsRequest, ListProjectsResponse, UpdateProjectPolicyRequest, UpdateProjectPolicyResponse, UpdateProjectRequest, UpdateProjectResponse } from "./project_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.ProjectService
 */
export const ProjectService = {
  typeName: "gitpod.v1.ProjectService",
  methods: {
    /**
     * CreateProject creates a new Project.
     *
     * @generated from rpc gitpod.v1.ProjectService.CreateProject
     */
    createProject: {
      name: "CreateProject",
      I: CreateProjectRequest,
      O: CreateProjectResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateProject creates a new Project using an environment as template.
     *
     * @generated from rpc gitpod.v1.ProjectService.CreateProjectFromEnvironment
     */
    createProjectFromEnvironment: {
      name: "CreateProjectFromEnvironment",
      I: CreateProjectFromEnvironmentRequest,
      O: CreateProjectFromEnvironmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetProject retrieves a single Project.
     *
     * @generated from rpc gitpod.v1.ProjectService.GetProject
     */
    getProject: {
      name: "GetProject",
      I: GetProjectRequest,
      O: GetProjectResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * UpdateProject updates the properties of a Project.
     *
     * @generated from rpc gitpod.v1.ProjectService.UpdateProject
     */
    updateProject: {
      name: "UpdateProject",
      I: UpdateProjectRequest,
      O: UpdateProjectResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListProjects lists all projects the caller has access to.
     *
     * @generated from rpc gitpod.v1.ProjectService.ListProjects
     */
    listProjects: {
      name: "ListProjects",
      I: ListProjectsRequest,
      O: ListProjectsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * DeleteProject deletes the specified project.
     *
     * @generated from rpc gitpod.v1.ProjectService.DeleteProject
     */
    deleteProject: {
      name: "DeleteProject",
      I: DeleteProjectRequest,
      O: DeleteProjectResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListProjectPolicies lists policies for a project.
     *
     * @generated from rpc gitpod.v1.ProjectService.ListProjectPolicies
     */
    listProjectPolicies: {
      name: "ListProjectPolicies",
      I: ListProjectPoliciesRequest,
      O: ListProjectPoliciesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * CreateProjectPolicy creates a Project Policy.
     *
     * @generated from rpc gitpod.v1.ProjectService.CreateProjectPolicy
     */
    createProjectPolicy: {
      name: "CreateProjectPolicy",
      I: CreateProjectPolicyRequest,
      O: CreateProjectPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteProjectPolicy deletes a Project Policy.
     *
     * @generated from rpc gitpod.v1.ProjectService.DeleteProjectPolicy
     */
    deleteProjectPolicy: {
      name: "DeleteProjectPolicy",
      I: DeleteProjectPolicyRequest,
      O: DeleteProjectPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateProjectPolicy updates a Project Policy.
     *
     * @generated from rpc gitpod.v1.ProjectService.UpdateProjectPolicy
     */
    updateProjectPolicy: {
      name: "UpdateProjectPolicy",
      I: UpdateProjectPolicyRequest,
      O: UpdateProjectPolicyResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

