// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/environment_logs.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum gitpod.v1.LogSectionOutcome
 */
export enum LogSectionOutcome {
  /**
   * @generated from enum value: LOG_SECTION_OUTCOME_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LOG_SECTION_OUTCOME_SUCCESS = 1;
   */
  SUCCESS = 1,

  /**
   * @generated from enum value: LOG_SECTION_OUTCOME_FAILURE = 2;
   */
  FAILURE = 2,

  /**
   * @generated from enum value: LOG_SECTION_OUTCOME_CANCELLED = 3;
   */
  CANCELLED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LogSectionOutcome)
proto3.util.setEnumType(LogSectionOutcome, "gitpod.v1.LogSectionOutcome", [
  { no: 0, name: "LOG_SECTION_OUTCOME_UNSPECIFIED" },
  { no: 1, name: "LOG_SECTION_OUTCOME_SUCCESS" },
  { no: 2, name: "LOG_SECTION_OUTCOME_FAILURE" },
  { no: 3, name: "LOG_SECTION_OUTCOME_CANCELLED" },
]);

/**
 * CreateLogSection defines the structure of JSON metadata for a create log section entry.
 * Example log line:
 * [section-create] {"id":"foo","title":"Foo"}
 *
 * @generated from message gitpod.v1.CreateLogSection
 */
export class CreateLogSection extends Message<CreateLogSection> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * title is an (optional) human-readable title for the log section.
   *
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * continuous indicates if the log section is continuous. Continuous log sections
   * are expected to remain open over the lifetime of an environment, e.g. system logs.
   *
   * @generated from field: bool continuous = 3;
   */
  continuous = false;

  constructor(data?: PartialMessage<CreateLogSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateLogSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "continuous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateLogSection {
    return new CreateLogSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateLogSection {
    return new CreateLogSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateLogSection {
    return new CreateLogSection().fromJsonString(jsonString, options);
  }

  static equals(a: CreateLogSection | PlainMessage<CreateLogSection> | undefined, b: CreateLogSection | PlainMessage<CreateLogSection> | undefined): boolean {
    return proto3.util.equals(CreateLogSection, a, b);
  }
}

/**
 * EndLogSection defines the structure of JSON metadata for an end log section entry.
 * Example log line:
 * [section-end] {"id":"section-id","outcome":"LOG_SECTION_OUTCOME_SUCCESS","secondsElapsed":1.3}
 *
 * @generated from message gitpod.v1.EndLogSection
 */
export class EndLogSection extends Message<EndLogSection> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * outcome indicates the outcome of the log section.
   *
   * @generated from field: gitpod.v1.LogSectionOutcome outcome = 2;
   */
  outcome = LogSectionOutcome.UNSPECIFIED;

  /**
   * seconds_elapsed is the number of seconds that have elapsed since the start of the log section.
   *
   * @generated from field: float seconds_elapsed = 3;
   */
  secondsElapsed = 0;

  constructor(data?: PartialMessage<EndLogSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EndLogSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "outcome", kind: "enum", T: proto3.getEnumType(LogSectionOutcome) },
    { no: 3, name: "seconds_elapsed", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EndLogSection {
    return new EndLogSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EndLogSection {
    return new EndLogSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EndLogSection {
    return new EndLogSection().fromJsonString(jsonString, options);
  }

  static equals(a: EndLogSection | PlainMessage<EndLogSection> | undefined, b: EndLogSection | PlainMessage<EndLogSection> | undefined): boolean {
    return proto3.util.equals(EndLogSection, a, b);
  }
}

