import { useCallback, type FC } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Button } from "@/components/flexkit/Button";
import { useDeleteSecret, type PlainSecret } from "@/queries/secret-queries";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Text } from "@/components/podkit/typography/Text";
import { formatError } from "@/utils/errors";
import { TrackLocations } from "@/hooks/use-segment";

export const DeleteSecretModal: FC<{ secret: PlainSecret; onClose: () => void }> = ({ secret, onClose }) => {
    const { toast } = useToast();
    const deleteSecret = useDeleteSecret();

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            try {
                await deleteSecret.mutateAsync({
                    projectId: secret.projectId,
                    secretId: secret.id,
                });
                onClose();
            } catch (e) {
                toast({
                    title: "Failed to delete secret",
                    description: formatError(e),
                });
            }
        },
        [secret, onClose, toast, deleteSecret],
    );

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent
                className="max-w-[600px]"
                data-testid="delete-secret-form-modal-content"
                data-track-location={TrackLocations.DeleteSecretModal}
            >
                <DialogHeader>
                    <DialogTitle>Delete secret</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody className="overflow-x max-w-full">
                    <form id="delete-secret-form" onSubmit={onSubmit} className="flex flex-col gap-4">
                        <ErrorMessage error={deleteSecret.error} data-testid="error-message-secret-deletion-failed" />
                        <Text className="text-base">Are you sure you want to delete this secret?</Text>
                        <div className="rounded-xl border-[0.5px] border-border-base bg-surface-glass px-6 py-3">
                            <Text className="text-base font-bold">{secret.name}</Text>
                        </div>
                    </form>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" autoFocus={true}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        variant="destructive"
                        form="delete-secret-form"
                        loading={deleteSecret.isPending}
                        disabled={deleteSecret.isPending}
                        data-testid="delete-secret-submit-button"
                    >
                        Yes, Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
