import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import { useMutation, useQuery, useQueryClient, type QueryClient } from "@tanstack/react-query";
import { type UserPreference } from "gitpod-next-api/gitpod/v1/user_pb";
import { ResourceOperation, type WatchEventsResponse } from "gitpod-next-api/gitpod/v1/event_pb";
import type { GitpodAPI } from "@/api";
import { Code, ConnectError } from "@connectrpc/connect";
import type { PaginationResponse } from "gitpod-next-api/gitpod/v1/pagination_pb";

export type PlainUserPreference = PlainMessage<UserPreference>;

export function toPlainUserPreference(pref: UserPreference): PlainUserPreference {
    return toPlainMessage(pref);
}

export const userPreferencesQueryKey = {
    list: () => keyWithPrincipal(["preferences", "list"]),
    get: (id?: string) => keyWithPrincipal(["preferences", { id }]),
};

export const handleUserPreferenceEvent = async (api: GitpodAPI, client: QueryClient, evt: WatchEventsResponse) => {
    if (evt.operation === ResourceOperation.UPDATE) {
        const userPref = await refetchUserPreference(api, evt.resourceId);
        setUserPreferenceInCache(client, evt.resourceId, userPref ? toPlainUserPreference(userPref) : undefined);
    }
    if (evt.operation === ResourceOperation.CREATE) {
        await client.invalidateQueries({ queryKey: userPreferencesQueryKey.list() });
    }
    if (evt.operation === ResourceOperation.DELETE) {
        setUserPreferenceInCache(client, evt.resourceId, undefined);
    }
};

const refetchUserPreference = async (api: GitpodAPI, Id: string): Promise<UserPreference | undefined> => {
    try {
        const response = await api.userService.getPreference({ userPreferenceId: Id });
        return response.preference;
    } catch (error) {
        const isNotFound = error instanceof ConnectError && error.code === Code.NotFound;
        if (!isNotFound) {
            console.error("Failed to refetch userpreference", Id);
        }
    }
};

function setUserPreferenceInCache(client: QueryClient, Id: string, pref?: PlainUserPreference) {
    client.setQueryData(userPreferencesQueryKey.get(Id), pref);
    client.setQueryData(userPreferencesQueryKey.list(), (currentData?: { preferences: PlainUserPreference[] }) => {
        if (!currentData) {
            return currentData;
        }
        return {
            ...currentData,
            preferences: currentData.preferences
                .map((p) => {
                    if (p.id !== Id) {
                        return p;
                    }
                    return pref;
                })
                .filter((w) => !!w),
        };
    });
}

export const useListPreferences = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    const query = useQuery({
        queryKey: userPreferencesQueryKey.list(),
        queryFn: async (): Promise<CachedUserPreferenceList> => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { preferences, pagination } = await api.userService.listPreferences({
                pagination: {
                    pageSize: 100,
                },
            });

            return {
                preferences: preferences.map(toPlainUserPreference),
                pagination: pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 1_000 * 60 * 60,
        gcTime: 1_000 * 60 * 60,
    });
    return { ...query, preferences: query.data?.preferences };
};

type CachedUserPreferenceList = {
    preferences: PlainUserPreference[];
    pagination: PaginationResponse | undefined;
};

export const useSetPreference = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ key, value }: { key: string; value: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            return await api.userService.setPreference({
                key,
                value,
            });
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: userPreferencesQueryKey.list() });
        },
        throwOnError: defaultThrowOnError,
    });
};

export const useGetPreference = (id: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: userPreferencesQueryKey.get(id),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { preference } = await api.userService.getPreference({ userPreferenceId: id });
            return preference ? toPlainUserPreference(preference) : undefined;
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user && !!id,
        staleTime: 1_000 * 10, // 10 seconds
        gcTime: 1_000 * 60 * 60 * 24, // 24 hours
    });
};

export const useDeletePreference = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ id }: { id: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            return await api.userService.deletePreference({ userPreferenceId: id });
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: userPreferencesQueryKey.list() });
        },
    });
};
