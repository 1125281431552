import { type FC, type PropsWithChildren } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { type PlainSecret } from "@/queries/secret-queries";
import { Button } from "@/components/flexkit/Button";
import { SecretsTable } from "@/components/projects/SecretsTable";

export const ProjectSecrets: FC<{ secrets: PlainSecret[]; onNewSecret: () => void }> = ({ secrets, onNewSecret }) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex justify-between">
                <div className="flex flex-col">
                    <Heading2 className="text-base font-bold text-content-primary">Secrets</Heading2>
                </div>
            </div>

            <SecretsCard>
                {secrets.length ? (
                    <SecretsTable secrets={secrets} onNewSecret={onNewSecret} />
                ) : (
                    <SecretsEmptyState onNewSecret={onNewSecret} />
                )}
            </SecretsCard>
        </div>
    );
};

export const SecretsCard: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="flex w-full justify-center rounded-xl border border-border-base bg-surface-glass px-4 py-3">
            {children}
        </div>
    );
};

const SecretsEmptyState: FC<{ onNewSecret: () => void }> = ({ onNewSecret }) => {
    return (
        <div className="flex flex-col gap-2 px-4 py-8" data-testid="secrets-empty-state">
            <Text className="text-base text-content-tertiary">This project has no secrets.</Text>
            <Button
                variant="secondary"
                size={"md"}
                onClick={() => onNewSecret()}
                data-testid="add-secret-modal-trigger"
            >
                New secret
            </Button>
        </div>
    );
};
