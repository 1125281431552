export enum DevContainerTemplateId {
    Nodejs = "nodejs",
    Go = "go",
}

export type DevContainerTemplate = {
    id: DevContainerTemplateId;
    title: string;
    devContainer: {
        sourceUrl: string;
        rawUrl: string;
    };
    dockerfile: {
        sourceUrl: string;
        rawUrl: string;
    };
};

export const templates: Record<DevContainerTemplateId, DevContainerTemplate> = {
    nodejs: {
        id: DevContainerTemplateId.Nodejs,
        title: "Node.js & JavaScript",
        devContainer: {
            sourceUrl:
                "https://github.com/devcontainers/images/blob/main/src/javascript-node/.devcontainer/devcontainer.json",
            rawUrl: "https://raw.githubusercontent.com/devcontainers/images/main/src/javascript-node/.devcontainer/devcontainer.json",
        },
        dockerfile: {
            sourceUrl: "https://github.com/devcontainers/images/blob/main/src/javascript-node/.devcontainer/Dockerfile",
            rawUrl: "https://raw.githubusercontent.com/devcontainers/images/main/src/javascript-node/.devcontainer/Dockerfile",
        },
    },
    go: {
        id: DevContainerTemplateId.Go,
        title: "Go",
        devContainer: {
            sourceUrl: "https://github.com/devcontainers/images/blob/main/src/go/.devcontainer/devcontainer.json",
            rawUrl: "https://raw.githubusercontent.com/devcontainers/images/main/src/go/.devcontainer/devcontainer.json",
        },
        dockerfile: {
            sourceUrl: "https://github.com/devcontainers/images/blob/main/src/go/.devcontainer/Dockerfile",
            rawUrl: "https://raw.githubusercontent.com/devcontainers/images/main/src/go/.devcontainer/Dockerfile",
        },
    },
};

export function templateInstructions(template: DevContainerTemplate): string {
    return `
        # Download Dockerfile and devcontainer.json files
        curl -Slo .devcontainer/Dockerfile ${template.dockerfile.rawUrl}

        curl -Slo .devcontainer/devcontainer.json ${template.devContainer.rawUrl}

        # rebuild the dev container
        gitpod env devcontainer rebuild
    `
        .trim()
        .split("\n")
        .map((line) => line.trim())
        .join("\n");
}
