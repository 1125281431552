import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconExternalLink: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 2H5.49091C4.26897 2 3.65801 2 3.19129 2.2378C2.78076 2.44698 2.44698 2.78076 2.2378 3.19129C2 3.65801 2 4.26897 2 5.49091V10.5091C2 11.7311 2 12.342 2.2378 12.8087C2.44698 13.2193 2.78076 13.553 3.19129 13.7622C3.65801 14 4.26897 14 5.49091 14H10.5091C11.7311 14 12.342 14 12.8087 13.7622C13.2193 13.553 13.553 13.2193 13.7622 12.8087C14 12.342 14 11.7311 14 10.5091V10M9.27273 2H14M14 2V6.72727M14 2L7.27273 8.72727"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.66667 3H7.07273C5.64714 3 4.93434 3 4.38984 3.27744C3.91088 3.52148 3.52148 3.91088 3.27744 4.38984C3 4.93434 3 5.64714 3 7.07273V12.9273C3 14.3529 3 15.0656 3.27744 15.6102C3.52148 16.0892 3.91088 16.4785 4.38984 16.7225C4.93434 17 5.64714 17 7.07273 17H12.9273C14.3529 17 15.0656 17 15.6102 16.7225C16.0892 16.4785 16.4785 16.0892 16.7225 15.6102C17 15.0656 17 14.3529 17 12.9273V12.3333M11.4848 3H17M17 3V8.51515M17 3L9.15152 10.8485"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.33333 4H8.65455C7.0253 4 6.21068 4 5.58839 4.31707C5.04101 4.59598 4.59598 5.04101 4.31707 5.58839C4 6.21068 4 7.0253 4 8.65455V15.3455C4 16.9747 4 17.7893 4.31707 18.4116C4.59598 18.959 5.04101 19.404 5.58839 19.6829C6.21068 20 7.0253 20 8.65455 20H15.3455C16.9747 20 17.7893 20 18.4116 19.6829C18.959 19.404 19.404 18.959 19.6829 18.4116C20 17.7893 20 16.9747 20 15.3455V14.6667M13.697 4H20M20 4V10.303M20 4L11.0303 12.9697"
                        stroke="currentColor"
                        strokeWidth="1.75"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
