import type { LogGroup, LogGroups, LogLine, SectionLogLine } from "@/routes/environments/log-streams/log-groups";
import { type LogSectionOutcome } from "gitpod-next-api/gitpod/v1/environment_logs_pb";

type Foo = { status?: LogSectionOutcome; line?: SectionLogLine };

export enum Titles {
    CreatingVirtualMachine = "Creating virtual machine",
    InitializingContent = "Initializing content",
    CreatingDevContainer = "Creating dev container",
    RunningDevContainerBackgroundCommands = "Running dev container background commands",
}

export type LatestLogLinePerStep = {
    "start-machine": Foo;
    clone: Foo;
    "start-dev-container": Foo;
};

/**
 * The mapping from log groups to environment start steps is based on the title of the log groups for now.
 * When there are multiple log groups with the same title - which is the case for dev container rebuilds - we select
 * the one first that isn't successful.
 */
export function latestLogLinePerStep(logGroups?: LogGroups): LatestLogLinePerStep {
    if (!logGroups) {
        return {
            "start-machine": {},
            clone: {},
            "start-dev-container": {},
        };
    }

    const groups = Object.values(logGroups || {});

    const createVmGroup: LogGroup | undefined = selectLogGroup(groups, Titles.CreatingVirtualMachine);
    const createVMLatestLogLine = latestSectionLogLine(createVmGroup?.lines ?? []);

    const cloneLogGroup = selectLogGroup(groups, Titles.InitializingContent);
    const cloneLogGroupLatestLogLine = latestSectionLogLine(cloneLogGroup?.lines ?? []);

    const devContainerCreateLogGroup = selectLogGroup(groups, Titles.CreatingDevContainer);
    const devContainerCreateLogGroupLatestLogLine = latestSectionLogLine(devContainerCreateLogGroup?.lines ?? []);

    const devContainerBackgroundCommandsGroup = selectLogGroup(groups, Titles.RunningDevContainerBackgroundCommands);
    const devContainerBackgroundCommandsGroupLatestLogLine = latestSectionLogLine(
        devContainerBackgroundCommandsGroup?.lines ?? [],
    );

    // The start dev container step covers both the "Start Dev Container" and "Running Dev Container Background Commands" log groups.
    // If the "Creating Dev Container" log group has completed, we show the "Running Dev Container Background Commands" log group.
    let startDevContainerStatus = devContainerCreateLogGroup?.outcome;
    let startDevContainerLine = devContainerCreateLogGroupLatestLogLine;
    if (startDevContainerStatus) {
        startDevContainerStatus = devContainerBackgroundCommandsGroup?.outcome;
        startDevContainerLine = devContainerBackgroundCommandsGroupLatestLogLine;
    }

    return {
        "start-machine": {
            status: createVmGroup?.outcome,
            line: createVMLatestLogLine,
        },
        clone: {
            status: cloneLogGroup?.outcome,
            line: cloneLogGroupLatestLogLine,
        },
        "start-dev-container": {
            status: startDevContainerStatus,
            line: startDevContainerLine,
        },
    };
}

function selectLogGroup(groups: LogGroup[], title: string): LogGroup | undefined {
    const matches = groups.filter((lg) => lg.title === title);

    if (!matches.length) {
        return;
    }

    if (matches.length === 1) {
        return matches[0];
    }

    const inProgress = matches.find((lg) => !lg.outcome);
    if (inProgress) {
        return inProgress;
    }

    let group: LogGroup | undefined;
    let latest = 0;
    for (const lg of matches) {
        const latestLineTs =
            lg.lines
                .filter((line) => line.type === "SectionLogLine")
                .pop()
                ?.timestamp?.getTime() || 0;
        if (!latest || latestLineTs > latest) {
            latest = latestLineTs;
            group = lg;
        }
    }

    // As a last report, e.g in case that are no log lines in any of the groups
    // or if the log lines don't have timestamps, then we return the last match
    return group ? group : matches.pop();
}

function latestSectionLogLine(lines: LogLine[]): SectionLogLine | undefined {
    return lines.filter((line) => line.type === "SectionLogLine").pop();
}
