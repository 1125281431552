import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconGitLab: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_554_756)">
                        <path
                            d="M13.7675 5.59597L13.7478 5.54566L11.8422 0.572486C11.8035 0.475014 11.7348 0.392328 11.6461 0.336293C11.5574 0.281209 11.4539 0.254679 11.3496 0.260284C11.2453 0.26589 11.1453 0.303362 11.0629 0.367639C10.9815 0.433763 10.9225 0.523362 10.8938 0.624244L9.60714 4.5608H4.39704L3.11037 0.624244C3.08246 0.522812 3.02328 0.432764 2.94124 0.36691C2.85893 0.302632 2.75887 0.265161 2.65458 0.259555C2.55028 0.25395 2.44678 0.28048 2.35805 0.335564C2.26957 0.391825 2.20098 0.474439 2.16195 0.571757L0.252724 5.54275L0.23377 5.59305C-0.0405473 6.3098 -0.0744073 7.0963 0.137295 7.83397C0.348998 8.57164 0.794786 9.22049 1.40745 9.68269L1.41401 9.68779L1.4315 9.70019L4.33435 11.874L5.77046 12.961L6.64525 13.6214C6.74757 13.6991 6.87252 13.7412 7.001 13.7412C7.12947 13.7412 7.25442 13.6991 7.35674 13.6214L8.23153 12.961L9.66765 11.874L12.588 9.68707L12.5953 9.68123C13.2066 9.21895 13.6513 8.57077 13.8627 7.83409C14.0741 7.09741 14.0407 6.31203 13.7675 5.59597Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_554_756">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.7343 8.3954L17.7118 8.33792L15.534 2.65428C15.4897 2.54289 15.4112 2.44839 15.3099 2.38435C15.2085 2.32139 15.0902 2.29107 14.971 2.29748C14.8518 2.30389 14.7374 2.34671 14.6434 2.42017C14.5503 2.49574 14.4828 2.59814 14.4501 2.71343L12.9796 7.21235H7.02519L5.55471 2.71343C5.52282 2.59751 5.45518 2.4946 5.36142 2.41934C5.26734 2.34588 5.15299 2.30305 5.0338 2.29665C4.91461 2.29024 4.79633 2.32056 4.69492 2.38351C4.5938 2.44781 4.51541 2.54223 4.4708 2.65345L2.28883 8.33458L2.26717 8.39207C1.95366 9.21121 1.91496 10.1101 2.15691 10.9531C2.39885 11.7962 2.90833 12.5377 3.60851 13.0659L3.61601 13.0718L3.636 13.0859L6.95354 15.5703L8.59481 16.8125L9.59457 17.5674C9.71151 17.6562 9.85431 17.7042 10.0011 17.7042C10.148 17.7042 10.2908 17.6562 10.4077 17.5674L11.4075 16.8125L13.0487 15.5703L16.3863 13.0709L16.3946 13.0643C17.0932 12.536 17.6015 11.7952 17.8431 10.9533C18.0847 10.1113 18.0465 9.21376 17.7343 8.3954Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M20.7011 10.1948L20.6758 10.1301L18.2257 3.73605C18.1759 3.61073 18.0876 3.50442 17.9736 3.43237C17.8595 3.36155 17.7264 3.32744 17.5924 3.33465C17.4583 3.34185 17.3296 3.39003 17.2238 3.47267C17.1191 3.55769 17.0432 3.67289 17.0063 3.8026L15.352 8.86388H8.65334L6.99905 3.8026C6.96317 3.67218 6.88708 3.55641 6.7816 3.47174C6.67576 3.38909 6.54711 3.34092 6.41303 3.33371C6.27894 3.3265 6.14587 3.36061 6.03178 3.43143C5.91802 3.50377 5.82983 3.60999 5.77965 3.73511L3.32493 10.1264L3.30056 10.1911C2.94787 11.1126 2.90433 12.1238 3.17652 13.0722C3.44871 14.0207 4.02187 14.8549 4.80957 15.4492L4.81801 15.4557L4.8405 15.4717L8.57273 18.2666L10.4192 19.6641L11.5439 20.5133C11.6755 20.6132 11.8361 20.6672 12.0013 20.6672C12.1665 20.6672 12.3271 20.6132 12.4587 20.5133L13.5834 19.6641L15.4298 18.2666L19.1846 15.4548L19.1939 15.4473C19.9799 14.8529 20.5517 14.0196 20.8235 13.0724C21.0952 12.1252 21.0523 11.1155 20.7011 10.1948Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
