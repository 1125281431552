// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/secret.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateSecretRequest, CreateSecretResponse, DeleteSecretRequest, DeleteSecretResponse, GetSecretValueRequest, GetSecretValueResponse, ListSecretsRequest, ListSecretsResponse, UpdateSecretValueRequest, UpdateSecretValueResponse } from "./secret_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.SecretService
 */
export const SecretService = {
  typeName: "gitpod.v1.SecretService",
  methods: {
    /**
     * CreateSecret creates a new secret.
     *
     * @generated from rpc gitpod.v1.SecretService.CreateSecret
     */
    createSecret: {
      name: "CreateSecret",
      I: CreateSecretRequest,
      O: CreateSecretResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateSecretValue updates the value of a secret.
     *
     * @generated from rpc gitpod.v1.SecretService.UpdateSecretValue
     */
    updateSecretValue: {
      name: "UpdateSecretValue",
      I: UpdateSecretValueRequest,
      O: UpdateSecretValueResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListSecrets lists secrets.
     *
     * @generated from rpc gitpod.v1.SecretService.ListSecrets
     */
    listSecrets: {
      name: "ListSecrets",
      I: ListSecretsRequest,
      O: ListSecretsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * DeleteSecret deletes a secret.
     *
     * @generated from rpc gitpod.v1.SecretService.DeleteSecret
     */
    deleteSecret: {
      name: "DeleteSecret",
      I: DeleteSecretRequest,
      O: DeleteSecretResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetSecretValue retrieves the value of a secret
     * Only Environments can perform this operation, and only for secrets specified on the EnvironmentSpec.
     *
     * @generated from rpc gitpod.v1.SecretService.GetSecretValue
     */
    getSecretValue: {
      name: "GetSecretValue",
      I: GetSecretValueRequest,
      O: GetSecretValueResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

