// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/pagination.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum gitpod.v1.SortOrder
 */
export enum SortOrder {
  /**
   * @generated from enum value: SORT_ORDER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SORT_ORDER_ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: SORT_ORDER_DESC = 2;
   */
  DESC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SortOrder)
proto3.util.setEnumType(SortOrder, "gitpod.v1.SortOrder", [
  { no: 0, name: "SORT_ORDER_UNSPECIFIED" },
  { no: 1, name: "SORT_ORDER_ASC" },
  { no: 2, name: "SORT_ORDER_DESC" },
]);

/**
 * @generated from message gitpod.v1.PaginationRequest
 */
export class PaginationRequest extends Message<PaginationRequest> {
  /**
   * Page size is the maximum number of results to retrieve per page.
   * Defaults to 25. Maximum 100.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0;

  /**
   * Token for the next set of results that was returned as next_token of a
   * PaginationResponse
   *
   * @generated from field: string token = 2;
   */
  token = "";

  constructor(data?: PartialMessage<PaginationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.PaginationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationRequest {
    return new PaginationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationRequest {
    return new PaginationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaginationRequest {
    return new PaginationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PaginationRequest | PlainMessage<PaginationRequest> | undefined, b: PaginationRequest | PlainMessage<PaginationRequest> | undefined): boolean {
    return proto3.util.equals(PaginationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.PaginationResponse
 */
export class PaginationResponse extends Message<PaginationResponse> {
  /**
   * Token passed for retreiving the next set of results. Empty if there are no
   * more results
   *
   * @generated from field: string next_token = 1;
   */
  nextToken = "";

  constructor(data?: PartialMessage<PaginationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.PaginationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "next_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationResponse {
    return new PaginationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationResponse {
    return new PaginationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaginationResponse {
    return new PaginationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PaginationResponse | PlainMessage<PaginationResponse> | undefined, b: PaginationResponse | PlainMessage<PaginationResponse> | undefined): boolean {
    return proto3.util.equals(PaginationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Sort
 */
export class Sort extends Message<Sort> {
  /**
   * Field name to sort by, in camelCase.
   *
   * @generated from field: string field = 1;
   */
  field = "";

  /**
   * @generated from field: gitpod.v1.SortOrder order = 2;
   */
  order = SortOrder.UNSPECIFIED;

  constructor(data?: PartialMessage<Sort>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Sort";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(SortOrder) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Sort {
    return new Sort().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Sort {
    return new Sort().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Sort {
    return new Sort().fromJsonString(jsonString, options);
  }

  static equals(a: Sort | PlainMessage<Sort> | undefined, b: Sort | PlainMessage<Sort> | undefined): boolean {
    return proto3.util.equals(Sort, a, b);
  }
}

