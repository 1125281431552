import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { Size } from "@/assets/icons/geist/Size";
import type { FC } from "react";

export const IconGitHub: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_347_348)">
                        <g clipPath="url(#clip1_347_348)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.97914 0.142853C3.11986 0.142853 0 3.28571 0 7.17385C0 10.2819 1.999 12.9127 4.77214 13.8439C5.11886 13.9139 5.24586 13.6926 5.24586 13.5064C5.24586 13.3434 5.23443 12.7847 5.23443 12.2026C3.293 12.6217 2.88871 11.3644 2.88871 11.3644C2.57671 10.5496 2.11443 10.3401 2.11443 10.3401C1.479 9.90942 2.16071 9.90942 2.16071 9.90942C2.86557 9.95599 3.23543 10.6311 3.23543 10.6311C3.85929 11.702 4.86457 11.3994 5.269 11.2131C5.32671 10.7591 5.51171 10.4449 5.70814 10.2703C4.15971 10.1073 2.53057 9.502 2.53057 6.80128C2.53057 6.033 2.80771 5.40442 3.24686 4.91557C3.17757 4.741 2.93486 4.01914 3.31629 3.053C3.31629 3.053 3.90557 2.86671 5.23429 3.77471C5.80315 3.6208 6.38982 3.54251 6.97914 3.54185C7.56843 3.54185 8.16914 3.62342 8.72386 3.77471C10.0527 2.86671 10.642 3.053 10.642 3.053C11.0234 4.01914 10.7806 4.741 10.7113 4.91557C11.162 5.40442 11.4277 6.033 11.4277 6.80128C11.4277 9.502 9.79857 10.0956 8.23857 10.2703C8.49286 10.4914 8.71229 10.9104 8.71229 11.574C8.71229 12.5169 8.70086 13.2736 8.70086 13.5063C8.70086 13.6926 8.828 13.9139 9.17457 13.844C11.9477 12.9126 13.9467 10.2819 13.9467 7.17385C13.9581 3.28571 10.8269 0.142853 6.97914 0.142853Z"
                                fill="currentColor"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_347_348">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_347_348">
                            <rect width="14" height="13.7143" fill="white" transform="translate(0 0.142853)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_347_351)">
                        <g clipPath="url(#clip1_347_351)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.97616 2.16327C5.56555 2.16327 2 5.75511 2 10.1987C2 13.7507 4.28457 16.7574 7.45388 17.8216C7.85012 17.9016 7.99527 17.6487 7.99527 17.4359C7.99527 17.2496 7.9822 16.6111 7.9822 15.9458C5.76343 16.4248 5.30139 14.9879 5.30139 14.9879C4.94482 14.0567 4.41649 13.8173 4.41649 13.8173C3.69029 13.3251 4.46939 13.3251 4.46939 13.3251C5.27494 13.3783 5.69763 14.1499 5.69763 14.1499C6.41061 15.3737 7.55951 15.0279 8.02171 14.815C8.08767 14.2962 8.2991 13.937 8.52359 13.7375C6.75396 13.5512 4.89208 12.8594 4.89208 9.7729C4.89208 8.89486 5.20882 8.17649 5.71069 7.6178C5.63151 7.41829 5.35412 6.59331 5.79004 5.48915C5.79004 5.48915 6.46351 5.27625 7.98204 6.31396C8.63218 6.13807 9.30265 6.04859 9.97616 6.04784C10.6496 6.04784 11.3362 6.14106 11.9701 6.31396C13.4888 5.27625 14.1623 5.48915 14.1623 5.48915C14.5982 6.59331 14.3207 7.41829 14.2415 7.6178C14.7566 8.17649 15.0602 8.89486 15.0602 9.7729C15.0602 12.8594 13.1984 13.5378 11.4155 13.7375C11.7061 13.9902 11.9569 14.4691 11.9569 15.2274C11.9569 16.305 11.9438 17.1698 11.9438 17.4358C11.9438 17.6487 12.0891 17.9016 12.4852 17.8217C15.6545 16.7572 17.9391 13.7507 17.9391 10.1987C17.9522 5.75511 14.3736 2.16327 9.97616 2.16327Z"
                                fill="currentColor"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_347_351">
                            <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                        </clipPath>
                        <clipPath id="clip1_347_351">
                            <rect width="16" height="15.6735" fill="white" transform="translate(2 2.16327)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_347_354)">
                        <g clipPath="url(#clip1_347_354)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.9732 3.18369C7.01125 3.18369 3 7.2245 3 12.2235C3 16.2195 5.57014 19.6021 9.13561 20.7993C9.58139 20.8893 9.74467 20.6047 9.74467 20.3654C9.74467 20.1558 9.72998 19.4375 9.72998 18.689C7.23386 19.2279 6.71406 17.6114 6.71406 17.6114C6.31292 16.5637 5.71855 16.2945 5.71855 16.2945C4.90157 15.7407 5.77806 15.7407 5.77806 15.7407C6.68431 15.8006 7.15984 16.6686 7.15984 16.6686C7.96194 18.0454 9.25445 17.6564 9.77443 17.4169C9.84863 16.8332 10.0865 16.4291 10.339 16.2047C8.3482 15.9951 6.25359 15.2169 6.25359 11.7445C6.25359 10.7567 6.60992 9.94856 7.17453 9.32003C7.08545 9.09558 6.77339 8.16748 7.2638 6.9253C7.2638 6.9253 8.02145 6.68579 9.7298 7.85322C10.4612 7.65534 11.2155 7.55468 11.9732 7.55383C12.7308 7.55383 13.5032 7.65871 14.2164 7.85322C15.9249 6.68579 16.6826 6.9253 16.6826 6.9253C17.173 8.16748 16.8607 9.09558 16.7717 9.32003C17.3511 9.94856 17.6928 10.7567 17.6928 11.7445C17.6928 15.2169 15.5982 15.98 13.5924 16.2047C13.9194 16.489 14.2015 17.0277 14.2015 17.8809C14.2015 19.0931 14.1868 20.066 14.1868 20.3652C14.1868 20.6047 14.3503 20.8893 14.7959 20.7994C18.3613 19.6019 20.9315 16.2195 20.9315 12.2235C20.9462 7.2245 16.9202 3.18369 11.9732 3.18369Z"
                                fill="currentColor"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_347_354">
                            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                        </clipPath>
                        <clipPath id="clip1_347_354">
                            <rect width="18" height="17.6327" fill="white" transform="translate(3 3.18369)" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
