import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCheckCircle: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_513_37)">
                        <g clipPath="url(#clip1_513_37)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.6875 7C12.6875 10.1412 10.1412 12.6875 7 12.6875C3.85888 12.6875 1.3125 10.1412 1.3125 7C1.3125 3.85888 3.85888 1.3125 7 1.3125C10.1412 1.3125 12.6875 3.85888 12.6875 7ZM14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7ZM10.089 5.71404L10.5531 5.25L9.625 4.32192L9.16099 4.78596L5.6875 8.25942L4.83904 7.41096L4.375 6.94692L3.44692 7.875L3.91096 8.33904L5.22346 9.65151C5.47974 9.9078 5.89526 9.9078 6.15154 9.65151L10.089 5.71404Z"
                                fill="currentColor"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_513_37">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_513_37">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_513_40)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.5303 8.53033L14.0607 8L13 6.93934L12.4697 7.46967L8.5 11.4393L7.53033 10.4697L7 9.93934L5.93934 11L6.46967 11.5303L7.96967 13.0303C8.26256 13.3232 8.73744 13.3232 9.03033 13.0303L13.5303 8.53033Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_513_40">
                            <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_513_43)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.3125 12C19.3125 16.0386 16.0386 19.3125 12 19.3125C7.96142 19.3125 4.6875 16.0386 4.6875 12C4.6875 7.96142 7.96142 4.6875 12 4.6875C16.0386 4.6875 19.3125 7.96142 19.3125 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02943 21 3 16.9706 3 12C3 7.02943 7.02943 3 12 3C16.9706 3 21 7.02943 21 12ZM15.9716 10.3466L16.5683 9.75L15.375 8.55676L14.7784 9.15338L10.3125 13.6193L9.22162 12.5284L8.625 11.9318L7.43176 13.125L8.02838 13.7216L9.71588 15.4091C10.0454 15.7386 10.5796 15.7386 10.9091 15.4091L15.9716 10.3466Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_513_43">
                            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
