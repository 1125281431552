import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconAzureDevOps: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.55471 1.45833L5.44862 4.91876L8.65477 9.07395L3.61133 8.4451L1.16666 12.5417L12.8333 12.5417L7.55471 1.45833ZM6.13132 4.85517L7.63166 2.41595L5.44862 7.37734L7.97243 7.69547L6.13132 4.85517Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.7925 2.08333L7.78374 7.02679L12.3639 12.9628L5.15904 12.0644L1.66666 17.9167L18.3333 17.9167L10.7925 2.08333ZM8.75903 6.93596L10.9024 3.45136L7.78374 10.5391L11.3892 10.9935L8.75903 6.93596Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.051 2.5L9.34049 8.43215L14.8367 15.5554L6.19085 14.4773L2 21.5H22L13.051 2.5ZM10.5108 8.32315L13.0829 4.14163L9.34049 12.6469L13.6671 13.1922L10.5108 8.32315Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
