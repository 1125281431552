import { type FC, useCallback } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { useDeleteEnvironment, type PlainEnvironment } from "@/queries/environment-queries";
import { formatError } from "@/utils/errors";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/flexkit/Button";
import { TrackLocations } from "@/hooks/use-segment";

type DeleteRunnerModalProps = {
    environment: PlainEnvironment;
    onClose: () => void;
};

export const DeleteEnvironmentModal: FC<DeleteRunnerModalProps> = ({
    environment,
    onClose,
}: DeleteRunnerModalProps) => {
    const { toast } = useToast();
    const location = useLocation();
    const navigate = useNavigate();

    const deleteEnvironment = useDeleteEnvironment();

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const handleDeleteEnvironment = useCallback(() => {
        deleteEnvironment.mutate(
            {
                environmentId: environment.id,
                force: false,
            },
            {
                onSuccess: () => {
                    toast({
                        title: "Deleting environment",
                    });
                    if (location.pathname !== "/") {
                        navigate("/");
                    }
                    onClose();
                },
                onError: (e) => {
                    toast({
                        title: "Failed to delete environment",
                        description: formatError(e),
                    });
                },
            },
        );
    }, [deleteEnvironment, environment.id, toast, location.pathname, onClose, navigate]);

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-xl" data-track-location={TrackLocations.DeleteEnvironmentModal}>
                <DialogHeader>
                    <DialogTitle>Delete environment</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <Text>Are you sure you want to delete the environment?</Text>

                    <Text className="py-4">
                        Deleting the environment will result in stopping the environment and removing any content on the
                        environment.
                    </Text>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        variant="destructive"
                        loading={deleteEnvironment.isPending}
                        onClick={handleDeleteEnvironment}
                    >
                        Yes, Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export const ForceDeleteEnvironmentModal: FC<DeleteRunnerModalProps> = ({
    environment,
    onClose,
}: DeleteRunnerModalProps) => {
    const { toast } = useToast();
    const location = useLocation();
    const navigate = useNavigate();

    const deleteEnvironment = useDeleteEnvironment();

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    const handleDeleteEnvironment = useCallback(() => {
        deleteEnvironment.mutate(
            {
                environmentId: environment.id,
                force: true,
            },
            {
                onSuccess: () => {
                    toast({
                        title: "Force deleting environment",
                    });
                    if (location.pathname !== "/") {
                        navigate("/");
                    }
                    onClose();
                },
                onError: (e) => {
                    toast({
                        title: "Failed to force delete environment",
                        description: formatError(e),
                    });
                },
            },
        );
    }, [deleteEnvironment, environment.id, toast, location.pathname, onClose, navigate]);

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent className="max-w-xl" data-track-location={TrackLocations.ForceDeleteEnvironmentModal}>
                <DialogHeader>
                    <DialogTitle>Irreversible Action</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <Text>
                        You are about to permanently delete the environment. This may leave some infrastructure, files,
                        or dependencies behind, requiring manual cleanup.
                    </Text>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        variant="destructive"
                        loading={deleteEnvironment.isPending}
                        onClick={handleDeleteEnvironment}
                    >
                        Force Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
