import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconDotLarge: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        xmlns="http://www.w3.org/2000/svg"
                        cx="7"
                        cy="7"
                        r="3"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeOpacity="0.3"
                        strokeWidth="4"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        xmlns="http://www.w3.org/2000/svg"
                        cx="10"
                        cy="10"
                        r="4"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeOpacity="0.3"
                        strokeWidth="6"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        xmlns="http://www.w3.org/2000/svg"
                        cx="12"
                        cy="12"
                        r="5"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeOpacity="0.3"
                        strokeWidth="8"
                    />
                </svg>
            );
    }
};
