import { type FC } from "react";
import { GitBranchIcon, SquareArrowOutUpRight } from "lucide-react";
import { getRepoUrl } from "@/hooks/use-grouped-environments";
import { SkeletonText } from "@/components/podkit/loading/Skeleton";
import { type PlainEnvironment } from "@/queries/environment-queries";
import { useListRunnerSCMIntegrations } from "@/queries/runner-configuration-queries";
import { SourceControlProviderIcon } from "@/components/runners/details/SourceControlProviderIcon";

export const Repository: FC<{ failed: boolean; environment?: PlainEnvironment; showRepoUrlLink?: boolean }> = ({
    failed,
    environment,
    showRepoUrlLink = true,
}) => {
    const { account, repo, repoUrl } = (environment && getRepoUrl(environment)) || {
        account: "unknown",
        repo: "unknown",
    };

    const { data: integrations } = useListRunnerSCMIntegrations(environment?.metadata?.runnerId);
    const scmId =
        repoUrl && integrations
            ? integrations.find((integration) => new URL(repoUrl).host === integration.host)?.scmId
            : undefined;

    return (
        <div className="flex min-h-5 flex-row items-center justify-start gap-2 text-lg font-bold">
            <SkeletonText size="lg" className="w-40" failed={failed} ready={Boolean(environment)} hideOnFailed={true}>
                <SourceControlProviderIcon scmId={scmId} className="inline-block" size="base" />
                <div>
                    {account}/{repo}
                </div>
                {showRepoUrlLink && (
                    <a href={repoUrl} target="_blank" rel="noreferrer">
                        <SquareArrowOutUpRight size={14} className="text-content-secondary" />
                    </a>
                )}
            </SkeletonText>
        </div>
    );
};

export const GitBranch: FC<{ branch?: string; failed: boolean }> = ({ branch, failed }) => {
    return (
        <div title={branch} className="flex min-w-6 flex-row items-center gap-2 text-base text-content-primary">
            <SkeletonText size="base" className="w-20" failed={failed} ready={Boolean(branch)} hideOnFailed={true}>
                <GitBranchIcon className="inline size-4 flex-none" />
                <span className="min-w-1 shrink truncate whitespace-nowrap">{branch}</span>
            </SkeletonText>
        </div>
    );
};
