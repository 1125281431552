import type { PlainService } from "@/queries/automations-queries";
import { ServicePhase } from "gitpod-next-api/gitpod/v1/environment_automation_pb";

export const showStop = (service: PlainService) => {
    const desired = service.spec?.desiredPhase ?? ServicePhase.UNSPECIFIED;

    if (desiredRunningStoppedSameSession(service)) {
        return false;
    }

    switch (desired) {
        case ServicePhase.STARTING:
        case ServicePhase.RUNNING:
            return true;
        default:
            return false;
    }
};

export const showStart = (service: PlainService) => {
    const desired = service.spec?.desiredPhase ?? ServicePhase.UNSPECIFIED;

    if (desiredRunningStoppedSameSession(service)) {
        return true;
    }

    switch (desired) {
        case ServicePhase.STOPPED:
        case ServicePhase.STOPPING:
        case ServicePhase.UNSPECIFIED:
            return true;
        default:
            return false;
    }
};

export const canShowLogs = (service: PlainService) => {
    return Boolean(service.status?.logUrl);
};

function desiredRunningStoppedSameSession(service: PlainService): boolean {
    const desired = service.spec?.desiredPhase ?? ServicePhase.UNSPECIFIED;
    const actual = service.status?.phase ?? ServicePhase.UNSPECIFIED;
    const sameSession = service.status?.session === service.spec?.session;
    return sameSession && desired === ServicePhase.RUNNING && actual === ServicePhase.STOPPED;
}
