import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "@/components/podkit/lib/cn";
import { IconClose } from "@/assets/icons/geist/IconClose";
import type { TrackLocation } from "@/hooks/use-segment";

const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(
            "fixed inset-0 z-50 bg-surface-glass backdrop-blur-modal backdrop-opacity-modal data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
            className,
        )}
        {...props}
    />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

type DialogTriggerProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    disableOverlay?: boolean;
    "data-track-location": TrackLocation;
};
const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogTriggerProps>(
    ({ className, children, disableOverlay, "data-track-location": dataTrackLocation, ...props }, ref) => (
        <DialogPortal>
            {!disableOverlay && <DialogOverlay />}
            <DialogPrimitive.Content
                ref={ref}
                className={cn(
                    // positioning
                    "fixed left-[50%] top-[50%] z-50 max-h-[90%] w-full translate-x-[-50%] translate-y-[-50%]",
                    // layout
                    "flex flex-col overflow-x-auto rounded-xl border-0.5 border-border-base bg-surface-01/75 p-6 shadow-modal",
                    // animations
                    "duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
                    className,
                )}
                {...props}
                data-track-location={dataTrackLocation}
            >
                {children}
                <DialogPrimitive.Close
                    className="ring-offset-background absolute right-6 top-6 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-0 disabled:pointer-events-none">
                    <IconClose size="base" className="text-content-primary" />
                    <span className="sr-only">Close</span>
                </DialogPrimitive.Close>
            </DialogPrimitive.Content>
        </DialogPortal>
    ),
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <>
        <header className={cn("flex flex-col text-left gap-1", className)} {...props} />
    </>
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <footer className={cn("flex flex-col-reverse justify-end space-x-2 sm:flex-row", className)} {...props} />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn("text-xl font-bold leading-none tracking-tight text-content-primary", className)}
        {...props}
    />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={cn("m-0 p-0 text-base text-content-secondary", className)}
        {...props}
    />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogBody = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("overflow-x-visible py-8 text-base text-content-primary", className)} {...props} />
);
DialogBody.displayName = "DialogBody";

export {
    Dialog,
    DialogPortal,
    DialogOverlay,
    DialogClose,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogDescription,
    DialogBody,
};
