import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { type PartialMessage, type PlainMessage, toPlainMessage } from "@bufbuild/protobuf";
import { type QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { type PaginationResponse } from "gitpod-next-api/gitpod/v1/pagination_pb";
import {
    type Environment,
    type EnvironmentInitializer,
    EnvironmentPhase,
    ListEnvironmentsRequest_Filter,
    UpdateEnvironmentRequest,
} from "gitpod-next-api/gitpod/v1/environment_pb";
import { ResourceOperation, type WatchEventsResponse } from "gitpod-next-api/gitpod/v1/event_pb";
import type { GitpodAPI } from "@/api";
import { useSegmentTrack } from "@/hooks/use-segment";
import { Code, ConnectError } from "@connectrpc/connect";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import {
    type EnvironmentClass,
    type ListEnvironmentClassesRequest_Filter,
} from "gitpod-next-api/gitpod/v1/runner_configuration_pb.ts";
import type { PlainProject } from "@/queries/project-queries";
import type { CheckAuthenticationForHostResponse, RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";

export const environmentNotDeletedPhases = [
    EnvironmentPhase.UNSPECIFIED,
    EnvironmentPhase.CREATING,
    EnvironmentPhase.STARTING,
    EnvironmentPhase.RUNNING,
    EnvironmentPhase.UPDATING,
    EnvironmentPhase.STOPPING,
    EnvironmentPhase.STOPPED,
    EnvironmentPhase.DELETING,
];

export type PlainEnvironment = PlainMessage<Environment>;

export const toPlainEnvironment = (environment: Environment): PlainEnvironment => {
    return toPlainMessage(environment);
};

export const environmentQueryKey = {
    list: () => keyWithPrincipal(["environments", "list", "all"]),
    listInventory: (filter: Record<string, string | number | undefined>) =>
        keyWithPrincipal(["environments", "list", "inventory", filter]),
    runnerEnvironments: (runnerId: string) => keyWithPrincipal(["environments", "list", "runner", runnerId]),
    listClasses: (filter: PartialMessage<ListEnvironmentClassesRequest_Filter>) =>
        keyWithPrincipal(["environments", "listClasses", filter]),
    get: (environmentId?: string) => keyWithPrincipal(["environments", { environmentId }]),
    logsToken: (environmentId?: string) => keyWithPrincipal(["environments", "logsToken", { environmentId }]),
};

export const handleEnvironmentEvent = async (api: GitpodAPI, client: QueryClient, evt: WatchEventsResponse) => {
    if (evt.operation === ResourceOperation.UPDATE) {
        const environment = await refetchEnvironment(api, evt.resourceId);
        setEnvironmentInCache(client, evt.resourceId, environment ? toPlainEnvironment(environment) : undefined);
    }
    if (evt.operation === ResourceOperation.CREATE) {
        await client.invalidateQueries({ queryKey: environmentQueryKey.list() });
    }
    if (evt.operation === ResourceOperation.DELETE) {
        setEnvironmentInCache(client, evt.resourceId, undefined);
    }
};

const refetchEnvironment = async (api: GitpodAPI, environmentId: string) => {
    try {
        const response = await api.environmentService.getEnvironment({ environmentId });
        return response.environment;
    } catch (error) {
        const isNotFound = error instanceof ConnectError && error.code === Code.NotFound;
        // this might happen if the environment was deleted in the meantime
        if (!isNotFound) {
            console.error("Failed to refetch environment", environmentId);
        }
    }
};

/**
 * Applies the passed PlainEnvironment shape to the cache, both "by id" and "list".
 * @param client
 * @param environment
 * @param optimisticUpdate Does not change the behavior, but helps to convey intend.
 */
function setEnvironmentInCache(client: QueryClient, environmentId: string, environment?: PlainEnvironment) {
    client.setQueryData(environmentQueryKey.get(environmentId), environment);
    client.setQueryData(environmentQueryKey.list(), (currentData?: PlainEnvironmentList) => {
        if (!currentData) {
            return currentData;
        }
        return {
            ...currentData,
            environments: currentData.environments
                .map((w: PlainEnvironment) => {
                    if (w.id !== environmentId) {
                        return w;
                    }
                    return environment;
                })
                .filter((w) => !!w),
        };
    });
    const prefixOfInventoryKeys = environmentQueryKey.listInventory({}).slice(0, -1);
    client.setQueriesData(
        { queryKey: prefixOfInventoryKeys },
        (currentData?: { pages: { environments: PlainEnvironment[] }[] }) => {
            if (!currentData?.pages) {
                return currentData;
            }

            const result = { ...currentData };
            result.pages = result.pages.map((page) => {
                return {
                    ...page,
                    environments: page.environments
                        .map((w: PlainEnvironment) => {
                            if (w.id !== environmentId) {
                                return w;
                            }
                            return environment;
                        })
                        .filter((w) => !!w) as PlainMessage<Environment>[], // TODO: update TS version and remove case
                };
            });
            return result;
        },
    );
}

type PlainEnvironmentList = {
    environments: PlainEnvironment[];
    pagination: PaginationResponse | undefined;
};

type ParamsWithProject = { project: PlainProject };
export const useCreateEnvironmentFromProject = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const segmentTrack = useSegmentTrack();

    return useMutation({
        onMutate: (params: ParamsWithProject) => {
            return params;
        },
        onSettled: (environment, error, _result, context) => {
            if (error) {
                segmentTrack("Environment Create Failed", { error: error.message || "Unknown error" });
            } else {
                segmentTrack("Environment Create Succeeded", { ...context, environmentId: environment?.id });
            }
        },
        mutationFn: async ({ project }: ParamsWithProject): Promise<PlainEnvironment> => {
            const { environment } = await api.environmentService.createEnvironmentFromProject({
                projectId: project.id,
                spec: {
                    desiredPhase: EnvironmentPhase.RUNNING,
                },
            });

            if (!environment) {
                throw new Error("Failed to create environment");
            }

            return toPlainEnvironment(environment);
        },
        onSuccess: async () => {
            // We want to see the created environment in the list immediately.
            // We are receiving an event as well, but that's only about the individual environment.
            await client.invalidateQueries({ queryKey: environmentQueryKey.list() });
        },
    });
};
export type CheckScmAuthForProjectResult = PlainMessage<CheckAuthenticationForHostResponse> & {
    environmentClass: PlainRunnerEnvironmentClass;
};
export const useCheckScmAuthForProject = () => {
    const api = useGitpodAPI();
    const { data: envClasses } = useListEnvironmentClasses({ filter: { enabled: true } });
    return useMutation<CheckScmAuthForProjectResult | undefined, Error, ParamsWithProject>({
        onMutate: (params: ParamsWithProject) => {
            return params;
        },
        mutationFn: async ({ project }: ParamsWithProject) => {
            if (!envClasses) {
                throw new Error("Environment classes not loaded");
            }
            const repoURL = getRepoUrlFromInitializer(project.initializer);
            if (!repoURL) {
                throw new Error("No context URL found");
            }
            const scmHost = new URL(repoURL).host;

            const clazz = project.environmentClass?.environmentClass;
            if (clazz?.case === "localRunner") {
                return undefined;
            }

            const environmentClass = envClasses.classes.find((c) => c.id === clazz?.value);
            if (!environmentClass) {
                throw new Error("Environment class not found");
            }

            const response = await api.runnerService.checkAuthenticationForHost({
                host: scmHost,
                runnerId: environmentClass.runnerId,
            });
            return { ...response, environmentClass } satisfies CheckScmAuthForProjectResult;
        },
    });
};

/**
 * Extracts the repository URL from the environment initializer.
 *
 * The environment initializer can contain multiple initialization specs, each with a different type of context.
 * This function searches through the specs and returns the URL from the first spec that has a "contextUrl" or "git" case.
 *
 * @param initializer The environment initializer object.
 * @returns The repository URL, or undefined if no URL could be found.
 */
export function getRepoUrlFromInitializer(initializer: PlainMessage<EnvironmentInitializer> | undefined) {
    const urls = initializer?.specs
        .map((s) => {
            switch (s.spec.case) {
                case "contextUrl":
                    return s.spec.value.url;
                case "git":
                    return s.spec.value.remoteUri;
            }
        })
        .filter((u) => !!u)
        .map((u) => u!);
    return urls?.[0];
}

type UseCreateEnvironmentParamsFromContextUrl = {
    type: "contextUrl";
    contextURL: string;
    classID: string;
};
export const useCreateEnvironment = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const segmentTrack = useSegmentTrack();

    return useMutation({
        onMutate: (params: UseCreateEnvironmentParamsFromContextUrl) => {
            return params;
        },
        onSettled: (environment, error, _result, context) => {
            if (error) {
                segmentTrack("Environment Create Failed", { error: error.message || "Unknown error" });
            } else {
                segmentTrack("Environment Create Succeeded", { ...context, environmentId: environment?.id });
            }
        },
        mutationFn: async (params: UseCreateEnvironmentParamsFromContextUrl): Promise<PlainEnvironment> => {
            const { environment } = await api.environmentService.createEnvironment({
                spec: {
                    desiredPhase: EnvironmentPhase.RUNNING,
                    machine: {
                        class: params.classID,
                    },
                    content: {
                        session: uuidv4(),
                        initializer: {
                            specs: [
                                {
                                    spec: {
                                        case: "contextUrl",
                                        value: {
                                            url: params.contextURL,
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            });

            if (!environment) {
                throw new Error("Failed to create environment");
            }

            return toPlainEnvironment(environment);
        },
        onSuccess: async () => {
            // We want to see the created environment in the list immediately.
            // We are receiving an event as well, but that's only about the individual environment.
            await client.invalidateQueries({ queryKey: environmentQueryKey.list() });
        },
    });
};

export const useUpdateEnvironment = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();
    return useMutation({
        mutationFn: async ({ req }: { req: PartialMessage<UpdateEnvironmentRequest> }) => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const resp = await api.environmentService.updateEnvironment(new UpdateEnvironmentRequest(req));
            if (!resp) {
                throw new Error("Failed to update of the environment");
            }
            return;
        },
        onSettled: async (_, __, { req }) => {
            await client.invalidateQueries({ queryKey: environmentQueryKey.get(req.environmentId) });
        },
    });
};

export const useUpdateContextURLEnvironment = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ contextURL, environmentID }: { contextURL: string; environmentID: string }) => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const resp = await api.environmentService.updateEnvironment({
                environmentId: environmentID,
                spec: {
                    content: {
                        session: uuidv4(),
                        initializer: {
                            specs: [
                                {
                                    spec: {
                                        case: "contextUrl",
                                        value: {
                                            url: contextURL,
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            });

            if (!resp) {
                throw new Error("Failed to update contextURL of environment");
            }
            return;
        },
        onSuccess: async (_, { environmentID }) => {
            await client.invalidateQueries({ queryKey: environmentQueryKey.get(environmentID) });
        },
    });
};

export const useListEnvironments = (filter?: PartialMessage<ListEnvironmentsRequest_Filter>) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: environmentQueryKey.list(),
        queryFn: async (): Promise<PlainEnvironmentList> => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            const resp = await api.environmentService.listEnvironments({
                organizationId: user?.organizationId,
                pagination: {
                    pageSize: 100,
                },
                filter: new ListEnvironmentsRequest_Filter(
                    new ListEnvironmentsRequest_Filter({
                        creatorIds: [user.id],
                        ...filter,
                    }),
                ),
            });

            return {
                environments: resp.environments.map(toPlainEnvironment),
                pagination: resp.pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 1_000 * 60 * 60,
        gcTime: 1_000 * 60 * 60,
        // even if the stale time is not over, we want to refetch on reconnect
        refetchOnReconnect: "always",
    });
};

export type UseListEnvironmentInventoryParams = {
    runnerID?: string;
    creatorID?: string;
    projectID?: string;
    status?: EnvironmentPhase;
    runnerKind?: RunnerKind;
    page?: string;
};

export const useListEnvironmentInventory = ({
    creatorID,
    projectID,
    runnerID,
    status,
    runnerKind,
    page,
}: UseListEnvironmentInventoryParams) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useInfiniteQuery({
        queryKey: environmentQueryKey.listInventory({ creatorID, projectID, runnerID, status, runnerKind }),
        queryFn: async ({ pageParam }): Promise<PlainEnvironmentList> => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            const resp = await api.environmentService.listEnvironments({
                organizationId: user?.organizationId,
                pagination: {
                    token: pageParam,
                },
                filter: new ListEnvironmentsRequest_Filter(
                    new ListEnvironmentsRequest_Filter({
                        creatorIds: creatorID ? [creatorID] : undefined,
                        projectIds: projectID ? [projectID] : undefined,
                        runnerIds: runnerID ? [runnerID] : undefined,
                        statusPhases: status ? [status] : undefined,
                        runnerKinds: runnerKind ? [runnerKind] : undefined,
                    }),
                ),
            });
            return {
                environments: resp.environments.map(toPlainEnvironment),
                pagination: resp.pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 200,
        initialPageParam: page,

        getNextPageParam: (lastPage) => {
            if (!lastPage.pagination?.nextToken) {
                return undefined;
            }
            return lastPage.pagination.nextToken;
        },
    });
};

/**
 * Returns all environments on `runnerId` that are not DELETED
 */
export const useRunnerEnvironments = (runnerId: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: environmentQueryKey.runnerEnvironments(runnerId),
        queryFn: async (): Promise<PlainEnvironmentList> => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            const resp = await api.environmentService.listEnvironments({
                organizationId: user?.organizationId,
                pagination: {
                    pageSize: 100,
                },
                filter: new ListEnvironmentsRequest_Filter(
                    new ListEnvironmentsRequest_Filter({
                        runnerIds: [runnerId],
                        statusPhases: environmentNotDeletedPhases,
                        creatorIds: [user.id],
                    }),
                ),
            });

            return {
                environments: resp.environments.map(toPlainEnvironment),
                pagination: resp.pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 200,
    });
};

export type PlainRunnerEnvironmentClass = PlainMessage<EnvironmentClass>;

export const toPlainRunnerEnvironmentClass = (clazz: EnvironmentClass): PlainRunnerEnvironmentClass => {
    return toPlainMessage(clazz);
};

export type PlainEnvironmentClassList = {
    classes: PlainRunnerEnvironmentClass[];
    pagination: PaginationResponse | undefined;
};

export const toRunnerEnvironmentClass = (runnerEnvironmentClass: EnvironmentClass): PlainRunnerEnvironmentClass => {
    return toPlainMessage(runnerEnvironmentClass);
};

export const handleEnvironmentClassEvent = async (client: QueryClient) => {
    // Invalidate environment class queries. We cannot just update the caches, because we do not know if the class still satisfies the filter used in the query.
    await client.invalidateQueries({ queryKey: environmentQueryKey.listClasses({}).slice(0, -1) }).catch(console.error);
};

export const useListEnvironmentClasses = (options: {
    filter: PartialMessage<ListEnvironmentClassesRequest_Filter>;
}) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    const { data, isLoading, isPending, isRefetching, isSuccess } = useQuery({
        queryKey: environmentQueryKey.listClasses(options.filter),
        queryFn: async (): Promise<PlainEnvironmentClassList> => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const resp = await api.environmentService.listEnvironmentClasses({
                filter: options.filter,
                pagination: {
                    pageSize: 100,
                },
            });

            return {
                classes: resp.environmentClasses.map(toRunnerEnvironmentClass),
                pagination: resp.pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 1_000 * 60 * 5, // 5 minutes
        gcTime: 1_000 * 60 * 60 * 24 * 7, // 1 week
        // even if the stale time is not over, we want to refetch on reconnect
        refetchOnReconnect: "always",
    });
    return { data, isLoading: isLoading || isPending || isRefetching, isSuccess };
};

export const useEnvironment = (environmentId?: string) => {
    const api = useGitpodAPI();

    return useQuery<PlainEnvironment, Error>({
        queryKey: environmentQueryKey.get(environmentId),
        queryFn: async (): Promise<PlainEnvironment> => {
            if (!environmentId) {
                throw new Error("No environment ID provided");
            }

            const { environment } = await api.environmentService.getEnvironment({ environmentId });
            if (!environment) {
                throw new Error("Environment not found");
            }

            return toPlainEnvironment(environment);
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!environmentId,
        staleTime: 5_000,
    });
};

export const useCreateEnvironmentLogsToken = (environmentId?: string) => {
    const api = useGitpodAPI();
    const tokenValidDuration = 1000 * 60 * 45; // 45 minutes
    return useQuery<string, Error>({
        queryKey: environmentQueryKey.logsToken(environmentId),
        queryFn: async (): Promise<string> => {
            if (!environmentId) {
                throw new Error("No environment ID provided");
            }

            const { accessToken } = await api.environmentService.createEnvironmentLogsToken({ environmentId });
            if (!accessToken) {
                throw new Error("No token provided not found");
            }

            return accessToken;
        },
        staleTime: tokenValidDuration,
        gcTime: tokenValidDuration,
        enabled: !!environmentId,
    });
};

export const useStartEnvironment = () => {
    const queryClient = useQueryClient();
    const api = useGitpodAPI();
    const segmentTrack = useSegmentTrack();

    return useMutation({
        onMutate: (environmentId: string) => {
            return environmentId;
        },
        onSettled: (_environment, error, _result, environmentId) => {
            if (!environmentId) {
                return;
            }
            if (error) {
                segmentTrack("Environment Start Failed", {
                    environmentId,
                    error: error.message || "Unknown error",
                });
            } else {
                segmentTrack("Environment Start Succeeded", {
                    environmentId,
                });
            }
        },
        mutationFn: async (environmentId: string): Promise<PlainEnvironment | undefined> => {
            await api.environmentService.startEnvironment({ environmentId });
            const { environment } = await api.environmentService.getEnvironment({ environmentId });
            if (!environment) {
                throw new Error("Environment not found");
            }
            const env = toPlainEnvironment(environment);
            setEnvironmentInCache(queryClient, environmentId, env);
            return env;
        },
    });
};

export const useStopEnvironment = () => {
    const queryClient = useQueryClient();
    const api = useGitpodAPI();
    const segmentTrack = useSegmentTrack();

    return useMutation({
        mutationFn: async (environmentId: string): Promise<PlainEnvironment | undefined> => {
            await api.environmentService.stopEnvironment({ environmentId });
            const { environment } = await api.environmentService.getEnvironment({ environmentId });
            if (!environment) {
                throw new Error("Environment not found");
            }
            const env = toPlainEnvironment(environment);
            setEnvironmentInCache(queryClient, environmentId, env);
            return env;
        },
        onSettled: (_environment, error, _result, environmentId) => {
            if (!environmentId) {
                return;
            }
            if (error) {
                segmentTrack("Environment Stop Failed", {
                    environmentId,
                    error: error.message || "Unknown error",
                });
            } else {
                segmentTrack("Environment Stop Succeeded", {
                    environmentId,
                });
            }
        },
    });
};

export const useDeleteEnvironment = () => {
    const queryClient = useQueryClient();
    const api = useGitpodAPI();
    const segmentTrack = useSegmentTrack();

    return useMutation({
        mutationFn: async ({ environmentId, force = false }: { environmentId: string; force: boolean }) => {
            await api.environmentService.deleteEnvironment({
                environmentId,
                force,
            });

            let env: PlainEnvironment | undefined;
            // When force-deleted, the worskpace will not be found
            if (!force) {
                const { environment } = await api.environmentService.getEnvironment({ environmentId });
                if (!environment) {
                    throw new Error("Environment not found");
                }
                env = toPlainEnvironment(environment);
            }
            setEnvironmentInCache(queryClient, environmentId, env);
            return env;
        },
        onSettled: (_environment, error, _result, environmentId) => {
            if (!environmentId) {
                return;
            }
            if (error) {
                segmentTrack("Environment Delete Failed", {
                    environmentId,
                    error: error.message || "Unknown error",
                });
            } else {
                segmentTrack("Environment Delete Succeeded", {
                    environmentId,
                });
            }
        },
    });
};

export const useEditorURL = (editorId: string, environment?: Environment | PlainMessage<Environment>) => {
    const environmentId = environment?.id;
    const organizationId = environment?.metadata?.organizationId;
    const api = useGitpodAPI();
    return useQuery({
        queryKey: keyWithPrincipal(["editorURL", { environmentId, organizationId, editorId }]),
        queryFn: () => {
            if (!environmentId || !organizationId) {
                throw new Error("No environment or organization ID provided");
            }
            return api.editorService.resolveEditorURL({
                environmentId,
                organizationId,
                editorId,
            });
        },
    });
};
