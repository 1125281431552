import type { PlainMessage } from "@bufbuild/protobuf";
import type { EnvironmentGitStatus } from "gitpod-next-api/gitpod/v1/environment_pb";

export function gitStatusChangesCount(git?: PlainMessage<EnvironmentGitStatus>): number | null {
    if (!git) {
        return null;
    }
    return (git?.totalUnpushedCommits || 0) + (git?.totalChangedFiles || 0);
}

export function gitStatusLabel(git?: PlainMessage<EnvironmentGitStatus>): string | null {
    if (!git) {
        return null;
    }

    const changes = gitStatusChangesCount(git) ?? 0;
    if (changes === 0) {
        return "No changes";
    }
    return `${changes} change${changes > 1 ? "s" : ""}`;
}
