import { IconPlaceholder } from "@/assets/icons/geist/IconPlaceholder";
import { EnvironmentDetailsEmptySection } from "@/components/environments/EnvironmentDetailsEmptySection";
import { EnvironmentServices } from "@/components/environments/EnvironmentServices";
import { EnvironmentTasks } from "@/components/environments/EnvironmentTasks";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useListServices, useListTasks } from "@/queries/automations-queries";
import { useEnvironment } from "@/queries/environment-queries";
import { EnvironmentStatus_AutomationsFile_Presence } from "gitpod-next-api/gitpod/v1/environment_pb";
import { type FC } from "react";

export const EnvironmentAutomations: FC<{ environmentId: string }> = ({ environmentId }) => {
    const { data: environment, isPending: isPendingEnvironment } = useEnvironment(environmentId);
    const { data: services, isPending: isPendingServices, error: listServicesError } = useListServices(environmentId);
    const { data: tasks, isPending: isPendingTasks, error: listTasksError } = useListTasks(environmentId);

    const fromProject = environment?.metadata?.projectId;
    const noAutomationsFile =
        environment?.status?.automationsFile?.automationsFilePresence ===
        EnvironmentStatus_AutomationsFile_Presence.ABSENT;
    const hasServices = (services?.length ?? 0) > 0;
    const hasTasks = (tasks?.length ?? 0) > 0;

    // If there are any tasks or services (or we failed to fetch them), we always want to render them
    if (hasTasks || hasServices || listServicesError || listTasksError) {
        return (
            <div className="flex flex-col gap-6" data-testid="automations-section">
                <ErrorMessage error={listServicesError} />
                <ErrorMessage error={listTasksError} />
                <EnvironmentServices environmentId={environmentId} />
                <EnvironmentTasks environmentId={environmentId} />
            </div>
        );
    }

    // We explicitly don't show a loading state but instead fade in the tasks/services.
    // Showing a loading state while starting an environment doesn't look nice and doesn't provide any useful information as the start sequence
    // already informs the user that things are in-progress
    if (isPendingServices || isPendingTasks) {
        return null;
    }

    // Until we have the environment we don't know if the empty-state is appropriate, so we return early
    if (isPendingEnvironment) {
        return null;
    }

    // If the environment is not created from a project then we show the onboarding section rather than
    // the empty state for services
    if (!fromProject) {
        return null;
    }

    if (noAutomationsFile) {
        return (
            <EnvironmentDetailsEmptySection
                data-testid="automations-empty-state"
                className="duration-700 animate-in fade-in"
                icon={<IconPlaceholder kind="automations" />}
                title="This project has no automations"
                text="Automations are reusable self-serve actions for automating common development workflows like seeding databases, provisioning infra, and configuring code assistants. Automations are defined in .gitpod/automations.yaml"
                docsUrl="https://www.gitpod.io/docs/flex/configuration/automations"
            />
        );
    }

    return null;
};
