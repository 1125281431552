import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconChevronDown: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3031 5.81248L12.839 6.27652L8.61871 10.4968C8.27701 10.8386 7.72298 10.8386 7.38128 10.4968L3.16095 6.27652L2.69691 5.81248L3.62499 4.8844L4.08903 5.34844L7.99999 9.2594L11.911 5.34844L12.375 4.8844L13.3031 5.81248Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.0607 7.49999L15.5303 8.03032L10.7071 12.8535C10.3166 13.2441 9.68341 13.2441 9.29289 12.8535L4.46966 8.03032L3.93933 7.49999L4.99999 6.43933L5.53032 6.96966L9.99999 11.4393L14.4697 6.96966L15 6.43933L16.0607 7.49999Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.8183 9.18747L18.2216 9.7841L12.7955 15.2102C12.3562 15.6496 11.6438 15.6496 11.2045 15.2102L5.77837 9.7841L5.18175 9.18747L6.37499 7.99423L6.97161 8.59085L12 13.6192L17.0284 8.59085L17.625 7.99423L18.8183 9.18747Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
