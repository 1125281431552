import { cn } from "@/components/podkit/lib/cn";
import type { FC, PropsWithChildren } from "react";

/**
 * Collapsable component that animates the height of its children.
 *
 * You can't animate between hiehgt: 0 and height: auto in CSS but it's possible to
 * achieve the same effect by using grid-template-rows and animating between 0fr and 1fr.
 * References:
 * - https://www.youtube.com/watch?v=B_n4YONte5A
 * - https://nijatniyazov.medium.com/smooth-height-transition-f6fa26ee905d
 *
 */
export const Collapsable: FC<PropsWithChildren<{ collapsed: boolean }>> = ({ children, collapsed }) => {
    return (
        <div
            className={cn(
                "grid transition-all duration-150 ease-in",
                collapsed ? "grid-rows-[0fr]" : "grid-rows-[1fr]",
            )}
        >
            <div className="overflow-y-hidden">{children}</div>
        </div>
    );
};
