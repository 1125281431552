import { DesktopContext } from "@/contexts/DesktopProvider";
import { useContext } from "react";

export const useDesktop = () => {
    const ctx = useContext(DesktopContext);

    if (!ctx) {
        throw new Error("useDesktop must be used within a DesktopProvider");
    }

    return ctx;
};
