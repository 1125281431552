import type { GitpodAPI } from "@/api";
import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import { Code, ConnectError } from "@connectrpc/connect";
import { useMutation, useQuery, useQueryClient, type QueryClient } from "@tanstack/react-query";
import {
    GetTaskExecutionRequest,
    GetTaskRequest,
    GetServiceRequest,
    ListServicesRequest,
    ListTaskExecutionsRequest,
    ListTasksRequest,
    ServicePhase,
    StartServiceRequest,
    StopServiceRequest,
    StartTaskRequest,
    type Service,
    type Task,
    type TaskExecution,
} from "gitpod-next-api/gitpod/v1/environment_automation_pb";
import { ResourceOperation, ResourceType, type WatchEventsResponse } from "gitpod-next-api/gitpod/v1/event_pb";

export type PlainService = PlainMessage<Service>;
export type PlainTask = PlainMessage<Task>;
export type PlainTaskExecution = PlainMessage<TaskExecution>;

export const environmentAutomationQueryKey = {
    listServices: (environmentId?: string) => keyWithPrincipal(["listServices", environmentId]),
    getService: (serviceId?: string) => keyWithPrincipal(["getService", serviceId]),
    getTask: (id?: string) => keyWithPrincipal(["getTask", id]),
    getTaskExecution: (id?: string) => keyWithPrincipal(["getTaskExecution", id]),
    listTasks: (environmentId?: string) => keyWithPrincipal(["listTasks", environmentId]),
    listTaskExecutions: (environmentId?: string) => keyWithPrincipal(["listTaskExecutions", environmentId]),
};

export const handleAutomationEvents = async (
    api: GitpodAPI,
    client: QueryClient,
    evt: WatchEventsResponse,
    environmentId: string,
) => {
    if (evt.resourceType === ResourceType.TASK) {
        if (evt.operation === ResourceOperation.UPDATE) {
            const task = await refetchTask(api, evt.resourceId);
            setTaskInCache(client, environmentId, evt.resourceId, task ? toPlainMessage(task) : undefined);
        }
        if (evt.operation === ResourceOperation.CREATE) {
            await client.invalidateQueries({ queryKey: environmentAutomationQueryKey.listTasks(environmentId) });
        }
        if (evt.operation === ResourceOperation.DELETE) {
            setTaskInCache(client, environmentId, evt.resourceId, undefined);
        }
    }
    if (evt.resourceType === ResourceType.SERVICE) {
        if (evt.operation === ResourceOperation.UPDATE_STATUS || evt.operation === ResourceOperation.UPDATE) {
            const service = await refetchService(api, evt.resourceId);
            setServiceInCache(client, environmentId, evt.resourceId, service ? toPlainMessage(service) : undefined);
        }
        if (evt.operation === ResourceOperation.CREATE) {
            await client.invalidateQueries({ queryKey: environmentAutomationQueryKey.listServices(environmentId) });
        }
        if (evt.operation === ResourceOperation.DELETE) {
            setServiceInCache(client, environmentId, evt.resourceId, undefined);
        }
    }
    if (evt.resourceType === ResourceType.TASK_EXECUTION) {
        if (evt.operation === ResourceOperation.UPDATE_STATUS || evt.operation === ResourceOperation.UPDATE) {
            const taskExecution = await refetchTaskExecution(api, evt.resourceId);
            setTaskExectionInCache(
                client,
                environmentId,
                evt.resourceId,
                taskExecution ? toPlainMessage(taskExecution) : undefined,
            );
        }
        if (evt.operation === ResourceOperation.CREATE) {
            await client.invalidateQueries({
                queryKey: environmentAutomationQueryKey.listTaskExecutions(environmentId),
            });
        }
        if (evt.operation === ResourceOperation.DELETE) {
            setTaskExectionInCache(client, environmentId, evt.resourceId, undefined);
        }
    }
};

const refetchTask = async (api: GitpodAPI, taskId: string) => {
    try {
        const response = await api.environmentAutomationService.getTask({ id: taskId });
        return response.task;
    } catch (error) {
        const isNotFound = error instanceof ConnectError && error.code === Code.NotFound;
        if (!isNotFound) {
            console.error("Failed to refetch task", taskId);
        }
    }
};

const refetchTaskExecution = async (api: GitpodAPI, taskExecutionId: string) => {
    try {
        const response = await api.environmentAutomationService.getTaskExecution({ id: taskExecutionId });
        return response.taskExecution;
    } catch (error) {
        const isNotFound = error instanceof ConnectError && error.code === Code.NotFound;
        if (!isNotFound) {
            console.error("Failed to refetch task execution", taskExecutionId);
        }
    }
};

const refetchService = async (api: GitpodAPI, serviceId: string) => {
    try {
        const response = await api.environmentAutomationService.getService({ id: serviceId });
        return response.service;
    } catch (error) {
        const isNotFound = error instanceof ConnectError && error.code === Code.NotFound;
        if (!isNotFound) {
            console.error("Failed to refetch service", serviceId);
        }
    }
};

function getListQueryUpdaterFn<T extends { id?: string }>(id: string, item?: T) {
    return (currentData?: T[]) => {
        if (!currentData) {
            return currentData;
        }
        return currentData
            .map((p) => {
                if (p?.id !== id) {
                    return p;
                }
                return item;
            })
            .filter((w) => !!w);
    };
}

function setTaskInCache(client: QueryClient, environmentId: string, taskId: string, task?: PlainTask) {
    client.setQueryData(environmentAutomationQueryKey.getTask(taskId), task);
    client.setQueryData(environmentAutomationQueryKey.listTasks(environmentId), getListQueryUpdaterFn(taskId, task));
}

function setTaskExectionInCache(
    client: QueryClient,
    environmentId: string,
    taskExecutionId: string,
    taskExecution?: PlainTaskExecution,
) {
    client.setQueryData(environmentAutomationQueryKey.getTaskExecution(taskExecutionId), taskExecution);
    client.setQueryData(
        environmentAutomationQueryKey.listTaskExecutions(environmentId),
        getListQueryUpdaterFn(taskExecutionId, taskExecution),
    );
}

function setServiceInCache(client: QueryClient, environmentId: string, serviceId: string, service?: PlainService) {
    client.setQueryData(environmentAutomationQueryKey.getService(serviceId), service);
    client.setQueryData(
        environmentAutomationQueryKey.listServices(environmentId),
        getListQueryUpdaterFn(serviceId, service),
    );
}

export const useListServices = (environmentId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: environmentAutomationQueryKey.listServices(environmentId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            if (!environmentId) {
                return [];
            }
            const { services } = await api.environmentAutomationService.listServices(
                new ListServicesRequest({
                    filter: {
                        environmentIds: [environmentId],
                    },
                }),
            );

            return services.map(toPlainMessage);
        },
        enabled: !!user && !!environmentId,
        staleTime: 60_000,
    });
};

export const useGetService = (serviceId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: environmentAutomationQueryKey.getService(serviceId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            if (!serviceId) {
                return;
            }

            const { service } = await api.environmentAutomationService.getService(
                new GetServiceRequest({
                    id: serviceId,
                }),
            );

            if (!service) {
                throw new Error("Service not found");
            }

            return toPlainMessage(service);
        },
        enabled: !!user && !!serviceId,
        staleTime: 60_000,
    });
};

export const useStopService = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();
    return useMutation({
        mutationFn: async (options: { serviceId: string; environmentId: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            await api.environmentAutomationService.stopService(
                new StopServiceRequest({
                    id: options.serviceId,
                }),
            );
            optimisticStopService(client, options.serviceId, options.environmentId);
        },
    });
};

export const useStartService = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();
    return useMutation({
        mutationFn: async (options: { serviceId: string; environmentId: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            await api.environmentAutomationService.startService(
                new StartServiceRequest({
                    id: options.serviceId,
                }),
            );
            optimisticStartService(client, options.serviceId, options.environmentId);
        },
    });
};

export const useListTasks = (environmentId?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: environmentAutomationQueryKey.listTasks(environmentId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            if (!environmentId) {
                return [];
            }
            const { tasks } = await api.environmentAutomationService.listTasks(
                new ListTasksRequest({
                    filter: {
                        environmentIds: [environmentId],
                    },
                }),
            );

            return tasks.map(toPlainMessage).sort((a, b) => {
                if (isManualTask(a) && isManualTask(b)) {
                    return (a.metadata?.name || "") < (b.metadata?.name || "") ? -1 : 1;
                } else if (isManualTask(a)) {
                    return -1;
                } else if (isManualTask(b)) {
                    return 1;
                }

                return (b.metadata?.triggeredBy?.length || 0) - (a.metadata?.triggeredBy?.length || 0);
            });
        },
        enabled: !!user && !!environmentId,
        staleTime: 60_000,
    });
};

export function isManualTask(a: PlainTask) {
    const triggers = (a.metadata?.triggeredBy || []).map((t) => t.trigger.case);
    return triggers.includes("manual");
}

export const useGetTask = (id?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: environmentAutomationQueryKey.getTask(id),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            const { task } = await api.environmentAutomationService.getTask(
                new GetTaskRequest({
                    id,
                }),
            );
            if (!task) {
                throw new Error("Task not found");
            }

            return toPlainMessage(task);
        },
        enabled: !!user && !!id,
        staleTime: 60_000,
    });
};

export const useListTaskExecutions = (environmentId: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: environmentAutomationQueryKey.listTaskExecutions(environmentId),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            if (!environmentId) {
                return [];
            }
            const { taskExecutions } = await api.environmentAutomationService.listTaskExecutions(
                new ListTaskExecutionsRequest({
                    filter: {
                        environmentIds: [environmentId],
                    },
                }),
            );

            return taskExecutions.map(toPlainMessage);
        },
        enabled: !!user && !!environmentId,
        staleTime: 60_000,
    });
};

export const useGetTaskExecution = (id?: string) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();
    return useQuery({
        queryKey: environmentAutomationQueryKey.getTaskExecution(id),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }
            const { taskExecution } = await api.environmentAutomationService.getTaskExecution(
                new GetTaskExecutionRequest({
                    id,
                }),
            );
            if (!taskExecution) {
                throw new Error("Task execution not found");
            }

            return toPlainMessage(taskExecution);
        },
        enabled: !!user && !!id,
        staleTime: 60_000,
    });
};

export const useStartTask = () => {
    const api = useGitpodAPI();
    return useMutation({
        mutationFn: async (id: string) => {
            const { taskExecution } = await api.environmentAutomationService.startTask(
                new StartTaskRequest({
                    id,
                }),
            );
            if (!taskExecution) {
                throw new Error("Task execution not found");
            }
            return toPlainMessage(taskExecution);
        },
    });
};

function optimisticStopService(client: QueryClient, serviceId: string, environmentId: string) {
    const update = (service?: PlainService) => {
        if (service?.spec && service?.status) {
            service.spec.desiredPhase = ServicePhase.STOPPED;
            service.status.phase = ServicePhase.STOPPING;
        }
    };

    client.setQueryData(environmentAutomationQueryKey.getService(serviceId), (old: PlainService) => update(old));
    client.setQueryData(environmentAutomationQueryKey.listServices(environmentId), (old?: PlainService[]) => {
        const service = (old || []).find((s) => s.id === serviceId);
        update(service);
    });
}

function optimisticStartService(client: QueryClient, serviceId: string, environmentId: string) {
    const update = (service?: PlainService) => {
        if (service?.spec && service?.status) {
            service.spec.desiredPhase = ServicePhase.RUNNING;
            service.status.phase = ServicePhase.STARTING;
        }
    };

    client.setQueryData(environmentAutomationQueryKey.getService(serviceId), (old: PlainService) => update(old));
    client.setQueryData(environmentAutomationQueryKey.listServices(environmentId), (old?: PlainService[]) => {
        const service = (old || []).find((s) => s.id === serviceId);
        update(service);
    });
}
