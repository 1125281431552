// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/user.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreatePersonalAccessTokenRequest, CreatePersonalAccessTokenResponse, DeletePersonalAccessTokenRequest, DeletePersonalAccessTokenResponse, DeletePreferenceRequest, DeletePreferenceResponse, GetAuthenticatedUserRequest, GetAuthenticatedUserResponse, GetPersonalAccessTokenRequest, GetPersonalAccessTokenResponse, GetPreferenceRequest, GetPreferenceResponse, ListPersonalAccessTokensRequest, ListPersonalAccessTokensResponse, ListPreferencesRequest, ListPreferencesResponse, SetPreferenceRequest, SetPreferenceResponse, SetSuspendedRequest, SetSuspendedResponse, UpdateUserRequest, UpdateUserResponse } from "./user_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.UserService
 */
export const UserService = {
  typeName: "gitpod.v1.UserService",
  methods: {
    /**
     * GetAuthenticatedUser allows to retrieve the current user.
     *
     * @generated from rpc gitpod.v1.UserService.GetAuthenticatedUser
     */
    getAuthenticatedUser: {
      name: "GetAuthenticatedUser",
      I: GetAuthenticatedUserRequest,
      O: GetAuthenticatedUserResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * UpdateUser updates the properties of a user.
     *
     * @generated from rpc gitpod.v1.UserService.UpdateUser
     */
    updateUser: {
      name: "UpdateUser",
      I: UpdateUserRequest,
      O: UpdateUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SetSuspended sets the suspended state of the user.
     *
     * @generated from rpc gitpod.v1.UserService.SetSuspended
     */
    setSuspended: {
      name: "SetSuspended",
      I: SetSuspendedRequest,
      O: SetSuspendedResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.UserService.CreatePersonalAccessToken
     */
    createPersonalAccessToken: {
      name: "CreatePersonalAccessToken",
      I: CreatePersonalAccessTokenRequest,
      O: CreatePersonalAccessTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.UserService.GetPersonalAccessToken
     */
    getPersonalAccessToken: {
      name: "GetPersonalAccessToken",
      I: GetPersonalAccessTokenRequest,
      O: GetPersonalAccessTokenResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.UserService.ListPersonalAccessTokens
     */
    listPersonalAccessTokens: {
      name: "ListPersonalAccessTokens",
      I: ListPersonalAccessTokensRequest,
      O: ListPersonalAccessTokensResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.UserService.DeletePersonalAccessToken
     */
    deletePersonalAccessToken: {
      name: "DeletePersonalAccessToken",
      I: DeletePersonalAccessTokenRequest,
      O: DeletePersonalAccessTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SetPreference upserts user preference settings for the user
     *
     * @generated from rpc gitpod.v1.UserService.SetPreference
     */
    setPreference: {
      name: "SetPreference",
      I: SetPreferenceRequest,
      O: SetPreferenceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gitpod.v1.UserService.GetPreference
     */
    getPreference: {
      name: "GetPreference",
      I: GetPreferenceRequest,
      O: GetPreferenceResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListPreferences returns the list of user preferences without any prefix scans
     *
     * @generated from rpc gitpod.v1.UserService.ListPreferences
     */
    listPreferences: {
      name: "ListPreferences",
      I: ListPreferencesRequest,
      O: ListPreferencesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc gitpod.v1.UserService.DeletePreference
     */
    deletePreference: {
      name: "DeletePreference",
      I: DeletePreferenceRequest,
      O: DeletePreferenceResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

