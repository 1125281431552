import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconTrash: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.35417 4.35419V11.6459C4.35417 12.6124 5.13767 13.3959 6.10417 13.3959H9.89583C10.8624 13.3959 11.6458 12.6124 11.6458 11.6459V4.35419M4.35417 4.35419H11.6458M4.35417 4.35419H3.1875M11.6458 4.35419H12.8125M9.16667 7.27085V10.4792M6.83333 7.27085V10.4792M6.25 4.35419C6.25 3.38769 7.03347 2.60419 8 2.60419C8.96652 2.60419 9.75 3.38769 9.75 4.35419"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.83333 5.83337V14.1667C5.83333 15.2713 6.72877 16.1667 7.83333 16.1667H12.1667C13.2713 16.1667 14.1667 15.2713 14.1667 14.1667V5.83337M5.83333 5.83337H14.1667M5.83333 5.83337H4.5M14.1667 5.83337H15.5M11.3333 9.16671V12.8334M8.66667 9.16671V12.8334M8 5.83337C8 4.72881 8.8954 3.83337 10 3.83337C11.1046 3.83337 12 4.72881 12 5.83337"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.3125 7.31256V16.6876C7.3125 17.9302 8.31986 18.9376 9.5625 18.9376H14.4375C15.6802 18.9376 16.6875 17.9302 16.6875 16.6876V7.31256M7.3125 7.31256H16.6875M7.3125 7.31256H5.8125M16.6875 7.31256H18.1875M13.5 11.0626V15.1876M10.5 11.0626V15.1876M9.75 7.31256C9.75 6.06992 10.7573 5.06256 12 5.06256C13.2427 5.06256 14.25 6.06992 14.25 7.31256"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
