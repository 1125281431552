import { type PlainRunner } from "@/queries/runner-queries";
import {
    useListEnvironmentClasses,
    useListEnvironments,
    type PlainRunnerEnvironmentClass,
    type PlainEnvironment,
} from "@/queries/environment-queries";
import { useEffect, useState } from "react";
import { useAvailableRunners } from "@/hooks/use-available-runners";

export type EnvironmentEntry = {
    environment: PlainEnvironment;
    runner?: PlainRunner;
    clazz?: PlainRunnerEnvironmentClass;
};

export function useGroupedEnvironments() {
    const { data: environmentsData, isLoading: isLoadingWs, isPending: isPendingWs } = useListEnvironments();
    const { availableRunners, isLoading: isLoadingRunners } = useAvailableRunners();

    // we need both enabled and disabled classes as existing environments might still use them
    const { data: environmentClassesData } = useListEnvironmentClasses({ filter: { enabled: undefined } });

    const [groupedEnvironments, setGroupedEnvironments] = useState<Map<string, EnvironmentEntry[]>>(new Map());

    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const environments = environmentsData?.environments;
        if (availableRunners === undefined || environments === undefined) {
            return;
        }

        const newGroups = new Map<string, EnvironmentEntry[]>();

        for (const environment of environments) {
            const runner = availableRunners.find((r) => r.runnerId === environment.metadata?.runnerId);

            const clazz = (environmentClassesData?.classes || []).find(
                (c) =>
                    c.runnerId === environment.metadata?.runnerId &&
                    !!c?.id &&
                    c?.id === environment.spec?.machine?.class,
            );

            const repoUrl = getRepoUrl(environment)?.repoUrl || "unknown";
            const group = newGroups.get(repoUrl) ?? [];
            group.push({ environment, runner, clazz });
            group.sort((a, b) => a.environment.id.localeCompare(b.environment.id)).reverse();
            newGroups.set(repoUrl, group);
        }

        setGroupedEnvironments(newGroups);
        setInitialized(true);
    }, [availableRunners, environmentsData?.environments, environmentClassesData?.classes]);

    return {
        isLoading: !initialized || isPendingWs || isLoadingWs || isLoadingRunners,
        groupedEnvironments,
    };
}

export function getRepoUrl(environment: PlainEnvironment) {
    const cloneUrl = environment.status?.content?.git?.cloneUrl;
    if (cloneUrl) {
        return parseRepoUrl(cloneUrl);
    }

    for (const spec of environment.spec?.content?.initializer?.specs || []) {
        if (spec.spec.case === "contextUrl") {
            const contextUrl = spec.spec.value.url;
            if (contextUrl) {
                return parseRepoUrl(contextUrl);
            }
        }
        if (spec.spec.case === "git") {
            const remoteUri = spec.spec.value.remoteUri;
            if (remoteUri) {
                return parseRepoUrl(remoteUri);
            }
        }
    }
    return undefined;
}

export function parseRepoUrl(url: string) {
    try {
        const normalizedUrl = url?.toLocaleLowerCase()?.replace(/\.git$/, "");
        const { host, pathname, protocol } = new URL(normalizedUrl);
        const parts = pathname.split("/");
        const account = parts[1];
        const repo = parts[2];
        const repoUrl = `${protocol}//${host}/${account}/${repo}`;
        return { repoUrl, account, repo };
    } catch (error) {
        console.log("Failed to parse repo url", JSON.stringify({ url, error }));
        return undefined;
    }
}
