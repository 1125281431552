import { IconChevronRight } from "@/assets/icons/geist/IconChevronRight";
import { Collapsable } from "@/components/Collapsable";
import {
    StepAutomations,
    StepClone,
    StepSecrets,
    StepStart,
    StepStartDevcontainer,
} from "@/components/environments/EnvironmentStartSteps";
import { EnvironmentToggle } from "@/components/environments/EnvironmentToggle";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/podkit/select/Select";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { duration, timeAgo } from "@/format/time";
import { useEnvironment, useUpdateEnvironment, type PlainEnvironment } from "@/queries/environment-queries";
import { useRunner, type PlainRunner } from "@/queries/runner-queries";
import { effectiveState, type EffectiveState } from "@/routes/environments/phase";
import { formatError } from "@/utils/errors";
import { Timestamp, type Duration, type PlainMessage } from "@bufbuild/protobuf";
import { useCallback, useMemo, useState, type FC, type PropsWithChildren } from "react";
import { Text } from "../podkit/typography/Text";
import { Flags } from "@/feature-flags";
import { useFlag } from "@/hooks/use-flag";
import { useStreamLogs } from "@/hooks/use-stream-logs";
import { environmentLogsUrl } from "@/utils/environment";
import { latestLogLinePerStep } from "@/components/environments/environment-start-steps-latest-log-line";

export const EnvironmentStartSequence: FC<{ environmentId: string; onShowSecrets: () => void }> = ({
    environmentId,
    onShowSecrets,
}) => {
    const { data: environment } = useEnvironment(environmentId);
    const { data: runner } = useRunner(environment?.metadata?.runnerId);

    const state = useMemo(() => {
        if (environment) {
            return effectiveState(environment);
        }
    }, [environment]);

    if (!runner || !environment) {
        return <EnvironmentStartSequenceLoading />;
    }

    return <FoldableSequence environment={environment} runner={runner} state={state} onShowSecrets={onShowSecrets} />;
};

const FoldableSequence: FC<{
    state?: EffectiveState;
    environment: PlainEnvironment;
    runner: PlainRunner;
    onShowSecrets: () => void;
}> = ({ environment, runner, state, onShowSecrets }) => {
    const [expanded, setExpanded] = useState(true);
    const { value: isSecretsEnabled } = useFlag(Flags.SecretsEnabled, false);

    const logsUrl = useMemo(() => environmentLogsUrl(environment), [environment]);
    const { logGroups } = useStreamLogs({ environment, logsUrl, maxLinesPerGroup: 5 });
    const logLinesPerStep = useMemo(() => latestLogLinePerStep(logGroups), [logGroups]);

    const toggleExpanded = useCallback(() => {
        setExpanded((current) => !current);
    }, []);

    return (
        <div translate="no" data-testid="environment-start-sequence">
            <div className="rounded-xl border-[0.5px] border-border-base bg-[rgb(var(--surface-glass))] transition-shadow hover:shadow-lg">
                <div className="relative flex h-20">
                    <Header environment={environment} state={state} expanded={expanded} onClick={toggleExpanded} />
                </div>
                <Collapsable collapsed={!expanded}>
                    <div className="w-full border-t-[0.5px] border-border-base pb-[20px]" />
                    <StepStart
                        environment={environment}
                        runner={runner}
                        key={`step-machine-${environment.id}`}
                        latestLogLine={logLinesPerStep["start-machine"].line}
                    />
                    <StepClone
                        environment={environment}
                        key={`step-clone-${environment.id}`}
                        latestLogLine={logLinesPerStep.clone.line}
                    />
                    {isSecretsEnabled && (
                        <StepSecrets
                            environment={environment}
                            key={`step-secrets-${environment.id}`}
                            onShowSecrets={onShowSecrets}
                        />
                    )}
                    <StepAutomations environment={environment} key={`step-automations-${environment.id}`} />
                    <StepStartDevcontainer
                        environment={environment}
                        key={`step-dev-container-${environment.id}`}
                        latestLogLine={logLinesPerStep["start-dev-container"].line}
                    />
                    <div className="w-full pb-[20px]" />
                </Collapsable>
            </div>
        </div>
    );
};

const EnvironmentStartSequenceLoading: FC = () => {
    return (
        <Container>
            <div className="border-border flex w-full items-center gap-2 rounded-t-xl bg-surface-glass px-4 py-3">
                <div className="flex size-6 items-center justify-center">
                    <SkeletonBlock className="size-5" ready={false} />
                </div>
                <SkeletonText size="lg" ready={false} className="w-20" />
            </div>
            <div className="flex flex-col border-t border-border-base">
                {["w-20", "w-80", "w-72"].map((w, i) => (
                    <div key={i} className="flex h-10 w-full items-center gap-2 px-4 py-2">
                        <div className="flex size-6 items-center justify-center">
                            <SkeletonBlock className="size-5" ready={false} />
                        </div>
                        <SkeletonText size="lg" ready={false} className={w} />
                    </div>
                ))}
            </div>
        </Container>
    );
};

const Container: FC<PropsWithChildren<{ state?: EffectiveState }>> = ({ children }) => {
    return <div className="flex w-full flex-col rounded-xl border border-border-base bg-surface-glass">{children}</div>;
};

const Header: FC<{
    state?: EffectiveState;
    environment: PlainEnvironment;
    onClick?: () => void;
    expanded: boolean;
}> = ({ state, environment, expanded, onClick }) => {
    return (
        <div className={"flex h-20 w-full items-center gap-2 rounded-t-xl"}>
            <div className="flex w-full min-w-0 shrink items-center gap-2 px-[24px] py-[20px]">
                <div className="flex h-full shrink flex-row items-center gap-3">
                    <EnvironmentToggle effectiveState={state} environment={environment} key={environment.id} />
                </div>
                <div className="h-12 flex-grow hover:cursor-pointer" onClick={onClick} />
                <div className="flex min-w-0 shrink items-center gap-[20px] lg:gap-[46px]">
                    <AutoStop key={`${environment.id}-auto-stop`} environment={environment} />
                    <Created environment={environment} />
                    <LastStartedAt environment={environment} />
                </div>
            </div>
            <div className="flex h-full w-16 items-center">
                <Button
                    variant={"ghost"}
                    className={cn("border-0 transition-transform hover:bg-transparent")}
                    onClick={onClick}
                    LeadingIcon={(props) => <IconChevronRight className={cn(expanded && "rotate-90")} {...props} />}
                    aria-label={expanded ? "Collapse environment start sequence" : "Expand environment start sequence"}
                />
            </div>
        </div>
    );
};

const Created: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const date = new Timestamp(environment.metadata?.createdAt).toDate();
    return (
        <div className="flex min-w-0 flex-col">
            <Text className="truncate text-sm text-content-secondary">Created</Text>
            <Text className="truncate text-base font-bold text-content-primary" title={date.toISOString()}>
                {timeAgo(date)}
            </Text>
        </div>
    );
};

const LastStartedAt: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const lastStartedAt = environment.metadata?.lastStartedAt;
    if (!lastStartedAt) {
        return;
    }
    const date = new Timestamp(lastStartedAt).toDate();
    return (
        <div className="flex min-w-0 flex-col">
            <Text className="truncate text-sm text-content-secondary">Last started</Text>
            <Text className="truncate text-base font-bold text-content-primary" title={date.toISOString()}>
                {timeAgo(date)}
            </Text>
        </div>
    );
};

const AutoStop: FC<{ environment: PlainEnvironment } & PropsWithClassName> = ({ className, environment }) => {
    const { toast } = useToast();
    const [open, setOpen] = useState(false);
    const timeoutValues = computeTimeoutSelect(environment);
    const [value, setValue] = useState<{ label: string; value: string } | undefined>(initialTimeoutValue(environment));

    const updateEnvironment = useUpdateEnvironment();
    const handleOnValueChanged = useCallback(
        async (newValue: string) => {
            const original = value;
            try {
                const seconds = BigInt(newValue);
                setValue(toTimeout(Number(newValue)));
                await updateEnvironment.mutateAsync({
                    req: {
                        environmentId: environment.id,
                        spec: { timeout: { disconnected: { seconds } } },
                    },
                });
                toast({
                    title: "Successfully updated auto-stop",
                });
            } catch (error) {
                setValue(original);
                toast({
                    title: "Failed to update auto-stop value",
                    description: formatError(error),
                });
            }
        },
        [value, updateEnvironment, environment.id, toast],
    );

    return (
        <div className={cn("flex min-w-0 flex-col", className)}>
            <div
                className="inline-block text-nowrap text-sm text-content-secondary"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen((o) => !o);
                }}
            >
                Auto-stop after
            </div>
            <Select value={value?.value || ""} onValueChange={handleOnValueChanged} onOpenChange={setOpen} open={open}>
                <SelectTrigger loading={false} className="border-0 bg-transparent p-0 ring-0 focus-visible:ring-0">
                    <span className="inline-block truncate font-bold text-content-primary">
                        <SelectValue className="truncate text-content-primary" placeholder={"Select a timeout"}>
                            {value?.label || ""}
                        </SelectValue>
                    </span>
                    <SelectContent>
                        <SelectGroup>
                            {timeoutValues.map((t) => (
                                <SelectItem key={t.value} value={t.value}>
                                    <div className="flex w-full flex-row items-center justify-between truncate pr-10">
                                        <div>{t.label}</div>
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </SelectTrigger>
            </Select>
        </div>
    );
};

function initialTimeoutValue(environment: PlainEnvironment) {
    const timeout = toTimeoutValue(environment?.spec?.timeout?.disconnected);
    return toTimeout(timeout);
}

function computeTimeoutSelect(environment: PlainEnvironment): { label: string; value: string }[] {
    const timeout = toTimeoutValue(environment?.spec?.timeout?.disconnected);
    const timeouts: number[] = [30 * 60, 60 * 60, 60 * 60 * 3, 60 * 60 * 8];
    const customTimeout = timeout !== 0 && timeouts.find((seconds) => seconds === timeout) === undefined;
    if (customTimeout) {
        timeouts.push(timeout);
        timeouts.sort((a, b) => a - b);
    }
    timeouts.push(0);
    return timeouts.map(toTimeout);
}

function toTimeout(seconds: number): { label: string; value: string } {
    if (seconds <= 0) {
        return {
            label: "Never",
            value: "0",
        };
    }
    return {
        label: duration(seconds) + " of inactivity",
        value: String(seconds),
    };
}

function toTimeoutValue(duration?: PlainMessage<Duration>): number {
    return duration === undefined ? 30 * 60 : Number(duration.seconds);
}
