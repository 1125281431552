import { useDesktop } from "@/hooks/use-desktop";
import { anonymizeFilePaths, useSegmentTrack } from "@/hooks/use-segment";
import { type LocalRunnerStatus } from "frontend-shared/local-runner";
import { useEffect, useState } from "react";

export const useLocalRunner = () => {
    const desktop = useDesktop();

    const [status, setStatus] = useState<LocalRunnerStatus | undefined>(undefined);

    useEffect(() => desktop.runnerService?.onRunnerStatus(setStatus), [desktop.runnerService]);

    const track = useSegmentTrack();
    useEffect(() => {
        if (!status) {
            return;
        }
        const currentEvent = mapLocalRunnerStatusToEvent(status);
        const currentTrackedEvent = JSON.stringify(currentEvent);
        if (currentTrackedEvent !== window.localRunnerLastTrackedEvent) {
            track("desktop_local_runner", currentEvent);
            window.localRunnerLastTrackedEvent = currentTrackedEvent;
        }
    }, [status, track]);

    if (!desktop.isDesktop) {
        return {
            loading: false,
        };
    }

    return {
        loading: !status,
        status,
    };
};

type DesktopLocalRunnerEvent = {
    phase: string;
    runner_id?: string;
    failure?: LocalRunnerStatus["failure"];
    installation?: Omit<LocalRunnerStatus["installation"], "steps"> & {
        errorStepName?: string;
        errorStepMessage?: string;
    };
};

export function mapLocalRunnerStatusToEvent(status: LocalRunnerStatus) {
    const currentEvent: DesktopLocalRunnerEvent = {
        phase: status.phase,
        runner_id: status.currentRunnerId,
        failure: status.phase === "failed" ? status.failure : undefined,
    };
    if (status.phase === "failed" && status.failure) {
        currentEvent.failure = {
            message: status.failure.message,
            details: status.failure.details && anonymizeFilePaths(status.failure.details),
        };
    }
    if (status.phase === "installing" && status.installation) {
        const installation: Partial<LocalRunnerStatus["installation"]> = {
            ...status.installation,
        };
        delete installation.steps;
        currentEvent.installation = installation;
        if (typeof status.installation.exitCode === "number" && status.installation.exitCode !== 0) {
            const steps = Object.entries(status.installation.steps);
            const lastErrorStep = steps.reverse().find(([_, s]) => !!s.error);
            if (lastErrorStep) {
                currentEvent.installation.errorStepName = lastErrorStep[1].name;
                const errorMessage = lastErrorStep[1].error;
                if (errorMessage) {
                    currentEvent.installation.errorStepMessage = anonymizeFilePaths(errorMessage);
                }
            }
        }
    }
    return currentEvent;
}
