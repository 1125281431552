import { useEnvironment } from "@/queries/environment-queries";
import { useEffect, useMemo, useRef, useState, type FC } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { cn } from "@/components/podkit/lib/cn";
import { DevContainerStep } from "@/components/environments/onboarding/DevContainerStep";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/podkit/tabs/Tabs";
import { AutomationsStep } from "@/components/environments/onboarding/AutomationsStep";
import { EnvironmentOnboardingStepId, useEnvironmentOnboardingSteps } from "@/hooks/use-environment-onboarding";
import { CommitFilesStep } from "@/components/environments/onboarding/CommitFilesStep";
import { ShareStep } from "@/components/environments/onboarding/ShareStep";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import { useMembership } from "@/hooks/use-membership";
import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { TrackLocations } from "@/hooks/use-segment";

export const EnvironmentOnboarding: FC<{ environmentId: string }> = ({ environmentId }) => {
    const { data: environment, isPending } = useEnvironment(environmentId);
    const { steps, defaultActive, isLoading: isLoadingDefaultActive } = useEnvironmentOnboardingSteps(environment?.id);
    const [selectedStep, setSelectedStep] = useState(EnvironmentOnboardingStepId.Devcontainer);
    const { membership } = useMembership();

    const calledOnce = useRef(false);
    useEffect(() => {
        if (calledOnce.current) {
            return;
        }
        if (!isLoadingDefaultActive) {
            calledOnce.current = true;
            setSelectedStep(defaultActive);
        }
    }, [isLoadingDefaultActive, defaultActive]);

    const completedDevContainer = useMemo(
        () => steps.find((step) => step.id === EnvironmentOnboardingStepId.Devcontainer)?.completed ?? false,
        [steps],
    );
    const completedAutomations = useMemo(
        () => steps.find((step) => step.id === EnvironmentOnboardingStepId.Automations)?.completed ?? false,
        [steps],
    );

    const completedCommitFiles = useMemo(
        () => steps.find((step) => step.id === EnvironmentOnboardingStepId.CommitFiles)?.completed ?? false,
        [steps],
    );

    const completedShare = false;

    // We only show the onboarding section for environments that are not associated with a project
    // we don't show the section for non-admins as only admins can create projects
    if (isPending || environment?.metadata?.projectId || membership?.userRole !== OrganizationRole.ADMIN) {
        return null;
    }

    return (
        <div className={cn("flex flex-col gap-3")} data-testid="environment-onboarding">
            <EnvironmentOnboardingHeader />
            <div className="rounded-xl border border-dashed border-border-base px-6 pb-8 pt-6">
                <Tabs defaultValue={selectedStep} value={selectedStep}>
                    <TabsList>
                        {steps.map((step, idx) => (
                            <TabsTrigger
                                key={step.id}
                                value={step.id}
                                onClick={() => setSelectedStep(step.id)}
                                className="shrink-0"
                            >
                                <div className="flex items-center justify-center gap-1 font-medium">
                                    {`${idx + 1}. ${step.title}`}
                                    {step.completed && <IconCheckFill size="base" className="text-content-green" />}
                                </div>
                            </TabsTrigger>
                        ))}
                    </TabsList>
                    <TabsContent value={EnvironmentOnboardingStepId.Devcontainer}>
                        <DevContainerStep
                            environment={environment}
                            completed={completedDevContainer}
                            onNext={() => setSelectedStep(EnvironmentOnboardingStepId.Automations)}
                            data-track-location={TrackLocations.EnvironmentOnboardingDevcontainer}
                        />
                    </TabsContent>
                    <TabsContent value={EnvironmentOnboardingStepId.Automations}>
                        <AutomationsStep
                            completed={completedAutomations}
                            onNext={() => setSelectedStep(EnvironmentOnboardingStepId.CommitFiles)}
                            data-track-location={TrackLocations.EnvironmentOnboardingAutomations}
                        />
                    </TabsContent>
                    <TabsContent value={EnvironmentOnboardingStepId.CommitFiles}>
                        <CommitFilesStep
                            environment={environment}
                            completed={completedCommitFiles}
                            onNext={() => setSelectedStep(EnvironmentOnboardingStepId.Share)}
                            data-track-location={TrackLocations.EnvironmentOnboardingCommitFiles}
                        />
                    </TabsContent>
                    <TabsContent value={EnvironmentOnboardingStepId.Share}>
                        <ShareStep
                            completed={completedShare}
                            environmentId={environmentId}
                            data-track-location={TrackLocations.EnvironmentOnboardingShare}
                        />
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    );
};

const EnvironmentOnboardingHeader: FC = () => {
    return (
        <div className="mt-2 flex flex-col">
            <Heading2 className="text-lg font-medium text-content-secondary">
                Finish configuring your development environment in your editor
            </Heading2>
        </div>
    );
};
