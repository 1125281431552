import { type PropsWithChildren, type ReactElement } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

export function Tooltip({
    children,
    content,
    delayDuration,
}: PropsWithChildren & { content?: string | ReactElement; delayDuration?: number }) {
    return (
        <TooltipPrimitive.Provider delayDuration={delayDuration || 500}>
            <TooltipPrimitive.Root>
                <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
                {content && (
                    <TooltipPrimitive.Content
                        side="top"
                        align="center"
                        className="z-50 m-4 rounded-md bg-surface-invert px-3 py-2 text-sm text-content-invert"
                    >
                        {content}
                    </TooltipPrimitive.Content>
                )}
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
}
