// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/runner_configuration.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";

/**
 * @generated from enum gitpod.v1.HostAuthenticationTokenSource
 */
export enum HostAuthenticationTokenSource {
  /**
   * @generated from enum value: HOST_AUTHENTICATION_TOKEN_SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: HOST_AUTHENTICATION_TOKEN_SOURCE_OAUTH = 1;
   */
  OAUTH = 1,

  /**
   * @generated from enum value: HOST_AUTHENTICATION_TOKEN_SOURCE_PAT = 2;
   */
  PAT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(HostAuthenticationTokenSource)
proto3.util.setEnumType(HostAuthenticationTokenSource, "gitpod.v1.HostAuthenticationTokenSource", [
  { no: 0, name: "HOST_AUTHENTICATION_TOKEN_SOURCE_UNSPECIFIED" },
  { no: 1, name: "HOST_AUTHENTICATION_TOKEN_SOURCE_OAUTH" },
  { no: 2, name: "HOST_AUTHENTICATION_TOKEN_SOURCE_PAT" },
]);

/**
 * @generated from message gitpod.v1.CreateHostAuthenticationTokenRequest
 */
export class CreateHostAuthenticationTokenRequest extends Message<CreateHostAuthenticationTokenRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: string host = 3;
   */
  host = "";

  /**
   * @generated from field: string token = 4;
   */
  token = "";

  /**
   * @generated from field: gitpod.v1.HostAuthenticationTokenSource source = 5;
   */
  source = HostAuthenticationTokenSource.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp expires_at = 6;
   */
  expiresAt?: Timestamp;

  /**
   * @generated from field: string refresh_token = 7;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<CreateHostAuthenticationTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateHostAuthenticationTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "source", kind: "enum", T: proto3.getEnumType(HostAuthenticationTokenSource) },
    { no: 6, name: "expires_at", kind: "message", T: Timestamp },
    { no: 7, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateHostAuthenticationTokenRequest {
    return new CreateHostAuthenticationTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateHostAuthenticationTokenRequest {
    return new CreateHostAuthenticationTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateHostAuthenticationTokenRequest {
    return new CreateHostAuthenticationTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateHostAuthenticationTokenRequest | PlainMessage<CreateHostAuthenticationTokenRequest> | undefined, b: CreateHostAuthenticationTokenRequest | PlainMessage<CreateHostAuthenticationTokenRequest> | undefined): boolean {
    return proto3.util.equals(CreateHostAuthenticationTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateHostAuthenticationTokenResponse
 */
export class CreateHostAuthenticationTokenResponse extends Message<CreateHostAuthenticationTokenResponse> {
  /**
   * @generated from field: gitpod.v1.HostAuthenticationToken token = 1;
   */
  token?: HostAuthenticationToken;

  constructor(data?: PartialMessage<CreateHostAuthenticationTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateHostAuthenticationTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "message", T: HostAuthenticationToken },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateHostAuthenticationTokenResponse {
    return new CreateHostAuthenticationTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateHostAuthenticationTokenResponse {
    return new CreateHostAuthenticationTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateHostAuthenticationTokenResponse {
    return new CreateHostAuthenticationTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateHostAuthenticationTokenResponse | PlainMessage<CreateHostAuthenticationTokenResponse> | undefined, b: CreateHostAuthenticationTokenResponse | PlainMessage<CreateHostAuthenticationTokenResponse> | undefined): boolean {
    return proto3.util.equals(CreateHostAuthenticationTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.HostAuthenticationToken
 */
export class HostAuthenticationToken extends Message<HostAuthenticationToken> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string runner_id = 2;
   */
  runnerId = "";

  /**
   * @generated from field: string user_id = 3;
   */
  userId = "";

  /**
   * @generated from field: string host = 4;
   */
  host = "";

  /**
   * @generated from field: gitpod.v1.HostAuthenticationTokenSource source = 5;
   */
  source = HostAuthenticationTokenSource.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp expires_at = 6;
   */
  expiresAt?: Timestamp;

  constructor(data?: PartialMessage<HostAuthenticationToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.HostAuthenticationToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "source", kind: "enum", T: proto3.getEnumType(HostAuthenticationTokenSource) },
    { no: 6, name: "expires_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HostAuthenticationToken {
    return new HostAuthenticationToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HostAuthenticationToken {
    return new HostAuthenticationToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HostAuthenticationToken {
    return new HostAuthenticationToken().fromJsonString(jsonString, options);
  }

  static equals(a: HostAuthenticationToken | PlainMessage<HostAuthenticationToken> | undefined, b: HostAuthenticationToken | PlainMessage<HostAuthenticationToken> | undefined): boolean {
    return proto3.util.equals(HostAuthenticationToken, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetHostAuthenticationTokenRequest
 */
export class GetHostAuthenticationTokenRequest extends Message<GetHostAuthenticationTokenRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetHostAuthenticationTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetHostAuthenticationTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHostAuthenticationTokenRequest {
    return new GetHostAuthenticationTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenRequest {
    return new GetHostAuthenticationTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenRequest {
    return new GetHostAuthenticationTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetHostAuthenticationTokenRequest | PlainMessage<GetHostAuthenticationTokenRequest> | undefined, b: GetHostAuthenticationTokenRequest | PlainMessage<GetHostAuthenticationTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetHostAuthenticationTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetHostAuthenticationTokenResponse
 */
export class GetHostAuthenticationTokenResponse extends Message<GetHostAuthenticationTokenResponse> {
  /**
   * @generated from field: gitpod.v1.HostAuthenticationToken token = 1;
   */
  token?: HostAuthenticationToken;

  constructor(data?: PartialMessage<GetHostAuthenticationTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetHostAuthenticationTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "message", T: HostAuthenticationToken },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHostAuthenticationTokenResponse {
    return new GetHostAuthenticationTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenResponse {
    return new GetHostAuthenticationTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenResponse {
    return new GetHostAuthenticationTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetHostAuthenticationTokenResponse | PlainMessage<GetHostAuthenticationTokenResponse> | undefined, b: GetHostAuthenticationTokenResponse | PlainMessage<GetHostAuthenticationTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetHostAuthenticationTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListHostAuthenticationTokensRequest
 */
export class ListHostAuthenticationTokensRequest extends Message<ListHostAuthenticationTokensRequest> {
  /**
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * @generated from field: gitpod.v1.ListHostAuthenticationTokensRequest.Filter filter = 2;
   */
  filter?: ListHostAuthenticationTokensRequest_Filter;

  constructor(data?: PartialMessage<ListHostAuthenticationTokensRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListHostAuthenticationTokensRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListHostAuthenticationTokensRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListHostAuthenticationTokensRequest {
    return new ListHostAuthenticationTokensRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListHostAuthenticationTokensRequest {
    return new ListHostAuthenticationTokensRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListHostAuthenticationTokensRequest {
    return new ListHostAuthenticationTokensRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListHostAuthenticationTokensRequest | PlainMessage<ListHostAuthenticationTokensRequest> | undefined, b: ListHostAuthenticationTokensRequest | PlainMessage<ListHostAuthenticationTokensRequest> | undefined): boolean {
    return proto3.util.equals(ListHostAuthenticationTokensRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListHostAuthenticationTokensRequest.Filter
 */
export class ListHostAuthenticationTokensRequest_Filter extends Message<ListHostAuthenticationTokensRequest_Filter> {
  /**
   * @generated from field: optional string runner_id = 1;
   */
  runnerId?: string;

  /**
   * @generated from field: optional string user_id = 2;
   */
  userId?: string;

  constructor(data?: PartialMessage<ListHostAuthenticationTokensRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListHostAuthenticationTokensRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListHostAuthenticationTokensRequest_Filter {
    return new ListHostAuthenticationTokensRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListHostAuthenticationTokensRequest_Filter {
    return new ListHostAuthenticationTokensRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListHostAuthenticationTokensRequest_Filter {
    return new ListHostAuthenticationTokensRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListHostAuthenticationTokensRequest_Filter | PlainMessage<ListHostAuthenticationTokensRequest_Filter> | undefined, b: ListHostAuthenticationTokensRequest_Filter | PlainMessage<ListHostAuthenticationTokensRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListHostAuthenticationTokensRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListHostAuthenticationTokensResponse
 */
export class ListHostAuthenticationTokensResponse extends Message<ListHostAuthenticationTokensResponse> {
  /**
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.HostAuthenticationToken tokens = 2;
   */
  tokens: HostAuthenticationToken[] = [];

  constructor(data?: PartialMessage<ListHostAuthenticationTokensResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListHostAuthenticationTokensResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "tokens", kind: "message", T: HostAuthenticationToken, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListHostAuthenticationTokensResponse {
    return new ListHostAuthenticationTokensResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListHostAuthenticationTokensResponse {
    return new ListHostAuthenticationTokensResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListHostAuthenticationTokensResponse {
    return new ListHostAuthenticationTokensResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListHostAuthenticationTokensResponse | PlainMessage<ListHostAuthenticationTokensResponse> | undefined, b: ListHostAuthenticationTokensResponse | PlainMessage<ListHostAuthenticationTokensResponse> | undefined): boolean {
    return proto3.util.equals(ListHostAuthenticationTokensResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateHostAuthenticationTokenRequest
 */
export class UpdateHostAuthenticationTokenRequest extends Message<UpdateHostAuthenticationTokenRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional string token = 5;
   */
  token?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp expires_at = 6;
   */
  expiresAt?: Timestamp;

  /**
   * @generated from field: optional string refresh_token = 7;
   */
  refreshToken?: string;

  constructor(data?: PartialMessage<UpdateHostAuthenticationTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateHostAuthenticationTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "expires_at", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateHostAuthenticationTokenRequest {
    return new UpdateHostAuthenticationTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateHostAuthenticationTokenRequest {
    return new UpdateHostAuthenticationTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateHostAuthenticationTokenRequest {
    return new UpdateHostAuthenticationTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateHostAuthenticationTokenRequest | PlainMessage<UpdateHostAuthenticationTokenRequest> | undefined, b: UpdateHostAuthenticationTokenRequest | PlainMessage<UpdateHostAuthenticationTokenRequest> | undefined): boolean {
    return proto3.util.equals(UpdateHostAuthenticationTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateHostAuthenticationTokenResponse
 */
export class UpdateHostAuthenticationTokenResponse extends Message<UpdateHostAuthenticationTokenResponse> {
  constructor(data?: PartialMessage<UpdateHostAuthenticationTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateHostAuthenticationTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateHostAuthenticationTokenResponse {
    return new UpdateHostAuthenticationTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateHostAuthenticationTokenResponse {
    return new UpdateHostAuthenticationTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateHostAuthenticationTokenResponse {
    return new UpdateHostAuthenticationTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateHostAuthenticationTokenResponse | PlainMessage<UpdateHostAuthenticationTokenResponse> | undefined, b: UpdateHostAuthenticationTokenResponse | PlainMessage<UpdateHostAuthenticationTokenResponse> | undefined): boolean {
    return proto3.util.equals(UpdateHostAuthenticationTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteHostAuthenticationTokenRequest
 */
export class DeleteHostAuthenticationTokenRequest extends Message<DeleteHostAuthenticationTokenRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteHostAuthenticationTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteHostAuthenticationTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteHostAuthenticationTokenRequest {
    return new DeleteHostAuthenticationTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteHostAuthenticationTokenRequest {
    return new DeleteHostAuthenticationTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteHostAuthenticationTokenRequest {
    return new DeleteHostAuthenticationTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteHostAuthenticationTokenRequest | PlainMessage<DeleteHostAuthenticationTokenRequest> | undefined, b: DeleteHostAuthenticationTokenRequest | PlainMessage<DeleteHostAuthenticationTokenRequest> | undefined): boolean {
    return proto3.util.equals(DeleteHostAuthenticationTokenRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteHostAuthenticationTokenResponse
 */
export class DeleteHostAuthenticationTokenResponse extends Message<DeleteHostAuthenticationTokenResponse> {
  constructor(data?: PartialMessage<DeleteHostAuthenticationTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteHostAuthenticationTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteHostAuthenticationTokenResponse {
    return new DeleteHostAuthenticationTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteHostAuthenticationTokenResponse {
    return new DeleteHostAuthenticationTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteHostAuthenticationTokenResponse {
    return new DeleteHostAuthenticationTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteHostAuthenticationTokenResponse | PlainMessage<DeleteHostAuthenticationTokenResponse> | undefined, b: DeleteHostAuthenticationTokenResponse | PlainMessage<DeleteHostAuthenticationTokenResponse> | undefined): boolean {
    return proto3.util.equals(DeleteHostAuthenticationTokenResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.FieldValue
 */
export class FieldValue extends Message<FieldValue> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<FieldValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.FieldValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldValue {
    return new FieldValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldValue {
    return new FieldValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldValue {
    return new FieldValue().fromJsonString(jsonString, options);
  }

  static equals(a: FieldValue | PlainMessage<FieldValue> | undefined, b: FieldValue | PlainMessage<FieldValue> | undefined): boolean {
    return proto3.util.equals(FieldValue, a, b);
  }
}

/**
 * @generated from message gitpod.v1.FieldValueUpdate
 */
export class FieldValueUpdate extends Message<FieldValueUpdate> {
  /**
   * key is the field key to update. If the field does not exist, it will be created.
   *
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * value is the new value for the field. If value is empty, the field will be removed.
   *
   * @generated from field: optional string value = 2;
   */
  value?: string;

  constructor(data?: PartialMessage<FieldValueUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.FieldValueUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldValueUpdate {
    return new FieldValueUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldValueUpdate {
    return new FieldValueUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldValueUpdate {
    return new FieldValueUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: FieldValueUpdate | PlainMessage<FieldValueUpdate> | undefined, b: FieldValueUpdate | PlainMessage<FieldValueUpdate> | undefined): boolean {
    return proto3.util.equals(FieldValueUpdate, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetRunnerConfigurationSchemaRequest
 */
export class GetRunnerConfigurationSchemaRequest extends Message<GetRunnerConfigurationSchemaRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  constructor(data?: PartialMessage<GetRunnerConfigurationSchemaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetRunnerConfigurationSchemaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRunnerConfigurationSchemaRequest {
    return new GetRunnerConfigurationSchemaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRunnerConfigurationSchemaRequest {
    return new GetRunnerConfigurationSchemaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRunnerConfigurationSchemaRequest {
    return new GetRunnerConfigurationSchemaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRunnerConfigurationSchemaRequest | PlainMessage<GetRunnerConfigurationSchemaRequest> | undefined, b: GetRunnerConfigurationSchemaRequest | PlainMessage<GetRunnerConfigurationSchemaRequest> | undefined): boolean {
    return proto3.util.equals(GetRunnerConfigurationSchemaRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetRunnerConfigurationSchemaResponse
 */
export class GetRunnerConfigurationSchemaResponse extends Message<GetRunnerConfigurationSchemaResponse> {
  /**
   * @generated from field: gitpod.v1.RunnerConfigurationSchema schema = 1;
   */
  schema?: RunnerConfigurationSchema;

  constructor(data?: PartialMessage<GetRunnerConfigurationSchemaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetRunnerConfigurationSchemaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schema", kind: "message", T: RunnerConfigurationSchema },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRunnerConfigurationSchemaResponse {
    return new GetRunnerConfigurationSchemaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRunnerConfigurationSchemaResponse {
    return new GetRunnerConfigurationSchemaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRunnerConfigurationSchemaResponse {
    return new GetRunnerConfigurationSchemaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRunnerConfigurationSchemaResponse | PlainMessage<GetRunnerConfigurationSchemaResponse> | undefined, b: GetRunnerConfigurationSchemaResponse | PlainMessage<GetRunnerConfigurationSchemaResponse> | undefined): boolean {
    return proto3.util.equals(GetRunnerConfigurationSchemaResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateSCMIntegrationRequest
 */
export class CreateSCMIntegrationRequest extends Message<CreateSCMIntegrationRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * scm_id references the scm_id in the runner's configuration schema that this integration is for
   *
   * @generated from field: string scm_id = 2;
   */
  scmId = "";

  /**
   * @generated from field: string host = 3;
   */
  host = "";

  /**
   * oauth_client_id is the OAuth app's client ID, if OAuth is configured.
   * If configured, oauth_plaintext_client_secret must also be set.
   *
   * @generated from field: optional string oauth_client_id = 4;
   */
  oauthClientId?: string;

  /**
   * oauth_plaintext_client_secret is the OAuth app's client secret in clear text.
   * This will first be encrypted with the runner's public key before being stored.
   *
   * @generated from field: optional string oauth_plaintext_client_secret = 5;
   */
  oauthPlaintextClientSecret?: string;

  /**
   * @generated from field: bool pat = 6;
   */
  pat = false;

  constructor(data?: PartialMessage<CreateSCMIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateSCMIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scm_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "oauth_client_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "oauth_plaintext_client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "pat", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSCMIntegrationRequest {
    return new CreateSCMIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSCMIntegrationRequest {
    return new CreateSCMIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSCMIntegrationRequest {
    return new CreateSCMIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSCMIntegrationRequest | PlainMessage<CreateSCMIntegrationRequest> | undefined, b: CreateSCMIntegrationRequest | PlainMessage<CreateSCMIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(CreateSCMIntegrationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateSCMIntegrationResponse
 */
export class CreateSCMIntegrationResponse extends Message<CreateSCMIntegrationResponse> {
  /**
   * id is a uniquely generated identifier for the SCM integration
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CreateSCMIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateSCMIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSCMIntegrationResponse {
    return new CreateSCMIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSCMIntegrationResponse {
    return new CreateSCMIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSCMIntegrationResponse {
    return new CreateSCMIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSCMIntegrationResponse | PlainMessage<CreateSCMIntegrationResponse> | undefined, b: CreateSCMIntegrationResponse | PlainMessage<CreateSCMIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(CreateSCMIntegrationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SCMIntegrationOAuthConfig
 */
export class SCMIntegrationOAuthConfig extends Message<SCMIntegrationOAuthConfig> {
  /**
   * client_id is the OAuth app's client ID in clear text.
   *
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * encrypted_client_secret is the OAuth app's secret encrypted with the runner's public key.
   *
   * @generated from field: bytes encrypted_client_secret = 2;
   */
  encryptedClientSecret = new Uint8Array(0);

  constructor(data?: PartialMessage<SCMIntegrationOAuthConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SCMIntegrationOAuthConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "encrypted_client_secret", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SCMIntegrationOAuthConfig {
    return new SCMIntegrationOAuthConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SCMIntegrationOAuthConfig {
    return new SCMIntegrationOAuthConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SCMIntegrationOAuthConfig {
    return new SCMIntegrationOAuthConfig().fromJsonString(jsonString, options);
  }

  static equals(a: SCMIntegrationOAuthConfig | PlainMessage<SCMIntegrationOAuthConfig> | undefined, b: SCMIntegrationOAuthConfig | PlainMessage<SCMIntegrationOAuthConfig> | undefined): boolean {
    return proto3.util.equals(SCMIntegrationOAuthConfig, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SCMIntegration
 */
export class SCMIntegration extends Message<SCMIntegration> {
  /**
   *  id is the unique identifier of the SCM integration
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * scm_id references the scm_id in the runner's configuration schema that this integration is for
   *
   * @generated from field: string scm_id = 2;
   */
  scmId = "";

  /**
   * @generated from field: string host = 3;
   */
  host = "";

  /**
   * @generated from field: optional gitpod.v1.SCMIntegrationOAuthConfig oauth = 4;
   */
  oauth?: SCMIntegrationOAuthConfig;

  /**
   * @generated from field: bool pat = 5;
   */
  pat = false;

  /**
   * @generated from field: string runner_id = 6;
   */
  runnerId = "";

  constructor(data?: PartialMessage<SCMIntegration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SCMIntegration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scm_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "oauth", kind: "message", T: SCMIntegrationOAuthConfig, opt: true },
    { no: 5, name: "pat", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SCMIntegration {
    return new SCMIntegration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SCMIntegration {
    return new SCMIntegration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SCMIntegration {
    return new SCMIntegration().fromJsonString(jsonString, options);
  }

  static equals(a: SCMIntegration | PlainMessage<SCMIntegration> | undefined, b: SCMIntegration | PlainMessage<SCMIntegration> | undefined): boolean {
    return proto3.util.equals(SCMIntegration, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSCMIntegrationRequest
 */
export class GetSCMIntegrationRequest extends Message<GetSCMIntegrationRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetSCMIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSCMIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCMIntegrationRequest {
    return new GetSCMIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCMIntegrationRequest {
    return new GetSCMIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCMIntegrationRequest {
    return new GetSCMIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCMIntegrationRequest | PlainMessage<GetSCMIntegrationRequest> | undefined, b: GetSCMIntegrationRequest | PlainMessage<GetSCMIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(GetSCMIntegrationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSCMIntegrationResponse
 */
export class GetSCMIntegrationResponse extends Message<GetSCMIntegrationResponse> {
  /**
   * @generated from field: gitpod.v1.SCMIntegration integration = 1;
   */
  integration?: SCMIntegration;

  constructor(data?: PartialMessage<GetSCMIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSCMIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration", kind: "message", T: SCMIntegration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCMIntegrationResponse {
    return new GetSCMIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCMIntegrationResponse {
    return new GetSCMIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCMIntegrationResponse {
    return new GetSCMIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCMIntegrationResponse | PlainMessage<GetSCMIntegrationResponse> | undefined, b: GetSCMIntegrationResponse | PlainMessage<GetSCMIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(GetSCMIntegrationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSCMIntegrationsRequest
 */
export class ListSCMIntegrationsRequest extends Message<ListSCMIntegrationsRequest> {
  /**
   * pagination contains the pagination options for listing scm integrations
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * @generated from field: gitpod.v1.ListSCMIntegrationsRequest.Filter filter = 2;
   */
  filter?: ListSCMIntegrationsRequest_Filter;

  constructor(data?: PartialMessage<ListSCMIntegrationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSCMIntegrationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListSCMIntegrationsRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCMIntegrationsRequest {
    return new ListSCMIntegrationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCMIntegrationsRequest {
    return new ListSCMIntegrationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCMIntegrationsRequest {
    return new ListSCMIntegrationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCMIntegrationsRequest | PlainMessage<ListSCMIntegrationsRequest> | undefined, b: ListSCMIntegrationsRequest | PlainMessage<ListSCMIntegrationsRequest> | undefined): boolean {
    return proto3.util.equals(ListSCMIntegrationsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSCMIntegrationsRequest.Filter
 */
export class ListSCMIntegrationsRequest_Filter extends Message<ListSCMIntegrationsRequest_Filter> {
  /**
   * runner_ids filters the response to only SCM integrations of these Runner IDs
   *
   * @generated from field: repeated string runner_ids = 1;
   */
  runnerIds: string[] = [];

  constructor(data?: PartialMessage<ListSCMIntegrationsRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSCMIntegrationsRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCMIntegrationsRequest_Filter {
    return new ListSCMIntegrationsRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCMIntegrationsRequest_Filter {
    return new ListSCMIntegrationsRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCMIntegrationsRequest_Filter {
    return new ListSCMIntegrationsRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCMIntegrationsRequest_Filter | PlainMessage<ListSCMIntegrationsRequest_Filter> | undefined, b: ListSCMIntegrationsRequest_Filter | PlainMessage<ListSCMIntegrationsRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListSCMIntegrationsRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSCMIntegrationsResponse
 */
export class ListSCMIntegrationsResponse extends Message<ListSCMIntegrationsResponse> {
  /**
   * pagination contains the pagination options for listing scm integrations
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.SCMIntegration integrations = 2;
   */
  integrations: SCMIntegration[] = [];

  constructor(data?: PartialMessage<ListSCMIntegrationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSCMIntegrationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "integrations", kind: "message", T: SCMIntegration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCMIntegrationsResponse {
    return new ListSCMIntegrationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCMIntegrationsResponse {
    return new ListSCMIntegrationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCMIntegrationsResponse {
    return new ListSCMIntegrationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCMIntegrationsResponse | PlainMessage<ListSCMIntegrationsResponse> | undefined, b: ListSCMIntegrationsResponse | PlainMessage<ListSCMIntegrationsResponse> | undefined): boolean {
    return proto3.util.equals(ListSCMIntegrationsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateSCMIntegrationRequest
 */
export class UpdateSCMIntegrationRequest extends Message<UpdateSCMIntegrationRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * oauth_client_id can be set to update the OAuth app's client ID.
   * If an empty string is set, the OAuth configuration will be removed (regardless
   * of whether a client secret is set), and any existing Host Authentication Tokens for the
   * SCM integration's runner and host that were created using the OAuth app will be deleted.
   * This might lead to users being unable to access their repositories until they re-authenticate.
   *
   * @generated from field: optional string oauth_client_id = 2;
   */
  oauthClientId?: string;

  /**
   * oauth_plaintext_client_secret can be set to update the OAuth app's client secret.
   * The cleartext secret will be encrypted with the runner's public key before being stored.
   *
   * @generated from field: optional string oauth_plaintext_client_secret = 3;
   */
  oauthPlaintextClientSecret?: string;

  /**
   * pat can be set to enable or disable Personal Access Tokens support.
   * When disabling PATs, any existing Host Authentication Tokens for the SCM integration's
   * runner and host that were created using a PAT will be deleted. This might lead to users
   * being unable to access their repositories until they re-authenticate.
   *
   * @generated from field: optional bool pat = 4;
   */
  pat?: boolean;

  constructor(data?: PartialMessage<UpdateSCMIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateSCMIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "oauth_client_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "oauth_plaintext_client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "pat", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSCMIntegrationRequest {
    return new UpdateSCMIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSCMIntegrationRequest {
    return new UpdateSCMIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSCMIntegrationRequest {
    return new UpdateSCMIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSCMIntegrationRequest | PlainMessage<UpdateSCMIntegrationRequest> | undefined, b: UpdateSCMIntegrationRequest | PlainMessage<UpdateSCMIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSCMIntegrationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateSCMIntegrationResponse
 */
export class UpdateSCMIntegrationResponse extends Message<UpdateSCMIntegrationResponse> {
  constructor(data?: PartialMessage<UpdateSCMIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateSCMIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSCMIntegrationResponse {
    return new UpdateSCMIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSCMIntegrationResponse {
    return new UpdateSCMIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSCMIntegrationResponse {
    return new UpdateSCMIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSCMIntegrationResponse | PlainMessage<UpdateSCMIntegrationResponse> | undefined, b: UpdateSCMIntegrationResponse | PlainMessage<UpdateSCMIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSCMIntegrationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteSCMIntegrationRequest
 */
export class DeleteSCMIntegrationRequest extends Message<DeleteSCMIntegrationRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteSCMIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteSCMIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSCMIntegrationRequest {
    return new DeleteSCMIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSCMIntegrationRequest {
    return new DeleteSCMIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSCMIntegrationRequest {
    return new DeleteSCMIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSCMIntegrationRequest | PlainMessage<DeleteSCMIntegrationRequest> | undefined, b: DeleteSCMIntegrationRequest | PlainMessage<DeleteSCMIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(DeleteSCMIntegrationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteSCMIntegrationResponse
 */
export class DeleteSCMIntegrationResponse extends Message<DeleteSCMIntegrationResponse> {
  constructor(data?: PartialMessage<DeleteSCMIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteSCMIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSCMIntegrationResponse {
    return new DeleteSCMIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSCMIntegrationResponse {
    return new DeleteSCMIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSCMIntegrationResponse {
    return new DeleteSCMIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSCMIntegrationResponse | PlainMessage<DeleteSCMIntegrationResponse> | undefined, b: DeleteSCMIntegrationResponse | PlainMessage<DeleteSCMIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(DeleteSCMIntegrationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentClass
 */
export class EnvironmentClass extends Message<EnvironmentClass> {
  /**
   * id is the unique identifier of the environment class
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * display_name is the human readable name of the environment class
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * description is a human readable description of the environment class
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * configuration describes the configuration of the environment class
   *
   * @generated from field: repeated gitpod.v1.FieldValue configuration = 4;
   */
  configuration: FieldValue[] = [];

  /**
   * runner_id is the unique identifier of the runner the environment class belongs to
   *
   * @generated from field: string runner_id = 5;
   */
  runnerId = "";

  /**
   * enabled indicates whether the environment class can be used to create
   * new environments.
   *
   * @generated from field: bool enabled = 6;
   */
  enabled = false;

  constructor(data?: PartialMessage<EnvironmentClass>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentClass";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "configuration", kind: "message", T: FieldValue, repeated: true },
    { no: 5, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentClass {
    return new EnvironmentClass().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentClass {
    return new EnvironmentClass().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentClass {
    return new EnvironmentClass().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentClass | PlainMessage<EnvironmentClass> | undefined, b: EnvironmentClass | PlainMessage<EnvironmentClass> | undefined): boolean {
    return proto3.util.equals(EnvironmentClass, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateEnvironmentClassRequest
 */
export class CreateEnvironmentClassRequest extends Message<CreateEnvironmentClassRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated gitpod.v1.FieldValue configuration = 4;
   */
  configuration: FieldValue[] = [];

  constructor(data?: PartialMessage<CreateEnvironmentClassRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentClassRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "configuration", kind: "message", T: FieldValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentClassRequest {
    return new CreateEnvironmentClassRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentClassRequest {
    return new CreateEnvironmentClassRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentClassRequest {
    return new CreateEnvironmentClassRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentClassRequest | PlainMessage<CreateEnvironmentClassRequest> | undefined, b: CreateEnvironmentClassRequest | PlainMessage<CreateEnvironmentClassRequest> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentClassRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateEnvironmentClassResponse
 */
export class CreateEnvironmentClassResponse extends Message<CreateEnvironmentClassResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CreateEnvironmentClassResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateEnvironmentClassResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentClassResponse {
    return new CreateEnvironmentClassResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentClassResponse {
    return new CreateEnvironmentClassResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentClassResponse {
    return new CreateEnvironmentClassResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentClassResponse | PlainMessage<CreateEnvironmentClassResponse> | undefined, b: CreateEnvironmentClassResponse | PlainMessage<CreateEnvironmentClassResponse> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentClassResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetEnvironmentClassRequest
 */
export class GetEnvironmentClassRequest extends Message<GetEnvironmentClassRequest> {
  /**
   * @generated from field: string environment_class_id = 2;
   */
  environmentClassId = "";

  constructor(data?: PartialMessage<GetEnvironmentClassRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetEnvironmentClassRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "environment_class_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEnvironmentClassRequest {
    return new GetEnvironmentClassRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEnvironmentClassRequest {
    return new GetEnvironmentClassRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEnvironmentClassRequest {
    return new GetEnvironmentClassRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEnvironmentClassRequest | PlainMessage<GetEnvironmentClassRequest> | undefined, b: GetEnvironmentClassRequest | PlainMessage<GetEnvironmentClassRequest> | undefined): boolean {
    return proto3.util.equals(GetEnvironmentClassRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetEnvironmentClassResponse
 */
export class GetEnvironmentClassResponse extends Message<GetEnvironmentClassResponse> {
  /**
   * @generated from field: gitpod.v1.EnvironmentClass environment_class = 1;
   */
  environmentClass?: EnvironmentClass;

  constructor(data?: PartialMessage<GetEnvironmentClassResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetEnvironmentClassResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_class", kind: "message", T: EnvironmentClass },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEnvironmentClassResponse {
    return new GetEnvironmentClassResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEnvironmentClassResponse {
    return new GetEnvironmentClassResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEnvironmentClassResponse {
    return new GetEnvironmentClassResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEnvironmentClassResponse | PlainMessage<GetEnvironmentClassResponse> | undefined, b: GetEnvironmentClassResponse | PlainMessage<GetEnvironmentClassResponse> | undefined): boolean {
    return proto3.util.equals(GetEnvironmentClassResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListEnvironmentClassesRequest
 */
export class ListEnvironmentClassesRequest extends Message<ListEnvironmentClassesRequest> {
  /**
   * pagination contains the pagination options for listing environment classes
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * @generated from field: gitpod.v1.ListEnvironmentClassesRequest.Filter filter = 2;
   */
  filter?: ListEnvironmentClassesRequest_Filter;

  constructor(data?: PartialMessage<ListEnvironmentClassesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEnvironmentClassesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListEnvironmentClassesRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentClassesRequest {
    return new ListEnvironmentClassesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentClassesRequest {
    return new ListEnvironmentClassesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentClassesRequest {
    return new ListEnvironmentClassesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentClassesRequest | PlainMessage<ListEnvironmentClassesRequest> | undefined, b: ListEnvironmentClassesRequest | PlainMessage<ListEnvironmentClassesRequest> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentClassesRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListEnvironmentClassesRequest.Filter
 */
export class ListEnvironmentClassesRequest_Filter extends Message<ListEnvironmentClassesRequest_Filter> {
  /**
   * runner_ids filters the response to only EnvironmentClasses of these Runner IDs
   *
   * @generated from field: repeated string runner_ids = 1;
   */
  runnerIds: string[] = [];

  /**
   * enabled filters the response to only enabled or disabled environment classes.
   * If not set, all environment classes are returned.
   *
   * @generated from field: optional bool enabled = 2;
   */
  enabled?: boolean;

  constructor(data?: PartialMessage<ListEnvironmentClassesRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEnvironmentClassesRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentClassesRequest_Filter {
    return new ListEnvironmentClassesRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentClassesRequest_Filter {
    return new ListEnvironmentClassesRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentClassesRequest_Filter {
    return new ListEnvironmentClassesRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentClassesRequest_Filter | PlainMessage<ListEnvironmentClassesRequest_Filter> | undefined, b: ListEnvironmentClassesRequest_Filter | PlainMessage<ListEnvironmentClassesRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentClassesRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListEnvironmentClassesResponse
 */
export class ListEnvironmentClassesResponse extends Message<ListEnvironmentClassesResponse> {
  /**
   * pagination contains the pagination options for listing environment classes
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.EnvironmentClass environment_classes = 2;
   */
  environmentClasses: EnvironmentClass[] = [];

  constructor(data?: PartialMessage<ListEnvironmentClassesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEnvironmentClassesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "environment_classes", kind: "message", T: EnvironmentClass, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentClassesResponse {
    return new ListEnvironmentClassesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentClassesResponse {
    return new ListEnvironmentClassesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentClassesResponse {
    return new ListEnvironmentClassesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentClassesResponse | PlainMessage<ListEnvironmentClassesResponse> | undefined, b: ListEnvironmentClassesResponse | PlainMessage<ListEnvironmentClassesResponse> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentClassesResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentClassRequest
 */
export class UpdateEnvironmentClassRequest extends Message<UpdateEnvironmentClassRequest> {
  /**
   * @generated from field: string environment_class_id = 1;
   */
  environmentClassId = "";

  /**
   * @generated from field: optional string display_name = 2;
   */
  displayName?: string;

  /**
   * @generated from field: optional string description = 3;
   */
  description?: string;

  /**
   * @generated from field: optional bool enabled = 4;
   */
  enabled?: boolean;

  constructor(data?: PartialMessage<UpdateEnvironmentClassRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentClassRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_class_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentClassRequest {
    return new UpdateEnvironmentClassRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentClassRequest {
    return new UpdateEnvironmentClassRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentClassRequest {
    return new UpdateEnvironmentClassRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentClassRequest | PlainMessage<UpdateEnvironmentClassRequest> | undefined, b: UpdateEnvironmentClassRequest | PlainMessage<UpdateEnvironmentClassRequest> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentClassRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateEnvironmentClassResponse
 */
export class UpdateEnvironmentClassResponse extends Message<UpdateEnvironmentClassResponse> {
  constructor(data?: PartialMessage<UpdateEnvironmentClassResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateEnvironmentClassResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentClassResponse {
    return new UpdateEnvironmentClassResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentClassResponse {
    return new UpdateEnvironmentClassResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentClassResponse {
    return new UpdateEnvironmentClassResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentClassResponse | PlainMessage<UpdateEnvironmentClassResponse> | undefined, b: UpdateEnvironmentClassResponse | PlainMessage<UpdateEnvironmentClassResponse> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentClassResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ValidateRunnerConfigurationRequest
 */
export class ValidateRunnerConfigurationRequest extends Message<ValidateRunnerConfigurationRequest> {
  /**
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * @generated from oneof gitpod.v1.ValidateRunnerConfigurationRequest.config
   */
  config: {
    /**
     * @generated from field: gitpod.v1.EnvironmentClass environment_class = 2;
     */
    value: EnvironmentClass;
    case: "environmentClass";
  } | {
    /**
     * @generated from field: gitpod.v1.ValidateRunnerConfigurationRequest.ValidateSCMIntegration scm_integration = 3;
     */
    value: ValidateRunnerConfigurationRequest_ValidateSCMIntegration;
    case: "scmIntegration";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ValidateRunnerConfigurationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ValidateRunnerConfigurationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_class", kind: "message", T: EnvironmentClass, oneof: "config" },
    { no: 3, name: "scm_integration", kind: "message", T: ValidateRunnerConfigurationRequest_ValidateSCMIntegration, oneof: "config" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateRunnerConfigurationRequest {
    return new ValidateRunnerConfigurationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateRunnerConfigurationRequest {
    return new ValidateRunnerConfigurationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateRunnerConfigurationRequest {
    return new ValidateRunnerConfigurationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateRunnerConfigurationRequest | PlainMessage<ValidateRunnerConfigurationRequest> | undefined, b: ValidateRunnerConfigurationRequest | PlainMessage<ValidateRunnerConfigurationRequest> | undefined): boolean {
    return proto3.util.equals(ValidateRunnerConfigurationRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ValidateRunnerConfigurationRequest.ValidateSCMIntegration
 */
export class ValidateRunnerConfigurationRequest_ValidateSCMIntegration extends Message<ValidateRunnerConfigurationRequest_ValidateSCMIntegration> {
  /**
   * id is the unique identifier of the SCM integration
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * scm_id references the scm_id in the runner's configuration schema that this integration is for
   *
   * @generated from field: string scm_id = 2;
   */
  scmId = "";

  /**
   * @generated from field: string host = 3;
   */
  host = "";

  /**
   * oauth_client_id is the OAuth app's client ID, if OAuth is configured.
   * If configured, oauth_client_secret must also be set.
   *
   * @generated from field: optional string oauth_client_id = 4;
   */
  oauthClientId?: string;

  /**
   * @generated from oneof gitpod.v1.ValidateRunnerConfigurationRequest.ValidateSCMIntegration.oauth_client_secret
   */
  oauthClientSecret: {
    /**
     * oauth_plaintext_client_secret is the OAuth app's client secret in clear text, if OAuth is configured.
     * This can be set to validate any new client secret before it is encrypted and stored. This value will
     * not be stored and get encrypted with the runner's public key before passing it to the runner.
     *
     * @generated from field: string oauth_plaintext_client_secret = 5;
     */
    value: string;
    case: "oauthPlaintextClientSecret";
  } | {
    /**
     * oauth_encrypted_client_secret is the OAuth app's client secret encrypted with the runner's public key,
     * if OAuth is configured.
     * This can be used to e.g. validate an already encrypted client secret of an existing SCM integration.
     *
     * @generated from field: bytes oauth_encrypted_client_secret = 6;
     */
    value: Uint8Array;
    case: "oauthEncryptedClientSecret";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool pat = 7;
   */
  pat = false;

  constructor(data?: PartialMessage<ValidateRunnerConfigurationRequest_ValidateSCMIntegration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ValidateRunnerConfigurationRequest.ValidateSCMIntegration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scm_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "oauth_client_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "oauth_plaintext_client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "oauth_client_secret" },
    { no: 6, name: "oauth_encrypted_client_secret", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "oauth_client_secret" },
    { no: 7, name: "pat", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateRunnerConfigurationRequest_ValidateSCMIntegration {
    return new ValidateRunnerConfigurationRequest_ValidateSCMIntegration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateRunnerConfigurationRequest_ValidateSCMIntegration {
    return new ValidateRunnerConfigurationRequest_ValidateSCMIntegration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateRunnerConfigurationRequest_ValidateSCMIntegration {
    return new ValidateRunnerConfigurationRequest_ValidateSCMIntegration().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateRunnerConfigurationRequest_ValidateSCMIntegration | PlainMessage<ValidateRunnerConfigurationRequest_ValidateSCMIntegration> | undefined, b: ValidateRunnerConfigurationRequest_ValidateSCMIntegration | PlainMessage<ValidateRunnerConfigurationRequest_ValidateSCMIntegration> | undefined): boolean {
    return proto3.util.equals(ValidateRunnerConfigurationRequest_ValidateSCMIntegration, a, b);
  }
}

/**
 * @generated from message gitpod.v1.FieldValidationError
 */
export class FieldValidationError extends Message<FieldValidationError> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: string error = 2;
   */
  error = "";

  constructor(data?: PartialMessage<FieldValidationError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.FieldValidationError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldValidationError {
    return new FieldValidationError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldValidationError {
    return new FieldValidationError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldValidationError {
    return new FieldValidationError().fromJsonString(jsonString, options);
  }

  static equals(a: FieldValidationError | PlainMessage<FieldValidationError> | undefined, b: FieldValidationError | PlainMessage<FieldValidationError> | undefined): boolean {
    return proto3.util.equals(FieldValidationError, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EnvironmentClassValidationResult
 */
export class EnvironmentClassValidationResult extends Message<EnvironmentClassValidationResult> {
  /**
   * @generated from field: bool valid = 1;
   */
  valid = false;

  /**
   * @generated from field: optional string display_name_error = 2;
   */
  displayNameError?: string;

  /**
   * @generated from field: optional string description_error = 3;
   */
  descriptionError?: string;

  /**
   * @generated from field: repeated gitpod.v1.FieldValidationError configuration_errors = 4;
   */
  configurationErrors: FieldValidationError[] = [];

  constructor(data?: PartialMessage<EnvironmentClassValidationResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EnvironmentClassValidationResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "display_name_error", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "description_error", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "configuration_errors", kind: "message", T: FieldValidationError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnvironmentClassValidationResult {
    return new EnvironmentClassValidationResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnvironmentClassValidationResult {
    return new EnvironmentClassValidationResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnvironmentClassValidationResult {
    return new EnvironmentClassValidationResult().fromJsonString(jsonString, options);
  }

  static equals(a: EnvironmentClassValidationResult | PlainMessage<EnvironmentClassValidationResult> | undefined, b: EnvironmentClassValidationResult | PlainMessage<EnvironmentClassValidationResult> | undefined): boolean {
    return proto3.util.equals(EnvironmentClassValidationResult, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SCMIntegrationValidationResult
 */
export class SCMIntegrationValidationResult extends Message<SCMIntegrationValidationResult> {
  /**
   * @generated from field: bool valid = 1;
   */
  valid = false;

  /**
   * @generated from field: optional string scm_id_error = 2;
   */
  scmIdError?: string;

  /**
   * @generated from field: optional string host_error = 3;
   */
  hostError?: string;

  /**
   * @generated from field: optional string oauth_error = 4;
   */
  oauthError?: string;

  /**
   * @generated from field: optional string pat_error = 5;
   */
  patError?: string;

  constructor(data?: PartialMessage<SCMIntegrationValidationResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SCMIntegrationValidationResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "scm_id_error", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "host_error", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "oauth_error", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "pat_error", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SCMIntegrationValidationResult {
    return new SCMIntegrationValidationResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SCMIntegrationValidationResult {
    return new SCMIntegrationValidationResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SCMIntegrationValidationResult {
    return new SCMIntegrationValidationResult().fromJsonString(jsonString, options);
  }

  static equals(a: SCMIntegrationValidationResult | PlainMessage<SCMIntegrationValidationResult> | undefined, b: SCMIntegrationValidationResult | PlainMessage<SCMIntegrationValidationResult> | undefined): boolean {
    return proto3.util.equals(SCMIntegrationValidationResult, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ValidateRunnerConfigurationResponse
 */
export class ValidateRunnerConfigurationResponse extends Message<ValidateRunnerConfigurationResponse> {
  /**
   * @generated from oneof gitpod.v1.ValidateRunnerConfigurationResponse.result
   */
  result: {
    /**
     * @generated from field: gitpod.v1.EnvironmentClassValidationResult environment_class = 1;
     */
    value: EnvironmentClassValidationResult;
    case: "environmentClass";
  } | {
    /**
     * @generated from field: gitpod.v1.SCMIntegrationValidationResult scm_integration = 2;
     */
    value: SCMIntegrationValidationResult;
    case: "scmIntegration";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ValidateRunnerConfigurationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ValidateRunnerConfigurationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_class", kind: "message", T: EnvironmentClassValidationResult, oneof: "result" },
    { no: 2, name: "scm_integration", kind: "message", T: SCMIntegrationValidationResult, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateRunnerConfigurationResponse {
    return new ValidateRunnerConfigurationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateRunnerConfigurationResponse {
    return new ValidateRunnerConfigurationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateRunnerConfigurationResponse {
    return new ValidateRunnerConfigurationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateRunnerConfigurationResponse | PlainMessage<ValidateRunnerConfigurationResponse> | undefined, b: ValidateRunnerConfigurationResponse | PlainMessage<ValidateRunnerConfigurationResponse> | undefined): boolean {
    return proto3.util.equals(ValidateRunnerConfigurationResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema
 */
export class RunnerConfigurationSchema extends Message<RunnerConfigurationSchema> {
  /**
   * The schema version
   *
   * @generated from field: string version = 1;
   */
  version = "";

  /**
   * @generated from field: repeated gitpod.v1.RunnerConfigurationSchema.Field runner_config = 2;
   */
  runnerConfig: RunnerConfigurationSchema_Field[] = [];

  /**
   * @generated from field: repeated gitpod.v1.RunnerConfigurationSchema.Field environment_classes = 3;
   */
  environmentClasses: RunnerConfigurationSchema_Field[] = [];

  /**
   * @generated from field: repeated gitpod.v1.RunnerConfigurationSchema.SCMConfigSchema scm = 4;
   */
  scm: RunnerConfigurationSchema_SCMConfigSchema[] = [];

  constructor(data?: PartialMessage<RunnerConfigurationSchema>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "runner_config", kind: "message", T: RunnerConfigurationSchema_Field, repeated: true },
    { no: 3, name: "environment_classes", kind: "message", T: RunnerConfigurationSchema_Field, repeated: true },
    { no: 4, name: "scm", kind: "message", T: RunnerConfigurationSchema_SCMConfigSchema, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema {
    return new RunnerConfigurationSchema().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema {
    return new RunnerConfigurationSchema().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema {
    return new RunnerConfigurationSchema().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema | PlainMessage<RunnerConfigurationSchema> | undefined, b: RunnerConfigurationSchema | PlainMessage<RunnerConfigurationSchema> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.StringField
 */
export class RunnerConfigurationSchema_StringField extends Message<RunnerConfigurationSchema_StringField> {
  /**
   * @generated from field: string default = 1;
   */
  default = "";

  /**
   * @generated from field: string pattern = 2;
   */
  pattern = "";

  constructor(data?: PartialMessage<RunnerConfigurationSchema_StringField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.StringField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pattern", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_StringField {
    return new RunnerConfigurationSchema_StringField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_StringField {
    return new RunnerConfigurationSchema_StringField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_StringField {
    return new RunnerConfigurationSchema_StringField().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_StringField | PlainMessage<RunnerConfigurationSchema_StringField> | undefined, b: RunnerConfigurationSchema_StringField | PlainMessage<RunnerConfigurationSchema_StringField> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_StringField, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.BoolField
 */
export class RunnerConfigurationSchema_BoolField extends Message<RunnerConfigurationSchema_BoolField> {
  /**
   * @generated from field: bool default = 1;
   */
  default = false;

  constructor(data?: PartialMessage<RunnerConfigurationSchema_BoolField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.BoolField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_BoolField {
    return new RunnerConfigurationSchema_BoolField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_BoolField {
    return new RunnerConfigurationSchema_BoolField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_BoolField {
    return new RunnerConfigurationSchema_BoolField().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_BoolField | PlainMessage<RunnerConfigurationSchema_BoolField> | undefined, b: RunnerConfigurationSchema_BoolField | PlainMessage<RunnerConfigurationSchema_BoolField> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_BoolField, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.IntField
 */
export class RunnerConfigurationSchema_IntField extends Message<RunnerConfigurationSchema_IntField> {
  /**
   * @generated from field: int32 default = 1;
   */
  default = 0;

  /**
   * @generated from field: int32 min = 2;
   */
  min = 0;

  /**
   * @generated from field: int32 max = 3;
   */
  max = 0;

  constructor(data?: PartialMessage<RunnerConfigurationSchema_IntField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.IntField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "max", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_IntField {
    return new RunnerConfigurationSchema_IntField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_IntField {
    return new RunnerConfigurationSchema_IntField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_IntField {
    return new RunnerConfigurationSchema_IntField().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_IntField | PlainMessage<RunnerConfigurationSchema_IntField> | undefined, b: RunnerConfigurationSchema_IntField | PlainMessage<RunnerConfigurationSchema_IntField> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_IntField, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.EnumField
 */
export class RunnerConfigurationSchema_EnumField extends Message<RunnerConfigurationSchema_EnumField> {
  /**
   * @generated from field: string default = 1;
   */
  default = "";

  /**
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<RunnerConfigurationSchema_EnumField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.EnumField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_EnumField {
    return new RunnerConfigurationSchema_EnumField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_EnumField {
    return new RunnerConfigurationSchema_EnumField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_EnumField {
    return new RunnerConfigurationSchema_EnumField().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_EnumField | PlainMessage<RunnerConfigurationSchema_EnumField> | undefined, b: RunnerConfigurationSchema_EnumField | PlainMessage<RunnerConfigurationSchema_EnumField> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_EnumField, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.DisplayField
 */
export class RunnerConfigurationSchema_DisplayField extends Message<RunnerConfigurationSchema_DisplayField> {
  /**
   * @generated from field: string default = 1;
   */
  default = "";

  constructor(data?: PartialMessage<RunnerConfigurationSchema_DisplayField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.DisplayField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_DisplayField {
    return new RunnerConfigurationSchema_DisplayField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_DisplayField {
    return new RunnerConfigurationSchema_DisplayField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_DisplayField {
    return new RunnerConfigurationSchema_DisplayField().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_DisplayField | PlainMessage<RunnerConfigurationSchema_DisplayField> | undefined, b: RunnerConfigurationSchema_DisplayField | PlainMessage<RunnerConfigurationSchema_DisplayField> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_DisplayField, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.Field
 */
export class RunnerConfigurationSchema_Field extends Message<RunnerConfigurationSchema_Field> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: bool required = 4;
   */
  required = false;

  /**
   * @generated from field: bool secret = 5;
   */
  secret = false;

  /**
   * @generated from oneof gitpod.v1.RunnerConfigurationSchema.Field.type
   */
  type: {
    /**
     * @generated from field: gitpod.v1.RunnerConfigurationSchema.StringField string = 6;
     */
    value: RunnerConfigurationSchema_StringField;
    case: "string";
  } | {
    /**
     * @generated from field: gitpod.v1.RunnerConfigurationSchema.BoolField bool = 7;
     */
    value: RunnerConfigurationSchema_BoolField;
    case: "bool";
  } | {
    /**
     * @generated from field: gitpod.v1.RunnerConfigurationSchema.IntField int = 8;
     */
    value: RunnerConfigurationSchema_IntField;
    case: "int";
  } | {
    /**
     * @generated from field: gitpod.v1.RunnerConfigurationSchema.EnumField enum = 9;
     */
    value: RunnerConfigurationSchema_EnumField;
    case: "enum";
  } | {
    /**
     * @generated from field: gitpod.v1.RunnerConfigurationSchema.DisplayField display = 10;
     */
    value: RunnerConfigurationSchema_DisplayField;
    case: "display";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<RunnerConfigurationSchema_Field>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.Field";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "secret", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "string", kind: "message", T: RunnerConfigurationSchema_StringField, oneof: "type" },
    { no: 7, name: "bool", kind: "message", T: RunnerConfigurationSchema_BoolField, oneof: "type" },
    { no: 8, name: "int", kind: "message", T: RunnerConfigurationSchema_IntField, oneof: "type" },
    { no: 9, name: "enum", kind: "message", T: RunnerConfigurationSchema_EnumField, oneof: "type" },
    { no: 10, name: "display", kind: "message", T: RunnerConfigurationSchema_DisplayField, oneof: "type" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_Field {
    return new RunnerConfigurationSchema_Field().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_Field {
    return new RunnerConfigurationSchema_Field().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_Field {
    return new RunnerConfigurationSchema_Field().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_Field | PlainMessage<RunnerConfigurationSchema_Field> | undefined, b: RunnerConfigurationSchema_Field | PlainMessage<RunnerConfigurationSchema_Field> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_Field, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.OAuth
 */
export class RunnerConfigurationSchema_OAuth extends Message<RunnerConfigurationSchema_OAuth> {
  /**
   * callback_url is the URL the OAuth app will redirect to after the user has authenticated.
   *
   * @generated from field: string callback_url = 1;
   */
  callbackUrl = "";

  constructor(data?: PartialMessage<RunnerConfigurationSchema_OAuth>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.OAuth";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "callback_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_OAuth {
    return new RunnerConfigurationSchema_OAuth().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_OAuth {
    return new RunnerConfigurationSchema_OAuth().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_OAuth {
    return new RunnerConfigurationSchema_OAuth().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_OAuth | PlainMessage<RunnerConfigurationSchema_OAuth> | undefined, b: RunnerConfigurationSchema_OAuth | PlainMessage<RunnerConfigurationSchema_OAuth> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_OAuth, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.PersonalAccessToken
 */
export class RunnerConfigurationSchema_PersonalAccessToken extends Message<RunnerConfigurationSchema_PersonalAccessToken> {
  /**
   * description is a human-readable description of the PAT.
   *
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * docs_link is a link to the documentation on how to create a PAT for this SCM system.
   *
   * @generated from field: string docs_link = 2;
   */
  docsLink = "";

  constructor(data?: PartialMessage<RunnerConfigurationSchema_PersonalAccessToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.PersonalAccessToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "docs_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_PersonalAccessToken {
    return new RunnerConfigurationSchema_PersonalAccessToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_PersonalAccessToken {
    return new RunnerConfigurationSchema_PersonalAccessToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_PersonalAccessToken {
    return new RunnerConfigurationSchema_PersonalAccessToken().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_PersonalAccessToken | PlainMessage<RunnerConfigurationSchema_PersonalAccessToken> | undefined, b: RunnerConfigurationSchema_PersonalAccessToken | PlainMessage<RunnerConfigurationSchema_PersonalAccessToken> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_PersonalAccessToken, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerConfigurationSchema.SCMConfigSchema
 */
export class RunnerConfigurationSchema_SCMConfigSchema extends Message<RunnerConfigurationSchema_SCMConfigSchema> {
  /**
   * @generated from field: string scm_id = 1;
   */
  scmId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated string default_hosts = 3;
   */
  defaultHosts: string[] = [];

  /**
   * @generated from field: gitpod.v1.RunnerConfigurationSchema.OAuth oauth = 4;
   */
  oauth?: RunnerConfigurationSchema_OAuth;

  /**
   * @generated from field: gitpod.v1.RunnerConfigurationSchema.PersonalAccessToken pat = 5;
   */
  pat?: RunnerConfigurationSchema_PersonalAccessToken;

  constructor(data?: PartialMessage<RunnerConfigurationSchema_SCMConfigSchema>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerConfigurationSchema.SCMConfigSchema";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scm_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "default_hosts", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "oauth", kind: "message", T: RunnerConfigurationSchema_OAuth },
    { no: 5, name: "pat", kind: "message", T: RunnerConfigurationSchema_PersonalAccessToken },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerConfigurationSchema_SCMConfigSchema {
    return new RunnerConfigurationSchema_SCMConfigSchema().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_SCMConfigSchema {
    return new RunnerConfigurationSchema_SCMConfigSchema().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerConfigurationSchema_SCMConfigSchema {
    return new RunnerConfigurationSchema_SCMConfigSchema().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerConfigurationSchema_SCMConfigSchema | PlainMessage<RunnerConfigurationSchema_SCMConfigSchema> | undefined, b: RunnerConfigurationSchema_SCMConfigSchema | PlainMessage<RunnerConfigurationSchema_SCMConfigSchema> | undefined): boolean {
    return proto3.util.equals(RunnerConfigurationSchema_SCMConfigSchema, a, b);
  }
}

