import * as Switch from "@radix-ui/react-switch";
import { type FC, useEffect, useState } from "react";
import { cn, type PropsWithClassName } from "./podkit/lib/cn";

export type State = "unchecked" | "pending" | "checked";

export type ToggleProps = {
    state: State;
    hasFailed: boolean;
    disabled?: boolean;
    onToggle: (checked: boolean) => Promise<void>;
    fixedWidth?: boolean; // When true, the toggle doesn't change width when animating.
    id?: string;
};

export const Toggle: FC<ToggleProps> = ({ state, hasFailed, disabled, onToggle, fixedWidth, id }) => {
    // This ensures we only animate state transitions, and not the initial render.
    const [initialState] = useState(state);
    const [hasTransitioned, setHasTransitioned] = useState(false);
    useEffect(() => {
        if (state !== initialState) {
            setHasTransitioned(true);
        }
    }, [state, initialState]);
    const isPending = state === "pending";
    const animate = hasTransitioned || isPending || state !== initialState;

    return (
        <div className="flex w-[40px] justify-center">
            <Switch.Root
                checked={state === "checked"}
                disabled={disabled || isPending}
                onCheckedChange={onToggle}
                className={cn(
                    "h-[25px] w-[40px] cursor-pointer rounded-full bg-surface-04",
                    "disabled:cursor-default",
                    {
                        "transition-[width] duration-300": animate,
                        "w-[25px]": isPending && !fixedWidth,
                        "data-[state=checked]:bg-content-green": !isPending && !disabled,
                        "bg-content-red data-[state=checked]:bg-content-red": hasFailed,
                    },
                )}
                id={id}
                data-testid={id}
            >
                <Switch.Thumb
                    className={cn("flex size-[25px] items-center justify-center", {
                        "transition-transform duration-300": animate,
                        "data-[state=checked]:translate-x-[15px]": !isPending,
                    })}
                >
                    <ThumbSVG animating={isPending} />
                </Switch.Thumb>
            </Switch.Root>
        </div>
    );
};

const ThumbSVG: FC<PropsWithClassName<{ animating: boolean }>> = ({ className, animating }) => {
    /**
     * Implementation notes:
     * The basic idea is to have a circle with a partial stroke and have it spin using CSS. The stroke is transparent
     * when the thumb is not "loading".
     *
     * You can't define if a border should be drawn "outside" of a circle, so to have full control of the border we're
     * using two circle: one for the thumb and one for the border. The border-circle is transparent and uses a stroke with
     * a stroke-dasharray to achieve the "partial border" effect.
     */
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn({ "animate-spin text-content-yield duration-1000": animating }, className)}
        >
            <g filter="url(#filter0_d_2076_8655)">
                <circle cx="12.5" cy="12.5" r={10} fill="rgb(var(--surface-glass))" />
            </g>
            <circle
                cx="12.5"
                cy="12.5"
                r={11.5}
                fill="none"
                stroke={animating ? "currentColor" : "transparent"}
                strokeWidth={2}
                // 18 is the length of the stroke
                // 76 is the circumference of the circle to ensure we only render "one" stroke
                strokeDasharray={"18 76"}
            />
            {/* Drop-shadow effect copied from Figma */}
            <defs>
                <filter
                    id="filter0_d_2076_8655"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2076_8655" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2076_8655" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};
