import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { getPrincipal, setPrincipal } from "@/principal";
import { defaultRetry, defaultThrowOnError, isNotFoundError, isUnauthorizedError } from "@/queries/errors";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import { hashKey, useQuery, useQueryClient } from "@tanstack/react-query";
import { ListLoginProvidersRequest, type Account, type LoginProvider } from "gitpod-next-api/gitpod/v1/account_pb";

export type PlainAccount = PlainMessage<Account>;
const toPlainAccount = (account: Account): PlainAccount => {
    return toPlainMessage(account);
};

export type PlainLoginProvider = PlainMessage<LoginProvider>;
const toPlainLoginProvider = (p: LoginProvider): PlainLoginProvider => {
    return toPlainMessage(p);
};

const key = ["account"];
export const getAccountQueryKey = () => key;

export const useGetAccount = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();

    return useQuery({
        queryKey: getAccountQueryKey(),
        queryFn: async () => {
            let account: PlainAccount;
            try {
                const { account: rawAccount } = await api.accountService.getAccount({});
                if (!rawAccount) {
                    throw new Error("Account not found");
                }
                account = toPlainAccount(rawAccount);
            } catch (error) {
                // Clear the account for unauthenticated errors
                if (isUnauthorizedError(error)) {
                    // Remove queries to purge any data since account is not authenticated
                    client.removeQueries({
                        type: "all",
                        // all keys except the getAccountQueryKey query - otherwise we'll be stuck in a loading state forever
                        predicate: (query) => {
                            const removeQuery = hashKey(query.queryKey) !== hashKey(getAccountQueryKey());
                            return removeQuery;
                        },
                    });
                    return null;
                }

                // Clear the session for not found errors
                // This happens when an account is deleted (or the db has been wiped) while they are logged in
                if (isNotFoundError(error)) {
                    await fetch("/auth/oidc/logout", {
                        method: "POST",
                        credentials: "same-origin",
                    });

                    return null;
                }

                throw error;
            }

            if (account) {
                const memberships = account.memberships || [];

                // if we have a principal, check if it's still valid
                const currentPrincipal = getPrincipal();
                if (currentPrincipal) {
                    if (memberships.some((m) => m.userId === currentPrincipal)) {
                        // previous principal is still valid
                        return account;
                    } else {
                        // previous principal is not valid anymore
                        setPrincipal("");
                    }
                }

                // set principal to first membership
                const membership = memberships[0];
                const userId = membership?.userId;
                if (userId) {
                    setPrincipal(userId);
                }

                return account;
            }
        },
        throwOnError: defaultThrowOnError,
        retry(failureCount, error) {
            // Don't retry if it's an unauthenticated error
            if (isUnauthorizedError(error)) {
                return false;
            }

            return defaultRetry(failureCount, error);
        },
        // Refetch the account to regularly check if session is still active/valid
        refetchInterval(query) {
            // Don't refetch if we're unauthenticated
            if (isUnauthorizedError(query.state.error) || query.state.data === null) {
                return false;
            }

            // otherwise refetch every minute
            return 1000 * 60;
        },
        staleTime: 1000 * 50,
        refetchOnMount: false, // disable additional instances of a query to trigger background refetches
    });
};

export const useListLoginProviders = (inviteId?: string) => {
    const api = useGitpodAPI();

    return useQuery({
        queryKey: ["loginProviders", { inviteId }],
        queryFn: async () => {
            const { loginProviders } = await api.accountService.listLoginProviders(
                new ListLoginProvidersRequest({
                    filter: {
                        inviteId,
                    },
                }),
            );
            if (!loginProviders) {
                throw new Error("Login providers not found");
            }
            return loginProviders.map(toPlainLoginProvider);
        },
        enabled: !!inviteId,
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
    });
};
