import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconGit: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
  switch (size) {
    case "sm":
      return (
        <svg
          className={className}
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3833 6.39617L7.60375 0.616583C7.45125 0.464083 7.23075 0.379333 7 0.379333C6.76925 0.379333 6.54875 0.464083 6.39625 0.616583L5.25 1.76283L6.59617 3.109C6.6755 3.08142 6.75883 3.06725 6.84333 3.06725C7.28592 3.06725 7.64333 3.42467 7.64333 3.86725C7.64333 3.95175 7.62917 4.03508 7.60158 4.11442L8.88575 5.39858C8.96508 5.371 9.04842 5.35683 9.13292 5.35683C9.5755 5.35683 9.93292 5.71425 9.93292 6.15683C9.93292 6.59942 9.5755 6.95683 9.13292 6.95683C8.69033 6.95683 8.33292 6.59942 8.33292 6.15683C8.33292 6.07233 8.34708 5.989 8.37467 5.90967L7.09633 4.63133C7.07458 4.64258 7.05225 4.65292 7.02933 4.66233V9.34183C7.23742 9.42233 7.38958 9.62608 7.38958 9.86308C7.38958 10.3057 7.03217 10.6631 6.58958 10.6631C6.147 10.6631 5.78958 10.3057 5.78958 9.86308C5.78958 9.62608 5.94175 9.42233 6.14983 9.34183V4.65942C5.94175 4.57892 5.78958 4.37517 5.78958 4.13817C5.78958 4.05367 5.80375 3.97033 5.83133 3.891L4.49683 2.55592L0.616583 6.43558C0.464083 6.58808 0.379333 6.80858 0.379333 7.03933C0.379333 7.27008 0.464083 7.49058 0.616583 7.64308L6.39625 13.4227C6.54875 13.5752 6.76925 13.66 7 13.66C7.23075 13.66 7.45125 13.5752 7.60375 13.4227L13.3833 7.6425C13.5358 7.49 13.6205 7.2695 13.6205 7.03875C13.6205 6.808 13.5358 6.5875 13.3833 6.435V6.39617Z"
            fill="currentColor"
          />
        </svg>
      );
    case "base":
      return (
        <svg
          className={className}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1191 9.13739L10.8625 0.880833C10.6446 0.662976 10.3296 0.541904 10 0.541904C9.67036 0.541904 9.35536 0.662976 9.13751 0.880833L7.5 2.51833L9.42311 4.44143C9.53643 4.40203 9.65547 4.38179 9.77619 4.38179C10.4085 4.38179 10.9191 4.89239 10.9191 5.52465C10.9191 5.64536 10.8988 5.76441 10.8594 5.87774L12.6939 7.71226C12.8073 7.67286 12.9263 7.65262 13.047 7.65262C13.6793 7.65262 14.1899 8.16322 14.1899 8.79548C14.1899 9.42774 13.6793 9.93834 13.047 9.93834C12.4148 9.93834 11.9042 9.42774 11.9042 8.79548C11.9042 8.67477 11.9244 8.55572 11.9638 8.44239L10.1376 6.61619C10.1065 6.63226 10.0746 6.64703 10.0419 6.66048V13.3455C10.3392 13.4605 10.5565 13.7515 10.5565 14.0901C10.5565 14.7224 10.0459 15.233 9.41369 15.233C8.78143 15.233 8.27083 14.7224 8.27083 14.0901C8.27083 13.7515 8.48821 13.4605 8.78548 13.3455V6.65631C8.48821 6.54131 8.27083 6.25024 8.27083 5.91167C8.27083 5.79096 8.29107 5.67191 8.33048 5.55858L6.42381 3.65131L0.880833 9.19369C0.662976 9.41155 0.541904 9.72655 0.541904 10.0562C0.541904 10.3858 0.662976 10.7008 0.880833 10.9187L9.13751 19.1753C9.35536 19.3932 9.67036 19.5143 10 19.5143C10.3296 19.5143 10.6446 19.3932 10.8625 19.1753L19.1191 10.9179C19.3369 10.7 19.4579 10.385 19.4579 10.0554C19.4579 9.72572 19.3369 9.41072 19.1191 9.19286V9.13739Z"
            fill="currentColor"
          />
        </svg>
      );
    case "lg":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9429 10.965L13.035 1.057C12.7735 0.795571 12.3955 0.650284 12 0.650284C11.6045 0.650284 11.2265 0.795571 10.965 1.057L9 3.022L11.3077 5.32971C11.4437 5.28243 11.5866 5.25814 11.7314 5.25814C12.4902 5.25814 13.1029 5.87086 13.1029 6.62957C13.1029 6.77443 13.0786 6.91729 13.0313 7.05329L15.2327 9.25471C15.3687 9.20743 15.5116 9.18314 15.6564 9.18314C16.4152 9.18314 17.0279 9.79586 17.0279 10.5546C17.0279 11.3133 16.4152 11.926 15.6564 11.926C14.8977 11.926 14.285 11.3133 14.285 10.5546C14.285 10.4097 14.3093 10.2669 14.3566 10.1309L12.1651 7.93943C12.1278 7.95871 12.0895 7.97643 12.0503 7.99257V16.0146C12.407 16.1526 12.6678 16.5018 12.6678 16.9081C12.6678 17.6668 12.0551 18.2796 11.2964 18.2796C10.5377 18.2796 9.92499 17.6668 9.92499 16.9081C9.92499 16.5018 10.1858 16.1526 10.5426 16.0146V7.98757C10.1858 7.84957 9.92499 7.50029 9.92499 7.094C9.92499 6.94914 9.94928 6.80629 9.99657 6.67029L7.70857 4.38157L1.057 11.0324C0.795571 11.2939 0.650284 11.6719 0.650284 12.0674C0.650284 12.4629 0.795571 12.8409 1.057 13.1024L10.965 23.0146C11.2265 23.2718 11.6045 23.4171 12 23.4171C12.3955 23.4171 12.7735 23.2718 13.035 23.0146L22.9429 13.1014C23.2043 12.8399 23.3495 12.4619 23.3495 12.0664C23.3495 11.6709 23.2043 11.2929 22.9429 11.0314V10.965Z"
            fill="currentColor"
          />
        </svg>
      );
  }
};
