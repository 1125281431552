import { useCreateEnvironmentLogsToken, type PlainEnvironment } from "@/queries/environment-queries";
import { addLinesToLogGroups, parse, type LogGroups, type LogLine } from "@/routes/environments/log-streams/log-groups";
import { FetchError, isFetchError } from "@/utils/errors";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useCallback, useEffect, useState } from "react";

export function useStreamLogs(options: {
    environment?: PlainEnvironment;
    logsUrl?: string;
    maxLinesPerGroup?: number;
}): {
    error?: Error;
    loadingMessage?: string;
    logGroups?: LogGroups;
} {
    const { environment, logsUrl, maxLinesPerGroup } = options;

    const [error, setError] = useState<Error | null>(null);
    const [logGroups, setLogGroups] = useState<LogGroups>({});
    const [loadingMessage, setIsLoadingMessage] = useState<string | undefined>();

    const {
        data: logAccessToken,
        isPending: logAccessTokenPending,
        error: logAccessTokenError,
    } = useCreateEnvironmentLogsToken(environment?.id);

    const waitAndRenderRetry = useCallback(async (seconds: number, signal: AbortSignal) => {
        for (let i = seconds; i >= 0 && !signal.aborted; i--) {
            setIsLoadingMessage(i === 0 ? `retrying now` : `retrying in ${i}s`);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
    }, []);

    useEffect(() => {
        const hasLogs = Object.keys(logGroups).length > 0;
        if (hasLogs) {
            return;
        }

        const phase = environment?.status?.phase;
        if (!phase || phase === EnvironmentPhase.CREATING || phase === EnvironmentPhase.STARTING) {
            setIsLoadingMessage("Waiting for logs to become available...");
            return;
        }

        if (!logsUrl) {
            setIsLoadingMessage("No logs available");
            return;
        }

        if (logAccessTokenPending) {
            setIsLoadingMessage("Requesting log access...");
            return;
        }

        setIsLoadingMessage("Loading logs...");
    }, [logsUrl, environment?.status?.phase, logAccessTokenPending, logGroups]);

    useEffect(() => {
        const abort = new AbortController();

        if (!logsUrl || !logAccessToken) {
            return;
        }

        void (async () => {
            let repeat = true;
            while (repeat) {
                try {
                    await streamLogsFromUrl(
                        abort.signal,
                        logsUrl,
                        logAccessToken,
                        ({ clearLogs, error, loadingMessage, logLinesToAdd }) => {
                            if (clearLogs) {
                                setLogGroups({});
                            }
                            if (error || error === null) {
                                setError(error);
                                if (isFetchError(error) && error.status === 404) {
                                    // For 404s we don't show an error, but just retry
                                    setError(null);
                                }
                            }
                            if (logLinesToAdd) {
                                setLogGroups((prevLogGroups) =>
                                    addLinesToLogGroups(prevLogGroups, logLinesToAdd, maxLinesPerGroup ?? 1000),
                                );
                            }
                            if (typeof loadingMessage === "string") {
                                setIsLoadingMessage(loadingMessage);
                            }
                        },
                    );
                    // no network error, no need to retry
                    repeat = false;
                } catch {
                    // retry after 3s
                    await waitAndRenderRetry(3, abort.signal);

                    // consider aborted state
                    repeat = !abort.signal.aborted;
                }
            }
        })();

        return () => {
            abort.abort("Unmounted");
        };
    }, [logAccessToken, logsUrl, maxLinesPerGroup, waitAndRenderRetry]);

    if (!logsUrl || logAccessTokenPending) {
        return {
            loadingMessage: loadingMessage,
        };
    }

    return {
        error: error || logAccessTokenError || undefined,
        loadingMessage: loadingMessage,
        logGroups: logGroups,
    };
}

async function streamLogsFromUrl(
    signal: AbortSignal,
    logsUrl: string,
    logAccessToken: string,
    callback: (p: {
        clearLogs?: boolean;
        error?: Error | null;
        logLinesToAdd?: LogLine[];
        loadingMessage?: string;
    }) => void,
) {
    let batchUpdateTimer = 0;
    signal.onabort = () => {
        clearTimeout(batchUpdateTimer);
    };
    try {
        // We need to clear the logs and error before we start streaming
        callback({
            error: null,
            clearLogs: true,
        });

        const response = await fetch(logsUrl, {
            signal,
            mode: "cors",
            keepalive: false,
            headers: { Authorization: `Bearer ${logAccessToken}` },
        });

        if (!response || !response.ok || !response.body) {
            // As fetch only rejects on network errors, render status on HTTP errors
            throw new FetchError("Failed to fetch logs", response.status, response.statusText);
        }

        // Log lines are buffered and only added to the log groups once 700ms has passed without new log lines.
        // This is attempting to detect if we're trying to catch up with an existing log stream or live-tailing a new one.
        // Once it's caught up we remove the buffer latency.
        let catchUp = true;
        let batch: LogLine[] = [];
        for await (const lines of parse(response.body, signal)) {
            batch.push(...lines);
            const hasReachedMaxBufferSize = batch.length >= 10000;
            window.clearTimeout(batchUpdateTimer);
            batchUpdateTimer = window.setTimeout(
                () => {
                    const toAdd = batch;
                    batch = [];
                    catchUp = false;
                    callback({
                        logLinesToAdd: toAdd,
                        loadingMessage: "",
                    });
                },
                !catchUp || hasReachedMaxBufferSize ? 0 : 700,
            );
        }
    } catch (e) {
        // Aborted requests aren't errors, user has closed the modal (or in dev, component is mounted twice and unmounted once)
        if (e instanceof DOMException && e.name === "AbortError") {
            return;
        }

        callback({
            error: e instanceof Error ? e : null,
        });

        // re-throw for repeat attempts
        throw e;
    }
}
