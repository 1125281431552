import { type PlainRunnerEnvironmentClass } from "@/queries/environment-queries.ts";
import { useDeleteHostAuthenticationTokenForHost } from "@/queries/runner-configuration-queries";
import { useIsUserAuthenticatedWithRunner, useParseContext } from "@/queries/runner-queries.ts";
import { useCallback, useEffect, useRef, useState } from "react";

export type SCMAuthenticationStage = "required" | "pending" | "verifying" | "successful" | "error";

export type UseSCMAuthenticationProps = {
    clazz: PlainRunnerEnvironmentClass | undefined;
    repoURL: string;
    authenticationUrl?: string;
};

export const useSCMAuthentication = ({ repoURL, clazz, authenticationUrl }: UseSCMAuthenticationProps) => {
    const parseContext = useParseContext();
    const deleteToken = useDeleteHostAuthenticationTokenForHost();

    const [stage, setStage] = useState<SCMAuthenticationStage>("required");
    const [verificationError, setError] = useState<Error | undefined>();

    const { data: authCheckResponse } = useIsUserAuthenticatedWithRunner(clazz?.runnerId, repoURL, {
        refetchUntilAuthenticated: true,
        enabled: stage !== "successful",
    });

    const calledOnce = useRef(false);
    const continueWithVerifying = useCallback(async () => {
        if (calledOnce.current) {
            return;
        }
        try {
            await parseContext.mutateAsync({
                contextUrl: repoURL,
                runnerId: clazz?.runnerId || "",
            });
            setStage("successful");
        } catch (error) {
            setError(error instanceof Error ? error : new Error("Failed to verify authentication"));
            setStage("error");
        }
    }, [clazz?.runnerId, parseContext, repoURL]);

    useEffect(() => {
        if (stage === "pending" && authCheckResponse?.type === "Authenticated") {
            setStage("verifying");
            continueWithVerifying().catch(console.error);
        }
    }, [stage, authCheckResponse?.type, continueWithVerifying]);

    const handleConnectWithOAuth = useCallback(() => {
        if (stage === "required") {
            window.open(authenticationUrl, "_blank", "noopener");
            setStage("pending");
        }
    }, [authenticationUrl, stage]);

    const handleConnectWithPAT = useCallback(async () => {
        if (stage === "required") {
            await continueWithVerifying();
        }
    }, [continueWithVerifying, stage]);

    const handleRemoveConnection = useCallback(async () => {
        try {
            await deleteToken.mutateAsync({ scmHost: new URL(repoURL).host, runnerId: clazz?.runnerId || "" });
        } catch (error) {
            console.error("Failed to remove connection", error);
        }
    }, [clazz?.runnerId, deleteToken, repoURL]);

    const reset = useCallback(() => {
        setStage("required");
        setError(undefined);
    }, []);

    return {
        stage,
        reset,
        verificationError,
        handleConnectWithOAuth,
        handleConnectWithPAT,
        handleRemoveConnection,
    };
};
