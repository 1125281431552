import { useContext } from "react";
import { OnboardingContext } from "@/contexts/OnboardingContext";

export enum OnboardingStepId {
    HowGitpodWorks = "how-gitpod-works",
    SetUpARunner = "set-up-a-runner",
    AutomateYourDevEnvironment = "automate-your-dev-environment",
    ShareGoldenPathWithYourTeam = "share-golden-path-with-your-team",
}

export type OrganizationOnboardingStep = {
    id: OnboardingStepId;
    title: string;
    completed: boolean;
};

export type OrganizationOnboardingSteps = {
    isLoading: boolean;
    steps: OrganizationOnboardingStep[];
    defaultActive: OnboardingStepId;
};

export enum OnboardingSetupRunnerStepId {
    ChoseRunner = "choseRunner",
    SelectProvider = "selectProvider",
    ConfigureInfrastructure = "configureInfrastructure",
}

export const useOnboarding = () => {
    const ctx = useContext(OnboardingContext);

    if (!ctx) {
        throw new Error("useOnboarding must be used within a OnboardingProvider");
    }

    return ctx;
};

export function routeForStep(step: OnboardingStepId): string {
    switch (step) {
        case OnboardingStepId.HowGitpodWorks:
            return "/onboarding/how-gitpod-works";
        case OnboardingStepId.SetUpARunner:
            return "/onboarding/set-up-runner";
        case OnboardingStepId.AutomateYourDevEnvironment:
            return "/onboarding/automate-your-dev-environment";
        case OnboardingStepId.ShareGoldenPathWithYourTeam:
            return "/onboarding/share-with-your-team";
    }
}

export function stepFromRoute(route: string): OnboardingStepId {
    if (route === "/onboarding/how-gitpod-works") {
        return OnboardingStepId.HowGitpodWorks;
    }

    if (route.startsWith("/onboarding/set-up-runner")) {
        return OnboardingStepId.SetUpARunner;
    }

    if (route === "/onboarding/automate-your-dev-environment") {
        return OnboardingStepId.AutomateYourDevEnvironment;
    }

    if (route === "/onboarding/share-with-your-team") {
        return OnboardingStepId.ShareGoldenPathWithYourTeam;
    }

    // Default to the first page if the route doesn't match
    return OnboardingStepId.HowGitpodWorks;
}
