// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/event.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { Principal } from "./identity_pb.js";

/**
 * @generated from enum gitpod.v1.ResourceOperation
 */
export enum ResourceOperation {
  /**
   * @generated from enum value: RESOURCE_OPERATION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RESOURCE_OPERATION_CREATE = 1;
   */
  CREATE = 1,

  /**
   * @generated from enum value: RESOURCE_OPERATION_UPDATE = 2;
   */
  UPDATE = 2,

  /**
   * @generated from enum value: RESOURCE_OPERATION_DELETE = 3;
   */
  DELETE = 3,

  /**
   * UPDATE_STATUS is emitted only iff the resource's status has been updated. If the status and something else
   * are updated, a single UPDATE event is emitted.
   *
   * @generated from enum value: RESOURCE_OPERATION_UPDATE_STATUS = 4;
   */
  UPDATE_STATUS = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ResourceOperation)
proto3.util.setEnumType(ResourceOperation, "gitpod.v1.ResourceOperation", [
  { no: 0, name: "RESOURCE_OPERATION_UNSPECIFIED" },
  { no: 1, name: "RESOURCE_OPERATION_CREATE" },
  { no: 2, name: "RESOURCE_OPERATION_UPDATE" },
  { no: 3, name: "RESOURCE_OPERATION_DELETE" },
  { no: 4, name: "RESOURCE_OPERATION_UPDATE_STATUS" },
]);

/**
 * @generated from enum gitpod.v1.ResourceType
 */
export enum ResourceType {
  /**
   * @generated from enum value: RESOURCE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RESOURCE_TYPE_ENVIRONMENT = 1;
   */
  ENVIRONMENT = 1,

  /**
   * @generated from enum value: RESOURCE_TYPE_RUNNER = 2;
   */
  RUNNER = 2,

  /**
   * @generated from enum value: RESOURCE_TYPE_PROJECT = 3;
   */
  PROJECT = 3,

  /**
   * @generated from enum value: RESOURCE_TYPE_TASK = 4;
   */
  TASK = 4,

  /**
   * @generated from enum value: RESOURCE_TYPE_TASK_EXECUTION = 5;
   */
  TASK_EXECUTION = 5,

  /**
   * @generated from enum value: RESOURCE_TYPE_SERVICE = 6;
   */
  SERVICE = 6,

  /**
   * @generated from enum value: RESOURCE_TYPE_ORGANIZATION = 7;
   */
  ORGANIZATION = 7,

  /**
   * @generated from enum value: RESOURCE_TYPE_USER = 8;
   */
  USER = 8,

  /**
   * @generated from enum value: RESOURCE_TYPE_ENVIRONMENT_CLASS = 9;
   */
  ENVIRONMENT_CLASS = 9,

  /**
   * @generated from enum value: RESOURCE_TYPE_RUNNER_SCM_INTEGRATION = 10;
   */
  RUNNER_SCM_INTEGRATION = 10,

  /**
   * @generated from enum value: RESOURCE_TYPE_HOST_AUTHENTICATION_TOKEN = 11;
   */
  HOST_AUTHENTICATION_TOKEN = 11,

  /**
   * @generated from enum value: RESOURCE_TYPE_GROUP = 12;
   */
  GROUP = 12,

  /**
   * @generated from enum value: RESOURCE_TYPE_PERSONAL_ACCESS_TOKEN = 13;
   */
  PERSONAL_ACCESS_TOKEN = 13,

  /**
   * @generated from enum value: RESOURCE_TYPE_USER_PREFERENCE = 14;
   */
  USER_PREFERENCE = 14,

  /**
   * @generated from enum value: RESOURCE_TYPE_SERVICE_ACCOUNT = 15;
   */
  SERVICE_ACCOUNT = 15,

  /**
   * @generated from enum value: RESOURCE_TYPE_SECRET = 16;
   */
  SECRET = 16,

  /**
   * @generated from enum value: RESOURCE_TYPE_SSO_CONFIG = 17;
   */
  SSO_CONFIG = 17,
}
// Retrieve enum metadata with: proto3.getEnumType(ResourceType)
proto3.util.setEnumType(ResourceType, "gitpod.v1.ResourceType", [
  { no: 0, name: "RESOURCE_TYPE_UNSPECIFIED" },
  { no: 1, name: "RESOURCE_TYPE_ENVIRONMENT" },
  { no: 2, name: "RESOURCE_TYPE_RUNNER" },
  { no: 3, name: "RESOURCE_TYPE_PROJECT" },
  { no: 4, name: "RESOURCE_TYPE_TASK" },
  { no: 5, name: "RESOURCE_TYPE_TASK_EXECUTION" },
  { no: 6, name: "RESOURCE_TYPE_SERVICE" },
  { no: 7, name: "RESOURCE_TYPE_ORGANIZATION" },
  { no: 8, name: "RESOURCE_TYPE_USER" },
  { no: 9, name: "RESOURCE_TYPE_ENVIRONMENT_CLASS" },
  { no: 10, name: "RESOURCE_TYPE_RUNNER_SCM_INTEGRATION" },
  { no: 11, name: "RESOURCE_TYPE_HOST_AUTHENTICATION_TOKEN" },
  { no: 12, name: "RESOURCE_TYPE_GROUP" },
  { no: 13, name: "RESOURCE_TYPE_PERSONAL_ACCESS_TOKEN" },
  { no: 14, name: "RESOURCE_TYPE_USER_PREFERENCE" },
  { no: 15, name: "RESOURCE_TYPE_SERVICE_ACCOUNT" },
  { no: 16, name: "RESOURCE_TYPE_SECRET" },
  { no: 17, name: "RESOURCE_TYPE_SSO_CONFIG" },
]);

/**
 * @generated from message gitpod.v1.WatchEventsRequest
 */
export class WatchEventsRequest extends Message<WatchEventsRequest> {
  /**
   * Scope defines the kind of events one watches. If no value is provided we default to organization scope.
   *
   * @generated from oneof gitpod.v1.WatchEventsRequest.scope
   */
  scope: {
    /**
     * Organization scope produces events for all projects, runners and environments
     * the caller can see within their organization. No task, task execution or service events
     * are produed.
     *
     * @generated from field: bool organization = 1;
     */
    value: boolean;
    case: "organization";
  } | {
    /**
     * Environment scope produces events for the environment itself, all tasks, task executions,
     * and services associated with that environment.
     *
     * @generated from field: string environment_id = 2;
     */
    value: string;
    case: "environmentId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<WatchEventsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.WatchEventsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "scope" },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "scope" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WatchEventsRequest {
    return new WatchEventsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WatchEventsRequest {
    return new WatchEventsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WatchEventsRequest {
    return new WatchEventsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WatchEventsRequest | PlainMessage<WatchEventsRequest> | undefined, b: WatchEventsRequest | PlainMessage<WatchEventsRequest> | undefined): boolean {
    return proto3.util.equals(WatchEventsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.WatchEventsResponse
 */
export class WatchEventsResponse extends Message<WatchEventsResponse> {
  /**
   * @generated from field: gitpod.v1.ResourceOperation operation = 1;
   */
  operation = ResourceOperation.UNSPECIFIED;

  /**
   * @generated from field: gitpod.v1.ResourceType resource_type = 2;
   */
  resourceType = ResourceType.UNSPECIFIED;

  /**
   * @generated from field: string resource_id = 3;
   */
  resourceId = "";

  constructor(data?: PartialMessage<WatchEventsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.WatchEventsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operation", kind: "enum", T: proto3.getEnumType(ResourceOperation) },
    { no: 2, name: "resource_type", kind: "enum", T: proto3.getEnumType(ResourceType) },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WatchEventsResponse {
    return new WatchEventsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WatchEventsResponse {
    return new WatchEventsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WatchEventsResponse {
    return new WatchEventsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WatchEventsResponse | PlainMessage<WatchEventsResponse> | undefined, b: WatchEventsResponse | PlainMessage<WatchEventsResponse> | undefined): boolean {
    return proto3.util.equals(WatchEventsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListAuditLogsRequest
 */
export class ListAuditLogsRequest extends Message<ListAuditLogsRequest> {
  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * @generated from field: gitpod.v1.ListAuditLogsRequest.Filter filter = 2;
   */
  filter?: ListAuditLogsRequest_Filter;

  constructor(data?: PartialMessage<ListAuditLogsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListAuditLogsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListAuditLogsRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAuditLogsRequest {
    return new ListAuditLogsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAuditLogsRequest {
    return new ListAuditLogsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAuditLogsRequest {
    return new ListAuditLogsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAuditLogsRequest | PlainMessage<ListAuditLogsRequest> | undefined, b: ListAuditLogsRequest | PlainMessage<ListAuditLogsRequest> | undefined): boolean {
    return proto3.util.equals(ListAuditLogsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListAuditLogsRequest.Filter
 */
export class ListAuditLogsRequest_Filter extends Message<ListAuditLogsRequest_Filter> {
  /**
   * @generated from field: repeated string actor_ids = 1;
   */
  actorIds: string[] = [];

  /**
   * @generated from field: repeated gitpod.v1.Principal actor_principals = 2;
   */
  actorPrincipals: Principal[] = [];

  /**
   * @generated from field: repeated string subject_ids = 3;
   */
  subjectIds: string[] = [];

  /**
   * @generated from field: repeated gitpod.v1.ResourceType subject_types = 4;
   */
  subjectTypes: ResourceType[] = [];

  constructor(data?: PartialMessage<ListAuditLogsRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListAuditLogsRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "actor_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "actor_principals", kind: "enum", T: proto3.getEnumType(Principal), repeated: true },
    { no: 3, name: "subject_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "subject_types", kind: "enum", T: proto3.getEnumType(ResourceType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAuditLogsRequest_Filter {
    return new ListAuditLogsRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAuditLogsRequest_Filter {
    return new ListAuditLogsRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAuditLogsRequest_Filter {
    return new ListAuditLogsRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListAuditLogsRequest_Filter | PlainMessage<ListAuditLogsRequest_Filter> | undefined, b: ListAuditLogsRequest_Filter | PlainMessage<ListAuditLogsRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListAuditLogsRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListAuditLogsResponse
 */
export class ListAuditLogsResponse extends Message<ListAuditLogsResponse> {
  /**
   * @generated from field: repeated gitpod.v1.ListAuditLogsResponse.AuditLogEntry entries = 1;
   */
  entries: ListAuditLogsResponse_AuditLogEntry[] = [];

  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 2;
   */
  pagination?: PaginationResponse;

  constructor(data?: PartialMessage<ListAuditLogsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListAuditLogsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: ListAuditLogsResponse_AuditLogEntry, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAuditLogsResponse {
    return new ListAuditLogsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAuditLogsResponse {
    return new ListAuditLogsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAuditLogsResponse {
    return new ListAuditLogsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAuditLogsResponse | PlainMessage<ListAuditLogsResponse> | undefined, b: ListAuditLogsResponse | PlainMessage<ListAuditLogsResponse> | undefined): boolean {
    return proto3.util.equals(ListAuditLogsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListAuditLogsResponse.AuditLogEntry
 */
export class ListAuditLogsResponse_AuditLogEntry extends Message<ListAuditLogsResponse_AuditLogEntry> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string actor_id = 2;
   */
  actorId = "";

  /**
   * @generated from field: gitpod.v1.Principal actor_principal = 3;
   */
  actorPrincipal = Principal.UNSPECIFIED;

  /**
   * @generated from field: string subject_id = 4;
   */
  subjectId = "";

  /**
   * @generated from field: gitpod.v1.ResourceType subject_type = 5;
   */
  subjectType = ResourceType.UNSPECIFIED;

  /**
   * @generated from field: string action = 6;
   */
  action = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp;

  constructor(data?: PartialMessage<ListAuditLogsResponse_AuditLogEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListAuditLogsResponse.AuditLogEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "actor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "actor_principal", kind: "enum", T: proto3.getEnumType(Principal) },
    { no: 4, name: "subject_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subject_type", kind: "enum", T: proto3.getEnumType(ResourceType) },
    { no: 6, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAuditLogsResponse_AuditLogEntry {
    return new ListAuditLogsResponse_AuditLogEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAuditLogsResponse_AuditLogEntry {
    return new ListAuditLogsResponse_AuditLogEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAuditLogsResponse_AuditLogEntry {
    return new ListAuditLogsResponse_AuditLogEntry().fromJsonString(jsonString, options);
  }

  static equals(a: ListAuditLogsResponse_AuditLogEntry | PlainMessage<ListAuditLogsResponse_AuditLogEntry> | undefined, b: ListAuditLogsResponse_AuditLogEntry | PlainMessage<ListAuditLogsResponse_AuditLogEntry> | undefined): boolean {
    return proto3.util.equals(ListAuditLogsResponse_AuditLogEntry, a, b);
  }
}

