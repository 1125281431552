import { Heading3 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { type FC } from "react";
import { useOpenEditor } from "@/hooks/use-open-editor";
import type { PlainEnvironment } from "@/queries/environment-queries";
import { TemplateIllutrationPlaceholder } from "@/assets/icons/onboarding/TemplateIllustrationPlaceholder";
import { GitCommit, GitFileChange } from "@/routes/environments/details/GitStatusPopOver";
import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { Button } from "@/components/flexkit/Button";

export const CommitFilesStep: FC<{ environment?: PlainEnvironment; completed: boolean; onNext: () => void }> = ({
    environment,
    completed,
    onNext,
}) => {
    const handleOpen = useOpenEditor(environment);
    return (
        <>
            <div className="mt-8 flex flex-col items-center justify-between gap-4 overflow-hidden px-5 md:flex-row">
                <div className="flex flex-col items-center gap-4 md:flex-row">
                    <TemplateIllutrationPlaceholder />
                    <div className="flex shrink flex-col">
                        {completed ? <ContentCompleted /> : <Content environment={environment} />}
                    </div>
                </div>
                <div className="flex flex-col gap-4 lg:flex-row">
                    {!completed && (
                        <Button variant="secondary" onClick={handleOpen}>
                            Open VS Code
                        </Button>
                    )}
                    {completed && (
                        <Button variant="primary" onClick={onNext}>
                            Next
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

const Content: FC<{ environment?: PlainEnvironment }> = ({ environment }) => {
    const hasChangedFiles = (environment?.status?.content?.git?.changedFiles?.length ?? 0) > 0;
    const hasUnpushedCommits = (environment?.status?.content?.git?.unpushedCommits?.length ?? 0) > 0;
    return (
        <div className="flex max-w-2xl flex-col gap-2">
            <Heading3 className="text-base font-bold">Commit and push the devcontainer and automation files</Heading3>
            <Text className="text-sm text-content-secondary">
                Add your dev container and automations files to your repository so that others can re-use the
                configuration. You can commit these to any branch, it doesn&apos;t have to be main.
            </Text>
            {hasChangedFiles && (
                <div className="mt-2 flex flex-col">
                    <Text className="text-sm font-bold">Uncomitted files</Text>
                    {environment?.status?.content?.git?.changedFiles.map((file) => (
                        <GitFileChange key={file.path} file={file} />
                    ))}
                </div>
            )}
            {hasUnpushedCommits && (
                <div className="mt-2 flex flex-col">
                    <Text className="text-sm font-bold">Unpushed commits</Text>
                    {environment?.status?.content?.git?.unpushedCommits.map((commit) => (
                        <GitCommit key={commit} commit={commit} />
                    ))}
                </div>
            )}
        </div>
    );
};

const ContentCompleted: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="flex items-center gap-1 text-base font-bold">
                <IconCheckFill className="text-content-green" size="lg" />
                Files committed and pushed
            </Heading3>
        </div>
    );
};
