import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconFile: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.6875 6.6875V12.8125C13.6875 14.0206 12.7081 15 11.5 15H4.5C3.29188 15 2.3125 14.0206 2.3125 12.8125V2.3125V1H3.625H8H8.95007C9.18212 1 9.40469 1.09219 9.56878 1.25628L13.4312 5.11872C13.5953 5.28281 13.6875 5.50537 13.6875 5.73743V6.6875ZM12.375 6.6875V12.8125C12.375 13.2958 11.9833 13.6875 11.5 13.6875H4.5C4.01675 13.6875 3.625 13.2958 3.625 12.8125V2.3125H8V5.375V6.6875H9.3125H12.375ZM9.3125 2.85616V5.375H11.8314L9.3125 2.85616Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.5 8.5V15.5C16.5 16.8807 15.3807 18 14 18H6C4.61929 18 3.5 16.8807 3.5 15.5V3.5V2H5H10H11.0858C11.351 2 11.6054 2.10536 11.7929 2.29289L16.2071 6.70711C16.3946 6.89464 16.5 7.149 16.5 7.41421V8.5ZM15 8.5V15.5C15 16.0523 14.5523 16.5 14 16.5H6C5.44772 16.5 5 16.0523 5 15.5V3.5H10V7V8.5H11.5H15ZM11.5 4.12132V7H14.3787L11.5 4.12132Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3125 10.3125V18.1875C19.3125 19.7408 18.0533 21 16.5 21H7.5C5.9467 21 4.6875 19.7408 4.6875 18.1875V4.6875V3H6.375H12H13.2215C13.5199 3 13.806 3.11853 14.017 3.3295L18.983 8.2955C19.1939 8.50647 19.3125 8.79262 19.3125 9.09099V10.3125ZM17.625 10.3125V18.1875C17.625 18.8088 17.1213 19.3125 16.5 19.3125H7.5C6.87868 19.3125 6.375 18.8088 6.375 18.1875V4.6875H12V8.625V10.3125H13.6875H17.625ZM13.6875 5.38649V8.625H16.926L13.6875 5.38649Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
