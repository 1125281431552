import { useDocumentTitle } from "@/hooks/use-document-title";
import { useStreamEvents } from "@/hooks/use-stream-events";
import { Suspense, useEffect, type FC } from "react";
import { Outlet, useParams } from "react-router-dom";

export const EnvironmentDetailsLayout: FC = () => {
    const { environmentId } = useParams();
    const { unsubscribe } = useStreamEvents({ case: "environmentId", value: environmentId || "" });
    useDocumentTitle("Environment");

    useEffect(() => {
        return () => {
            unsubscribe();
        };
        // this should happen on unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Suspense>
            <Outlet />
        </Suspense>
    );
};
