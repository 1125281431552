import { Code, ConnectError } from "@connectrpc/connect";

/**
 * Formats the `error` in a human-readable way
 */
export function formatError(error: unknown, messages?: Partial<Record<Code, string>>): string {
    if (error instanceof ConnectError) {
        const customMessage = messages?.[error.code];
        if (customMessage) {
            return customMessage;
        }
        return `${codeToText(error.code)}: ${error.rawMessage}`;
    }
    if (error instanceof Error) {
        return error.message;
    }
    if (typeof error === "string") {
        return error;
    }
    return "Unknown error";
}

function codeToText(code: Code): string {
    switch (code) {
        case Code.Canceled:
            return "Canceled";
        case Code.InvalidArgument:
            return "Invalid argument";
        case Code.DeadlineExceeded:
            return "Deadline exceeded";
        case Code.NotFound:
            return "Not found";
        case Code.AlreadyExists:
            return "Already exists";
        case Code.PermissionDenied:
            return "Permission denied";
        case Code.ResourceExhausted:
            return "Resource exhausted";
        case Code.FailedPrecondition:
            return "Failed precondition";
        case Code.Aborted:
            return "Aborted";
        case Code.OutOfRange:
            return "Out of range";
        case Code.Unknown:
        case Code.Unimplemented:
        case Code.Internal:
            return "Internal";
        case Code.Unavailable:
            return "Unavailable";
        case Code.DataLoss:
            return "Data loss";
        case Code.Unauthenticated:
            return "Unauthenticated";
    }
}

/**
 * List of all Connect error codes
 * Intended to be used in Storybook and tests.
 */
export const connectErrors = {
    [Code.Canceled]: new ConnectError("message", Code.Canceled),
    [Code.Unknown]: new ConnectError("message", Code.Unknown),
    [Code.InvalidArgument]: new ConnectError("message", Code.InvalidArgument),
    [Code.DeadlineExceeded]: new ConnectError("message", Code.DeadlineExceeded),
    [Code.NotFound]: new ConnectError("message", Code.NotFound),
    [Code.AlreadyExists]: new ConnectError("message", Code.AlreadyExists),
    [Code.PermissionDenied]: new ConnectError("message", Code.PermissionDenied),
    [Code.ResourceExhausted]: new ConnectError("message", Code.ResourceExhausted),
    [Code.FailedPrecondition]: new ConnectError("message", Code.FailedPrecondition),
    [Code.Aborted]: new ConnectError("message", Code.Aborted),
    [Code.OutOfRange]: new ConnectError("message", Code.OutOfRange),
    [Code.Unimplemented]: new ConnectError("message", Code.Unimplemented),
    [Code.Internal]: new ConnectError("message", Code.Internal),
    [Code.Unavailable]: new ConnectError("message", Code.Unavailable),
    [Code.DataLoss]: new ConnectError("message", Code.DataLoss),
    [Code.Unauthenticated]: new ConnectError("message", Code.Unauthenticated),
};

export class FetchError extends Error {
    public readonly status: number;
    public readonly statusText: string;

    constructor(message: string, status: number, statusText: string) {
        super(message);
        this.name = "FetchError";
        this.status = status;
        this.statusText = statusText;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchError);
        }
    }
}

export function isFetchError(error: unknown): error is FetchError {
    return error instanceof FetchError;
}
