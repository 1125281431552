import { EnvironmentDetailsLayout } from "@/routes/environments/details/EnvironmentDetailsLayout";
import { EnvironmentDetailsPage } from "@/routes/environments/details/EnvironmentDetailsPage";
import { EnvironmentLogsPage } from "@/routes/environments/details/EnvironmentLogsPage";
import { EnvironmentTaskRunLogsPage } from "@/routes/environments/details/EnvironmentTaskRunLogsPage";
import { EnvironmentTaskRunsPage } from "@/routes/environments/details/EnvironmentTaskRunsPage";
import { ServiceLogsPage } from "@/routes/environments/details/services/ServiceLogsPage";
import type { RouteObject } from "react-router-dom";

export function createEnvironmentDetailsRoute(): RouteObject {
    return {
        path: "details/:environmentId",
        element: <EnvironmentDetailsLayout />,
        children: [
            {
                index: true,
                element: <EnvironmentDetailsPage />,
            },
            {
                path: "logs",
                element: <EnvironmentLogsPage />,
                handle: {
                    label: "Logs",
                },
            },
            {
                path: "services/:serviceId/logs",
                element: <ServiceLogsPage />,
                handle: {
                    label: "Service Logs",
                },
            },
            {
                path: "tasks",
                element: <EnvironmentTaskRunsPage />,
                handle: {
                    label: "All Task Runs",
                },
            },
            {
                path: "task/:taskId",
                element: <EnvironmentTaskRunsPage />,
                handle: {
                    label: "Task Runs",
                },
            },
            {
                path: "task/:taskId/run/:runId",
                element: <EnvironmentTaskRunLogsPage />,
                handle: {
                    label: "Task Run Logs",
                },
            },
        ],
    };
}
