// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/environment.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateEnvironmentFromProjectRequest, CreateEnvironmentFromProjectResponse, CreateEnvironmentLogsTokenRequest, CreateEnvironmentLogsTokenResponse, CreateEnvironmentRequest, CreateEnvironmentResponse, DeleteEnvironmentRequest, DeleteEnvironmentResponse, GetEnvironmentRequest, GetEnvironmentResponse, ListEnvironmentsRequest, ListEnvironmentsResponse, StartEnvironmentRequest, StartEnvironmentResponse, StopEnvironmentRequest, StopEnvironmentResponse, UpdateEnvironmentRequest, UpdateEnvironmentResponse } from "./environment_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { ListEnvironmentClassesRequest, ListEnvironmentClassesResponse } from "./runner_configuration_pb.js";

/**
 * @generated from service gitpod.v1.EnvironmentService
 */
export const EnvironmentService = {
  typeName: "gitpod.v1.EnvironmentService",
  methods: {
    /**
     * GetEnvironment returns a single environment.
     *
     * +return NOT_FOUND User does not have access to an environment with the given
     * ID +return NOT_FOUND Environment does not exist
     *
     * @generated from rpc gitpod.v1.EnvironmentService.GetEnvironment
     */
    getEnvironment: {
      name: "GetEnvironment",
      I: GetEnvironmentRequest,
      O: GetEnvironmentResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListEnvironments returns a list of environments that match the query.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.ListEnvironments
     */
    listEnvironments: {
      name: "ListEnvironments",
      I: ListEnvironmentsRequest,
      O: ListEnvironmentsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListEnvironmentClasses returns the list of environment classes with runner details a user is able to use based on the
     * query
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     *
     * @generated from rpc gitpod.v1.EnvironmentService.ListEnvironmentClasses
     */
    listEnvironmentClasses: {
      name: "ListEnvironmentClasses",
      I: ListEnvironmentClassesRequest,
      O: ListEnvironmentClassesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * CreateEnvironment creates a new environment and starts it.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.CreateEnvironment
     */
    createEnvironment: {
      name: "CreateEnvironment",
      I: CreateEnvironmentRequest,
      O: CreateEnvironmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateAbdStartEnvironmentFromProject creates a new environment from a project and starts it.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.CreateEnvironmentFromProject
     */
    createEnvironmentFromProject: {
      name: "CreateEnvironmentFromProject",
      I: CreateEnvironmentFromProjectRequest,
      O: CreateEnvironmentFromProjectResponse,
      kind: MethodKind.Unary,
    },
    /**
     * StartEnvironment starts an environment. This function is idempotent, i.e. if
     * the environment is already running no error is returned.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.StartEnvironment
     */
    startEnvironment: {
      name: "StartEnvironment",
      I: StartEnvironmentRequest,
      O: StartEnvironmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * StopEnvironment stops a running environment.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.StopEnvironment
     */
    stopEnvironment: {
      name: "StopEnvironment",
      I: StopEnvironmentRequest,
      O: StopEnvironmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateEnvironment updates the environment partially.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.UpdateEnvironment
     */
    updateEnvironment: {
      name: "UpdateEnvironment",
      I: UpdateEnvironmentRequest,
      O: UpdateEnvironmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteEnvironment deletes an environment.
     * When the environment is running, it will be stopped as well.
     * Deleted environments cannot be started again.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.DeleteEnvironment
     */
    deleteEnvironment: {
      name: "DeleteEnvironment",
      I: DeleteEnvironmentRequest,
      O: DeleteEnvironmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateEnvironmentLogsToken creates a token that can be used to access the logs of an environment.
     *
     * @generated from rpc gitpod.v1.EnvironmentService.CreateEnvironmentLogsToken
     */
    createEnvironmentLogsToken: {
      name: "CreateEnvironmentLogsToken",
      I: CreateEnvironmentLogsTokenRequest,
      O: CreateEnvironmentLogsTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

