import { Check, Copy } from "lucide-react";
import { useTemporaryState } from "../../../hooks/use-temporary-value";
import { forwardRef, useCallback, useMemo } from "react";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import type { PropsWithTestId } from "@/components/podkit/lib/testid";

type InputProps = React.ComponentPropsWithoutRef<"textarea"> & {
    copyable?: boolean;
    value: string;
} & PropsWithTestId;
const CopyableTextArea = forwardRef<HTMLTextAreaElement, InputProps>(
    ({ value, className, copyable, ...props }, ref) => {
        const [copied, setCopied] = useTemporaryState(false, 2000);

        const handleCopyToClipboard = useCallback(() => {
            void navigator.clipboard.writeText(value).then(() => {
                setCopied(true);
            });
        }, [setCopied, value]);

        const rows = useMemo(() => {
            return value.split("\n").length;
        }, [value]);

        return (
            <div className="relative">
                <textarea
                    className={cn(
                        "w-full resize-none overflow-y-auto whitespace-pre text-wrap rounded-lg border border-border-light bg-surface-secondary px-3 py-2 pr-12 font-mono text-base ring-inset ring-offset-surface-primary",
                        className,
                    )}
                    rows={rows}
                    readOnly
                    value={value}
                    {...props}
                    ref={ref}
                />
                {copyable && (
                    <div className="absolute inset-y-2 right-2 flex items-start">
                        <Button
                            variant={"ghost"}
                            type="button"
                            className="h-8 rounded-lg border-none px-2 py-2 text-content-tertiary hover:text-content-secondary hover:opacity-100"
                            onClick={handleCopyToClipboard}
                            aria-label="Copy to clipboard"
                        >
                            {copied ? <Check className="text-green-500" size={16} /> : <Copy size={16} />}
                        </Button>
                    </div>
                )}
            </div>
        );
    },
);
CopyableTextArea.displayName = "CopyableTextArea";

export { CopyableTextArea };
