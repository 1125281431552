import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconPlaceholder: FC<{ kind: "automations" | "port" } & PropsWithClassName> = ({ kind, className }) => {
    switch (kind) {
        case "automations":
            return (
                <svg
                    width="84"
                    height="68"
                    viewBox="0 0 84 68"
                    className={className}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_d_671_8514)">
                        <path
                            d="M2 5C2 2.79086 3.79086 1 6 1H78C80.2091 1 82 2.79086 82 5V50V61C82 63.2091 80.2091 65 78 65H61.5H6C3.79086 65 2 63.2091 2 61V5Z"
                            fill="white"
                        />
                        <path
                            d="M2.5 5C2.5 3.067 4.067 1.5 6 1.5H78C79.933 1.5 81.5 3.067 81.5 5V50V61C81.5 62.933 79.933 64.5 78 64.5H61.5H6C4.067 64.5 2.5 62.933 2.5 61V5Z"
                            stroke="#D9D9D9"
                        />
                    </g>
                    <path
                        d="M73.8333 52.6667C70.4637 52.9584 68.625 54.7971 68.3333 58.1667C68.0293 54.7447 66.2003 53.0481 62.8333 52.6667C66.2535 52.2723 67.9389 50.5869 68.3333 47.1667C68.7147 50.5337 70.4113 52.3627 73.8333 52.6667Z"
                        stroke="#D9D9D9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M74.1932 44.8393C74.1821 44.7411 74.0991 44.6668 74.0002 44.6667C73.9013 44.6666 73.8181 44.7407 73.8069 44.8389C73.7332 45.4774 73.5423 45.9264 73.2343 46.2344C72.9263 46.5424 72.4773 46.7333 71.8388 46.8069C71.7407 46.8182 71.6665 46.9014 71.6667 47.0003C71.6668 47.0991 71.741 47.1822 71.8392 47.1933C72.4672 47.2644 72.9257 47.4552 73.241 47.7648C73.5551 48.0733 73.75 48.5221 73.8063 49.1562C73.8153 49.2566 73.8994 49.3335 74.0002 49.3334C74.101 49.3333 74.185 49.2562 74.1937 49.1557C74.2477 48.5321 74.4424 48.0738 74.7581 47.7581C75.0737 47.4425 75.5321 47.2478 76.1557 47.1938C76.2561 47.1851 76.3332 47.1011 76.3333 47.0003C76.3335 46.8995 76.2565 46.8153 76.1561 46.8064C75.5221 46.7501 75.0732 46.5552 74.7647 46.2411C74.4551 45.9258 74.2643 45.4673 74.1932 44.8393Z"
                        fill="#D9D9D9"
                    />
                    <path
                        d="M7 4.33325L6 4.91659V6.08325L7 6.66659L8 6.08325V4.91659L7 5.49992"
                        stroke="#D9D9D9"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M81 7H39.75C38.2312 7 37 5.76878 37 4.25V4.25C37 2.73122 35.7688 1.5 34.25 1.5H6.5C4.29086 1.5 2.5 3.29086 2.5 5.5L2.5 12.5"
                        stroke="#D9D9D9"
                    />
                    <path
                        d="M12.36 23V22.508H13.704V19.844H12.588V19.4H13.164C13.62 19.4 13.8 19.22 13.8 18.74H14.22V22.508H15.24V23H12.36Z"
                        fill="#D9D9D9"
                    />
                    <path
                        d="M12.3 33C12.3 32.1 12.654 31.506 13.932 30.828C14.52 30.516 14.754 30.324 14.754 29.892C14.754 29.454 14.466 29.148 13.902 29.148C13.302 29.148 12.954 29.472 12.864 30.048L12.324 30.012C12.42 29.178 12.978 28.644 13.902 28.644C14.778 28.644 15.294 29.148 15.294 29.88C15.294 30.528 15.006 30.852 14.124 31.32C13.356 31.728 12.96 32.172 12.936 32.496H15.3V33H12.3Z"
                        fill="#D9D9D9"
                    />
                    <path
                        d="M13.764 42.096C12.84 42.096 12.336 41.604 12.3 40.902L12.834 40.866C12.882 41.388 13.254 41.592 13.764 41.592C14.28 41.592 14.76 41.388 14.76 40.812C14.76 40.248 14.346 39.99 13.794 39.99H13.458V39.486H13.794C14.238 39.486 14.61 39.306 14.61 38.82C14.61 38.382 14.322 38.148 13.794 38.148C13.254 38.148 12.99 38.376 12.936 38.754L12.396 38.718C12.474 38.082 12.966 37.644 13.794 37.644C14.622 37.644 15.15 38.088 15.15 38.796C15.15 39.252 14.898 39.558 14.412 39.708C14.964 39.858 15.3 40.266 15.3 40.812C15.3 41.628 14.652 42.096 13.764 42.096Z"
                        fill="#D9D9D9"
                    />
                    <path
                        d="M14.328 52V51.076H12.24V50.608L14.292 47.74H14.844V50.572H15.36V51.076H14.844V52H14.328ZM12.864 50.572H14.328V48.532L12.864 50.572Z"
                        fill="#D9D9D9"
                    />
                    <rect x="20" y="20" width="35" height="2" rx="1" fill="#D9D9D9" />
                    <rect x="10" y="5" width="13" height="1" rx="0.5" fill="#D9D9D9" />
                    <rect x="28" y="30" width="20" height="2" rx="1" fill="#D9D9D9" />
                    <rect x="25" y="39" width="35" height="2" rx="1" fill="#D9D9D9" />
                    <rect x="20" y="49" width="16" height="2" rx="1" fill="#D9D9D9" />
                    <defs>
                        <filter
                            id="filter0_d_671_8514"
                            x="0"
                            y="0"
                            width="84"
                            height="68"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_671_8514" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_671_8514"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
            );
        case "port":
            return (
                <svg
                    width="84"
                    height="68"
                    viewBox="0 0 84 68"
                    className={className}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_d_671_8540)">
                        <rect x="2" y="1" width="80" height="64" rx="4" fill="white" />
                        <rect x="2.5" y="1.5" width="79" height="63" rx="3.5" stroke="#D9D9D9" strokeLinecap="round" />
                    </g>
                    <path
                        d="M7 4.33325L6 4.91659V6.08325L7 6.66659L8 6.08325V4.91659L7 5.49992"
                        stroke="#D9D9D9"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M81 7H39.75C38.2312 7 37 5.76878 37 4.25V4.25C37 2.73122 35.7688 1.5 34.25 1.5H6.5C4.29086 1.5 2.5 3.29086 2.5 5.5L2.5 12.5"
                        stroke="#D9D9D9"
                    />
                    <rect x="10" y="5" width="13" height="1" rx="0.5" fill="#D9D9D9" />
                    <circle opacity="0.5" cx="42.6665" cy="36" r="17.5" stroke="#D9D9D9" />
                    <circle opacity="0.6" cx="42.6665" cy="36" r="10.5" stroke="#D9D9D9" />
                    <circle cx="42.6665" cy="36" r="4.5" stroke="#D9D9D9" />
                    <circle cx="25.6665" cy="31" r="2.5" fill="#D9D9D9" stroke="#D9D9D9" />
                    <circle cx="48.6665" cy="27" r="2.5" fill="#D9D9D9" stroke="#D9D9D9" />
                    <circle cx="57.6665" cy="45" r="2.5" fill="#D9D9D9" stroke="#D9D9D9" />
                    <defs>
                        <filter
                            id="filter0_d_671_8540"
                            x="0"
                            y="0"
                            width="84"
                            height="68"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_671_8540" />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_671_8540"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
            );
    }
};
