// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/editor.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetEditorRequest, GetEditorResponse, ListEditorsRequest, ListEditorsResponse, ResolveEditorURLRequest, ResolveEditorURLResponse } from "./editor_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.EditorService
 */
export const EditorService = {
  typeName: "gitpod.v1.EditorService",
  methods: {
    /**
     * ListEditors lists all editors available to the caller
     *
     * @generated from rpc gitpod.v1.EditorService.ListEditors
     */
    listEditors: {
      name: "ListEditors",
      I: ListEditorsRequest,
      O: ListEditorsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * GetEditor returns the editor with the given ID
     *
     * @generated from rpc gitpod.v1.EditorService.GetEditor
     */
    getEditor: {
      name: "GetEditor",
      I: GetEditorRequest,
      O: GetEditorResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ResolveEditorURL resolves the editor's URL for an environment
     *
     * @generated from rpc gitpod.v1.EditorService.ResolveEditorURL
     */
    resolveEditorURL: {
      name: "ResolveEditorURL",
      I: ResolveEditorURLRequest,
      O: ResolveEditorURLResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

