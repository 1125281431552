import { EnvironmentServiceActions } from "@/components/environments/EnvironmentServiceActions";
import { EnvironmentServiceStatus } from "@/components/environments/EnvironmentServiceStatus";
import { cn } from "@/components/podkit/lib/cn";
import { Text } from "@/components/podkit/typography/Text";
import type { PlainService } from "@/queries/automations-queries";
import type { FC } from "react";
import { Link } from "react-router-dom";

export const ServiceCard: FC<{ service: PlainService; environmentId: string; disabled: boolean }> = ({
    service,
    environmentId,
    disabled,
}) => {
    return (
        <Link to={`/details/${environmentId}/services/${service.id}/logs`} draggable={false}>
            <div
                className={cn(
                    "flex flex-col items-start gap-3 rounded-xl border-[0.5px] border-border-base bg-surface-glass px-5 py-4",
                    "transition-shadow duration-200 hover:shadow",
                )}
            >
                <div className="flex w-full items-center justify-between gap-2">
                    <EnvironmentServiceActions
                        service={service}
                        environmentId={environmentId}
                        showLogs={false}
                        disabled={disabled}
                        withLabels={true}
                    />
                    <EnvironmentServiceStatus service={service} />
                </div>
                <div className="flex w-full min-w-0 flex-col">
                    <Text className="truncate text-base font-bold text-content-primary">{service.metadata?.name}</Text>
                    <Text
                        className="line-clamp-2 min-h-[32px] text-sm text-content-secondary"
                        title={service.metadata?.description || "Automated service"}
                    >
                        {service.metadata?.description || "Automated service"}
                    </Text>
                </div>
            </div>
        </Link>
    );
};
