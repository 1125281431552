import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { getAccountQueryKey, useGetAccount } from "@/queries/account-queries";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    type OrganizationMember,
    type Organization,
    InviteDomains,
    OrganizationRole,
    CreateSSOConfigurationRequest,
    UpdateSSOConfigurationRequest,
} from "gitpod-next-api/gitpod/v1/organization_pb";

export type PlainOrganization = PlainMessage<Organization>;

export const organizationQueryKey = {
    list: () => keyWithPrincipal(["preferences", "list"]),
    get: (id?: string) => keyWithPrincipal(["preferences", { id }]),
    orgInvite: (organizationId: string) => keyWithPrincipal(["org-invite", { organizationId }]),
    listSSOConfigurations: (organizationId: string) => keyWithPrincipal(["sso-configs", "list", { organizationId }]),
};

const toPlainOrganization = (organization: Organization): PlainOrganization => {
    return toPlainMessage(organization);
};

export type PlainOrganizationMember = PlainMessage<OrganizationMember>;

const toPlainOrganizationMember = (member: OrganizationMember): PlainOrganizationMember => {
    return toPlainMessage(member);
};

export const useOrganization = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: keyWithPrincipal(["organization", { organizationId: user?.organizationId }]),
        queryFn: async () => {
            const { organization } = await api.organizationService.getOrganization({
                organizationId: user?.organizationId,
            });

            if (!organization) {
                throw new Error("Organization not found");
            }
            return toPlainMessage(organization);
        },
        enabled: !!user?.organizationId,
        retry: false,
    });
};

export const useOrganizationInviteSummary = (inviteID?: string) => {
    const api = useGitpodAPI();

    const query = useQuery({
        queryKey: keyWithPrincipal(["invite-summary", { inviteID: inviteID }]),
        queryFn: async () => {
            const resp = await api.organizationService.getOrganizationInviteSummary({
                inviteId: inviteID,
            });

            return { ...resp };
        },
        staleTime: 10000,
        retry: false,
        enabled: !!inviteID,
    });

    return { ...query, isLoading: query.isLoading || query.isFetching || query.isPending };
};

export const useCreateOrganization = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: account } = useGetAccount();

    return useMutation({
        mutationFn: async ({
            name,
            inviteAccountsWithMatchingDomain,
        }: {
            name: string;
            inviteAccountsWithMatchingDomain: boolean;
        }) => {
            if (!account) {
                throw new Error("Not authenticated");
            }

            const { organization, member } = await api.organizationService.createOrganization({
                name,
                joinOrganization: true,
                inviteAccountsWithMatchingDomain,
            });

            if (!organization) {
                throw new Error("Failed to create organization");
            }
            if (!member) {
                throw new Error("Failed to join organization");
            }

            return {
                organization: toPlainOrganization(organization),
                member: toPlainOrganizationMember(member),
            };
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: getAccountQueryKey() });
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export const useJoinOrganization = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: account } = useGetAccount();

    return useMutation({
        mutationFn: async ({ organizationID }: { organizationID: string }) => {
            if (!account) {
                throw new Error("Not authenticated");
            }

            const { member } = await api.organizationService.joinOrganization({
                joinId: {
                    value: organizationID,
                    case: "organizationId",
                },
            });

            if (!member) {
                throw new Error("Failed to join organization");
            }

            return {
                member: toPlainOrganizationMember(member),
            };
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: getAccountQueryKey() });
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export const useLeaveOrganization = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: account } = useGetAccount();

    return useMutation({
        mutationFn: async ({ userId }: { userId: string }) => {
            if (!account) {
                throw new Error("Not authenticated");
            }

            return await api.organizationService.leaveOrganization({
                userId,
            });
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: getAccountQueryKey() });
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export const useUpdateOrganization = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ name, inviteDomains }: { name?: string; inviteDomains?: string[] }) => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            let inviteDomainsMessage: InviteDomains | undefined = undefined;
            if (inviteDomains !== undefined) {
                inviteDomainsMessage = new InviteDomains();
                inviteDomainsMessage.domains = inviteDomains;
            }

            const organizationId = user.organizationId;
            const { organization } = await api.organizationService.updateOrganization({
                organizationId,
                name,
                inviteDomains: inviteDomainsMessage,
            });

            if (!organization) {
                throw new Error("Failed to update organization");
            }

            return {
                organization: toPlainOrganization(organization),
            };
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: keyWithPrincipal(["organization", { organizationId: user?.organizationId }]),
            });
            // to also update the memberships (org switcher!)
            await client.invalidateQueries({ queryKey: getAccountQueryKey() });
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export const useMembers = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: keyWithPrincipal(["members", { organizationId: user?.organizationId }]),
        queryFn: async () => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const { members, pagination } = await api.organizationService.listMembers({
                organizationId: user?.organizationId,
                pagination: {
                    pageSize: 100,
                },
            });

            return {
                members: members.map(toPlainOrganizationMember),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user?.organizationId,
    });
};

export const useDeleteOrganization = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async () => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const organizationId = user.organizationId;
            await api.organizationService.deleteOrganization({
                organizationId,
            });
            return;
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: getAccountQueryKey() });
            await client.invalidateQueries({
                queryKey: keyWithPrincipal(["organization", { organizationId: user?.organizationId }]),
            });
            await client.invalidateQueries({
                queryKey: keyWithPrincipal(["members", { organizationId: user?.organizationId }]),
            });
        },
    });
};

export const useSetRole = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ userID, role }: { userID: string; role: OrganizationRole }) => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const organizationId = user.organizationId;
            await api.organizationService.setRole({
                organizationId,
                userId: userID,
                role,
            });

            return {};
        },
        onSuccess: async () => {
            if (!user) {
                return;
            }

            await client.invalidateQueries({
                queryKey: keyWithPrincipal(["members", { organizationId: user.organizationId }]),
            });
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export function roleName(role: OrganizationRole) {
    switch (role) {
        case OrganizationRole.ADMIN:
            return "Admin";
        case OrganizationRole.MEMBER:
            return "Member";
        case OrganizationRole.UNSPECIFIED:
            return "Unspecified";
    }
}

export const useOrganizationInvite = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: organizationQueryKey.orgInvite(user?.organizationId || ""),
        queryFn: async () => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const { invite } = await api.organizationService.getOrganizationInvite({
                organizationId: user?.organizationId,
            });

            return invite;
        },
    });
};

export const useCreateOrganizationInvite = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async () => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const organizationId = user.organizationId;
            const resp = await api.organizationService.createOrganizationInvite({
                organizationId,
            });

            return resp.invite;
        },
        onSuccess: async () => {
            if (!user) {
                return;
            }

            await client.invalidateQueries({
                queryKey: organizationQueryKey.orgInvite(user?.organizationId || ""),
            });
        },
    });
};

export const useJoinOrganizationWithInvite = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ inviteID }: { inviteID: string }) => {
            const { member } = await api.organizationService.joinOrganization({
                joinId: {
                    value: inviteID,
                    case: "inviteId",
                },
            });

            if (!member) {
                throw new Error("Failed to join organization");
            }

            return member;
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: getAccountQueryKey() });
        },
    });
};

export const useListSSOConfigurations = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: organizationQueryKey.listSSOConfigurations(user?.organizationId || ""),
        queryFn: async () => {
            if (!user) {
                throw new Error("Not authenticated");
            }

            const { ssoConfigurations } = await api.organizationService.listSSOConfigurations({
                organizationId: user?.organizationId,
            });

            return ssoConfigurations;
        },
    });
};

export const useCreateSSOConfiguration = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            organizationId,
            clientId,
            clientSecret,
            emailDomain,
            issuerUrl,
        }: CreateSSOConfigurationRequest) => {
            const { ssoConfiguration } = await api.organizationService.createSSOConfiguration(
                new CreateSSOConfigurationRequest({
                    organizationId,
                    clientId,
                    clientSecret,
                    emailDomain,
                    issuerUrl,
                }),
            );

            if (!ssoConfiguration) {
                throw new Error("Failed to create SSO configuration");
            }

            return ssoConfiguration;
        },
        onSuccess: async (data) => {
            await client.invalidateQueries({
                queryKey: organizationQueryKey.listSSOConfigurations(data.organizationId || ""),
            });
        },
    });
};

export const useUpdateSSOConfiguration = () => {
    const api = useGitpodAPI();
    const client = useQueryClient();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({
            ssoConfigurationId,
            clientId,
            clientSecret,
            issuerUrl,
            claims,
            state,
            emailDomain,
        }: UpdateSSOConfigurationRequest) => {
            await api.organizationService.updateSSOConfiguration(
                new UpdateSSOConfigurationRequest({
                    ssoConfigurationId,
                    clientId,
                    clientSecret,
                    issuerUrl,
                    claims,
                    state,
                    emailDomain,
                }),
            );
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: organizationQueryKey.listSSOConfigurations(user?.organizationId || ""),
            });
        },
    });
};
