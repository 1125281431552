import { useSegmentTrack } from "@/hooks/use-segment";
import { useEffect } from "react";

export function useLoaded() {
    const track = useSegmentTrack();
    useEffect(() => {
        if (document.body.classList.contains("loaded")) {
            // we  only want this to run once
            return;
        }
        // This makes it possible for us to fade-out the loading screen once all the assets
        // are loaded and React has performed the first render.
        document.body.classList.add("loaded");
        track("dashboard_loaded");
    }, [track]);
}
