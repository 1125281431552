const configElement = document.getElementById("gitpod-web-configuration");
const configElementAttribute = configElement ? configElement.getAttribute("data-settings") : undefined;

// should be evolved in the backward compatible way
export type WebConfig = {
    origin: string;
    accessToken?: string;
    principal?: string;
    environmentId?: string;
};

const webConfig = configElementAttribute
    ? (JSON.parse(atob(configElementAttribute)) as WebConfig)
    : {
          origin: window.location.origin,
      };

export default webConfig;
