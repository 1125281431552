import { useGetAccount } from "@/queries/account-queries";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useMemo } from "react";

export const useMembership = () => {
    const { data: account, isPending: isPendingAccount } = useGetAccount();
    const { data: user, isPending: isPendingUser } = useAuthenticatedUser();

    const isPending = isPendingAccount || isPendingUser;

    const membership = useMemo(() => {
        if (!account || !user) {
            return undefined;
        }
        return (account.memberships || []).find((m) => m.organizationId === user.organizationId);
    }, [account, user]);

    return {
        isPending,
        membership,
    };
};
