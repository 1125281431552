import {
    isStaleMachineSession,
    stepAutomationsState,
    stepCloneState,
    stepDevcontainerState,
    stepSecretsState,
    stepStartState,
    StepState,
} from "@/components/environments/environment-start-steps-state";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useEnvironmentComputeDescription } from "@/hooks/use-environment-compute-description";
import {
    getRepoUrlFromInitializer,
    type PlainEnvironment,
    type PlainRunnerEnvironmentClass,
    toPlainRunnerEnvironmentClass,
    useStartEnvironment,
    useUpdateEnvironment,
} from "@/queries/environment-queries";
import { v4 as uuidv4 } from "uuid";
import { useGetEnvironmentClass } from "@/queries/runner-configuration-queries.ts";
import {
    type AuthenticatedWithRunnerResponse,
    type PlainRunner,
    useIsUserAuthenticatedWithRunner,
} from "@/queries/runner-queries.ts";
import { SCMAuthenticationModal } from "@/routes/environments/create/SCMAuthentication.tsx";
import { EnvironmentPhase, EnvironmentStatus_ContentPhase } from "gitpod-next-api/gitpod/v1/environment_pb.ts";
import { type FC, type ReactNode, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { StartSequenceStepIcon } from "@/assets/icons/geist/StartSequenceStepIcon";
import { TrackLocations, type TrackLocation } from "@/hooks/use-segment";
import type { PropsWithTestId } from "@/components/podkit/lib/testid";
import type { SectionLogLine } from "@/routes/environments/log-streams/log-groups";
import { IconMaximize } from "@/assets/icons/geist/IconMaximize";
import { Tooltip } from "@/components/Tooltip";
import { Collapsable } from "@/components/Collapsable";
import { environmentLogGroupUrl } from "@/utils/environment";
import { formatMediumTime } from "@/format/time";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";

export const StepStart: FC<{ environment: PlainEnvironment; runner: PlainRunner; latestLogLine?: SectionLogLine }> = ({
    environment,
    runner,
    latestLogLine,
}) => {
    const navigate = useNavigate();

    const state = useMemo(() => stepStartState(environment), [environment]);

    const title = useMemo(() => {
        const machine = runner.kind == RunnerKind.LOCAL ? "local virtual machine" : "remote virtual machine";
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return `Start ${machine}`;
            case StepState.Running:
                return `Starting ${machine}`;
            case StepState.Success:
                return `Started ${machine}`;
        }
    }, [runner.kind, state]);

    const description = useEnvironmentComputeDescription(environment);
    const subtitle = [description?.runnerName, description?.clazz?.displayName, description?.clazz?.description]
        .filter(Boolean)
        .join("\u00A0\u00A0•\u00A0\u00A0");

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return [environment.status?.machine?.failureMessage, environment.status?.machine?.warningMessage]
            .filter(Boolean)
            .join("\n");
    }, [environment]);

    const onViewLogs = useCallback(
        () => navigate(environmentLogGroupUrl(environment.id, latestLogLine?.id)),
        [environment.id, latestLogLine?.id, navigate],
    );

    const viewLogs = useMemo(() => {
        const isShowingLatestLogLine = !!latestLogLine && state === StepState.Running;
        if (state == StepState.Waiting || isShowingLatestLogLine) {
            return;
        }
        if (onViewLogs) {
            return (
                <Button size="sm" variant="secondary" onClick={onViewLogs}>
                    Logs
                </Button>
            );
        }
    }, [latestLogLine, onViewLogs, state]);

    return (
        <Step
            environmentId={environment.id}
            title={title}
            subtitle={subtitle}
            logLine={latestLogLine}
            text={text}
            state={state}
            hoverActions={viewLogs}
        />
    );
};

export const StepClone: FC<{ environment: PlainEnvironment; latestLogLine?: SectionLogLine }> = ({
    environment,
    latestLogLine,
}) => {
    const navigate = useNavigate();
    const startEnvironment = useStartEnvironment();

    const state = useMemo(() => stepCloneState(environment), [environment]);

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Clone repository";
            case StepState.Running:
                return "Cloning repository";
            case StepState.Success:
                return "Cloned repository";
        }
    }, [state]);

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return [environment.status?.content?.failureMessage, environment.status?.content?.warningMessage]
            .filter(Boolean)
            .join("\n");
    }, [environment]);

    const repoURL = useMemo(() => {
        return getRepoUrlFromInitializer(environment.spec?.content?.initializer);
    }, [environment]);

    const isFailed = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return environment.status?.content?.phase == EnvironmentStatus_ContentPhase.FAILED;
    }, [environment]);
    const { data: authCheckResponse } = useIsUserAuthenticatedWithRunner(environment.metadata?.runnerId, repoURL, {
        refetchUntilAuthenticated: true,
        sessionId: environment.spec?.machine?.session,

        // Auth check should be polled only when content is in a failed state.
        enabled: isFailed,
    });

    const isAuthenticationRequired = isFailed && authCheckResponse?.type == "AuthenticationRequired";
    const [showScmAuthModal, setShowScmAuthModal] = useState<SCMAuthenticationModalProps | undefined>();

    const { data: clazz } = useGetEnvironmentClass(environment.spec?.machine?.class);

    const onViewLogs = useCallback(
        () => navigate(environmentLogGroupUrl(environment.id, latestLogLine?.id)),
        [environment.id, latestLogLine?.id, navigate],
    );

    const viewLogs = useMemo(() => {
        const isShowingLatestLogLine = !!latestLogLine && state === StepState.Running;
        if (state == StepState.Waiting || isShowingLatestLogLine) {
            return;
        }
        if (onViewLogs) {
            return (
                <Button size="sm" variant="secondary" onClick={onViewLogs}>
                    Logs
                </Button>
            );
        }
    }, [latestLogLine, onViewLogs, state]);

    const reAuthenticate = useMemo(() => {
        if (!isAuthenticationRequired) {
            return;
        }
        return (
            <Button
                size="sm"
                variant="primary"
                onClick={() => {
                    if (!authCheckResponse || !clazz) {
                        return;
                    }
                    setShowScmAuthModal({
                        repoURL: repoURL || "",
                        clazz: toPlainRunnerEnvironmentClass(clazz),
                        authResponse: authCheckResponse,
                        onClose: () => setShowScmAuthModal(undefined),
                        onAuthSuccess: () => {
                            setShowScmAuthModal(undefined);
                        },
                        "data-track-location": TrackLocations.EnvironmentSCMAuthenticationModal,
                    });
                }}
                data-track-label="true"
            >
                Re-Authenticate
            </Button>
        );
    }, [authCheckResponse, clazz, isAuthenticationRequired, repoURL]);

    return (
        <>
            <Step
                environmentId={environment.id}
                title={title}
                subtitle={environment.status?.content?.git?.cloneUrl}
                text={text}
                state={state}
                logLine={latestLogLine}
                actions={reAuthenticate}
                hoverActions={viewLogs}
            />
            {showScmAuthModal && (
                <AuthenticationModal
                    {...showScmAuthModal}
                    onClose={() => setShowScmAuthModal(undefined)}
                    onAuthSuccess={async () => {
                        setShowScmAuthModal(undefined);

                        // Restart environment when authentication is successful and the environment is stopped.
                        if (environment.status?.phase === EnvironmentPhase.STOPPED) {
                            await startEnvironment.mutateAsync(environment.id);
                        }
                    }}
                />
            )}
        </>
    );
};

export const StepStartDevcontainer: FC<{ environment: PlainEnvironment; latestLogLine?: SectionLogLine }> = ({
    environment,
    latestLogLine,
}) => {
    const navigate = useNavigate();

    const updateEnvironment = useUpdateEnvironment();
    const { toast } = useToast();
    const { state, canRebuild } = useMemo(() => stepDevcontainerState(environment), [environment]);

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Start dev container";
            case StepState.Running:
                return "Starting dev container";
            case StepState.Success:
                return "Started dev container";
        }
    }, [state]);

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return [environment.status?.devcontainer?.failureMessage, environment.status?.devcontainer?.warningMessage]
            .filter(Boolean)
            .join("\n");
    }, [environment]);

    const [hint, rebuildButtonLabel] = canRebuild
        ? ["The dev container file is out of sync with the current environment", "Rebuild"]
        : [undefined, undefined];

    const onClickRebuild = useCallback(async () => {
        await updateEnvironment.mutateAsync(
            {
                req: {
                    environmentId: environment.id,
                    spec: {
                        devcontainer: {
                            session: uuidv4(),
                        },
                    },
                },
            },
            {
                onError(error) {
                    toast({
                        title: "Failed to trigger rebuild of the dev container",
                        description: formatError(error),
                    });
                },
            },
        );
    }, [toast, updateEnvironment, environment.id]);

    const onViewLogs = useCallback(
        () => navigate(environmentLogGroupUrl(environment.id, latestLogLine?.id)),
        [environment.id, latestLogLine?.id, navigate],
    );

    const subtitle = useMemo(() => {
        const contentPath = environment.status?.content?.contentLocationInMachine || "";
        const devcontainerPath = environment.status?.devcontainer?.devcontainerFilePath || "";
        return devcontainerPath.slice(contentPath.length).replace(/^\//, "");
    }, [environment]);

    const viewLogs = useMemo(() => {
        const isShowingLatestLogLine = !!latestLogLine && state === StepState.Running;
        if (state == StepState.Waiting || isShowingLatestLogLine) {
            return;
        }
        if (onViewLogs) {
            return (
                <Button size="sm" variant="secondary" onClick={onViewLogs}>
                    Logs
                </Button>
            );
        }
    }, [latestLogLine, onViewLogs, state]);

    const rebuildButton = useMemo(() => {
        if (!canRebuild) {
            return;
        }
        return (
            <Button
                size="sm"
                variant="primary"
                loading={updateEnvironment.isPending}
                onClick={onClickRebuild}
                data-track-label="true"
            >
                {rebuildButtonLabel}
            </Button>
        );
    }, [canRebuild, updateEnvironment.isPending, onClickRebuild, rebuildButtonLabel]);

    return (
        <Step
            environmentId={environment.id}
            title={title}
            subtitle={subtitle}
            text={text}
            hint={hint}
            logLine={latestLogLine}
            state={state}
            actions={rebuildButton}
            hoverActions={viewLogs}
            hasNext={false}
        />
    );
};

export const StepAutomations: FC<{ environment: PlainEnvironment }> = ({ environment }) => {
    const state = useMemo(() => stepAutomationsState(environment), [environment]);

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Load Automations";
            case StepState.Running:
                return "Loading Automations";
            case StepState.Success:
                return "Loaded Automations";
        }
    }, [state]);

    const subtitle = useMemo(() => {
        if (state == StepState.Empty) {
            return "No Automations file";
        }
        return environment.status?.automationsFile?.automationsFilePath;
    }, [state, environment.status?.automationsFile?.automationsFilePath]);

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        return environment.status?.automationsFile?.failureMessage;
    }, [environment]);
    return <Step environmentId={environment.id} title={title} subtitle={subtitle} text={text} state={state} />;
};

export const StepSecrets: FC<{ environment: PlainEnvironment; onShowSecrets: () => void }> = ({
    environment,
    onShowSecrets,
}) => {
    const state = useMemo(() => stepSecretsState(environment), [environment]);
    const specSecretsCount = environment.spec?.secrets?.length ?? 0;

    const title = useMemo(() => {
        switch (state) {
            case StepState.Failure:
            case StepState.Waiting:
            case StepState.Empty:
                return "Load Secrets";
            case StepState.Running:
                return "Loading Secrets";
            case StepState.Success:
                return "Loaded Secrets";
        }
    }, [state]);

    const subtitle = useMemo(() => {
        if (state === StepState.Empty) {
            return "No secrets configured";
        } else if (state === StepState.Success && specSecretsCount > 0) {
            return `${specSecretsCount} secret${specSecretsCount > 1 ? "s" : ""}`;
        }
        return;
    }, [state, specSecretsCount]);

    const text = useMemo(() => {
        if (isStaleMachineSession(environment)) {
            return;
        }
        const messages = [];
        for (const secret of environment.status?.secrets || []) {
            if (secret.failureMessage) {
                messages.push(`${secret.secretName}: ${secret.failureMessage}`);
            }
            if (secret.warningMessage) {
                messages.push(`${secret.secretName}: ${secret.warningMessage}`);
            }
        }

        if (!messages.length) {
            return;
        }

        return messages.join(", ");
    }, [environment]);

    const hoverActions = useMemo(() => {
        if (state === StepState.Success) {
            return (
                <Button size="sm" variant="secondary" onClick={onShowSecrets}>
                    View
                </Button>
            );
        }
        return;
    }, [onShowSecrets, state]);

    const actions = useMemo(() => {
        if (state === StepState.Empty) {
            return (
                <ExternalLink href="https://www.gitpod.io/docs/flex/secrets" iconSize="sm">
                    Learn about secrets
                </ExternalLink>
            );
        }
        return;
    }, [state]);

    return (
        <Step
            environmentId={environment.id}
            title={title}
            subtitle={subtitle}
            text={text}
            state={state}
            data-testid="secrets-step"
            actions={actions}
            hoverActions={hoverActions}
        />
    );
};

const Step: FC<
    {
        environmentId: string;
        title: string;
        subtitle?: string;
        text?: string;
        hint?: string;
        logLine?: SectionLogLine;
        state: StepState;
        actions?: ReactNode;
        hoverActions?: ReactNode;
        hasNext?: boolean;
    } & PropsWithClassName &
        PropsWithTestId
> = ({
    environmentId,
    title,
    subtitle,
    text,
    hint,
    logLine,
    actions,
    hoverActions,
    state,
    className,
    "data-testid": dataTestId,
    hasNext = true,
}) => {
    const secondLine = text;

    const navigate = useNavigate();

    const onLogLineClick = useCallback(
        () => navigate(environmentLogGroupUrl(environmentId, logLine?.id)),
        [environmentId, logLine?.id, navigate],
    );

    return (
        <div
            className={cn("group flex flex-row gap-2 pr-5 last:hover:rounded-b-xl", className)}
            data-testid={dataTestId}
        >
            {/* Step icon */}
            <div className="ml-[24px] flex w-[40px] min-w-[40px] flex-col items-center py-1">
                <StepIcon state={state} />
                {hasNext && (
                    <div className="mt-1 h-full w-0.5 grow border-r-[2.5px] border-dotted border-gray-600/20" />
                )}
            </div>

            {/* Step content */}
            <div className="flex grow flex-col truncate">
                <div className="flex min-h-8 w-full flex-row items-center">
                    {/* Step text */}
                    <div className="flex flex-grow flex-col overflow-x-hidden">
                        {/* Title & subtitle */}
                        <div className="inline-flex grow gap-2">
                            <Heading1 className="shrink-0 text-base">{title}</Heading1>
                            {subtitle && (
                                <span className="min-w-0 truncate text-base text-content-secondary">{subtitle}</span>
                            )}
                        </div>
                        {/* Additional content */}
                        {hint && (
                            <div className="h-fit max-w-full text-left">
                                <Text className="text-wrap text-sm text-content-secondary">{hint}</Text>
                            </div>
                        )}
                        {secondLine && (
                            <div className="h-fit max-w-full">
                                <Text className="text-wrap font-mono text-sm text-content-secondary">{secondLine}</Text>
                            </div>
                        )}

                        <Collapsable collapsed={state !== StepState.Running || !logLine}>
                            <div
                                className={cn(
                                    "mt-1 flex items-center justify-between gap-1",
                                    "rounded-md border-[0.5px] border-border-base bg-surface-pure px-2 py-2",
                                )}
                            >
                                <div className="flex items-start gap-3 overflow-x-hidden">
                                    {logLine?.timestamp && (
                                        <span className="min-w-fit font-mono text-sm text-content-tertiary">
                                            {formatMediumTime(logLine.timestamp)}
                                        </span>
                                    )}
                                    <Text className="truncate font-mono text-sm text-content-primary">
                                        {logLine?.line}
                                    </Text>
                                </div>
                                <Tooltip content="View logs">
                                    <Button
                                        LeadingIcon={IconMaximize}
                                        variant="secondary"
                                        size="sm"
                                        onClick={onLogLineClick}
                                    />
                                </Tooltip>
                            </div>
                        </Collapsable>
                    </div>
                    {/* Step actions */}
                    <div className="flex gap-2">
                        <div className={cn("hidden", hoverActions && "group-hover:block")}>{hoverActions}</div>
                        {actions}
                    </div>
                </div>
                {/* Step bottom border */}
                {hasNext && <div className="my-[10px] border-t-[1.5px] border-dotted border-gray-600/20" />}
            </div>
        </div>
    );
};

const StepIcon = ({ state }: { state: StepState }) => {
    return (
        <div className="flex min-h-6 min-w-6 items-center justify-center">
            <StartSequenceStepIcon state={state} />
        </div>
    );
};

type SCMAuthenticationModalProps = {
    repoURL: string;
    authResponse: AuthenticatedWithRunnerResponse;
    clazz: PlainRunnerEnvironmentClass;
    onClose: () => void;
    onAuthSuccess: () => void;
    "data-track-location": TrackLocation;
};
const AuthenticationModal: FC<SCMAuthenticationModalProps> = (p) => {
    const patSupported = useMemo(() => {
        return p.authResponse.type === "AuthenticationRequired" && p.authResponse.patSupported;
    }, [p]);
    const authUrl = useMemo(() => {
        return p.authResponse.type === "AuthenticationRequired" ? p.authResponse.url : "";
    }, [p]);
    const scmId = useMemo(() => {
        return p.authResponse.type === "AuthenticationRequired" ? p.authResponse.scmId : "";
    }, [p]);

    return (
        <div>
            <SCMAuthenticationModal
                repoURL={p.repoURL}
                authenticationUrl={authUrl}
                patSupported={patSupported}
                scmId={scmId}
                onClose={p.onClose}
                onClickContinue={p.onAuthSuccess}
                clazz={p.clazz}
                data-track-location={p["data-track-location"]}
            />
        </div>
    );
};
