// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gitpod/v1/runner.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CheckAuthenticationForHostRequest, CheckAuthenticationForHostResponse, CreateRunnerPolicyRequest, CreateRunnerPolicyResponse, CreateRunnerRequest, CreateRunnerResponse, CreateRunnerTokenRequest, CreateRunnerTokenResponse, DeleteRunnerPolicyRequest, DeleteRunnerPolicyResponse, DeleteRunnerRequest, DeleteRunnerResponse, GetRunnerRequest, GetRunnerResponse, ListRunnerPoliciesRequest, ListRunnerPoliciesResponse, ListRunnersRequest, ListRunnersResponse, ParseContextURLRequest, ParseContextURLResponse, UpdateRunnerPolicyRequest, UpdateRunnerPolicyResponse, UpdateRunnerRequest, UpdateRunnerResponse } from "./runner_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gitpod.v1.RunnerService
 */
export const RunnerService = {
  typeName: "gitpod.v1.RunnerService",
  methods: {
    /**
     * CreateRunner creates a new runner with the server. Registrations are very
     * short-lived and must be renewed every 30 seconds. Runners can be registered
     * for an entire organisation or a single user.
     *
     * @generated from rpc gitpod.v1.RunnerService.CreateRunner
     */
    createRunner: {
      name: "CreateRunner",
      I: CreateRunnerRequest,
      O: CreateRunnerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetRunner returns a single runner.
     *
     * @generated from rpc gitpod.v1.RunnerService.GetRunner
     */
    getRunner: {
      name: "GetRunner",
      I: GetRunnerRequest,
      O: GetRunnerResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * ListRunners returns all runners registered in the scope.
     *
     * @generated from rpc gitpod.v1.RunnerService.ListRunners
     */
    listRunners: {
      name: "ListRunners",
      I: ListRunnersRequest,
      O: ListRunnersResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * UpdateRunner updates an environment runner.
     *
     * @generated from rpc gitpod.v1.RunnerService.UpdateRunner
     */
    updateRunner: {
      name: "UpdateRunner",
      I: UpdateRunnerRequest,
      O: UpdateRunnerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteRunner deletes an environment runner.
     *
     * @generated from rpc gitpod.v1.RunnerService.DeleteRunner
     */
    deleteRunner: {
      name: "DeleteRunner",
      I: DeleteRunnerRequest,
      O: DeleteRunnerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateRunnerToken returns a token that can be used to authenticate as the
     * runner. Use this call to renew an outdated token - this does not expire any
     * previouly issued tokens.
     *
     * @generated from rpc gitpod.v1.RunnerService.CreateRunnerToken
     */
    createRunnerToken: {
      name: "CreateRunnerToken",
      I: CreateRunnerTokenRequest,
      O: CreateRunnerTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ParseContextURL asks a runner to parse a context URL, and return the parsed result.
     *
     * This call returns
     *  - FAILED_PRECONDITION if the user requires authentication on the runner to access the context URL
     *  - PERMISSION_DENIED if the user is not allowed to access the context URL using the credentials they have
     *  - INVALID_ARGUMENT if the context URL is invalid
     *  - NOT_FOUND if the repository or branch indicated by the context URL does not exist
     *
     * @generated from rpc gitpod.v1.RunnerService.ParseContextURL
     */
    parseContextURL: {
      name: "ParseContextURL",
      I: ParseContextURLRequest,
      O: ParseContextURLResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CheckAuthenticationForHost asks a runner if the user is authenticated against a particular host, e.g. an SCM system.
     * If not, this function will return a URL that the user should visit to authenticate, or indicate that Personal Access Tokens are supported.
     *
     * @generated from rpc gitpod.v1.RunnerService.CheckAuthenticationForHost
     */
    checkAuthenticationForHost: {
      name: "CheckAuthenticationForHost",
      I: CheckAuthenticationForHostRequest,
      O: CheckAuthenticationForHostResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListRunnerPolicies lists runner policies.
     *
     * @generated from rpc gitpod.v1.RunnerService.ListRunnerPolicies
     */
    listRunnerPolicies: {
      name: "ListRunnerPolicies",
      I: ListRunnerPoliciesRequest,
      O: ListRunnerPoliciesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * CreateRunnerPolicy creates a new runner policy.
     *
     * @generated from rpc gitpod.v1.RunnerService.CreateRunnerPolicy
     */
    createRunnerPolicy: {
      name: "CreateRunnerPolicy",
      I: CreateRunnerPolicyRequest,
      O: CreateRunnerPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateRunnerPolicy an existing runner policy.
     *
     * @generated from rpc gitpod.v1.RunnerService.UpdateRunnerPolicy
     */
    updateRunnerPolicy: {
      name: "UpdateRunnerPolicy",
      I: UpdateRunnerPolicyRequest,
      O: UpdateRunnerPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DeleteRunnerPolicy deletes a runner policy.
     *
     * @generated from rpc gitpod.v1.RunnerService.DeleteRunnerPolicy
     */
    deleteRunnerPolicy: {
      name: "DeleteRunnerPolicy",
      I: DeleteRunnerPolicyRequest,
      O: DeleteRunnerPolicyResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

