import { Button } from "@/components/flexkit/Button";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { useCallback, type FC } from "react";
import { CopyableTextArea } from "@/components/podkit/forms/CopyableTextArea";
import {
    AutomationsTemplateId,
    templates,
    type AutomationsTemplate,
} from "@/components/environments/onboarding/automations-template";
import { CopyableInput } from "@/components/podkit/forms/CopyableInput";
import { TrackLocations } from "@/hooks/use-segment";

export const AutomationsTemplateModal: FC<{
    onClose: () => void;
}> = ({ onClose }) => {
    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent
                className="max-w-[600px]"
                data-track-location={TrackLocations.EnvironmentOnboardingAutomationsTemplateModal}
            >
                <DialogHeader>
                    <DialogTitle>Add automations</DialogTitle>
                    <DialogDescription>Create an automations.yaml to run tasks and services</DialogDescription>
                </DialogHeader>

                <DialogBody className="overflow-x max-w-full">
                    <TemplateContent template={templates[AutomationsTemplateId.Example]} />
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const TemplateContent: FC<{ template: AutomationsTemplate }> = ({ template }) => {
    return (
        <div className="flex flex-col gap-2">
            <Text>
                Add the following contents to <span className="font-mono">.gitpod/automations.yaml</span>
            </Text>
            <CopyableTextArea value={template.automationsFileContents} className="min-h-[140px]" copyable />
            <Text>And configure the environment to use the Automations using</Text>
            <CopyableInput value="gitpod automations update -s .gitpod/automations.yaml" copyable />
        </div>
    );
};
