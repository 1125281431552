import type React from "react";
import { useCallback, type DependencyList } from "react";

/**
 * Same as {@link useCallback}, but prevents the default behavior of the event.
 * Useful when e.g. you have buttons wrapped in links.
 */
export function useCallbackPreventDefault(
    callback: (() => Promise<void>) | (() => void),
    deps: DependencyList,
): (e: React.SyntheticEvent) => Promise<void> {
    return useCallback(
        async (e: React.SyntheticEvent) => {
            e.preventDefault();
            await callback();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [callback, deps],
    );
}
