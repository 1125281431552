/* eslint-disable */

import { ConnectError, type Code } from "@connectrpc/connect";
export type IPCTransport = {
    onDidConnect(callback: () => void): () => void;
    send(channel: string, param?: any, responseChannel?: string, abortChannel?: string): void;
    event(
        channel: string,
        callback: (param?: any, responseChannel?: string, abortChannel?: string) => void,
    ): () => void;
};

export type IPCRequest<P = void, R = void> = {
    kind: "request";
    param?: P;
    result?: R;
};
export type IPCEvent<P = void> = {
    kind: "event";
    param?: P;
};
export type IPCNotification<P = void> = {
    kind: "notification";
    param?: P;
};
export type IPCMethod<P = void, R = void> = IPCRequest<P, R> | IPCEvent<P> | IPCNotification<P>;
export function ipcRequest<P = void, R = void>(): IPCRequest<P, R> {
    return { kind: "request" };
}
export function ipcEvent<P = void>(): IPCEvent<P> {
    return { kind: "event" };
}
export function ipcNotification<P = void>(): IPCNotification<P> {
    return { kind: "notification" };
}

export type ICPServiceType = {
    type: string;
    methods: Record<string, IPCMethod<any, any>>;
};

export type IPCService<T extends ICPServiceType> = {
    [K in keyof T["methods"]]: T["methods"][K] extends IPCRequest<infer P, infer R>
        ? (param: P, signal?: AbortSignal) => Promise<R>
        : T["methods"][K] extends IPCEvent<infer P>
        ? (callback: (param: P) => void) => () => void
        : T["methods"][K] extends IPCNotification<infer P>
        ? (param: P) => void
        : never;
};
export type IPCServiceProxy<T extends ICPServiceType> = IPCService<T> & { dispose: () => void };

type IPCMessage = {
    id: number;
    channel: string;
    param?: any;
    responseChannel?: string;
    abortChannel?: string;
};

type IPCRequestResult = {
    result?: any;
    error?:
        | {
              kind: "ConnectError";
              message: string;
              code: Code;
          }
        | any;
};

export function createIPCProxy<T extends ICPServiceType>(type: T, transport: IPCTransport): IPCServiceProxy<T> {
    let msgSequenceNumber = 0;
    let eventSequenceNumber = 0;
    const pending = new Map<number, IPCMessage>();
    function submitPending() {
        for (const msg of pending.values()) {
            transport.send(msg.channel, msg.param, msg.responseChannel, msg.abortChannel);
        }
    }
    let disposed = false;
    const disposeAbortController = new AbortController();
    const disposeSignal = disposeAbortController.signal;
    const disposables: (() => void)[] = [() => disposeAbortController.abort()];
    disposables.push(
        transport.onDidConnect(() => {
            transport.send(connectChannel(type));
            submitPending();
        }),
    );
    const register = (dispose: () => void) => {
        disposables.push(dispose);
        return () => {
            dispose();
            disposables.splice(disposables.indexOf(dispose), 1);
        };
    };
    return new Proxy(
        {},
        {
            get: (_, prop) => {
                if (disposed) {
                    throw new Error(`'${type.type}' service proxy is disposed`);
                }
                const methodName = prop as string;
                if (methodName === "dispose") {
                    return () => {
                        disposed = true;
                        for (const dispose of disposables) {
                            dispose();
                        }
                    };
                }
                const method = type.methods[methodName];
                if (!method) {
                    throw new Error(`Method '${methodName}' not found on '${type.type}' service type`);
                }
                const channel = methodChannel(type, methodName);
                if (method.kind === "request") {
                    return (param: any, signal?: AbortSignal) => {
                        const id = msgSequenceNumber++;
                        const responseChannel = `${channel}/${id}/response`;
                        const abortChannel = `${channel}/${id}/abort`;
                        const msg = { id, channel, param, responseChannel, abortChannel };
                        transport.send(channel, param, responseChannel, abortChannel);
                        pending.set(id, msg);
                        const listeners: (() => void)[] = [];
                        const dispose = register(() => {
                            for (const removeListener of listeners) {
                                removeListener();
                            }
                            pending.delete(id);
                        });
                        return new Promise((resolve, reject) => {
                            const abortListener = () => {
                                transport.send(abortChannel);
                                reject(Object.assign(new Error("Aborted"), { name: "AbortError" }));
                            };
                            signal?.addEventListener("abort", abortListener);
                            disposeSignal.addEventListener("abort", abortListener);
                            listeners.push(() => {
                                signal?.removeEventListener("abort", abortListener);
                                disposeSignal.removeEventListener("abort", abortListener);
                            });
                            listeners.push(
                                transport.event(responseChannel, ({ result, error }: IPCRequestResult) => {
                                    if (error) {
                                        if (error.kind === "ConnectError") {
                                            reject(new ConnectError(error.message, error.code));
                                        } else {
                                            reject(error);
                                        }
                                    } else {
                                        resolve(result);
                                    }
                                }),
                            );
                        }).finally(dispose);
                    };
                }
                if (method.kind === "notification") {
                    return (param: any) => transport.send(channel, param);
                }
                if (method.kind === "event") {
                    const callbackChannel = `${channel}/${eventSequenceNumber++}/callback`;
                    return (callback: (param: any) => void) => {
                        const id = msgSequenceNumber++;
                        const removeListener = transport.event(callbackChannel, callback);
                        transport.send(channel, { callbackChannel, kind: "subscribe" });
                        pending.set(id, { id, channel, param: { callbackChannel, kind: "subscribe" } });
                        return register(() => {
                            removeListener();
                            pending.delete(id);
                            transport.send(channel, { callbackChannel, kind: "unsubscribe" });
                        });
                    };
                }
            },
        },
    ) as IPCServiceProxy<T>;
}

export function bindIPCService<T extends ICPServiceType>(
    type: T,
    transport: IPCTransport,
    service: IPCService<T>,
): () => void {
    const disposables: (() => void)[] = [];
    const eventListeners = new Map<string, () => void>();
    disposables.push(
        transport.event(connectChannel(type), () => {
            eventListeners.forEach((removeListener) => removeListener());
            eventListeners.clear();
        }),
    );
    for (const methodName in type.methods) {
        const method = type.methods[methodName];
        const channel = methodChannel(type, methodName);
        if (method.kind === "request") {
            disposables.push(
                transport.event(channel, async (param?: any, responseChannel?: string, abortChannel?: string) => {
                    let signal: AbortSignal | undefined;
                    let removeAbortListener: () => void | undefined;
                    if (abortChannel) {
                        const abortController = new AbortController();
                        signal = abortController.signal;
                        removeAbortListener = transport.event(abortChannel, () => abortController.abort());
                        eventListeners.set(abortChannel, removeAbortListener);
                    }
                    try {
                        const result = await (service[methodName] as any)(param, signal);
                        if (responseChannel) {
                            transport.send(responseChannel, { result });
                        }
                    } catch (error) {
                        if (responseChannel) {
                            if (error instanceof ConnectError) {
                                // TODO(ak) for now we only translate message and code
                                transport.send(responseChannel, {
                                    error: {
                                        kind: "ConnectError",
                                        message: error.message,
                                        code: error.code,
                                    },
                                });
                            } else {
                                transport.send(responseChannel, { error });
                            }
                        }
                    } finally {
                        if (abortChannel) {
                            removeAbortListener!();
                            eventListeners.delete(abortChannel);
                        }
                    }
                }),
            );
        }
        if (method.kind === "notification") {
            disposables.push(transport.event(channel, (param: any) => service[methodName](param)));
        }
        if (method.kind === "event") {
            disposables.push(
                transport.event(
                    channel,
                    ({ callbackChannel, kind }: { callbackChannel: string; kind: "subscribe" | "unsubscribe" }) => {
                        if (kind === "subscribe") {
                            const removeListener = (service[methodName] as any)((param: any) =>
                                transport.send(callbackChannel!, param),
                            );
                            eventListeners.set(callbackChannel!, removeListener);
                        } else if (kind === "unsubscribe") {
                            const removeListener = eventListeners.get(callbackChannel!);
                            if (removeListener) {
                                removeListener();
                                eventListeners.delete(callbackChannel!);
                            }
                        }
                    },
                ),
            );
        }
    }
    return () => {
        disposables.forEach((dispose) => dispose());
        disposables.length = 0;
        eventListeners.forEach((removeListener) => removeListener());
        eventListeners.clear();
    };
}

function methodChannel<T extends ICPServiceType>(type: T, methodName: string) {
    return `gitpod.${type.type}/${methodName}`;
}
function connectChannel<T extends ICPServiceType>(type: T) {
    return `gitpod.${type.type}.connect`;
}
