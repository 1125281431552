import { IconBox } from "@/assets/icons/geist/IconBox";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useEnvironment } from "@/queries/environment-queries";
import { EnvironmentDetails } from "@/routes/environments/details/EnvironmentDetails";
import { type FC } from "react";
import { useParams } from "react-router-dom";

export const EnvironmentDetailsPage: FC = () => {
    const { environmentId } = useParams();
    useDocumentTitle("Environment - Details");

    const { data: environment, isPending } = useEnvironment(environmentId);
    if (!isPending && !environment) {
        return <EnvironmentDetailsNotFound />;
    }

    return <EnvironmentDetails environmentId={environmentId || ""} />;
};

export const EnvironmentDetailsNotFound: FC = () => {
    return (
        <div className="flex h-full flex-col items-center justify-center px-4 text-center">
            <IconBox state="open" className="mb-10 h-16 w-16 animate-rotateBox text-content-primary" />
            <h1 className="mb-2 text-xl font-semibold text-content-primary">That environment doesn&#x2019;t exist</h1>
            <p className="text-lg text-content-secondary">You can always create a new one.</p>
        </div>
    );
};
