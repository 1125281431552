import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import type { Size } from "@/assets/icons/geist/Size";
import { cn } from "@/components/podkit/lib/cn";
import type { FC, HTMLProps } from "react";

type ExternalLinkProps = {
    iconSize?: Size;
} & HTMLProps<HTMLAnchorElement>;

export const ExternalLink: FC<ExternalLinkProps> = ({ iconSize, className, children, ...props }) => {
    return (
        <a
            className={cn("inline-flex items-center gap-1 text-base text-content-link", className)}
            target="_blank"
            rel="noreferrer"
            {...props}
        >
            {iconSize && <IconExternalLink size={iconSize} />}
            {children}
        </a>
    );
};
