import { ErrorMessage } from "@/components/ErrorMessage";
import { EnvironmentLogs } from "@/components/EnvironmentLogs";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useEnvironment } from "@/queries/environment-queries";
import { Text } from "@/components/podkit/typography/Text";
import { useMemo, type FC } from "react";
import { useParams } from "react-router-dom";
import { Code } from "@connectrpc/connect";
import { EnvironmentDetailsHeader } from "@/components/environments/EnvironmentDetailsHeader";
import { BackButton } from "@/components/environments/BackButton";
import { environmentLogsUrl } from "@/utils/environment";

export const EnvironmentLogsPage: FC = () => {
    const { environmentId } = useParams();
    useDocumentTitle("Environment - Details");

    const { data: environment, error } = useEnvironment(environmentId);

    const logsUrl = useMemo(() => environmentLogsUrl(environment), [environment]);

    if (error) {
        return (
            <div className="flex size-full flex-col items-center justify-center">
                <ErrorMessage
                    error={error}
                    messages={{
                        [Code.InvalidArgument]: "Invalid environment ID",
                    }}
                />
                <Text className="text-sm text-content-negative">Environment ID: {environmentId}</Text>
            </div>
        );
    }

    return (
        <div className="flex size-full flex-col">
            {environmentId && (
                <div className="flex flex-col gap-4">
                    <EnvironmentDetailsHeader showActionBar={false} />
                    <div className="flex justify-between pb-4">
                        <BackButton />
                    </div>
                </div>
            )}
            <EnvironmentLogs
                environment={environment}
                logsUrl={logsUrl}
                // We're checking for timestamps here as a way to filter out log lines that supervisor is not
                // intentionally emitting. This allows us to clean up the log output even for legacy runners
                filterLogLinesWithoutTimestamps={true}
            />
        </div>
    );
};
