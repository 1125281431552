// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/editor.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { PaginationRequest } from "./pagination_pb.js";

/**
 * @generated from message gitpod.v1.ListEditorsRequest
 */
export class ListEditorsRequest extends Message<ListEditorsRequest> {
  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  constructor(data?: PartialMessage<ListEditorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEditorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEditorsRequest {
    return new ListEditorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEditorsRequest {
    return new ListEditorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEditorsRequest {
    return new ListEditorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEditorsRequest | PlainMessage<ListEditorsRequest> | undefined, b: ListEditorsRequest | PlainMessage<ListEditorsRequest> | undefined): boolean {
    return proto3.util.equals(ListEditorsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListEditorsResponse
 */
export class ListEditorsResponse extends Message<ListEditorsResponse> {
  /**
   * editors contains the list of editors
   *
   * @generated from field: repeated gitpod.v1.Editor editors = 1;
   */
  editors: Editor[] = [];

  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 2;
   */
  pagination?: PaginationRequest;

  constructor(data?: PartialMessage<ListEditorsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListEditorsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "editors", kind: "message", T: Editor, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: PaginationRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEditorsResponse {
    return new ListEditorsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEditorsResponse {
    return new ListEditorsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEditorsResponse {
    return new ListEditorsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEditorsResponse | PlainMessage<ListEditorsResponse> | undefined, b: ListEditorsResponse | PlainMessage<ListEditorsResponse> | undefined): boolean {
    return proto3.util.equals(ListEditorsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetEditorRequest
 */
export class GetEditorRequest extends Message<GetEditorRequest> {
  /**
   * id is the ID of the editor to get
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetEditorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetEditorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEditorRequest {
    return new GetEditorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEditorRequest {
    return new GetEditorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEditorRequest {
    return new GetEditorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEditorRequest | PlainMessage<GetEditorRequest> | undefined, b: GetEditorRequest | PlainMessage<GetEditorRequest> | undefined): boolean {
    return proto3.util.equals(GetEditorRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetEditorResponse
 */
export class GetEditorResponse extends Message<GetEditorResponse> {
  /**
   * editor contains the editor
   *
   * @generated from field: gitpod.v1.Editor editor = 1;
   */
  editor?: Editor;

  constructor(data?: PartialMessage<GetEditorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetEditorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "editor", kind: "message", T: Editor },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEditorResponse {
    return new GetEditorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEditorResponse {
    return new GetEditorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEditorResponse {
    return new GetEditorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEditorResponse | PlainMessage<GetEditorResponse> | undefined, b: GetEditorResponse | PlainMessage<GetEditorResponse> | undefined): boolean {
    return proto3.util.equals(GetEditorResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ResolveEditorURLRequest
 */
export class ResolveEditorURLRequest extends Message<ResolveEditorURLRequest> {
  /**
   * editorId is the ID of the editor to resolve the URL for
   *
   * @generated from field: string editor_id = 1;
   */
  editorId = "";

  /**
   * environmentId is the ID of the environment to resolve the URL for
   *
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * organizationId is the ID of the organization to resolve the URL for
   *
   * @generated from field: string organization_id = 3;
   */
  organizationId = "";

  constructor(data?: PartialMessage<ResolveEditorURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ResolveEditorURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "editor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveEditorURLRequest {
    return new ResolveEditorURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveEditorURLRequest {
    return new ResolveEditorURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveEditorURLRequest {
    return new ResolveEditorURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveEditorURLRequest | PlainMessage<ResolveEditorURLRequest> | undefined, b: ResolveEditorURLRequest | PlainMessage<ResolveEditorURLRequest> | undefined): boolean {
    return proto3.util.equals(ResolveEditorURLRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ResolveEditorURLResponse
 */
export class ResolveEditorURLResponse extends Message<ResolveEditorURLResponse> {
  /**
   * url is the resolved editor URL
   *
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<ResolveEditorURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ResolveEditorURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResolveEditorURLResponse {
    return new ResolveEditorURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResolveEditorURLResponse {
    return new ResolveEditorURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResolveEditorURLResponse {
    return new ResolveEditorURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResolveEditorURLResponse | PlainMessage<ResolveEditorURLResponse> | undefined, b: ResolveEditorURLResponse | PlainMessage<ResolveEditorURLResponse> | undefined): boolean {
    return proto3.util.equals(ResolveEditorURLResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Editor
 */
export class Editor extends Message<Editor> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string short_description = 3;
   */
  shortDescription = "";

  /**
   * @generated from field: string installation_instructions = 4;
   */
  installationInstructions = "";

  /**
   * @generated from field: string icon_url = 5;
   */
  iconUrl = "";

  /**
   * @generated from field: string url_template = 6;
   */
  urlTemplate = "";

  constructor(data?: PartialMessage<Editor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Editor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "installation_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "url_template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Editor {
    return new Editor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Editor {
    return new Editor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Editor {
    return new Editor().fromJsonString(jsonString, options);
  }

  static equals(a: Editor | PlainMessage<Editor> | undefined, b: Editor | PlainMessage<Editor> | undefined): boolean {
    return proto3.util.equals(Editor, a, b);
  }
}

