// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/runner_interaction.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Any, Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { EnvironmentClass, EnvironmentClassValidationResult, FieldValueUpdate, RunnerConfigurationSchema, SCMIntegration, SCMIntegrationValidationResult } from "./runner_configuration_pb.js";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { EnvironmentMetadata, EnvironmentSpec, EnvironmentStatus } from "./environment_pb.js";
import { CheckAuthenticationForHostResponse, ParseContextURLResponse, RunnerCapability } from "./runner_pb.js";

/**
 * @generated from message gitpod.v1.SignupRequest
 */
export class SignupRequest extends Message<SignupRequest> {
  /**
   * The runner's public key. Must be an ECDH public key encoded in PKIX, ASN.1 DER format.
   *
   * @generated from field: bytes public_key = 1;
   */
  publicKey = new Uint8Array(0);

  /**
   * The environment classes this runner has to offer
   *
   * @generated from field: repeated gitpod.v1.EnvironmentClass environment_classes = 2;
   */
  environmentClasses: EnvironmentClass[] = [];

  constructor(data?: PartialMessage<SignupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SignupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "public_key", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "environment_classes", kind: "message", T: EnvironmentClass, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupRequest {
    return new SignupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignupRequest | PlainMessage<SignupRequest> | undefined, b: SignupRequest | PlainMessage<SignupRequest> | undefined): boolean {
    return proto3.util.equals(SignupRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SignupResponse
 */
export class SignupResponse extends Message<SignupResponse> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  constructor(data?: PartialMessage<SignupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SignupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupResponse {
    return new SignupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupResponse {
    return new SignupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupResponse {
    return new SignupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignupResponse | PlainMessage<SignupResponse> | undefined, b: SignupResponse | PlainMessage<SignupResponse> | undefined): boolean {
    return proto3.util.equals(SignupResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.MarkRunnerActiveRequest
 */
export class MarkRunnerActiveRequest extends Message<MarkRunnerActiveRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  constructor(data?: PartialMessage<MarkRunnerActiveRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.MarkRunnerActiveRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkRunnerActiveRequest {
    return new MarkRunnerActiveRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkRunnerActiveRequest {
    return new MarkRunnerActiveRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkRunnerActiveRequest {
    return new MarkRunnerActiveRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MarkRunnerActiveRequest | PlainMessage<MarkRunnerActiveRequest> | undefined, b: MarkRunnerActiveRequest | PlainMessage<MarkRunnerActiveRequest> | undefined): boolean {
    return proto3.util.equals(MarkRunnerActiveRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.MarkRunnerActiveResponse
 */
export class MarkRunnerActiveResponse extends Message<MarkRunnerActiveResponse> {
  constructor(data?: PartialMessage<MarkRunnerActiveResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.MarkRunnerActiveResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkRunnerActiveResponse {
    return new MarkRunnerActiveResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkRunnerActiveResponse {
    return new MarkRunnerActiveResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkRunnerActiveResponse {
    return new MarkRunnerActiveResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MarkRunnerActiveResponse | PlainMessage<MarkRunnerActiveResponse> | undefined, b: MarkRunnerActiveResponse | PlainMessage<MarkRunnerActiveResponse> | undefined): boolean {
    return proto3.util.equals(MarkRunnerActiveResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerEnvironmentsRequest
 */
export class ListRunnerEnvironmentsRequest extends Message<ListRunnerEnvironmentsRequest> {
  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * The runner's identifier
   *
   * @generated from field: string runner_id = 2;
   */
  runnerId = "";

  /**
   * An optional list of environment IDs to fetch. If this list is empty/not provided
   * all environments that ought to run on the runner are returned.
   *
   * @generated from field: repeated string environment_ids = 3;
   */
  environmentIds: string[] = [];

  constructor(data?: PartialMessage<ListRunnerEnvironmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerEnvironmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "environment_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerEnvironmentsRequest {
    return new ListRunnerEnvironmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentsRequest {
    return new ListRunnerEnvironmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentsRequest {
    return new ListRunnerEnvironmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerEnvironmentsRequest | PlainMessage<ListRunnerEnvironmentsRequest> | undefined, b: ListRunnerEnvironmentsRequest | PlainMessage<ListRunnerEnvironmentsRequest> | undefined): boolean {
    return proto3.util.equals(ListRunnerEnvironmentsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.RunnerEnvironment
 */
export class RunnerEnvironment extends Message<RunnerEnvironment> {
  /**
   * ID is a unique identifier of this environment. No other environment with the same name must be managed by this
   * environment manager
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Metadata is data associated with this environment that's required for other parts of Gitpod to function
   *
   * @generated from field: gitpod.v1.EnvironmentMetadata metadata = 2;
   */
  metadata?: EnvironmentMetadata;

  /**
   * Spec is the configuration of the environment that's required for the runner to start the environment
   *
   * @generated from field: gitpod.v1.EnvironmentSpec spec = 3;
   */
  spec?: EnvironmentSpec;

  /**
   * The environment's access token
   *
   * @generated from field: string environment_access_token = 4;
   */
  environmentAccessToken = "";

  constructor(data?: PartialMessage<RunnerEnvironment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.RunnerEnvironment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: EnvironmentMetadata },
    { no: 3, name: "spec", kind: "message", T: EnvironmentSpec },
    { no: 4, name: "environment_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunnerEnvironment {
    return new RunnerEnvironment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunnerEnvironment {
    return new RunnerEnvironment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunnerEnvironment {
    return new RunnerEnvironment().fromJsonString(jsonString, options);
  }

  static equals(a: RunnerEnvironment | PlainMessage<RunnerEnvironment> | undefined, b: RunnerEnvironment | PlainMessage<RunnerEnvironment> | undefined): boolean {
    return proto3.util.equals(RunnerEnvironment, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerEnvironmentsResponse
 */
export class ListRunnerEnvironmentsResponse extends Message<ListRunnerEnvironmentsResponse> {
  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * The environments running on the runner
   *
   * @generated from field: repeated gitpod.v1.RunnerEnvironment environments = 2;
   */
  environments: RunnerEnvironment[] = [];

  constructor(data?: PartialMessage<ListRunnerEnvironmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerEnvironmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "environments", kind: "message", T: RunnerEnvironment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerEnvironmentsResponse {
    return new ListRunnerEnvironmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentsResponse {
    return new ListRunnerEnvironmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentsResponse {
    return new ListRunnerEnvironmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerEnvironmentsResponse | PlainMessage<ListRunnerEnvironmentsResponse> | undefined, b: ListRunnerEnvironmentsResponse | PlainMessage<ListRunnerEnvironmentsResponse> | undefined): boolean {
    return proto3.util.equals(ListRunnerEnvironmentsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetRunnerEnvironmentRequest
 */
export class GetRunnerEnvironmentRequest extends Message<GetRunnerEnvironmentRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * The environment's ID
   *
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  constructor(data?: PartialMessage<GetRunnerEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetRunnerEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRunnerEnvironmentRequest {
    return new GetRunnerEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRunnerEnvironmentRequest {
    return new GetRunnerEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRunnerEnvironmentRequest {
    return new GetRunnerEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRunnerEnvironmentRequest | PlainMessage<GetRunnerEnvironmentRequest> | undefined, b: GetRunnerEnvironmentRequest | PlainMessage<GetRunnerEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(GetRunnerEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetRunnerEnvironmentResponse
 */
export class GetRunnerEnvironmentResponse extends Message<GetRunnerEnvironmentResponse> {
  /**
   * @generated from field: gitpod.v1.RunnerEnvironment environment = 1;
   */
  environment?: RunnerEnvironment;

  constructor(data?: PartialMessage<GetRunnerEnvironmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetRunnerEnvironmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment", kind: "message", T: RunnerEnvironment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRunnerEnvironmentResponse {
    return new GetRunnerEnvironmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRunnerEnvironmentResponse {
    return new GetRunnerEnvironmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRunnerEnvironmentResponse {
    return new GetRunnerEnvironmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRunnerEnvironmentResponse | PlainMessage<GetRunnerEnvironmentResponse> | undefined, b: GetRunnerEnvironmentResponse | PlainMessage<GetRunnerEnvironmentResponse> | undefined): boolean {
    return proto3.util.equals(GetRunnerEnvironmentResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerEnvironmentStatusRequest
 */
export class UpdateRunnerEnvironmentStatusRequest extends Message<UpdateRunnerEnvironmentStatusRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * The environment's ID
   *
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * The environment's status
   *
   * @generated from field: gitpod.v1.EnvironmentStatus status = 3;
   */
  status?: EnvironmentStatus;

  constructor(data?: PartialMessage<UpdateRunnerEnvironmentStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerEnvironmentStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "message", T: EnvironmentStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerEnvironmentStatusRequest {
    return new UpdateRunnerEnvironmentStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerEnvironmentStatusRequest {
    return new UpdateRunnerEnvironmentStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerEnvironmentStatusRequest {
    return new UpdateRunnerEnvironmentStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerEnvironmentStatusRequest | PlainMessage<UpdateRunnerEnvironmentStatusRequest> | undefined, b: UpdateRunnerEnvironmentStatusRequest | PlainMessage<UpdateRunnerEnvironmentStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerEnvironmentStatusRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerEnvironmentStatusResponse
 */
export class UpdateRunnerEnvironmentStatusResponse extends Message<UpdateRunnerEnvironmentStatusResponse> {
  constructor(data?: PartialMessage<UpdateRunnerEnvironmentStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerEnvironmentStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerEnvironmentStatusResponse {
    return new UpdateRunnerEnvironmentStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerEnvironmentStatusResponse {
    return new UpdateRunnerEnvironmentStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerEnvironmentStatusResponse {
    return new UpdateRunnerEnvironmentStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerEnvironmentStatusResponse | PlainMessage<UpdateRunnerEnvironmentStatusResponse> | undefined, b: UpdateRunnerEnvironmentStatusResponse | PlainMessage<UpdateRunnerEnvironmentStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerEnvironmentStatusResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerStatusRequest
 */
export class UpdateRunnerStatusRequest extends Message<UpdateRunnerStatusRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * version is the version of the runner. This is used to detect if the runner is outdated.
   *
   * @generated from field: optional string version = 2;
   */
  version?: string;

  /**
   * system_details is a runner specific system detail string. Think of this like a user agent string.
   * It's intended to be used for debugging and support purposes and might be shown to the user.
   *
   * @generated from field: optional string system_details = 4;
   */
  systemDetails?: string;

  /**
   * log_url is the URL to the runner's logs
   *
   * @generated from field: optional string log_url = 5;
   */
  logUrl?: string;

  /**
   * Degredation message is an optional message that is shown to users when the runner is in a degraded state.
   * Setting this to "" changes the runner's phase back from "degraded" to "active".
   *
   * @generated from field: optional string degredation_message = 6;
   */
  degredationMessage?: string;

  /**
   * region is the region the runner is running in, if applicable.
   *
   * @generated from field: optional string region = 7;
   */
  region?: string;

  /**
   * additional_info updates fields in the runner's additional_info.
   *
   * @generated from field: repeated gitpod.v1.FieldValueUpdate additional_info = 8;
   */
  additionalInfo: FieldValueUpdate[] = [];

  /**
   * capabilities contains the runner's supported capabilities.
   * Optional field, only overwrites previous capabilities if set to a non-empty list.
   * To clear capabilities, set this to a list with a single UNSPECIFIED capability.
   *
   * @generated from field: repeated gitpod.v1.RunnerCapability capabilities = 9;
   */
  capabilities: RunnerCapability[] = [];

  constructor(data?: PartialMessage<UpdateRunnerStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "system_details", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "log_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "degredation_message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "additional_info", kind: "message", T: FieldValueUpdate, repeated: true },
    { no: 9, name: "capabilities", kind: "enum", T: proto3.getEnumType(RunnerCapability), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerStatusRequest {
    return new UpdateRunnerStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerStatusRequest {
    return new UpdateRunnerStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerStatusRequest {
    return new UpdateRunnerStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerStatusRequest | PlainMessage<UpdateRunnerStatusRequest> | undefined, b: UpdateRunnerStatusRequest | PlainMessage<UpdateRunnerStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerStatusRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerStatusResponse
 */
export class UpdateRunnerStatusResponse extends Message<UpdateRunnerStatusResponse> {
  constructor(data?: PartialMessage<UpdateRunnerStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerStatusResponse {
    return new UpdateRunnerStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerStatusResponse {
    return new UpdateRunnerStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerStatusResponse {
    return new UpdateRunnerStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerStatusResponse | PlainMessage<UpdateRunnerStatusResponse> | undefined, b: UpdateRunnerStatusResponse | PlainMessage<UpdateRunnerStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerStatusResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerConfigurationSchemaRequest
 */
export class UpdateRunnerConfigurationSchemaRequest extends Message<UpdateRunnerConfigurationSchemaRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * config_schema is the schema for the runner's configuration
   *
   * @generated from field: gitpod.v1.RunnerConfigurationSchema config_schema = 2;
   */
  configSchema?: RunnerConfigurationSchema;

  constructor(data?: PartialMessage<UpdateRunnerConfigurationSchemaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerConfigurationSchemaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "config_schema", kind: "message", T: RunnerConfigurationSchema },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerConfigurationSchemaRequest {
    return new UpdateRunnerConfigurationSchemaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerConfigurationSchemaRequest {
    return new UpdateRunnerConfigurationSchemaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerConfigurationSchemaRequest {
    return new UpdateRunnerConfigurationSchemaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerConfigurationSchemaRequest | PlainMessage<UpdateRunnerConfigurationSchemaRequest> | undefined, b: UpdateRunnerConfigurationSchemaRequest | PlainMessage<UpdateRunnerConfigurationSchemaRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerConfigurationSchemaRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateRunnerConfigurationSchemaResponse
 */
export class UpdateRunnerConfigurationSchemaResponse extends Message<UpdateRunnerConfigurationSchemaResponse> {
  constructor(data?: PartialMessage<UpdateRunnerConfigurationSchemaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateRunnerConfigurationSchemaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRunnerConfigurationSchemaResponse {
    return new UpdateRunnerConfigurationSchemaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRunnerConfigurationSchemaResponse {
    return new UpdateRunnerConfigurationSchemaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRunnerConfigurationSchemaResponse {
    return new UpdateRunnerConfigurationSchemaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRunnerConfigurationSchemaResponse | PlainMessage<UpdateRunnerConfigurationSchemaResponse> | undefined, b: UpdateRunnerConfigurationSchemaResponse | PlainMessage<UpdateRunnerConfigurationSchemaResponse> | undefined): boolean {
    return proto3.util.equals(UpdateRunnerConfigurationSchemaResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetHostAuthenticationTokenValueRequest
 */
export class GetHostAuthenticationTokenValueRequest extends Message<GetHostAuthenticationTokenValueRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * The principal's ID to get the authentication token for
   *
   * @generated from field: string principal_id = 2;
   */
  principalId = "";

  /**
   * The host to get the authentication token for
   *
   * @generated from field: string host = 3;
   */
  host = "";

  constructor(data?: PartialMessage<GetHostAuthenticationTokenValueRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetHostAuthenticationTokenValueRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHostAuthenticationTokenValueRequest {
    return new GetHostAuthenticationTokenValueRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenValueRequest {
    return new GetHostAuthenticationTokenValueRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenValueRequest {
    return new GetHostAuthenticationTokenValueRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetHostAuthenticationTokenValueRequest | PlainMessage<GetHostAuthenticationTokenValueRequest> | undefined, b: GetHostAuthenticationTokenValueRequest | PlainMessage<GetHostAuthenticationTokenValueRequest> | undefined): boolean {
    return proto3.util.equals(GetHostAuthenticationTokenValueRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetHostAuthenticationTokenValueResponse
 */
export class GetHostAuthenticationTokenValueResponse extends Message<GetHostAuthenticationTokenValueResponse> {
  /**
   * The authentication token encrypted as NaCL anonymous sealed box using the runner's public key
   *
   * @generated from field: bytes value = 1;
   */
  value = new Uint8Array(0);

  /**
   * The host authentication token's ID
   *
   * @generated from field: string token_id = 2;
   */
  tokenId = "";

  /**
   * The host authentication token's expiry
   *
   * @generated from field: google.protobuf.Timestamp expires_at = 3;
   */
  expiresAt?: Timestamp;

  /**
   * The host authentication token's refresh token encrypted as NaCL anonymous sealed box using the runner's public key
   *
   * @generated from field: bytes refresh_token = 4;
   */
  refreshToken = new Uint8Array(0);

  constructor(data?: PartialMessage<GetHostAuthenticationTokenValueResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetHostAuthenticationTokenValueResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "token_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expires_at", kind: "message", T: Timestamp },
    { no: 4, name: "refresh_token", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHostAuthenticationTokenValueResponse {
    return new GetHostAuthenticationTokenValueResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenValueResponse {
    return new GetHostAuthenticationTokenValueResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHostAuthenticationTokenValueResponse {
    return new GetHostAuthenticationTokenValueResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetHostAuthenticationTokenValueResponse | PlainMessage<GetHostAuthenticationTokenValueResponse> | undefined, b: GetHostAuthenticationTokenValueResponse | PlainMessage<GetHostAuthenticationTokenValueResponse> | undefined): boolean {
    return proto3.util.equals(GetHostAuthenticationTokenValueResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerEnvironmentClassesRequest
 */
export class ListRunnerEnvironmentClassesRequest extends Message<ListRunnerEnvironmentClassesRequest> {
  /**
   * pagination contains the pagination options for listing environment classes
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 2;
   */
  runnerId = "";

  /**
   * @generated from field: gitpod.v1.ListRunnerEnvironmentClassesRequest.Filter filter = 3;
   */
  filter?: ListRunnerEnvironmentClassesRequest_Filter;

  constructor(data?: PartialMessage<ListRunnerEnvironmentClassesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerEnvironmentClassesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filter", kind: "message", T: ListRunnerEnvironmentClassesRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerEnvironmentClassesRequest {
    return new ListRunnerEnvironmentClassesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentClassesRequest {
    return new ListRunnerEnvironmentClassesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentClassesRequest {
    return new ListRunnerEnvironmentClassesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerEnvironmentClassesRequest | PlainMessage<ListRunnerEnvironmentClassesRequest> | undefined, b: ListRunnerEnvironmentClassesRequest | PlainMessage<ListRunnerEnvironmentClassesRequest> | undefined): boolean {
    return proto3.util.equals(ListRunnerEnvironmentClassesRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerEnvironmentClassesRequest.Filter
 */
export class ListRunnerEnvironmentClassesRequest_Filter extends Message<ListRunnerEnvironmentClassesRequest_Filter> {
  /**
   * environment_class_ids filters the response to only environment classes with these IDs
   *
   * @generated from field: repeated string environment_class_ids = 1;
   */
  environmentClassIds: string[] = [];

  constructor(data?: PartialMessage<ListRunnerEnvironmentClassesRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerEnvironmentClassesRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_class_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerEnvironmentClassesRequest_Filter {
    return new ListRunnerEnvironmentClassesRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentClassesRequest_Filter {
    return new ListRunnerEnvironmentClassesRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentClassesRequest_Filter {
    return new ListRunnerEnvironmentClassesRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerEnvironmentClassesRequest_Filter | PlainMessage<ListRunnerEnvironmentClassesRequest_Filter> | undefined, b: ListRunnerEnvironmentClassesRequest_Filter | PlainMessage<ListRunnerEnvironmentClassesRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListRunnerEnvironmentClassesRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerEnvironmentClassesResponse
 */
export class ListRunnerEnvironmentClassesResponse extends Message<ListRunnerEnvironmentClassesResponse> {
  /**
   * pagination contains the pagination options for listing environment classes
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * The environment classes configured for the runner
   *
   * @generated from field: repeated gitpod.v1.EnvironmentClass environment_classes = 2;
   */
  environmentClasses: EnvironmentClass[] = [];

  constructor(data?: PartialMessage<ListRunnerEnvironmentClassesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerEnvironmentClassesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "environment_classes", kind: "message", T: EnvironmentClass, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerEnvironmentClassesResponse {
    return new ListRunnerEnvironmentClassesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentClassesResponse {
    return new ListRunnerEnvironmentClassesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerEnvironmentClassesResponse {
    return new ListRunnerEnvironmentClassesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerEnvironmentClassesResponse | PlainMessage<ListRunnerEnvironmentClassesResponse> | undefined, b: ListRunnerEnvironmentClassesResponse | PlainMessage<ListRunnerEnvironmentClassesResponse> | undefined): boolean {
    return proto3.util.equals(ListRunnerEnvironmentClassesResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerSCMIntegrationsRequest
 */
export class ListRunnerSCMIntegrationsRequest extends Message<ListRunnerSCMIntegrationsRequest> {
  /**
   * pagination contains the pagination options for listing SCM integrations
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 2;
   */
  runnerId = "";

  /**
   * @generated from field: gitpod.v1.ListRunnerSCMIntegrationsRequest.Filter filter = 3;
   */
  filter?: ListRunnerSCMIntegrationsRequest_Filter;

  constructor(data?: PartialMessage<ListRunnerSCMIntegrationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerSCMIntegrationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filter", kind: "message", T: ListRunnerSCMIntegrationsRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerSCMIntegrationsRequest {
    return new ListRunnerSCMIntegrationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerSCMIntegrationsRequest {
    return new ListRunnerSCMIntegrationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerSCMIntegrationsRequest {
    return new ListRunnerSCMIntegrationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerSCMIntegrationsRequest | PlainMessage<ListRunnerSCMIntegrationsRequest> | undefined, b: ListRunnerSCMIntegrationsRequest | PlainMessage<ListRunnerSCMIntegrationsRequest> | undefined): boolean {
    return proto3.util.equals(ListRunnerSCMIntegrationsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerSCMIntegrationsRequest.Filter
 */
export class ListRunnerSCMIntegrationsRequest_Filter extends Message<ListRunnerSCMIntegrationsRequest_Filter> {
  /**
   * environment_class_ids filters the response to only SCM integrations with these IDs
   *
   * @generated from field: repeated string scm_integration_ids = 1;
   */
  scmIntegrationIds: string[] = [];

  constructor(data?: PartialMessage<ListRunnerSCMIntegrationsRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerSCMIntegrationsRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scm_integration_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerSCMIntegrationsRequest_Filter {
    return new ListRunnerSCMIntegrationsRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerSCMIntegrationsRequest_Filter {
    return new ListRunnerSCMIntegrationsRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerSCMIntegrationsRequest_Filter {
    return new ListRunnerSCMIntegrationsRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerSCMIntegrationsRequest_Filter | PlainMessage<ListRunnerSCMIntegrationsRequest_Filter> | undefined, b: ListRunnerSCMIntegrationsRequest_Filter | PlainMessage<ListRunnerSCMIntegrationsRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListRunnerSCMIntegrationsRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListRunnerSCMIntegrationsResponse
 */
export class ListRunnerSCMIntegrationsResponse extends Message<ListRunnerSCMIntegrationsResponse> {
  /**
   * pagination contains the pagination options for listing SCM integrations
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * The SCM integrations configured for the runner
   *
   * @generated from field: repeated gitpod.v1.SCMIntegration scm_integrations = 2;
   */
  scmIntegrations: SCMIntegration[] = [];

  constructor(data?: PartialMessage<ListRunnerSCMIntegrationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListRunnerSCMIntegrationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "scm_integrations", kind: "message", T: SCMIntegration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRunnerSCMIntegrationsResponse {
    return new ListRunnerSCMIntegrationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRunnerSCMIntegrationsResponse {
    return new ListRunnerSCMIntegrationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRunnerSCMIntegrationsResponse {
    return new ListRunnerSCMIntegrationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRunnerSCMIntegrationsResponse | PlainMessage<ListRunnerSCMIntegrationsResponse> | undefined, b: ListRunnerSCMIntegrationsResponse | PlainMessage<ListRunnerSCMIntegrationsResponse> | undefined): boolean {
    return proto3.util.equals(ListRunnerSCMIntegrationsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.WatchRequestsRequest
 */
export class WatchRequestsRequest extends Message<WatchRequestsRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  constructor(data?: PartialMessage<WatchRequestsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.WatchRequestsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WatchRequestsRequest {
    return new WatchRequestsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WatchRequestsRequest {
    return new WatchRequestsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WatchRequestsRequest {
    return new WatchRequestsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WatchRequestsRequest | PlainMessage<WatchRequestsRequest> | undefined, b: WatchRequestsRequest | PlainMessage<WatchRequestsRequest> | undefined): boolean {
    return proto3.util.equals(WatchRequestsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.WatchRequestsResponse
 */
export class WatchRequestsResponse extends Message<WatchRequestsResponse> {
  /**
   * The request's ID
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * The request to handle
   *
   * @generated from oneof gitpod.v1.WatchRequestsResponse.request
   */
  request: {
    /**
     * @generated from field: gitpod.v1.EventEnvironmentSpecChange event_environment_spec_change = 10;
     */
    value: EventEnvironmentSpecChange;
    case: "eventEnvironmentSpecChange";
  } | {
    /**
     * @generated from field: gitpod.v1.EventEnvironmentMarkedActive event_environment_marked_active = 11;
     */
    value: EventEnvironmentMarkedActive;
    case: "eventEnvironmentMarkedActive";
  } | {
    /**
     * @generated from field: gitpod.v1.EventSCMIntegrationChange event_scm_integration_change = 12;
     */
    value: EventSCMIntegrationChange;
    case: "eventScmIntegrationChange";
  } | {
    /**
     * @generated from field: gitpod.v1.EventHostAuthenticationTokenDeleted event_host_authentication_token_deleted = 13;
     */
    value: EventHostAuthenticationTokenDeleted;
    case: "eventHostAuthenticationTokenDeleted";
  } | {
    /**
     * @generated from field: gitpod.v1.CallParseContext.Request call_parse_context = 50;
     */
    value: CallParseContext_Request;
    case: "callParseContext";
  } | {
    /**
     * @generated from field: gitpod.v1.CallPing.Request call_ping = 51;
     */
    value: CallPing_Request;
    case: "callPing";
  } | {
    /**
     * @generated from field: gitpod.v1.CallCheckAuthenticationForHost.Request call_check_authentication_for_host = 52;
     */
    value: CallCheckAuthenticationForHost_Request;
    case: "callCheckAuthenticationForHost";
  } | {
    /**
     * @generated from field: gitpod.v1.CallValidateConfig.Request call_validate_config = 53;
     */
    value: CallValidateConfig_Request;
    case: "callValidateConfig";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<WatchRequestsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.WatchRequestsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "event_environment_spec_change", kind: "message", T: EventEnvironmentSpecChange, oneof: "request" },
    { no: 11, name: "event_environment_marked_active", kind: "message", T: EventEnvironmentMarkedActive, oneof: "request" },
    { no: 12, name: "event_scm_integration_change", kind: "message", T: EventSCMIntegrationChange, oneof: "request" },
    { no: 13, name: "event_host_authentication_token_deleted", kind: "message", T: EventHostAuthenticationTokenDeleted, oneof: "request" },
    { no: 50, name: "call_parse_context", kind: "message", T: CallParseContext_Request, oneof: "request" },
    { no: 51, name: "call_ping", kind: "message", T: CallPing_Request, oneof: "request" },
    { no: 52, name: "call_check_authentication_for_host", kind: "message", T: CallCheckAuthenticationForHost_Request, oneof: "request" },
    { no: 53, name: "call_validate_config", kind: "message", T: CallValidateConfig_Request, oneof: "request" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WatchRequestsResponse {
    return new WatchRequestsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WatchRequestsResponse {
    return new WatchRequestsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WatchRequestsResponse {
    return new WatchRequestsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WatchRequestsResponse | PlainMessage<WatchRequestsResponse> | undefined, b: WatchRequestsResponse | PlainMessage<WatchRequestsResponse> | undefined): boolean {
    return proto3.util.equals(WatchRequestsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SendResponseRequest
 */
export class SendResponseRequest extends Message<SendResponseRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * The request's ID we're responding to
   *
   * @generated from field: string request_id = 2;
   */
  requestId = "";

  /**
   * The response to send
   *
   * @generated from oneof gitpod.v1.SendResponseRequest.response
   */
  response: {
    /**
     * @generated from field: gitpod.v1.SendResponseRequest.CallError error = 5;
     */
    value: SendResponseRequest_CallError;
    case: "error";
  } | {
    /**
     * @generated from field: gitpod.v1.CallParseContext.Response call_parse_context = 50;
     */
    value: CallParseContext_Response;
    case: "callParseContext";
  } | {
    /**
     * @generated from field: gitpod.v1.CallPing.Response call_ping = 51;
     */
    value: CallPing_Response;
    case: "callPing";
  } | {
    /**
     * @generated from field: gitpod.v1.CallCheckAuthenticationForHost.Response call_check_authentication_for_host = 52;
     */
    value: CallCheckAuthenticationForHost_Response;
    case: "callCheckAuthenticationForHost";
  } | {
    /**
     * @generated from field: gitpod.v1.CallValidateConfig.Response call_validate_config = 53;
     */
    value: CallValidateConfig_Response;
    case: "callValidateConfig";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SendResponseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SendResponseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "error", kind: "message", T: SendResponseRequest_CallError, oneof: "response" },
    { no: 50, name: "call_parse_context", kind: "message", T: CallParseContext_Response, oneof: "response" },
    { no: 51, name: "call_ping", kind: "message", T: CallPing_Response, oneof: "response" },
    { no: 52, name: "call_check_authentication_for_host", kind: "message", T: CallCheckAuthenticationForHost_Response, oneof: "response" },
    { no: 53, name: "call_validate_config", kind: "message", T: CallValidateConfig_Response, oneof: "response" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendResponseRequest {
    return new SendResponseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendResponseRequest {
    return new SendResponseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendResponseRequest {
    return new SendResponseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendResponseRequest | PlainMessage<SendResponseRequest> | undefined, b: SendResponseRequest | PlainMessage<SendResponseRequest> | undefined): boolean {
    return proto3.util.equals(SendResponseRequest, a, b);
  }
}

/**
 * @generated from enum gitpod.v1.SendResponseRequest.ErrorCode
 */
export enum SendResponseRequest_ErrorCode {
  /**
   * @generated from enum value: ERROR_CODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ERROR_CODE_CANCELED = 1;
   */
  CANCELED = 1,

  /**
   * @generated from enum value: ERROR_CODE_INVALID_ARGUMENT = 3;
   */
  INVALID_ARGUMENT = 3,

  /**
   * @generated from enum value: ERROR_CODE_DEADLINE_EXCEEDED = 4;
   */
  DEADLINE_EXCEEDED = 4,

  /**
   * @generated from enum value: ERROR_CODE_NOT_FOUND = 5;
   */
  NOT_FOUND = 5,

  /**
   * @generated from enum value: ERROR_CODE_ALREADY_EXISTS = 6;
   */
  ALREADY_EXISTS = 6,

  /**
   * @generated from enum value: ERROR_CODE_PERMISSION_DENIED = 7;
   */
  PERMISSION_DENIED = 7,

  /**
   * @generated from enum value: ERROR_CODE_RESOURCE_EXHAUSTED = 8;
   */
  RESOURCE_EXHAUSTED = 8,

  /**
   * @generated from enum value: ERROR_CODE_FAILED_PRECONDITION = 9;
   */
  FAILED_PRECONDITION = 9,

  /**
   * @generated from enum value: ERROR_CODE_UNIMPLEMENTED = 12;
   */
  UNIMPLEMENTED = 12,

  /**
   * @generated from enum value: ERROR_CODE_UNAVAILABLE = 14;
   */
  UNAVAILABLE = 14,

  /**
   * @generated from enum value: ERROR_CODE_UNAUTHENTICATED = 16;
   */
  UNAUTHENTICATED = 16,
}
// Retrieve enum metadata with: proto3.getEnumType(SendResponseRequest_ErrorCode)
proto3.util.setEnumType(SendResponseRequest_ErrorCode, "gitpod.v1.SendResponseRequest.ErrorCode", [
  { no: 0, name: "ERROR_CODE_UNSPECIFIED" },
  { no: 1, name: "ERROR_CODE_CANCELED" },
  { no: 3, name: "ERROR_CODE_INVALID_ARGUMENT" },
  { no: 4, name: "ERROR_CODE_DEADLINE_EXCEEDED" },
  { no: 5, name: "ERROR_CODE_NOT_FOUND" },
  { no: 6, name: "ERROR_CODE_ALREADY_EXISTS" },
  { no: 7, name: "ERROR_CODE_PERMISSION_DENIED" },
  { no: 8, name: "ERROR_CODE_RESOURCE_EXHAUSTED" },
  { no: 9, name: "ERROR_CODE_FAILED_PRECONDITION" },
  { no: 12, name: "ERROR_CODE_UNIMPLEMENTED" },
  { no: 14, name: "ERROR_CODE_UNAVAILABLE" },
  { no: 16, name: "ERROR_CODE_UNAUTHENTICATED" },
]);

/**
 * @generated from message gitpod.v1.SendResponseRequest.CallError
 */
export class SendResponseRequest_CallError extends Message<SendResponseRequest_CallError> {
  /**
   * The error's code
   *
   * @generated from field: gitpod.v1.SendResponseRequest.ErrorCode code = 1;
   */
  code = SendResponseRequest_ErrorCode.UNSPECIFIED;

  /**
   * The error's message
   *
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * Error details
   *
   * @generated from field: repeated google.protobuf.Any details = 3;
   */
  details: Any[] = [];

  constructor(data?: PartialMessage<SendResponseRequest_CallError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SendResponseRequest.CallError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "enum", T: proto3.getEnumType(SendResponseRequest_ErrorCode) },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "details", kind: "message", T: Any, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendResponseRequest_CallError {
    return new SendResponseRequest_CallError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendResponseRequest_CallError {
    return new SendResponseRequest_CallError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendResponseRequest_CallError {
    return new SendResponseRequest_CallError().fromJsonString(jsonString, options);
  }

  static equals(a: SendResponseRequest_CallError | PlainMessage<SendResponseRequest_CallError> | undefined, b: SendResponseRequest_CallError | PlainMessage<SendResponseRequest_CallError> | undefined): boolean {
    return proto3.util.equals(SendResponseRequest_CallError, a, b);
  }
}

/**
 * @generated from message gitpod.v1.SendResponseResponse
 */
export class SendResponseResponse extends Message<SendResponseResponse> {
  constructor(data?: PartialMessage<SendResponseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.SendResponseResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendResponseResponse {
    return new SendResponseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendResponseResponse {
    return new SendResponseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendResponseResponse {
    return new SendResponseResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendResponseResponse | PlainMessage<SendResponseResponse> | undefined, b: SendResponseResponse | PlainMessage<SendResponseResponse> | undefined): boolean {
    return proto3.util.equals(SendResponseResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EventEnvironmentSpecChange
 */
export class EventEnvironmentSpecChange extends Message<EventEnvironmentSpecChange> {
  /**
   * The environment's ID
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<EventEnvironmentSpecChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EventEnvironmentSpecChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventEnvironmentSpecChange {
    return new EventEnvironmentSpecChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventEnvironmentSpecChange {
    return new EventEnvironmentSpecChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventEnvironmentSpecChange {
    return new EventEnvironmentSpecChange().fromJsonString(jsonString, options);
  }

  static equals(a: EventEnvironmentSpecChange | PlainMessage<EventEnvironmentSpecChange> | undefined, b: EventEnvironmentSpecChange | PlainMessage<EventEnvironmentSpecChange> | undefined): boolean {
    return proto3.util.equals(EventEnvironmentSpecChange, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EventEnvironmentMarkedActive
 */
export class EventEnvironmentMarkedActive extends Message<EventEnvironmentMarkedActive> {
  /**
   * The environment's ID
   *
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<EventEnvironmentMarkedActive>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EventEnvironmentMarkedActive";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventEnvironmentMarkedActive {
    return new EventEnvironmentMarkedActive().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventEnvironmentMarkedActive {
    return new EventEnvironmentMarkedActive().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventEnvironmentMarkedActive {
    return new EventEnvironmentMarkedActive().fromJsonString(jsonString, options);
  }

  static equals(a: EventEnvironmentMarkedActive | PlainMessage<EventEnvironmentMarkedActive> | undefined, b: EventEnvironmentMarkedActive | PlainMessage<EventEnvironmentMarkedActive> | undefined): boolean {
    return proto3.util.equals(EventEnvironmentMarkedActive, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EventSCMIntegrationChange
 */
export class EventSCMIntegrationChange extends Message<EventSCMIntegrationChange> {
  /**
   * The SCM integration's ID
   *
   * @generated from field: string scm_integration_id = 1;
   */
  scmIntegrationId = "";

  constructor(data?: PartialMessage<EventSCMIntegrationChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EventSCMIntegrationChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scm_integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventSCMIntegrationChange {
    return new EventSCMIntegrationChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventSCMIntegrationChange {
    return new EventSCMIntegrationChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventSCMIntegrationChange {
    return new EventSCMIntegrationChange().fromJsonString(jsonString, options);
  }

  static equals(a: EventSCMIntegrationChange | PlainMessage<EventSCMIntegrationChange> | undefined, b: EventSCMIntegrationChange | PlainMessage<EventSCMIntegrationChange> | undefined): boolean {
    return proto3.util.equals(EventSCMIntegrationChange, a, b);
  }
}

/**
 * @generated from message gitpod.v1.EventHostAuthenticationTokenDeleted
 */
export class EventHostAuthenticationTokenDeleted extends Message<EventHostAuthenticationTokenDeleted> {
  /**
   * The host authentication token's host.
   *
   * @generated from field: string host = 1;
   */
  host = "";

  /**
   * The principal ID of the deleted token.
   *
   * @generated from field: string principal_id = 2;
   */
  principalId = "";

  constructor(data?: PartialMessage<EventHostAuthenticationTokenDeleted>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.EventHostAuthenticationTokenDeleted";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventHostAuthenticationTokenDeleted {
    return new EventHostAuthenticationTokenDeleted().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventHostAuthenticationTokenDeleted {
    return new EventHostAuthenticationTokenDeleted().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventHostAuthenticationTokenDeleted {
    return new EventHostAuthenticationTokenDeleted().fromJsonString(jsonString, options);
  }

  static equals(a: EventHostAuthenticationTokenDeleted | PlainMessage<EventHostAuthenticationTokenDeleted> | undefined, b: EventHostAuthenticationTokenDeleted | PlainMessage<EventHostAuthenticationTokenDeleted> | undefined): boolean {
    return proto3.util.equals(EventHostAuthenticationTokenDeleted, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallParseContext
 */
export class CallParseContext extends Message<CallParseContext> {
  constructor(data?: PartialMessage<CallParseContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallParseContext";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallParseContext {
    return new CallParseContext().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallParseContext {
    return new CallParseContext().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallParseContext {
    return new CallParseContext().fromJsonString(jsonString, options);
  }

  static equals(a: CallParseContext | PlainMessage<CallParseContext> | undefined, b: CallParseContext | PlainMessage<CallParseContext> | undefined): boolean {
    return proto3.util.equals(CallParseContext, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallParseContext.Request
 */
export class CallParseContext_Request extends Message<CallParseContext_Request> {
  /**
   * @generated from field: string context_url = 1;
   */
  contextUrl = "";

  /**
   * @generated from field: string principal_id = 2;
   */
  principalId = "";

  constructor(data?: PartialMessage<CallParseContext_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallParseContext.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "context_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallParseContext_Request {
    return new CallParseContext_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallParseContext_Request {
    return new CallParseContext_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallParseContext_Request {
    return new CallParseContext_Request().fromJsonString(jsonString, options);
  }

  static equals(a: CallParseContext_Request | PlainMessage<CallParseContext_Request> | undefined, b: CallParseContext_Request | PlainMessage<CallParseContext_Request> | undefined): boolean {
    return proto3.util.equals(CallParseContext_Request, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallParseContext.Response
 */
export class CallParseContext_Response extends Message<CallParseContext_Response> {
  /**
   * @generated from field: gitpod.v1.ParseContextURLResponse resp = 1;
   */
  resp?: ParseContextURLResponse;

  constructor(data?: PartialMessage<CallParseContext_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallParseContext.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resp", kind: "message", T: ParseContextURLResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallParseContext_Response {
    return new CallParseContext_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallParseContext_Response {
    return new CallParseContext_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallParseContext_Response {
    return new CallParseContext_Response().fromJsonString(jsonString, options);
  }

  static equals(a: CallParseContext_Response | PlainMessage<CallParseContext_Response> | undefined, b: CallParseContext_Response | PlainMessage<CallParseContext_Response> | undefined): boolean {
    return proto3.util.equals(CallParseContext_Response, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallPing
 */
export class CallPing extends Message<CallPing> {
  constructor(data?: PartialMessage<CallPing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallPing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallPing {
    return new CallPing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallPing {
    return new CallPing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallPing {
    return new CallPing().fromJsonString(jsonString, options);
  }

  static equals(a: CallPing | PlainMessage<CallPing> | undefined, b: CallPing | PlainMessage<CallPing> | undefined): boolean {
    return proto3.util.equals(CallPing, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallPing.Request
 */
export class CallPing_Request extends Message<CallPing_Request> {
  constructor(data?: PartialMessage<CallPing_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallPing.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallPing_Request {
    return new CallPing_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallPing_Request {
    return new CallPing_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallPing_Request {
    return new CallPing_Request().fromJsonString(jsonString, options);
  }

  static equals(a: CallPing_Request | PlainMessage<CallPing_Request> | undefined, b: CallPing_Request | PlainMessage<CallPing_Request> | undefined): boolean {
    return proto3.util.equals(CallPing_Request, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallPing.Response
 */
export class CallPing_Response extends Message<CallPing_Response> {
  constructor(data?: PartialMessage<CallPing_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallPing.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallPing_Response {
    return new CallPing_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallPing_Response {
    return new CallPing_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallPing_Response {
    return new CallPing_Response().fromJsonString(jsonString, options);
  }

  static equals(a: CallPing_Response | PlainMessage<CallPing_Response> | undefined, b: CallPing_Response | PlainMessage<CallPing_Response> | undefined): boolean {
    return proto3.util.equals(CallPing_Response, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallCheckAuthenticationForHost
 */
export class CallCheckAuthenticationForHost extends Message<CallCheckAuthenticationForHost> {
  constructor(data?: PartialMessage<CallCheckAuthenticationForHost>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallCheckAuthenticationForHost";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallCheckAuthenticationForHost {
    return new CallCheckAuthenticationForHost().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallCheckAuthenticationForHost {
    return new CallCheckAuthenticationForHost().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallCheckAuthenticationForHost {
    return new CallCheckAuthenticationForHost().fromJsonString(jsonString, options);
  }

  static equals(a: CallCheckAuthenticationForHost | PlainMessage<CallCheckAuthenticationForHost> | undefined, b: CallCheckAuthenticationForHost | PlainMessage<CallCheckAuthenticationForHost> | undefined): boolean {
    return proto3.util.equals(CallCheckAuthenticationForHost, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallCheckAuthenticationForHost.Request
 */
export class CallCheckAuthenticationForHost_Request extends Message<CallCheckAuthenticationForHost_Request> {
  /**
   * @generated from field: string host = 1;
   */
  host = "";

  /**
   * @generated from field: string principal_id = 2;
   */
  principalId = "";

  constructor(data?: PartialMessage<CallCheckAuthenticationForHost_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallCheckAuthenticationForHost.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallCheckAuthenticationForHost_Request {
    return new CallCheckAuthenticationForHost_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallCheckAuthenticationForHost_Request {
    return new CallCheckAuthenticationForHost_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallCheckAuthenticationForHost_Request {
    return new CallCheckAuthenticationForHost_Request().fromJsonString(jsonString, options);
  }

  static equals(a: CallCheckAuthenticationForHost_Request | PlainMessage<CallCheckAuthenticationForHost_Request> | undefined, b: CallCheckAuthenticationForHost_Request | PlainMessage<CallCheckAuthenticationForHost_Request> | undefined): boolean {
    return proto3.util.equals(CallCheckAuthenticationForHost_Request, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallCheckAuthenticationForHost.Response
 */
export class CallCheckAuthenticationForHost_Response extends Message<CallCheckAuthenticationForHost_Response> {
  /**
   * @generated from field: gitpod.v1.CheckAuthenticationForHostResponse resp = 1;
   */
  resp?: CheckAuthenticationForHostResponse;

  constructor(data?: PartialMessage<CallCheckAuthenticationForHost_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallCheckAuthenticationForHost.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resp", kind: "message", T: CheckAuthenticationForHostResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallCheckAuthenticationForHost_Response {
    return new CallCheckAuthenticationForHost_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallCheckAuthenticationForHost_Response {
    return new CallCheckAuthenticationForHost_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallCheckAuthenticationForHost_Response {
    return new CallCheckAuthenticationForHost_Response().fromJsonString(jsonString, options);
  }

  static equals(a: CallCheckAuthenticationForHost_Response | PlainMessage<CallCheckAuthenticationForHost_Response> | undefined, b: CallCheckAuthenticationForHost_Response | PlainMessage<CallCheckAuthenticationForHost_Response> | undefined): boolean {
    return proto3.util.equals(CallCheckAuthenticationForHost_Response, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallValidateConfig
 */
export class CallValidateConfig extends Message<CallValidateConfig> {
  constructor(data?: PartialMessage<CallValidateConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallValidateConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallValidateConfig {
    return new CallValidateConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallValidateConfig {
    return new CallValidateConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallValidateConfig {
    return new CallValidateConfig().fromJsonString(jsonString, options);
  }

  static equals(a: CallValidateConfig | PlainMessage<CallValidateConfig> | undefined, b: CallValidateConfig | PlainMessage<CallValidateConfig> | undefined): boolean {
    return proto3.util.equals(CallValidateConfig, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallValidateConfig.Request
 */
export class CallValidateConfig_Request extends Message<CallValidateConfig_Request> {
  /**
   * @generated from oneof gitpod.v1.CallValidateConfig.Request.config
   */
  config: {
    /**
     * @generated from field: gitpod.v1.EnvironmentClass environment_class = 1;
     */
    value: EnvironmentClass;
    case: "environmentClass";
  } | {
    /**
     * @generated from field: gitpod.v1.SCMIntegration scm = 2;
     */
    value: SCMIntegration;
    case: "scm";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CallValidateConfig_Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallValidateConfig.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_class", kind: "message", T: EnvironmentClass, oneof: "config" },
    { no: 2, name: "scm", kind: "message", T: SCMIntegration, oneof: "config" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallValidateConfig_Request {
    return new CallValidateConfig_Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallValidateConfig_Request {
    return new CallValidateConfig_Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallValidateConfig_Request {
    return new CallValidateConfig_Request().fromJsonString(jsonString, options);
  }

  static equals(a: CallValidateConfig_Request | PlainMessage<CallValidateConfig_Request> | undefined, b: CallValidateConfig_Request | PlainMessage<CallValidateConfig_Request> | undefined): boolean {
    return proto3.util.equals(CallValidateConfig_Request, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CallValidateConfig.Response
 */
export class CallValidateConfig_Response extends Message<CallValidateConfig_Response> {
  /**
   * @generated from oneof gitpod.v1.CallValidateConfig.Response.result
   */
  result: {
    /**
     * @generated from field: gitpod.v1.EnvironmentClassValidationResult environment_class = 1;
     */
    value: EnvironmentClassValidationResult;
    case: "environmentClass";
  } | {
    /**
     * @generated from field: gitpod.v1.SCMIntegrationValidationResult scm = 2;
     */
    value: SCMIntegrationValidationResult;
    case: "scm";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CallValidateConfig_Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CallValidateConfig.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_class", kind: "message", T: EnvironmentClassValidationResult, oneof: "result" },
    { no: 2, name: "scm", kind: "message", T: SCMIntegrationValidationResult, oneof: "result" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CallValidateConfig_Response {
    return new CallValidateConfig_Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CallValidateConfig_Response {
    return new CallValidateConfig_Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CallValidateConfig_Response {
    return new CallValidateConfig_Response().fromJsonString(jsonString, options);
  }

  static equals(a: CallValidateConfig_Response | PlainMessage<CallValidateConfig_Response> | undefined, b: CallValidateConfig_Response | PlainMessage<CallValidateConfig_Response> | undefined): boolean {
    return proto3.util.equals(CallValidateConfig_Response, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetLatestVersionRequest
 */
export class GetLatestVersionRequest extends Message<GetLatestVersionRequest> {
  /**
   * The runner's identity
   *
   * @generated from field: string runner_id = 1;
   */
  runnerId = "";

  /**
   * The current version of the runner
   *
   * @generated from field: string current_version = 2;
   */
  currentVersion = "";

  /**
   * The version of the infrastructure
   *
   * @generated from field: string infrastructure_version = 3;
   */
  infrastructureVersion = "";

  constructor(data?: PartialMessage<GetLatestVersionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetLatestVersionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "runner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "infrastructure_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLatestVersionRequest {
    return new GetLatestVersionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLatestVersionRequest {
    return new GetLatestVersionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLatestVersionRequest {
    return new GetLatestVersionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLatestVersionRequest | PlainMessage<GetLatestVersionRequest> | undefined, b: GetLatestVersionRequest | PlainMessage<GetLatestVersionRequest> | undefined): boolean {
    return proto3.util.equals(GetLatestVersionRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetLatestVersionResponse
 */
export class GetLatestVersionResponse extends Message<GetLatestVersionResponse> {
  /**
   * The latest version of the runner
   *
   * @generated from field: string version = 1;
   */
  version = "";

  /**
   * The container image of the runner
   *
   * @generated from field: string runner_image = 2;
   */
  runnerImage = "";

  /**
   * auto-update indicates if the runner should be updated automatically
   *
   * @generated from field: bool auto_update = 3;
   */
  autoUpdate = false;

  /**
   * supervisor_download_url is the URL to download the supervisor
   *
   * @generated from field: string supervisor_download_url = 4;
   */
  supervisorDownloadUrl = "";

  /**
   * gitpod_cli_download_url is the URL to download the gitpod CLI
   *
   * @generated from field: string gitpod_cli_download_url = 5;
   */
  gitpodCliDownloadUrl = "";

  constructor(data?: PartialMessage<GetLatestVersionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetLatestVersionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "runner_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "auto_update", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "supervisor_download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "gitpod_cli_download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLatestVersionResponse {
    return new GetLatestVersionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLatestVersionResponse {
    return new GetLatestVersionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLatestVersionResponse {
    return new GetLatestVersionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLatestVersionResponse | PlainMessage<GetLatestVersionResponse> | undefined, b: GetLatestVersionResponse | PlainMessage<GetLatestVersionResponse> | undefined): boolean {
    return proto3.util.equals(GetLatestVersionResponse, a, b);
  }
}

