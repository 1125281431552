// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file gitpod/v1/secret.proto (package gitpod.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { PaginationRequest, PaginationResponse } from "./pagination_pb.js";
import { Subject } from "./identity_pb.js";

/**
 * @generated from message gitpod.v1.CreateSecretRequest
 */
export class CreateSecretRequest extends Message<CreateSecretRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * project_id is the ProjectID this Secret belongs to
   *
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * value is the plaintext value of the secret
   *
   * @generated from field: string value = 3;
   */
  value = "";

  /**
   * mount specifies how the secret should be mounted in Environments
   *
   * @generated from oneof gitpod.v1.CreateSecretRequest.mount
   */
  mount: {
    /**
     * absolute path to the file where the secret is mounted
     *
     * @generated from field: string file_path = 7;
     */
    value: string;
    case: "filePath";
  } | {
    /**
     * secret will be created as an Environment Variable with the same name as the secret
     *
     * @generated from field: bool environment_variable = 8;
     */
    value: boolean;
    case: "environmentVariable";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateSecretRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateSecretRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "mount" },
    { no: 8, name: "environment_variable", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "mount" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSecretRequest {
    return new CreateSecretRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSecretRequest {
    return new CreateSecretRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSecretRequest {
    return new CreateSecretRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSecretRequest | PlainMessage<CreateSecretRequest> | undefined, b: CreateSecretRequest | PlainMessage<CreateSecretRequest> | undefined): boolean {
    return proto3.util.equals(CreateSecretRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.CreateSecretResponse
 */
export class CreateSecretResponse extends Message<CreateSecretResponse> {
  /**
   * @generated from field: gitpod.v1.Secret secret = 1;
   */
  secret?: Secret;

  constructor(data?: PartialMessage<CreateSecretResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.CreateSecretResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secret", kind: "message", T: Secret },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSecretResponse {
    return new CreateSecretResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSecretResponse {
    return new CreateSecretResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSecretResponse {
    return new CreateSecretResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSecretResponse | PlainMessage<CreateSecretResponse> | undefined, b: CreateSecretResponse | PlainMessage<CreateSecretResponse> | undefined): boolean {
    return proto3.util.equals(CreateSecretResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateSecretValueRequest
 */
export class UpdateSecretValueRequest extends Message<UpdateSecretValueRequest> {
  /**
   * @generated from field: string secret_id = 1;
   */
  secretId = "";

  /**
   * value is the plaintext value of the secret
   *
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<UpdateSecretValueRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateSecretValueRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secret_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSecretValueRequest {
    return new UpdateSecretValueRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSecretValueRequest {
    return new UpdateSecretValueRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSecretValueRequest {
    return new UpdateSecretValueRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSecretValueRequest | PlainMessage<UpdateSecretValueRequest> | undefined, b: UpdateSecretValueRequest | PlainMessage<UpdateSecretValueRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSecretValueRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.UpdateSecretValueResponse
 */
export class UpdateSecretValueResponse extends Message<UpdateSecretValueResponse> {
  constructor(data?: PartialMessage<UpdateSecretValueResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.UpdateSecretValueResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSecretValueResponse {
    return new UpdateSecretValueResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSecretValueResponse {
    return new UpdateSecretValueResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSecretValueResponse {
    return new UpdateSecretValueResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSecretValueResponse | PlainMessage<UpdateSecretValueResponse> | undefined, b: UpdateSecretValueResponse | PlainMessage<UpdateSecretValueResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSecretValueResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSecretsRequest
 */
export class ListSecretsRequest extends Message<ListSecretsRequest> {
  /**
   * pagination contains the pagination options for listing environments
   *
   * @generated from field: gitpod.v1.PaginationRequest pagination = 1;
   */
  pagination?: PaginationRequest;

  /**
   * @generated from field: gitpod.v1.ListSecretsRequest.Filter filter = 2;
   */
  filter?: ListSecretsRequest_Filter;

  constructor(data?: PartialMessage<ListSecretsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSecretsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationRequest },
    { no: 2, name: "filter", kind: "message", T: ListSecretsRequest_Filter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSecretsRequest {
    return new ListSecretsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSecretsRequest {
    return new ListSecretsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSecretsRequest {
    return new ListSecretsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSecretsRequest | PlainMessage<ListSecretsRequest> | undefined, b: ListSecretsRequest | PlainMessage<ListSecretsRequest> | undefined): boolean {
    return proto3.util.equals(ListSecretsRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSecretsRequest.Filter
 */
export class ListSecretsRequest_Filter extends Message<ListSecretsRequest_Filter> {
  /**
   * project_ids filters the response to only Secrets used by these Project IDs
   *
   * @generated from field: repeated string project_ids = 1;
   */
  projectIds: string[] = [];

  constructor(data?: PartialMessage<ListSecretsRequest_Filter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSecretsRequest.Filter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSecretsRequest_Filter {
    return new ListSecretsRequest_Filter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSecretsRequest_Filter {
    return new ListSecretsRequest_Filter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSecretsRequest_Filter {
    return new ListSecretsRequest_Filter().fromJsonString(jsonString, options);
  }

  static equals(a: ListSecretsRequest_Filter | PlainMessage<ListSecretsRequest_Filter> | undefined, b: ListSecretsRequest_Filter | PlainMessage<ListSecretsRequest_Filter> | undefined): boolean {
    return proto3.util.equals(ListSecretsRequest_Filter, a, b);
  }
}

/**
 * @generated from message gitpod.v1.ListSecretsResponse
 */
export class ListSecretsResponse extends Message<ListSecretsResponse> {
  /**
   * pagination contains the pagination options for listing secrets
   *
   * @generated from field: gitpod.v1.PaginationResponse pagination = 1;
   */
  pagination?: PaginationResponse;

  /**
   * @generated from field: repeated gitpod.v1.Secret secrets = 2;
   */
  secrets: Secret[] = [];

  constructor(data?: PartialMessage<ListSecretsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.ListSecretsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: PaginationResponse },
    { no: 2, name: "secrets", kind: "message", T: Secret, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSecretsResponse {
    return new ListSecretsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSecretsResponse {
    return new ListSecretsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSecretsResponse {
    return new ListSecretsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSecretsResponse | PlainMessage<ListSecretsResponse> | undefined, b: ListSecretsResponse | PlainMessage<ListSecretsResponse> | undefined): boolean {
    return proto3.util.equals(ListSecretsResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteSecretRequest
 */
export class DeleteSecretRequest extends Message<DeleteSecretRequest> {
  /**
   * @generated from field: string secret_id = 1;
   */
  secretId = "";

  constructor(data?: PartialMessage<DeleteSecretRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteSecretRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secret_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSecretRequest {
    return new DeleteSecretRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSecretRequest {
    return new DeleteSecretRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSecretRequest {
    return new DeleteSecretRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSecretRequest | PlainMessage<DeleteSecretRequest> | undefined, b: DeleteSecretRequest | PlainMessage<DeleteSecretRequest> | undefined): boolean {
    return proto3.util.equals(DeleteSecretRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.DeleteSecretResponse
 */
export class DeleteSecretResponse extends Message<DeleteSecretResponse> {
  constructor(data?: PartialMessage<DeleteSecretResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.DeleteSecretResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSecretResponse {
    return new DeleteSecretResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSecretResponse {
    return new DeleteSecretResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSecretResponse {
    return new DeleteSecretResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSecretResponse | PlainMessage<DeleteSecretResponse> | undefined, b: DeleteSecretResponse | PlainMessage<DeleteSecretResponse> | undefined): boolean {
    return proto3.util.equals(DeleteSecretResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSecretValueRequest
 */
export class GetSecretValueRequest extends Message<GetSecretValueRequest> {
  /**
   * @generated from field: string secret_id = 1;
   */
  secretId = "";

  constructor(data?: PartialMessage<GetSecretValueRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSecretValueRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "secret_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSecretValueRequest {
    return new GetSecretValueRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSecretValueRequest {
    return new GetSecretValueRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSecretValueRequest {
    return new GetSecretValueRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSecretValueRequest | PlainMessage<GetSecretValueRequest> | undefined, b: GetSecretValueRequest | PlainMessage<GetSecretValueRequest> | undefined): boolean {
    return proto3.util.equals(GetSecretValueRequest, a, b);
  }
}

/**
 * @generated from message gitpod.v1.GetSecretValueResponse
 */
export class GetSecretValueResponse extends Message<GetSecretValueResponse> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  constructor(data?: PartialMessage<GetSecretValueResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.GetSecretValueResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSecretValueResponse {
    return new GetSecretValueResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSecretValueResponse {
    return new GetSecretValueResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSecretValueResponse {
    return new GetSecretValueResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSecretValueResponse | PlainMessage<GetSecretValueResponse> | undefined, b: GetSecretValueResponse | PlainMessage<GetSecretValueResponse> | undefined): boolean {
    return proto3.util.equals(GetSecretValueResponse, a, b);
  }
}

/**
 * @generated from message gitpod.v1.Secret
 */
export class Secret extends Message<Secret> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Name of the secret for humans.
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * The Project ID this Secret belongs to
   *
   * @generated from field: string project_id = 3;
   */
  projectId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;

  /**
   * creator is the identity of the creator of the secret
   *
   * @generated from field: gitpod.v1.Subject creator = 6;
   */
  creator?: Subject;

  /**
   * @generated from oneof gitpod.v1.Secret.mount
   */
  mount: {
    /**
     * absolute path to the file where the secret is mounted
     *
     * @generated from field: string file_path = 7;
     */
    value: string;
    case: "filePath";
  } | {
    /**
     * secret will be created as an Environment Variable with the same name as the secret
     *
     * @generated from field: bool environment_variable = 8;
     */
    value: boolean;
    case: "environmentVariable";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Secret>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gitpod.v1.Secret";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
    { no: 6, name: "creator", kind: "message", T: Subject },
    { no: 7, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "mount" },
    { no: 8, name: "environment_variable", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "mount" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Secret {
    return new Secret().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Secret {
    return new Secret().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Secret {
    return new Secret().fromJsonString(jsonString, options);
  }

  static equals(a: Secret | PlainMessage<Secret> | undefined, b: Secret | PlainMessage<Secret> | undefined): boolean {
    return proto3.util.equals(Secret, a, b);
  }
}

