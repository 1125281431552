import { useGetAccount } from "@/queries/account-queries";
import { Timestamp } from "@bufbuild/protobuf";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

const disableIntercom = process.env.NODE_ENV === "development";

export const useBootIntercom = () => {
    const intercom = useIntercom();
    const { data: account } = useGetAccount();

    useEffect(() => {
        if (disableIntercom) {
            return;
        }
        if (!account) {
            intercom.boot();
            return;
        }

        const memberships = account.memberships || [];
        try {
            intercom.boot({
                userId: account.id,
                name: account.name, // Full name
                email: account.email,
                createdAt: new Timestamp(account.createdAt).toDate().getTime() / 1000, // Signup date as a Unix timestamp
                companies: memberships.map((m) => {
                    return {
                        companyId: m.organizationId,
                        name: m.organizationName,
                        customAttributes: {
                            userId: m.userId,
                            role: m.userRole,
                        },
                    };
                }),
                avatar: {
                    type: "avatar",
                    imageUrl: account.avatarUrl,
                },
            });
        } catch (err) {
            console.error("Failed to boot intercom", err);
        }
    }, [account, intercom]);
};

export const useShutdownIntercom = () => {
    const intercom = useIntercom();

    return () => {
        if (disableIntercom) {
            return;
        }
        try {
            intercom.shutdown();
        } catch (err) {
            console.error("Failed to shutdown intercom", err);
        }
    };
};

export const useIntercomTrack = () => {
    const intercom = useIntercom();

    return (eventName: string, metadata?: Record<string, string | undefined>) => {
        if (disableIntercom) {
            return;
        }
        try {
            intercom.trackEvent(eventName, metadata);
        } catch (err) {
            console.error("Failed to track event", err);
        }
    };
};

export const useNewIntercomMessage = () => {
    const intercom = useIntercom();

    return (content?: string) => {
        if (disableIntercom) {
            return;
        }
        try {
            intercom.showNewMessage(content);
        } catch (err) {
            console.error("Failed to show New Intercom Message", err);
        }
    };
};
