import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const TemplateIllutrationPlaceholder: FC<PropsWithClassName> = ({ className }) => {
    return (
        <svg
            width="84"
            height="68"
            viewBox="0 0 84 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn("h-16 min-h-16 w-20 min-w-20", className)}
        >
            <g filter="url(#filter0_d_33_2488)">
                <path
                    d="M2 5C2 2.79086 3.79086 1 6 1H78C80.2091 1 82 2.79086 82 5V50V61C82 63.2091 80.2091 65 78 65H61.5H6C3.79086 65 2 63.2091 2 61V5Z"
                    fill="white"
                />
                <path
                    d="M2.5 5C2.5 3.067 4.067 1.5 6 1.5H78C79.933 1.5 81.5 3.067 81.5 5V50V61C81.5 62.933 79.933 64.5 78 64.5H61.5H6C4.067 64.5 2.5 62.933 2.5 61V5Z"
                    stroke="#D6D6D6"
                />
            </g>
            <path
                d="M73.8335 52.667C70.4638 52.9587 68.6252 54.7973 68.3335 58.167C68.0295 54.7449 66.2004 53.0484 62.8335 52.667C66.2537 52.2725 67.939 50.5872 68.3335 47.167C68.7149 50.5339 70.4114 52.363 73.8335 52.667Z"
                stroke="#D6D6D6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M74.193 44.8396C74.1819 44.7413 74.0989 44.6671 74 44.667C73.9012 44.6669 73.818 44.741 73.8067 44.8392C73.733 45.4777 73.5421 45.9267 73.2342 46.2346C72.9262 46.5426 72.4772 46.7335 71.8386 46.8072C71.7405 46.8185 71.6664 46.9017 71.6665 47.0005C71.6666 47.0994 71.7408 47.1824 71.839 47.1935C72.467 47.2647 72.9256 47.4555 73.2408 47.7651C73.555 48.0735 73.7498 48.5224 73.8062 49.1564C73.8151 49.2568 73.8992 49.3338 74 49.3337C74.1008 49.3335 74.1848 49.2564 74.1936 49.156C74.2476 48.5324 74.4422 48.0741 74.7579 47.7584C75.0736 47.4427 75.5319 47.248 76.1555 47.194C76.2559 47.1854 76.333 47.1014 76.3332 47.0005C76.3333 46.8997 76.2564 46.8156 76.1559 46.8066C75.5219 46.7503 75.073 46.5554 74.7646 46.2413C74.455 45.926 74.2642 45.4675 74.193 44.8396Z"
                fill="#D6D6D6"
            />
            <path
                d="M7 4.33337L6 4.91671V6.08337L7 6.66671L8 6.08337V4.91671L7 5.50004"
                stroke="#D6D6D6"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M81 7H39.75C38.2312 7 37 5.76878 37 4.25V4.25C37 2.73122 35.7688 1.5 34.25 1.5H6.5C4.29086 1.5 2.5 3.29086 2.5 5.5L2.5 12.5"
                stroke="#D6D6D6"
            />
            <path
                d="M12.36 23V22.508H13.704V19.844H12.588V19.4H13.164C13.62 19.4 13.8 19.22 13.8 18.74H14.22V22.508H15.24V23H12.36Z"
                fill="#D6D6D6"
            />
            <path
                d="M12.3 33C12.3 32.1 12.654 31.506 13.932 30.828C14.52 30.516 14.754 30.324 14.754 29.892C14.754 29.454 14.466 29.148 13.902 29.148C13.302 29.148 12.954 29.472 12.864 30.048L12.324 30.012C12.42 29.178 12.978 28.644 13.902 28.644C14.778 28.644 15.294 29.148 15.294 29.88C15.294 30.528 15.006 30.852 14.124 31.32C13.356 31.728 12.96 32.172 12.936 32.496H15.3V33H12.3Z"
                fill="#D6D6D6"
            />
            <path
                d="M13.764 42.096C12.84 42.096 12.336 41.604 12.3 40.902L12.834 40.866C12.882 41.388 13.254 41.592 13.764 41.592C14.28 41.592 14.76 41.388 14.76 40.812C14.76 40.248 14.346 39.99 13.794 39.99H13.458V39.486H13.794C14.238 39.486 14.61 39.306 14.61 38.82C14.61 38.382 14.322 38.148 13.794 38.148C13.254 38.148 12.99 38.376 12.936 38.754L12.396 38.718C12.474 38.082 12.966 37.644 13.794 37.644C14.622 37.644 15.15 38.088 15.15 38.796C15.15 39.252 14.898 39.558 14.412 39.708C14.964 39.858 15.3 40.266 15.3 40.812C15.3 41.628 14.652 42.096 13.764 42.096Z"
                fill="#D6D6D6"
            />
            <path
                d="M14.328 52V51.076H12.24V50.608L14.292 47.74H14.844V50.572H15.36V51.076H14.844V52H14.328ZM12.864 50.572H14.328V48.532L12.864 50.572Z"
                fill="#D6D6D6"
            />
            <rect x="20" y="20" width="35" height="2" rx="1" fill="#D6D6D6" />
            <rect x="10" y="5" width="13" height="1" rx="0.5" fill="#D6D6D6" />
            <rect x="28" y="30" width="20" height="2" rx="1" fill="#D6D6D6" />
            <rect x="25" y="39" width="35" height="2" rx="1" fill="#D6D6D6" />
            <rect x="20" y="49" width="16" height="2" rx="1" fill="#D6D6D6" />
            <defs>
                <filter
                    id="filter0_d_33_2488"
                    x="0"
                    y="0"
                    width="84"
                    height="68"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_2488" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_2488" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};
