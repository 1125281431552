import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCopy: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_144_588)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.40625 1.4375C2.56056 1.4375 1.875 2.12306 1.875 2.96875V9.53125C1.875 10.3769 2.56056 11.0625 3.40625 11.0625H4.28125H4.9375V9.75H4.28125H3.40625C3.28544 9.75 3.1875 9.65206 3.1875 9.53125V2.96875C3.1875 2.84794 3.28544 2.75 3.40625 2.75H8.21875C8.33956 2.75 8.4375 2.84794 8.4375 2.96875V3.625H9.75V2.96875C9.75 2.12306 9.06444 1.4375 8.21875 1.4375H3.40625ZM7.78125 4.9375C6.93556 4.9375 6.25 5.62306 6.25 6.46875V13.0312C6.25 13.8769 6.93556 14.5625 7.78125 14.5625H12.5938C13.4394 14.5625 14.125 13.8769 14.125 13.0312V6.46875C14.125 5.62306 13.4394 4.9375 12.5938 4.9375H7.78125ZM7.5625 6.46875C7.5625 6.34794 7.66044 6.25 7.78125 6.25H12.5938C12.7146 6.25 12.8125 6.34794 12.8125 6.46875V13.0312C12.8125 13.1521 12.7146 13.25 12.5938 13.25H7.78125C7.66044 13.25 7.5625 13.1521 7.5625 13.0312V6.46875Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_144_588">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.75 2.5C3.7835 2.5 3 3.2835 3 4.25V11.75C3 12.7165 3.7835 13.5 4.75 13.5H5.75H6.5V12H5.75H4.75C4.61193 12 4.5 11.8881 4.5 11.75V4.25C4.5 4.11193 4.61193 4 4.75 4H10.25C10.3881 4 10.5 4.11193 10.5 4.25V5H12V4.25C12 3.2835 11.2165 2.5 10.25 2.5H4.75ZM9.75 6.5C8.7835 6.5 8 7.2835 8 8.25V15.75C8 16.7165 8.7835 17.5 9.75 17.5H15.25C16.2165 17.5 17 16.7165 17 15.75V8.25C17 7.2835 16.2165 6.5 15.25 6.5H9.75ZM9.5 8.25C9.5 8.11193 9.61193 8 9.75 8H15.25C15.3881 8 15.5 8.11193 15.5 8.25V15.75C15.5 15.8881 15.3881 16 15.25 16H9.75C9.61193 16 9.5 15.8881 9.5 15.75V8.25Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.09375 3.5625C5.00644 3.5625 4.125 4.44394 4.125 5.53125V13.9688C4.125 15.0561 5.00644 15.9375 6.09375 15.9375H7.21875H8.0625V14.25H7.21875H6.09375C5.93842 14.25 5.8125 14.1241 5.8125 13.9688V5.53125C5.8125 5.37592 5.93842 5.25 6.09375 5.25H12.2812C12.4366 5.25 12.5625 5.37592 12.5625 5.53125V6.375H14.25V5.53125C14.25 4.44394 13.3686 3.5625 12.2812 3.5625H6.09375ZM11.7188 8.0625C10.6314 8.0625 9.75 8.94394 9.75 10.0312V18.4688C9.75 19.5561 10.6314 20.4375 11.7188 20.4375H17.9062C18.9936 20.4375 19.875 19.5561 19.875 18.4688V10.0312C19.875 8.94394 18.9936 8.0625 17.9062 8.0625H11.7188ZM11.4375 10.0312C11.4375 9.87592 11.5634 9.75 11.7188 9.75H17.9062C18.0616 9.75 18.1875 9.87592 18.1875 10.0312V18.4688C18.1875 18.6241 18.0616 18.75 17.9062 18.75H11.7188C11.5634 18.75 11.4375 18.6241 11.4375 18.4688V10.0312Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
