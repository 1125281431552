import { useCallback, type FC } from "react";

import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { TrackLocations } from "@/hooks/use-segment";
import { Button } from "@/components/flexkit/Button";
import { useEnvironment } from "@/queries/environment-queries";
import { SecretsCard } from "@/components/projects/ProjectSecrets";
import { EnvironmentSecretsTable } from "@/components/environments/EnvironmentSecretsTable";

export const EnvironmentSecretsModal: FC<{ environmentId: string; onClose: () => void }> = ({
    environmentId,
    onClose,
}) => {
    const { data: environment } = useEnvironment(environmentId);

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent
                className="max-w-[600px]"
                data-testid="environment-secrets-modal-content"
                data-track-location={TrackLocations.EnvironmentSecretsModal}
            >
                <DialogHeader>
                    <DialogTitle>Secrets</DialogTitle>
                    <DialogDescription>Secrets available in this environment</DialogDescription>
                </DialogHeader>

                <DialogBody className="overflow-x max-w-full">
                    <SecretsCard>{environment && <EnvironmentSecretsTable environment={environment} />}</SecretsCard>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Close
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
