import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useQuery } from "@tanstack/react-query";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import type { Group } from "gitpod-next-api/gitpod/v1/group_pb";

export const groupsQueryKey = {
    list: () => keyWithPrincipal(["groups", "list", "all"]),
};

export type PlainGroup = PlainMessage<Group>;

export function toPlainGroup(group: Group): PlainGroup {
    return toPlainMessage(group);
}

export const useListGroups = () => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useQuery({
        queryKey: groupsQueryKey.list(),
        queryFn: async () => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { groups, pagination } = await api.groupService.listGroups({
                pagination: {
                    pageSize: 100,
                },
            });

            return {
                groups: groups.map(toPlainGroup),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 200,
    });
};
