import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconClose: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.911 12.839L12.375 13.3031L13.3031 12.375L12.839 11.911L8.92807 7.99999L12.839 4.08903L13.3031 3.62499L12.375 2.69691L11.911 3.16095L7.99999 7.07191L4.08903 3.16095L3.62499 2.69691L2.69691 3.62499L3.16095 4.08903L7.07191 7.99999L3.16095 11.911L2.69691 12.375L3.62499 13.3031L4.08903 12.839L7.99999 8.92807L11.911 12.839Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.4697 15.5303L15 16.0607L16.0607 15L15.5303 14.4697L11.0607 9.99999L15.5303 5.53032L16.0607 4.99999L15 3.93933L14.4697 4.46966L9.99999 8.93933L5.53032 4.46966L4.99999 3.93933L3.93933 4.99999L4.46966 5.53032L8.93933 9.99999L4.46966 14.4697L3.93933 15L4.99999 16.0607L5.53032 15.5303L9.99999 11.0607L14.4697 15.5303Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0284 18.2216L17.625 18.8183L18.8183 17.625L18.2216 17.0284L13.1932 12L18.2216 6.97161L18.8183 6.37499L17.625 5.18175L17.0284 5.77837L12 10.8067L6.97161 5.77837L6.37499 5.18175L5.18175 6.37499L5.77837 6.97161L10.8067 12L5.77837 17.0284L5.18175 17.625L6.37499 18.8183L6.97161 18.2216L12 13.1932L17.0284 18.2216Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
