import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconBox: FC<{ state: "open" | "closed" } & PropsWithClassName> = ({ state, className }) => {
    switch (state) {
        case "closed":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="icon-box-closed" transform="translate(0, 1)">
                        <path
                            id="Vector 20"
                            d="M3 15.7003V8.29975C3 7.50937 3.46547 6.79313 4.18772 6.47212L11.1877 3.36101C11.7049 3.13118 12.2951 3.13118 12.8123 3.36101L19.8123 6.47212C20.5345 6.79313 21 7.50937 21 8.29975V15.7003C21 16.4906 20.5345 17.2069 19.8123 17.5279L12.8123 20.639C12.2951 20.8688 11.7049 20.8688 11.1877 20.639L4.18772 17.5279C3.46547 17.2069 3 16.4906 3 15.7003Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="Vector 21"
                            d="M20 8L12 11V20"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="Vector 22"
                            d="M4 8L12 11"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="Vector 23"
                            d="M16 5L7 9"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </g>
                </svg>
            );
        case "open":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="icon-box-open" transform="translate(0, -1)">
                        <path
                            id="Vector 20"
                            d="M18 7.6667L16.5 7.00003L12.8123 5.36104C12.2951 5.13121 11.7049 5.13121 11.1877 5.36104L4.18772 8.47215C3.46547 8.79316 3 9.5094 3 10.2998V17.7003C3 18.4907 3.46547 19.2069 4.18772 19.5279L11.1877 22.639C11.7049 22.8689 12.2951 22.8689 12.8123 22.639L19.8123 19.5279C20.5345 19.2069 21 18.4907 21 17.7003V9.50003"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="Vector 21"
                            d="M20 10L19.977 10.0087M12 22V13L19.977 10.0087M19.977 10.0087L21 9.50003"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="Vector 22"
                            d="M4 10L12 13"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="Vector 24"
                            d="M3 9.00003L1.468 5.93604C1.21304 5.42612 1.43532 4.80655 1.95629 4.57501L9.12678 1.38813C9.61533 1.17099 10.1883 1.37654 10.4273 1.85473L12 5.00003"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="Vector 25"
                            d="M12 13L14.8286 9.22852C14.9406 9.07918 15.0922 8.96417 15.2662 8.89651L22.5247 6.07376C22.98 5.89668 23.3929 6.41061 23.1219 6.81711L21 10"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </g>
                </svg>
            );
    }
};
