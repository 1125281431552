import webConfig from "@/web-config";
let _principal: string | undefined;
if (webConfig.principal) {
    _principal = webConfig.principal;
}

const principalKey = "principal";

const principalChangedEventType = "principal-changed";
class PrincipalChangedEvent extends CustomEvent<void> {
    constructor() {
        super(principalChangedEventType);
    }
}

export function getPrincipal(): string {
    return _principal || "";
}

export function onDidChangePrincipal(callback: () => void) {
    const listener = (event: Event) => {
        if (event instanceof PrincipalChangedEvent) {
            callback();
        }
    };
    window.addEventListener(principalChangedEventType, listener);
    return () => window.removeEventListener(principalChangedEventType, listener);
}

export function setPrincipal(principal: string) {
    const currentPrincipal = _principal;
    _principal = principal;
    if (currentPrincipal === principal) {
        return;
    }
    storePrincipal();
    window.dispatchEvent(new PrincipalChangedEvent());
}

export function restorePrincipal(): void {
    let principal = "";
    try {
        principal = window.localStorage.getItem(principalKey) || "";
        // TODO validate for UUID
    } catch (error) {
        console.log(error);
    }
    setPrincipal(principal);
}

function storePrincipal(): void {
    try {
        window.localStorage.setItem(principalKey, _principal || "");
    } catch (error) {
        console.log(error);
    }
}

export function clearPrincipal(): void {
    _principal = undefined;
    try {
        window.localStorage.removeItem(principalKey);
    } catch (error) {
        console.log(error);
    }
}
// TODO this is helper to clear the principal from the console
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).clearPrincipal = clearPrincipal;
