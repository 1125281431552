import { IconCheckCircle } from "@/assets/icons/geist/IconCheckCircle";
import { IconChevronDown } from "@/assets/icons/geist/IconChevronDown";
import { IconChevronRight } from "@/assets/icons/geist/IconChevronRight";
import { IconSpinner } from "@/assets/icons/geist/IconSpinner";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { duration, formatMediumTime } from "@/format/time";
import { useOpenEditor } from "@/hooks/use-open-editor";
import { useTriggerOnRunning } from "@/hooks/use-trigger-on-running";
import { type PlainEnvironment } from "@/queries/environment-queries";
import { type LogGroup, type LogLine, NO_LOG_GROUP } from "@/routes/environments/log-streams/log-groups";
import Anser from "anser";
import { type FC, type ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { IconDotLarge } from "@/assets/icons/geist/IconDotLarge.tsx";
import { useThrottledCallback } from "use-debounce";
import { LogSectionOutcome } from "gitpod-next-api/gitpod/v1/environment_logs_pb.ts";
import { useLocation } from "react-router-dom";
import { useStreamLogs } from "@/hooks/use-stream-logs";

type EnvironmentLogsProps = {
    environment?: PlainEnvironment;
    filterLogLinesWithoutTimestamps: boolean;
    logsUrl?: string;
    maxLines?: number;
};

export const EnvironmentLogs: FC<EnvironmentLogsProps & PropsWithClassName> = ({
    className,
    environment,
    logsUrl,
    filterLogLinesWithoutTimestamps,
}) => {
    const logContainerRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const logEndRef = useRef<HTMLDivElement>(null);
    const [isAutoScroll, setIsAutoScroll] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const { logGroups, loadingMessage, error } = useStreamLogs({ environment, logsUrl, maxLinesPerGroup: 10000 });

    const openEditor = useOpenEditor(environment);
    useTriggerOnRunning(environment?.status?.phase, openEditor);

    // Handle auto-scroll
    const debouncedScroll = useThrottledCallback((scrollContainer: HTMLDivElement, scrollTop: number) => {
        scrollContainer.scrollTo({ top: scrollTop, behavior: "smooth" });
    }, 500);

    // Handle scroll event
    const handleScroll = useCallback(() => {
        if (!scrollContainerRef.current || !logEndRef.current) {
            return;
        }
        const { scrollTop, offsetHeight, scrollHeight } = scrollContainerRef.current;
        const bottom = scrollHeight - offsetHeight;

        // Disable auto scroll if the user scrolls up.
        // Only if there is any scrollable content - this ensure we still auto-scroll when re-fetching logs
        const scrolledBack = scrollTop < lastScrollTop;
        setLastScrollTop(scrollTop);
        if (scrolledBack && scrollHeight > 0) {
            setIsAutoScroll(false);

            // Cancel any pending/throttled autoscroll events.
            debouncedScroll.cancel();

            // Scroll once instantly to stop any ongoing smooth scroll.
            scrollContainerRef.current.scrollTo({ top: scrollTop, behavior: "instant" });
        } else if (scrollTop >= bottom - 100) {
            setIsAutoScroll(true);
        }
    }, [debouncedScroll, lastScrollTop]);

    const updateHeight = useCallback(() => {
        if (logContainerRef.current) {
            const topPosition = logContainerRef.current.getBoundingClientRect().top;
            const viewportHeight = window.innerHeight - 32;
            logContainerRef.current.style.height = `${viewportHeight - topPosition}px`;
        }
    }, []);

    useEffect(() => {
        if (isAutoScroll && scrollContainerRef.current && logEndRef.current) {
            const scrollContainer = scrollContainerRef.current;
            const logEnd = logEndRef.current;

            // Calculate the scroll position needed to bring logEnd into view
            const desiredScrollTop = logEnd.offsetTop - scrollContainer.offsetTop;

            // Smoothly scroll to the desired position
            debouncedScroll(scrollContainer, desiredScrollTop);
        }
    }, [logGroups, isAutoScroll, debouncedScroll]);

    // Update height on mount and window resize
    useEffect(() => {
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, [updateHeight]);

    const isLegacyRunner =
        logGroups && Object.keys(logGroups).length === 1 && logGroups[NO_LOG_GROUP]?.lines?.length > 0;
    const legacyLogLines = logGroups?.[NO_LOG_GROUP]?.lines;

    return (
        <div className="relative flex h-full w-full flex-col overflow-hidden rounded-xl bg-always-dark p-4 text-always-light">
            {(error || loadingMessage) && (
                <div className="absolute bottom-0 flex w-full flex-col items-center p-4">
                    <ErrorMessage error={error} />
                    {loadingMessage && (
                        <span className="text-sm" data-testid="logs-loading-message">
                            {loadingMessage}
                        </span>
                    )}
                </div>
            )}
            <div className={cn(className, "relative shrink grow")} ref={logContainerRef}>
                <div
                    className="scrollbar-hide absolute top-0 max-h-full w-full overflow-y-auto"
                    onScroll={handleScroll}
                    ref={scrollContainerRef}
                >
                    {!isLegacyRunner && logGroups ? (
                        Object.values(logGroups).map((logGroup, i) => (
                            <LogGroup
                                key={i}
                                logGroup={logGroup}
                                filterLogLinesWithoutTimestamps={filterLogLinesWithoutTimestamps}
                            />
                        ))
                    ) : (
                        <LogLines
                            lines={legacyLogLines || []}
                            filterLogLinesWithoutTimestamps={filterLogLinesWithoutTimestamps}
                        />
                    )}
                    <div ref={logEndRef} />
                </div>
            </div>
        </div>
    );
};

const LogGroup: FC<{ logGroup: LogGroup; filterLogLinesWithoutTimestamps: boolean }> = ({
    logGroup,
    filterLogLinesWithoutTimestamps,
}) => {
    const location = useLocation();
    const isInProgress = logGroup.outcome === undefined;
    const isContinuous = logGroup.continuous;
    const hasFailed = logGroup.outcome === LogSectionOutcome.FAILURE;
    const isDeepLinkToThisGroup = location.hash === `#${logGroup.id}`;
    const hasDeepLinkToAnotherGroup = location.hash && !isDeepLinkToThisGroup;

    const [expanded, setExpanded] = useState<{ expanded: boolean; byUser: boolean }>({
        byUser: isDeepLinkToThisGroup,
        expanded: !hasDeepLinkToAnotherGroup && !isContinuous && (isInProgress || hasFailed || isDeepLinkToThisGroup),
    });

    // Automatically collapse log groups when they complete, unless the user has manually expanded them or they've failed
    useEffect(() => {
        if (expanded.expanded && !isInProgress && !expanded.byUser && logGroup.outcome === LogSectionOutcome.SUCCESS) {
            setExpanded({ expanded: false, byUser: false });
        }
    }, [expanded, setExpanded, isInProgress, logGroup, location]);

    if (logGroup.id == NO_LOG_GROUP) {
        return;
    }

    let icon: ReactNode;
    if (logGroup.outcome === undefined && !logGroup.continuous) {
        icon = <IconSpinner size="lg" className="animate-spin text-content-yield" />;
    } else if (logGroup.outcome === undefined && logGroup.continuous) {
        icon = <IconDotLarge size="lg" className="animate-pulse text-content-positive" />;
    } else if (logGroup.outcome === LogSectionOutcome.SUCCESS) {
        icon = <IconCheckCircle size="lg" className="text-content-positive" />;
    } else {
        icon = <IconWarning size="lg" className="text-content-negative" />;
    }
    return (
        <div className="w-full" id={logGroup.id}>
            <div
                className={cn(
                    "my-1 flex h-9 w-full justify-between rounded-lg px-2 py-1",
                    expanded.expanded && "sticky top-0 bg-content-secondary dark:bg-content-tertiary",
                )}
            >
                <div className="flex items-center gap-4">
                    <Button
                        variant={"ghost"}
                        onClick={() => setExpanded({ expanded: !expanded.expanded, byUser: true })}
                        className="p-0"
                        aria-label={expanded.expanded ? "Collapse log group" : "Expand log group"}
                    >
                        {expanded.expanded ? (
                            <IconChevronDown size="sm" className="text-always-light" />
                        ) : (
                            <IconChevronRight size="sm" className="text-always-light" />
                        )}
                    </Button>
                    <div className="flex min-h-6 min-w-6 items-center justify-center">{icon}</div>
                    <span className="font-mono text-sm text-always-light">{logGroup.title}</span>
                </div>
                <div>
                    {logGroup.secondsElapsed && (
                        <span className="font-mono text-sm text-always-light">{duration(logGroup.secondsElapsed)}</span>
                    )}
                </div>
            </div>
            <div className={cn("px-2", expanded.expanded ? "visible" : "hidden")}>
                <LogLines lines={logGroup.lines} filterLogLinesWithoutTimestamps={filterLogLinesWithoutTimestamps} />
            </div>
        </div>
    );
};

const LogLines: FC<{ lines: LogLine[]; filterLogLinesWithoutTimestamps: boolean }> = ({
    lines,
    filterLogLinesWithoutTimestamps,
}) => {
    return (
        <>
            {lines.map((line, i) => {
                if (
                    (line.type === "SectionLogLine" || line.type === "LegacyLogLine") &&
                    (!filterLogLinesWithoutTimestamps || (filterLogLinesWithoutTimestamps && line.timestamp))
                ) {
                    return (
                        <div key={i} className="flex items-start gap-3">
                            {line.timestamp && (
                                <span className="min-w-fit font-mono text-sm text-gray-500">
                                    {formatMediumTime(line.timestamp)}
                                </span>
                            )}
                            <span
                                className="font-mono text-sm"
                                dangerouslySetInnerHTML={{ __html: Anser.ansiToHtml(line.line) }}
                            />
                        </div>
                    );
                }
            })}
        </>
    );
};
