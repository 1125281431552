import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconVSCode: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_7_1053)">
                        <g clipPath="url(#clip1_7_1053)">
                            <path
                                d="M13.6906 3.19491L11.1705 1.98254C10.8773 1.8409 10.5285 1.90062 10.2992 2.12992L5.47235 6.53418L3.36879 4.939C3.17356 4.78932 2.89832 4.80157 2.71686 4.96695L2.04311 5.58098C1.81993 5.78234 1.81993 6.13338 2.0412 6.33474L3.86568 7.99959L2.0412 9.66444C1.81993 9.8658 1.81993 10.2168 2.04311 10.4182L2.71686 11.0322C2.90023 11.1976 3.17356 11.2099 3.36879 11.0602L5.47235 9.46308L10.3015 13.8693C10.5289 14.0986 10.8777 14.1583 11.1709 14.0166L13.6932 12.8024C13.9585 12.6749 14.1258 12.4077 14.1258 12.1125V3.88397C14.1258 3.59074 13.9562 3.32162 13.6913 3.19414L13.6906 3.19491ZM11.0645 10.7815L7.39981 7.99997L11.0645 5.21845V10.7815Z"
                                fill="currentColor"
                            />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_7_1053">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                        <clipPath id="clip1_7_1053">
                            <rect width="12.25" height="12.25" fill="white" transform="translate(1.875 1.875)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_7_1057)">
                        <path
                            d="M16.5034 4.5085L13.6234 3.12294C13.2882 2.96106 12.8897 3.02931 12.6276 3.29138L7.11118 8.32481L4.70712 6.50175C4.484 6.33069 4.16943 6.34469 3.96206 6.53369L3.19206 7.23544C2.937 7.46556 2.937 7.86675 3.18987 8.09688L5.275 9.99956L3.18987 11.9023C2.937 12.1324 2.937 12.5336 3.19206 12.7637L3.96206 13.4654C4.17162 13.6544 4.484 13.6684 4.70712 13.4974L7.11118 11.6721L12.6302 16.7078C12.8901 16.9698 13.2887 17.0381 13.6238 16.8762L16.5065 15.4884C16.8097 15.3428 17.0009 15.0374 17.0009 14.7001V5.296C17.0009 4.96088 16.8071 4.65331 16.5043 4.50763L16.5034 4.5085ZM13.5022 13.1789L9.314 10L13.5022 6.82113V13.1789Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_7_1057">
                            <rect width="14" height="14" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_7_1061)">
                        <path
                            d="M19.3164 5.82197L16.0764 4.26321C15.6993 4.0811 15.251 4.15789 14.9561 4.45271L8.75014 10.1153L6.04557 8.06438C5.79456 7.87193 5.44067 7.88768 5.20738 8.10031L4.34113 8.88978C4.05418 9.14867 4.05418 9.6 4.33867 9.85889L6.68443 11.9994L4.33867 14.1399C4.05418 14.3988 4.05418 14.8502 4.34113 15.1091L5.20738 15.8985C5.44314 16.1112 5.79456 16.1269 6.04557 15.9345L8.75014 13.8811L14.9591 19.5461C15.2514 19.841 15.6998 19.9177 16.0768 19.7356L19.3199 18.1744C19.661 18.0105 19.876 17.667 19.876 17.2875V6.70791C19.876 6.33089 19.658 5.98489 19.3174 5.82099L19.3164 5.82197ZM15.94 15.5761L11.2283 11.9999L15.94 8.42368V15.5761Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_7_1061">
                            <rect width="15.75" height="15.75" fill="white" transform="translate(4.125 4.125)" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
