import { Heading3 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useCallback, useState, type FC } from "react";

import { TemplateIllutrationPlaceholder } from "@/assets/icons/onboarding/TemplateIllustrationPlaceholder";
import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { CreateProjectFromEnvironmentModal } from "@/components/environments/onboarding/CreateProjectFromEnvironmentModal";
import { Button } from "@/components/flexkit/Button";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "@/hooks/use-onboarding";
import { ExternalLink } from "@/components/podkit/typography/Link";

export const ShareStep: FC<{ environmentId: string; completed: boolean }> = ({ environmentId, completed }) => {
    const [showProjectModal, setShowProjectModal] = useState(false);
    const navigate = useNavigate();
    const onboarding = useOnboarding();

    const onShowModal = useCallback(() => {
        setShowProjectModal(true);
    }, []);

    const onHideModal = useCallback(() => {
        setShowProjectModal(false);
    }, []);

    const onProjectCreated = useCallback(() => {
        const onboardingCompleted = onboarding.steps.steps.every((step) => step.completed);
        if (onboardingCompleted) {
            return;
        }
        navigate("/onboarding/share-with-your-team");
    }, [navigate, onboarding]);

    return (
        <>
            <div className="mt-8 flex flex-col items-center justify-between gap-4 overflow-hidden px-5 md:flex-row">
                <div className="flex flex-col items-center gap-4 md:flex-row">
                    <TemplateIllutrationPlaceholder />
                    <div className="flex shrink flex-col">{completed ? <ContentCompleted /> : <Content />}</div>
                </div>
                <div className="flex flex-col gap-4 lg:flex-row">
                    <ExternalLink href="https://www.gitpod.io/docs/flex/projects" iconSize="sm">
                        Docs
                    </ExternalLink>
                    <Button variant="primary" onClick={onShowModal}>
                        Create
                    </Button>
                </div>
            </div>
            {showProjectModal && (
                <CreateProjectFromEnvironmentModal
                    environmentId={environmentId}
                    onClose={onHideModal}
                    onSuccess={onProjectCreated}
                />
            )}
        </>
    );
};

const Content: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="text-base font-bold">Turn this environment into a project</Heading3>
            <Text className="text-sm text-content-secondary">
                Projects allow others to reuse your dev container configuration and automations.
            </Text>
        </div>
    );
};

const ContentCompleted: FC = () => {
    return (
        <div className="flex flex-col gap-2">
            <Heading3 className="flex items-center gap-1 text-base font-bold">
                <IconCheckFill className="text-content-positive" size="lg" />
                Created project
            </Heading3>
        </div>
    );
};
