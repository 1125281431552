import { EnvironmentLogs } from "@/components/EnvironmentLogs";
import { BackButton } from "@/components/environments/BackButton";
import { EnvironmentDetailsHeader } from "@/components/environments/EnvironmentDetailsHeader";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useGetTaskExecution } from "@/queries/automations-queries";
import { useEnvironment } from "@/queries/environment-queries";
import { type FC } from "react";
import { useParams } from "react-router-dom";

export const EnvironmentTaskRunLogsPage: FC = () => {
    useDocumentTitle("Task Run Logs");

    const { environmentId, taskId, runId } = useParams();

    const { data: environment } = useEnvironment(environmentId);
    const { data: taskExecution } = useGetTaskExecution(runId);

    const logUrl = taskExecution?.status?.logUrl;

    return (
        <div className="flex size-full flex-col">
            {environmentId && taskId && (
                <>
                    <div className="flex flex-col justify-between gap-4">
                        <EnvironmentDetailsHeader showActionBar={false} />

                        <div className="flex justify-between pb-4">
                            <BackButton />
                        </div>
                    </div>
                    <EnvironmentLogs
                        environment={environment}
                        logsUrl={logUrl}
                        filterLogLinesWithoutTimestamps={true}
                    />
                </>
            )}
        </div>
    );
};
