import webConfig from "@/web-config";
import { AnalyticsBrowser, type InitOptions, type AnalyticsBrowserSettings } from "@segment/analytics-next";
import { createContext } from "react";

export const SegmentContext = createContext<AnalyticsBrowser | undefined>(undefined);

export type SegmentContext = AnalyticsBrowser | undefined;

// eslint-disable-next-line react-refresh/only-export-components
export function createSegment() {
    const originURL = new URL(webConfig.origin);
    const segmentSettings: AnalyticsBrowserSettings = {
        cdnURL: webConfig.origin + "/segment",
        writeKey:
            originURL.host === "app.gitpod.io"
                ? "g5Dgcf8kFxV7EPvOp7gGcJhH0FAwE1GW"
                : "Gb48f7bya7dVEGSj4zlhB4Ssc3hYTpBS",
    };
    const segmentConfig: InitOptions = {
        integrations: {
            "Segment.io": {
                apiHost: originURL.host + "/segment/v1",
                protocol: originURL.protocol.replace(":", ""),
            },
        },
        highEntropyValuesClientHints: [
            "architecture",
            "bitness",
            "model",
            "platformVersion",
            "uaFullVersion",
            "fullVersionList",
            "wow64",
        ],
    };
    const analytics = AnalyticsBrowser.load(segmentSettings, segmentConfig);
    return analytics;
}
