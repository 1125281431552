import { CreateLogSection, EndLogSection, LogSectionOutcome } from "gitpod-next-api/gitpod/v1/environment_logs_pb.ts";
import { type PlainMessage } from "@bufbuild/protobuf";

const Tokens = {
    SectionCreate: "[section-create]",
    SectionLogLine: "[id:",
    SectionEnd: "[section-end]",
};

export const NO_LOG_GROUP = "no-log-group";

const SectionLogLineRegex = new RegExp(`^\\[id:([^\\]]+)\\] (.+)$`);
const LogLineWithTimestampAndLevelRegex = new RegExp(`^\\[([^\\]]+)\\] ([A-Za-z]+) (.+)$`);

export type PlainCreateLogSection = PlainMessage<CreateLogSection>;
export type PlainEndLogSection = PlainMessage<EndLogSection>;

export type LogGroup = {
    id: string;
    title: string;
    lines: LogLine[];
    continuous: boolean;
    outcome?: LogSectionOutcome;
    secondsElapsed?: number;
};

export type LogGroups = Record<string, LogGroup>;

export type LogLine = SectionCreate | SectionLogLine | SectionEnd | LegacyLogLine;
export type SectionCreate = { type: "SectionCreate" } & PlainCreateLogSection;
export type SectionLogLine = { type: "SectionLogLine"; id: string; line: string; timestamp?: Date; logLevel?: string };
export type SectionEnd = { type: "SectionEnd" } & PlainEndLogSection;
export type LegacyLogLine = { type: "LegacyLogLine"; line: string; timestamp?: Date; logLevel?: string };

export async function* parse(
    stream: ReadableStream<Uint8Array>,
    signal: AbortSignal,
): AsyncGenerator<LogLine[], void, void> {
    for await (const lines of streamTextLines(stream, signal)) {
        const logs: LogLine[] = [];
        for (const line of lines) {
            const parsed = parseLine(line.trim());
            if (parsed) {
                logs.push(parsed);
            }
        }
        yield logs;
    }
}

function parseLine(line: string): LogLine | undefined {
    if (line.trim() === "") {
        return;
    }

    // Removes any garbage before the log section meta-data
    const trimmed = line.trim().substring(line.indexOf("["));

    if (line.includes(Tokens.SectionCreate)) {
        return parseSectionCreate(trimmed);
    } else if (line.includes(Tokens.SectionLogLine)) {
        return parseSectionLogLine(trimmed);
    } else if (line.includes(Tokens.SectionEnd)) {
        return parseSectionEnd(trimmed);
    } else {
        return parseLegacyLogLine(line);
    }
}

function parseSectionCreate(line: string): SectionCreate | undefined {
    let metadata: PlainCreateLogSection | undefined;
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const json = JSON.parse(line.replace(Tokens.SectionCreate, ""));
        if (isSectionCreateJSON(json)) {
            metadata = CreateLogSection.fromJson(json);
        }
    } catch {
        return;
    }

    if (!metadata) {
        return;
    }

    return {
        type: "SectionCreate",
        ...metadata,
    };
}

function parseSectionLogLine(line: string): SectionLogLine | undefined {
    const groups = SectionLogLineRegex.exec(line);
    if (groups === null) {
        return;
    }

    const id = groups[1].trim();
    const rest = groups[2].trim();
    const { timestamp, logLevel, content } = parseTimestampAndLogLevel(rest);

    return {
        type: "SectionLogLine",
        id: id,
        timestamp: timestamp ? new Date(timestamp) : undefined,
        logLevel: logLevel,
        line: content,
    };
}

function parseLegacyLogLine(line: string): LogLine | undefined {
    return {
        type: "LegacyLogLine",
        line,
    };
}

/**
 * Attempts to parse a line with the format '[yyyy-mm-ddThh:mm:ssZ] <log level> <text>'
 * If it doesn't match the pattern it returns the entire line as the "content"
 */
function parseTimestampAndLogLevel(line: string): { timestamp?: string; logLevel?: string; content: string } {
    let content = line;
    let timestamp: string | undefined;
    let logLevel: string | undefined;
    const lineGroups = LogLineWithTimestampAndLevelRegex.exec(line);
    if (lineGroups != null) {
        timestamp = lineGroups[1];
        logLevel = lineGroups[2];
        content = lineGroups[3];
    }
    return { timestamp, logLevel, content };
}

function parseSectionEnd(line: string): SectionEnd | undefined {
    let metadata: PlainEndLogSection | undefined;
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const json = JSON.parse(line.replace(Tokens.SectionEnd, ""));
        if (isSectionEndJSON(json)) {
            // If the JSON contains a "success" boolean property, use that instead to compute the outcome (for backwards compatibility)
            if ("success" in json) {
                json.outcome = json.success ? LogSectionOutcome.SUCCESS : LogSectionOutcome.FAILURE;
                delete json.success;
            }

            metadata = EndLogSection.fromJson(json);
        }
    } catch {
        return;
    }

    if (!metadata) {
        return;
    }

    return {
        type: "SectionEnd",
        ...metadata,
    };
}

async function* streamTextLines(
    stream: ReadableStream<Uint8Array>,
    signal: AbortSignal,
): AsyncGenerator<string[], void, unknown> {
    const decoder = new TextDecoder();
    const reader = stream.getReader();

    // The stream chunks are not guaranteed to be line-aligned, so for each chunk we split it at the last newline and carry
    // over the remainder to the next chunk
    let remainder = "";
    try {
        while (true) {
            if (signal.aborted) {
                break;
            }

            const { done, value } = await reader.read();
            if (done) break;

            const decoded = remainder + decoder.decode(value);

            const lastNewlineIndex = decoded.lastIndexOf("\n");
            const chunk = decoded.substring(0, lastNewlineIndex);
            remainder = decoded.substring(lastNewlineIndex + 1);

            yield chunk.split("\n");
        }
        if (remainder) {
            yield [remainder];
        }
    } finally {
        reader.releaseLock();
    }
}

export function addLinesToLogGroups(logGroups: LogGroups, lines: LogLine[], maxLines: number): LogGroups {
    const newLogGroups = { ...logGroups };

    if (!newLogGroups[NO_LOG_GROUP]) {
        newLogGroups[NO_LOG_GROUP] = {
            id: NO_LOG_GROUP,
            title: "",
            continuous: false,
            lines: [],
        };
    }

    for (const line of lines) {
        switch (line.type) {
            case "SectionCreate":
                ensureInitialized(newLogGroups, line.id);
                newLogGroups[line.id].title = line.title;
                newLogGroups[line.id].continuous = line.continuous;
                break;
            case "SectionLogLine":
                ensureInitialized(newLogGroups, line.id);
                newLogGroups[line.id].lines.push(line);
                break;
            case "LegacyLogLine":
                newLogGroups[NO_LOG_GROUP].lines.push(line);
                break;
            case "SectionEnd":
                ensureInitialized(newLogGroups, line.id);
                newLogGroups[line.id].outcome = line.outcome;
                newLogGroups[line.id].secondsElapsed = line.secondsElapsed;
                break;
        }
    }

    // Only keep the last N lines
    for (const key in newLogGroups) {
        newLogGroups[key].lines = newLogGroups[key].lines.slice(-maxLines);
    }
    return newLogGroups;
}

function ensureInitialized(groups: LogGroups, id: string): void {
    if (!groups[id]) {
        groups[id] = {
            id: id,
            title: id,
            continuous: false,
            lines: [],
        };
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSectionCreateJSON(json: any): json is PlainCreateLogSection {
    return isString(json?.id) && isString(json?.title);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSectionEndJSON(json: any): json is PlainEndLogSection {
    return isString(json?.id) && (isBoolean(json?.success) || isEnum(json?.outcome));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isString(value: any): value is string {
    return typeof value === "string";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBoolean(value: any): value is string {
    return typeof value === "boolean";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEnum(value: any): value is string {
    return typeof value === "string";
}
