import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { Text } from "@/components/podkit/typography/Text";
import { formatError } from "@/utils/errors";
import { type Code, ConnectError } from "@connectrpc/connect";
import type { FC } from "react";

type ErrorMessageProps = PropsWithClassName & {
    error: Error | string | undefined | null;
    messages?: Partial<Record<Code, string>>;
    "data-testid"?: string;
};

/**
 * Renders Error (especially ConnectError) in a human-readable way
 *
 * If no error is supplied it doesn't render anything; so no need to conditionally render
 * this component
 */
export const ErrorMessage: FC<ErrorMessageProps> = ({ error, className, messages, "data-testid": testId }) => {
    if (!error) {
        return null;
    }

    const fallBackTestId = error instanceof ConnectError ? `error-message-connect-${error.code}` : "error-message";
    return (
        <Text className={cn("text-base text-content-negative", className)} data-testid={testId ?? fallBackTestId}>
            {formatError(error, messages)}
        </Text>
    );
};
