import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCommit: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 10.1875C9.20812 10.1875 10.1875 9.20812 10.1875 8C10.1875 6.79188 9.20812 5.8125 8 5.8125C6.79188 5.8125 5.8125 6.79188 5.8125 8C5.8125 9.20812 6.79188 10.1875 8 10.1875ZM8 11.5C9.70869 11.5 11.1314 10.2755 11.4386 8.65625H14.3438H15V7.34375H14.3438H11.4386C11.1314 5.72443 9.70869 4.5 8 4.5C6.29131 4.5 4.86859 5.72443 4.56142 7.34375H1.65625H1V8.65625H1.65625H4.56142C4.86859 10.2755 6.29131 11.5 8 11.5Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5ZM10 14C11.9528 14 13.5787 12.6006 13.9298 10.75H17.25H18V9.25H17.25H13.9298C13.5787 7.39935 11.9528 6 10 6C8.04721 6 6.42125 7.39935 6.0702 9.25H2.75H2V10.75H2.75H6.0702C6.42125 12.6006 8.04721 14 10 14Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 14.8125C13.5533 14.8125 14.8125 13.5533 14.8125 12C14.8125 10.4467 13.5533 9.1875 12 9.1875C10.4467 9.1875 9.1875 10.4467 9.1875 12C9.1875 13.5533 10.4467 14.8125 12 14.8125ZM12 16.5C14.1969 16.5 16.026 14.9257 16.421 12.8438H20.1562H21V11.1562H20.1562H16.421C16.026 9.07427 14.1969 7.5 12 7.5C9.80311 7.5 7.97391 9.07427 7.57897 11.1562H3.84375H3V12.8438H3.84375H7.57897C7.97391 14.9257 9.80311 16.5 12 16.5Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
