import { useCallback, useState, type FC } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Button } from "@/components/flexkit/Button";
import { useUpdateSecretValue, type PlainSecret } from "@/queries/secret-queries";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { EditSecretForm, type EditSecretFormOnSubmitInput } from "@/components/projects/EditSecretForm";
import { TrackLocations } from "@/hooks/use-segment";

export const EditSecretModal: FC<{ secret: PlainSecret; onClose: () => void }> = ({ secret, onClose }) => {
    const { toast } = useToast();
    const updateSecretValue = useUpdateSecretValue();

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const onSubmit = useCallback(
        async ({ secretValuePlaintext }: EditSecretFormOnSubmitInput) => {
            try {
                await updateSecretValue.mutateAsync(
                    {
                        secretId: secret.id,
                        plaintextValue: secretValuePlaintext,
                    },
                    {
                        onSuccess: () => {
                            toast({
                                title: `${secret.name} was successfully updated`,
                            });
                        },
                    },
                );
                onClose();
            } catch {
                toast({
                    title: "Failed to update secret",
                    description: "An error occurred while updating the secret",
                });
            }
        },
        [secret.id, secret.name, updateSecretValue, onClose, toast],
    );

    const [disabled, setDisabled] = useState<boolean>(true);

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent
                className="max-w-[600px]"
                data-testid="edit-secret-form-modal-content"
                data-track-location={TrackLocations.EditSecretModal}
            >
                <DialogHeader>
                    <DialogTitle>Edit secret</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody className="overflow-x max-w-full">
                    <ErrorMessage
                        error={updateSecretValue.error}
                        data-testid="error-message-secret-update-failed"
                        className="text-center"
                    />
                    <EditSecretForm
                        formName="edit-secret-form"
                        onSubmit={onSubmit}
                        onDisabledChange={setDisabled}
                        secret={secret}
                    />
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        variant="primary"
                        form="edit-secret-form"
                        loading={updateSecretValue.isPending}
                        disabled={updateSecretValue.isPending || disabled}
                        data-testid="edit-secret-submit-button"
                    >
                        Update
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
