import { ServiceCard } from "@/components/environments/ServiceCard";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { useListServices, type PlainService } from "@/queries/automations-queries";
import { useEnvironment } from "@/queries/environment-queries";
import { canOpen } from "@/routes/environments/phase";
import { type FC } from "react";

export const EnvironmentServices: FC<{ environmentId: string }> = ({ environmentId }) => {
    const { data: environment } = useEnvironment(environmentId);
    const { data: services, error } = useListServices(environmentId);

    const disabled = !environment || !canOpen(environment);
    const hasServices = (services?.length ?? 0) > 0;

    /** Empty state is handled in {@link EnvironmentAutomations} */
    if (!hasServices && !error) {
        return null;
    }

    return (
        <div className="duration-700 animate-in fade-in">
            <div className="flex items-center justify-between pb-4" translate="no">
                <Heading2 className="select-none text-xl font-bold text-content-primary">Services</Heading2>
            </div>
            <ErrorMessage error={error} />
            <Services environmentId={environmentId} services={services || []} disabled={disabled} />
        </div>
    );
};

const Services: FC<{
    services: PlainService[];
    environmentId: string;
    disabled: boolean;
}> = ({ services, environmentId, disabled }) => {
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            {services.map((service) => (
                <ServiceCard key={service.id} service={service} environmentId={environmentId} disabled={disabled} />
            ))}
        </div>
    );
};
