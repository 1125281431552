import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { IconDot } from "@/assets/icons/geist/IconDot";
import { IconSpinner } from "@/assets/icons/geist/IconSpinner";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { BackButton } from "@/components/environments/BackButton";
import { Button } from "@/components/flexkit/Button";
import type { PropsWithClassName } from "@/components/podkit/lib/cn";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/podkit/select/Select";
import { timeAgo } from "@/format/time";
import {
    useListTaskExecutions,
    useListTasks,
    type PlainTask,
    type PlainTaskExecution,
} from "@/queries/automations-queries";
import { Timestamp } from "@bufbuild/protobuf";
import { TaskExecutionPhase } from "gitpod-next-api/gitpod/v1/environment_automation_pb";
import { useCallback, useState, type FC } from "react";
import { useNavigate } from "react-router-dom";

export const EnvironmentTaskRuns: FC<{ environmentId: string; taskId?: string }> = ({ environmentId, taskId }) => {
    const { data: tasks, isLoading, error } = useListTasks(environmentId);
    const { data: taskRuns } = useListTaskExecutions(environmentId);
    const ready = (!isLoading && tasks !== undefined) || Boolean(error);

    const [taskFilter, setTaskFilter] = useState<string | undefined>(undefined);
    const [phaseFilter, setPhaseFilter] = useState<TaskExecutionPhase | undefined>(undefined);

    const runs = (taskId ? taskRuns?.filter((t) => t.metadata?.taskId === taskId) : taskRuns) || [];
    const filteredRuns = runs
        .filter((t) => !taskFilter || t.metadata?.taskId == taskFilter)
        .filter((t) => !phaseFilter || t.status?.phase === phaseFilter);
    const taskFilterItems = new Map(
        runs?.map((r) => [
            r.metadata?.taskId || r.id,
            tasks?.find((t) => t.id === r.metadata?.taskId)?.metadata?.name || r.metadata?.taskId || "",
        ]),
    );

    return (
        <>
            <div className="flex items-center justify-between">
                <BackButton />
                <div className="flex grow justify-end gap-2">
                    <FilterBox
                        placeholder="All Tasks"
                        className="w-3/12"
                        onChange={(value) => setTaskFilter(value)}
                        items={taskFilterItems}
                    />
                    <FilterBox
                        placeholder="All Status"
                        className="w-3/12"
                        onChange={(value) => setPhaseFilter(parseInt(value) as TaskExecutionPhase)}
                        items={
                            new Map(runs?.map((r) => [r.status?.phase.toString() || "", phaseName(r.status?.phase)]))
                        }
                    />
                </div>
            </div>
            <SkeletonBlock failed={Boolean(error)} ready={ready} className="h-20">
                <TaskRunCards tasks={tasks || []} runs={filteredRuns || []} />
            </SkeletonBlock>
        </>
    );
};

const TaskRunCards: FC<{ tasks: PlainTask[]; runs: PlainTaskExecution[] }> = ({ tasks, runs }) => {
    if (runs.length === 0) {
        return <>No tasks runs available!</>;
    }

    return (
        <div className="flex flex-col gap-2">
            {runs.map((run) => (
                <TaskRunCard key={run.id} task={tasks.find((t) => t.id === run.metadata?.taskId)} run={run} />
            ))}
        </div>
    );
};

const TaskRunCard: FC<{ task?: PlainTask; run: PlainTaskExecution }> = ({ task, run }) => {
    const navigate = useNavigate();
    const navigateToTask = useCallback(() => {
        navigate(`/details/${run.metadata?.environmentId}/task/${run.metadata?.taskId}/run/${run.id}`);
    }, [navigate, run.metadata?.environmentId, run.metadata?.taskId, run.id]);
    const lastRunTimestamp = run?.metadata?.createdAt;
    const lastRunTime = lastRunTimestamp && timeAgo(new Timestamp(lastRunTimestamp).toDate());

    return (
        <div className="group flex flex-row items-center gap-2 truncate rounded-xl border border-border-base bg-surface-glass p-4 hover:shadow">
            <div className="flex grow text-base">{task?.metadata?.name}</div>
            <div className="flex gap-2">
                {phaseIcon(run?.status?.phase)}
                <div className="text-color-secondary text-base">{lastRunTime || "not run yet"}</div>
            </div>
            <div className="ml-8 flex opacity-0 group-hover:opacity-100">
                <Button variant="secondary" onClick={navigateToTask} size="sm">
                    Logs
                </Button>
            </div>
        </div>
    );
};

function phaseName(phase?: TaskExecutionPhase) {
    switch (phase) {
        case TaskExecutionPhase.SUCCEEDED:
            return "Succeeded";
        case TaskExecutionPhase.FAILED:
            return "Failed";
        case TaskExecutionPhase.RUNNING:
            return "Running";
        case TaskExecutionPhase.PENDING:
            return "Pending";
        default:
            return "";
    }
}

function phaseIcon(phase?: TaskExecutionPhase) {
    switch (phase) {
        case TaskExecutionPhase.SUCCEEDED:
            return <IconCheckFill size="sm" className="text-content-positive" />;
        case TaskExecutionPhase.FAILED:
            return <IconWarning size="sm" className="text-content-negative" />;
        case TaskExecutionPhase.RUNNING:
            return <IconSpinner size="sm" className="animate-spin text-content-yield" />;
        default:
            return <IconDot size="sm" />;
    }
}

type FilterBoxProps = {
    initialValue?: string;
    items: Map<string, string>;
    placeholder: string;
    onChange?: (value: string) => void;
};

const FilterBox: FC<FilterBoxProps & PropsWithClassName> = ({ initialValue, placeholder, items, onChange }) => {
    const [value, setValue] = useState<string | undefined>(initialValue);
    const onValueChange = useCallback(
        (value: string) => {
            const newValue = value === "*" ? "" : value;
            setValue(newValue);
            if (onChange) {
                onChange(newValue);
            }
        },
        [onChange],
    );

    return (
        <Select value={value || ""} onValueChange={onValueChange}>
            <SelectTrigger className="w-3/12" loading={false}>
                <span className="inline-block truncate">
                    <SelectValue placeholder={placeholder}>{value ? items.get(value) : ""}</SelectValue>
                </span>
                <SelectContent className="bg-surface-glass">
                    <SelectGroup>
                        <SelectItem key={"reset-item"} value={"*"}>
                            <div className="flex w-full flex-row items-center justify-between pr-10">
                                <div>{placeholder}</div>
                            </div>
                        </SelectItem>
                        {Array.from(items.entries()).map(([id, label]) => (
                            <SelectItem key={"item-" + id} value={id}>
                                <div className="flex w-full flex-row items-center justify-between pr-10">
                                    <div>{label}</div>
                                </div>
                            </SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </SelectTrigger>
        </Select>
    );
};
